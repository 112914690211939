import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { TextInput } from '../../form-generator/components';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';

const CheckBoxGroup = ({
  title,
  groupKey,
  onAllCheck,
  options = [],
  values = [],
  searchable,
  onChange,
  optionColumnWidth = 6
}) => {
  const [query, setQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (!query) {
      return setFilteredOptions([]);
    }

    setFilteredOptions(
      options.filter(o => o.option.toLowerCase().includes(query.trim().toLowerCase())).map(o => o['option'])
    );
  }, [query]);

  return (
    <>
      <div className={'form-check'}>
        <input
          className="form-check-input"
          type="checkbox"
          style={styles.checkboxsm}
          checked={options.length === values.length}
          onChange={onAllCheck}
        />

        <label style={{ fontSize: 14 }} className="ml-1 form-check-label my-1">
          <b>{title}</b>
        </label>
      </div>
      <hr className="mt-1 mb-2" />

      {searchable && (
        <Row className="mb-2">
          <Col xs={12} className="px-5">
            <TextInput value={query} size={'sm'} hint="Search options here" onChange={e => setQuery(e.target.value)} />
          </Col>
          <Col className="text-right text-muted small px-5">
            <i>{values.length === options.length ? 'All' : values.length} options selected</i>
          </Col>
          {filteredOptions.length === 0 && (
            <Col xs={12}>
              <h6 className="text-center text-muted p-4 small">
                {!query ? 'Nothing to show. Type in the search box to view options.' : 'No matching options found!'}
              </h6>
            </Col>
          )}
        </Row>
      )}
      <Row className={'mb-4'}>
        {options.map(option => {
          const { option: label, value } = option;
          return (
            <Col key={label} xs={12} md={optionColumnWidth} hidden={searchable && !filteredOptions.includes(label)}>
              <div className={'form-check ml-3'}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  style={styles.checkboxsm}
                  checked={values.includes(value)}
                  value={value}
                  onChange={e => onChange(groupKey, value, e.target.checked)}
                />

                <label style={{ fontSize: 14 }} className="ml-1 form-check-label my-1">
                  <b>{label}</b>
                </label>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

const FilterSideBar = ({ show, onSideBarClose, filterOptions = [], onFilterApply, initialFilterValues }) => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  useEffect(() => setFilterValues(filterValues), [initialFilterValues]);

  const onAllCheckChange = (key, options, checked) => {
    filterValues[key] = checked ? options.map(o => o.value) : [];
    setFilterValues({ ...filterValues });
  };

  const onCheckboxOptionChange = (title, value, checked) => {
    const currentValues = [...filterValues[title]];
    if (checked) {
      currentValues.push(value);
    } else {
      currentValues.splice(currentValues.indexOf(value), 1);
    }

    filterValues[title] = currentValues;

    setFilterValues({ ...filterValues });
  };

  return (
    <SlidingSideBar visible={show} onClose={onSideBarClose} title="Filters">
      {filterOptions.map(option => (
        <CheckBoxGroup
          key={option.title}
          groupKey={option.key}
          title={option.title}
          options={option.options}
          values={filterValues[option.key]}
          onAllCheck={e => e && onAllCheckChange(option.key, option.options, e.target.checked)}
          searchable={option.searchable}
          onChange={onCheckboxOptionChange}
        />
      ))}

      <div className="text-right">
        <Button onClick={() => onFilterApply(filterValues)}>Apply</Button>
      </div>
    </SlidingSideBar>
  );
};

const styles = {
  checkboxsm: {
    height: 17,
    width: 17
  }
};

export default FilterSideBar;
