import { API_URL, ENDPOINTS } from './constants';
export const ABORT_ERROR = 'REQUEST_ABORTED';

export const makeApiRequests = async ({
  endpoint,
  requestBody,
  contentType = 'application/json',
  method = 'POST',
  stringify = true,
  signal
}) => {
  const token = localStorage.getItem('user-token');
  const headers = {
    Authorization: token ? `Bearer ${token}` : undefined
  };

  if (endpoint !== ENDPOINTS.FILE) {
    headers['Content-Type'] = contentType;
  }

  try {
    const response = await fetch(API_URL + endpoint, {
      method: method,
      body: requestBody ? (stringify ? JSON.stringify(requestBody) : requestBody) : undefined,
      headers,
      signal
    });

    const responseBody = await response.json();
    if (response.status < 200 || response.status >= 300) {
      if (response.status === 401) {
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1500);
        return { error: 'Your session has expired, please try logging in again!' };
      }
      return { error: responseBody['message'] || 'Oops something went wrong!' };
    } else {
      return { response: responseBody };
    }
  } catch (e) {
    if (e.name === 'AbortError') {
      return { error: ABORT_ERROR };
    }

    console.log(e);
    return { error: 'Oops something went wrong!' };
  }
};

export const readFile = async ({ fileName, mimeType }) => {
  const token = localStorage.getItem('user-token');
  try {
    const response = await fetch(API_URL + ENDPOINTS.FILE_STREAM_WITH_ID(fileName), {
      method: 'GET',
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined
      }
    });

    if (response.status < 200 || response.status >= 300) {
      if (response.status === 401) {
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1500);
        return { error: 'Your session has expired, please try logging in again!' };
      }
      return { error: 'Could not load file!' };
    } else {
      const blobFromResponse = await response.blob();
      const blobWithFileMimeType = new Blob([blobFromResponse], { type: mimeType });
      const returnValue = URL.createObjectURL(blobWithFileMimeType);
      return { response: returnValue };
    }
  } catch (e) {
    console.log(e);
    return { error: 'Oops something went wrong!' };
  }
};

export const deleteFileFromServer = file => {
  //we do not care for response
  makeApiRequests({
    endpoint: ENDPOINTS.FILE_WITH_ID(file.filename),
    method: 'DELETE'
  });
};

export const getCoordinatesOfAddress = async address => {
  try {
    const geocodeResponse = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${
        process.env.REACT_APP_MAP_KEY
      }`
    );

    if (!geocodeResponse.ok) {
      return;
    }

    const geocodeData = await geocodeResponse.json();

    if (geocodeData.results.length > 0) {
      return geocodeData.results[0].geometry.location;
    }
  } catch (geocodeError) {}
};

const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};
