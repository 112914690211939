import React, { useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import File from '../components/common/file';
import SupportTicket from '../components/common/icons/SupportTicket';
import Logo from '../components/common/Logo';
import FormGenerator from '../form-generator/FormGenerator';
import { makeApiRequests } from '../helpers/api';
import { BRAND_NAME, CLIENT_EMAIL, CLIENT_PHONE_NUMBER, ENDPOINTS, serviceForm } from '../helpers/constants';
import useKeypress from '../hooks/KeyPressHook';

const LegalCheck = ({ checked, onChange }) => (
  <div className="mx-3 my-4">
    <div className="form-check">
      <input
        id="legalCheck"
        className="form-check-input"
        type="checkbox"
        style={{
          height: 17,
          width: 17
        }}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
      />

      <label style={{ fontSize: 14 }} htmlFor="legalCheck" className="ml-1 form-check-label my-1">
        <b>
          Your home comes with a 1 year manufacturer's warranty against defects in construction* (Plumbing/Electrical)
        </b>
      </label>
    </div>
    <div className="mt-2">
      <span class="text-secondary midFont">
        *This does not cover cosmetic issues (loose trim, scratched paint, torn flooring, etc) These items are to be
        taken care of by you, the homeowner. If you are unsure if an item is covered under warranty, call our MHC
        Service Department.
        <br />
        In the case of an emergency, please contact {BRAND_NAME} after submitting this form at{' '}
        <a href={`tel:${CLIENT_PHONE_NUMBER}`}>{CLIENT_PHONE_NUMBER}</a> or{' '}
        <a href={`mailto:${CLIENT_EMAIL}`}>{CLIENT_EMAIL}</a>
      </span>
    </div>
  </div>
);
const RequestService = () => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [legalChecked, setLegalChecked] = useState(false);

  if (localStorage.getItem('user-token')) {
    return <Redirect from="/request_service" to="/" />;
  }

  const onRequestFormSubmit = async form => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    if (!legalChecked) {
      toast.error("Please check the manufacturer's warranty checkbox!");
      return;
    }

    setDisabled(true);

    //manage 'other' checkbox values
    [
      { otherField: 'locationProblemOther', checkBoxGroupField: 'locationOfProblem' },
      { otherField: 'typeOther', checkBoxGroupField: 'type' }
    ].forEach(({ otherField, checkBoxGroupField }) => {
      if (form[otherField]) {
        //remove Others
        form[checkBoxGroupField].splice(form[checkBoxGroupField].indexOf('Other'), 1);
        //add typed in value
        form[checkBoxGroupField].push(form[otherField]);
      }
    });

    form['files'] = uploadedFiles.map(f => f.id);
    const { error, response } = await makeApiRequests({ endpoint: ENDPOINTS.SERVICE_REQUEST_BASE, requestBody: form });
    if (error) {
      toast.error(error);
      setDisabled(false);
      return;
    }

    toast(`Request opened successfully!`, {
      type: 'success'
    });
    setTimeout(() => document.location.reload(), 2000);
  };

  window['onRequestFormSubmit'] = onRequestFormSubmit;

  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col xs={1} className="bg-dark d-block"></Col>
        <Col xs={11} className="p-3 p-md-4 bg-light">
          <Row className="flex-grow-1">
            <Col xs={12} md={12} className="">
              <Card>
                <Card.Header>
                  <div className="d-flex  align-items-center">
                    <Logo small />
                    <b className="ml-3 pl-3 d-inline largeFont border-left">
                      <SupportTicket className="mr-2" />
                      Open A New Service Request
                    </b>
                  </div>
                </Card.Header>
                <Card.Body className="p-2">
                  <FormGenerator formJson={serviceForm} />
                  <File
                    uploadedFiles={uploadedFiles}
                    onUploadedFilesChange={setUploadedFiles}
                    containerClassName="mx-3"
                  />
                  <LegalCheck checked={legalChecked} onChange={setLegalChecked} />
                  <Row>
                    <Col xs={12} className={`text-right mb-3 mt-2`}>
                      <Button
                        disabled={disabled}
                        onClick={() => document.getElementById('openaservicerequest').click()}
                        size="sm"
                        className="ml-2"
                      >
                        Submit Request
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default RequestService;
