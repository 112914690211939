import React from 'react';
import { Button } from 'react-bootstrap';
import CrmSearchItem from '../crm/CrmSearchItem';

const NoPotentialContacts = ({ onAddContactClick }) => {
  return (
    <div className="text-center ">
      <h6 className="smallFont">No potential buyers found for this unit. Tap on the button below to add one:</h6>
      <Button className="tinyFont" variant="success" size="sm" onClick={onAddContactClick}>
        Add a contact
      </Button>
    </div>
  );
};

const PotentialBuyersOverview = ({
  contacts = [],
  onAddContactClick,
  onRemoveContactClick,
  onChangeContactClick,
  activeContactId,
  onContactSelect
}) => {
  return (
    <>
      <div className="d-flex mb-2">
        <div className="flex-grow-1 align-self-center">
          <h6 className="mb-0">Potential Buyers</h6>
          {onContactSelect && <h6 className="mb-0 text-muted smallFont">Tap on a unit to select.</h6>}
        </div>
        {contacts.length > 0 && (
          <div className="align-self-center text-right">
            <Button className="tinyFont ml-1" variant="success" size="sm" onClick={onAddContactClick}>
              Add New Contact
            </Button>
          </div>
        )}
      </div>
      <div className="border rounded p-3 mb-2">
        {contacts.length > 0 ? (
          contacts.map(contact => (
            <CrmSearchItem
              key={contact['_id']}
              crm={contact}
              fromPicker
              onRemoveClick={onRemoveContactClick ? () => onRemoveContactClick(contact) : undefined}
              onChangeClick={onChangeContactClick ? () => onChangeContactClick(contact) : undefined}
              onContactClick={onContactSelect ? () => onContactSelect(contact) : undefined}
              selected={activeContactId === contact['_id']}
              mini
            />
          ))
        ) : (
          <NoPotentialContacts onAddContactClick={onAddContactClick ? () => onAddContactClick() : undefined} />
        )}
      </div>
    </>
  );
};

export default PotentialBuyersOverview;
