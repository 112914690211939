import React from 'react';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import CreateInventory from './CreateInventory';

const CreateSideBar = ({ show, onHide, appChoices, onInventoryAdd, prefillUnit, isDuplicating }) => {
  return (
    <SlidingSideBar fullScreen visible={show} onClose={onHide} title={'New Inventory Unit'}>
      {show && (
        <CreateInventory
          appChoices={appChoices}
          onInventoryAdd={onInventoryAdd}
          prefillUnit={prefillUnit}
          isDuplicating={isDuplicating}
        />
      )}
    </SlidingSideBar>
  );
};

export default CreateSideBar;
