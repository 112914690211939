import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ArrowDownCircle, ArrowUpCircle } from 'react-bootstrap-icons';
import { normalizeId } from '../helpers/utility';
import CheckBoxInput from './CheckBoxInput';
import TextInput from './TextInput';

const KeyValueInput = ({
  id,
  options = [],
  value: defaultValues = [],
  onChange,
  onCheckedChange,
  disabled = false,
  preValue,
  boxWidth = 6,
  required = false,
  hint = '',
  defaultHideOptions = true,
  size
}) => {
  const [hideComponent, setHideComponent] = useState(defaultHideOptions);

  if (preValue) defaultValues = preValue;

  //called after render only once
  useEffect(() => {
    internalOnChange();
  }, []);

  const internalOnChange = e => {
    //run internal functions here
    if (onChange && window[onChange]) {
      window[onChange](e);
    }

    if (e && e.target.type === 'checkbox') {
      internalCheckedChange(e);
    }
  };

  const internalCheckedChange = e => {
    if (onCheckedChange && window[onCheckedChange]) {
      window[onCheckedChange](e);
    }
  };

  return (
    <>
      <Row className={`mb-3 ${hideComponent ? 'd-none' : ''} ${required ? 'fg-key-value' : ''}`} id={id}>
        {options.map((option, index) => {
          const { key, type } = option;
          const preValueIndex = defaultValues ? defaultValues.findIndex(v => v.key === key) : -1;
          const preValue = preValueIndex !== -1 ? defaultValues[preValueIndex]['value'] : '';
          return (
            <>
              <Col xs={12} md={boxWidth} className="mt-2">
                <CheckBoxInput
                  id={`${id}-${normalizeId(key)}-key-value-check`}
                  showLabel
                  label={key}
                  defaultChecked={preValueIndex !== -1}
                  value={key}
                  size={size}
                  onChangeFunction={internalOnChange}
                  disabled={disabled}
                  checkboxStyle={{ width: '20px' }}
                  labelStyle={{ fontSize: '10px' }}
                />
              </Col>
              <Col xs={12} md={boxWidth} className="mt-2">
                <TextInput
                  variant={type}
                  value={preValue}
                  id={`${id}-${normalizeId(key)}-key-value`}
                  hint={`${key}${hint}`}
                  disabled={disabled}
                  size={size}
                  onChange={internalOnChange}
                  style={{ fontSize: '10px' }}
                />
              </Col>
              <Col xs={12}>
                <hr className="my-1" />
              </Col>
            </>
          );
        })}
      </Row>

      <h6 className="hover-underline smallFont text-primary mb-2" onClick={() => setHideComponent(!hideComponent)}>
        {hideComponent ? (
          <>
            Show Options <ArrowDownCircle size={13} />
          </>
        ) : (
          <>
            Hide Options <ArrowUpCircle size={13} />
          </>
        )}
      </h6>
    </>
  );
};

export default KeyValueInput;
