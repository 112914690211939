import React from 'react';
import { Modal } from 'react-bootstrap';
import FormGenerator from '../../form-generator/FormGenerator';
import HorizontalProgress from '../common/HorizontalProgress';
import { notificationFormJson } from './form';

const NotificationModal = ({ activeNotificationType, onHide, sendingNotification }) => (
  <Modal size="lg" show={activeNotificationType} onHide={onHide} centered backdrop="static">
    <Modal.Header closeButton={!sendingNotification}>
      <Modal.Title>
        <h6 className="mb-0">Notify {activeNotificationType}</h6>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="overflow-auto">
      <FormGenerator formJson={notificationFormJson} prefix="noti" />
      {sendingNotification && <HorizontalProgress text="Sending notification..." />}
    </Modal.Body>
  </Modal>
);

export default NotificationModal;
