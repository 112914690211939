import React from 'react';
import CachedResponse from '../../common/CachedResponse';
import SlidingSideBar from '../../SlidingSideBar/SlidingSideBar';
import PayrollRecord from './PayrollRecord';

const PayrollAddSidebar = ({ show, activeContract, onPayrollAdd, onHide }) => {
  return (
    <SlidingSideBar
      zIndex={2000}
      fullScreen
      visible={show}
      onClose={onHide}
      title={`Add New Payroll Bill ${activeContract ? `(${activeContract.buyer})` : ''}`}
    >
      {show && (
        <CachedResponse
          listEndpoint={'app-choices'}
          render={appChoices => (
            <PayrollRecord
              fromSidebar
              appChoices={appChoices}
              activeContract={activeContract}
              onPayrollAdd={onPayrollAdd}
            />
          )}
        />
      )}
    </SlidingSideBar>
  );
};

export default PayrollAddSidebar;
