import React from 'react';
import { Button } from 'react-bootstrap';

const SmallButton = ({ children, style = {}, className = '', ...props }) => {
  return (
    <Button size="sm" className={`py-1 ${className}`} style={{ fontSize: 12, ...style }} {...props}>
      {children}
    </Button>
  );
};

export default SmallButton;
