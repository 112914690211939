const installerFieldRows = [
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'exteriorSiding',
          title: 'Exterior Siding',
          type: 'radio',
          options: ['Hardboard', 'Vinyl', 'Hardilap', 'Metal']
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'spottedSection',
          title: 'Spotted Section',
          type: 'radio',
          options: ['Front', 'Back', 'Center']
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'perimeterBlocking',
          title: 'Perimeter Blocking',
          type: 'radio',
          options: ['Yes', 'No']
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'mortarBlocksRequired',
          title: 'Mortar Blocks Required',
          type: 'radio',
          options: ['Yes', 'No']
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          type: 'custom-html',
          html: `<h6 class='text-muted my-3'>Complete and check each item listed below. Make any comments if necessary. </h6><hr/>`
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'acCrossOver',
          title: 'A/C Crossover',
          type: 'radio',
          options: ['Yes', 'No']
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'waterCrossover',
          title: 'Water Crossover',
          type: 'radio',
          options: ['Yes', 'No']
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'electricalCrossover',
          title: 'Electrical Crossover',
          type: 'radio',
          options: ['Yes', 'No']
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'groundWireConnected',
          title: 'Ground Wire Connected',
          type: 'radio',
          options: ['Yes', 'No']
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'perimeterBlockingComplete',
          title: 'Perimeter Blocking Complete',
          type: 'radio',
          options: ['Yes', 'No']
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'tieDownsComplete',
          title: 'Tie Downs Complete',
          type: 'radio',
          options: ['Yes', 'No']
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 12,
        field: {
          id: 'tongueRemovedForReturn',
          title: 'Tongue Removed For Return',
          type: 'radio',
          options: ['Yes', 'No']
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'axelsAndTiredRemovedForReturn',
          title: 'Axels and Tires Removed for return',
          type: 'text'
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'quantityReturned',
          title: 'Quantity Returned',
          type: 'number'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'allBlocksChecked',
          title: 'All Blocks checked',
          type: 'radio',
          options: ['Yes', 'No']
        }
      },
      {
        xs: 12,
        default: 6,
        field: {
          id: 'doorsBlocked',
          title: 'Doors Blocked',
          type: 'radio',
          options: ['Yes', 'No']
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'ridgeCapAndFlashingInstalled',
          title: 'Ridge Cap And Flashing Installed',
          type: 'radio',
          options: ['Yes', 'No', 'NA']
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'allNailsRemovedAndHolesTarred',
          title: 'All nails removed and holes tarred',
          type: 'radio',
          options: ['Yes', 'No']
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'chimneyCap',
          title: 'Chimney Cap',
          type: 'radio',
          options: ['Yes', 'No', 'NA']
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'homeCorrectHeightOffGround',
          title: 'Home correct height off ground',
          type: 'radio',
          options: ['Yes', 'No']
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'trashCollected',
          title: 'Trash collected',
          type: 'radio',
          options: ['Yes', 'No']
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'toolsPickedUp',
          title: 'Tools Picked up',
          type: 'radio',
          options: ['Yes', 'No']
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'doorsWindowsLocked',
          title: 'Doors & Windows Locked',
          type: 'radio',
          options: ['Yes', 'No']
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'materialListCompleted',
          title: 'Material List Completed',
          type: 'radio',
          options: ['Yes', 'No']
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 12,
        field: {
          id: 'angleOfStraps',
          title: 'Angle of straps',
          type: 'text'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'comment',
          title: 'Please comment on any damages service will need to perform.',
          type: 'text-area'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'customerSatisfied',
          title: 'Customer is satisfied and has seen water level',
          type: 'radio',
          options: ['Yes', 'No', 'No customer on site']
        }
      }
    ]
  }
];

export const installerFormJson = {
  forms: [
    {
      name: 'Installer',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Save',
        show: true,
        onSubmit: 'onInstallerFormSubmit'
      },
      rows: installerFieldRows
    }
  ]
};
