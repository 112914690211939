import React from 'react';
import { XCircleFill } from 'react-bootstrap-icons';

const ListFilterText = ({ allListMode, onClearFilterClick }) => {
  return (
    <p className="tinyFont text-muted my-1 px-2">
      <i>
        Showing{' '}
        <b className="text-dark">
          {allListMode ? (
            'all'
          ) : (
            <>
              filtered <XCircleFill className="text-danger hover-light" onClick={onClearFilterClick} />
            </>
          )}
        </b>{' '}
        contracts
      </i>
    </p>
  );
};

export default ListFilterText;
