import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Check, Pen, X } from 'react-bootstrap-icons';
import TextInput from '../form-generator/components/TextInput';

const TextWithEdit = ({ text, onSubmit, preFix = '', required, onRequiredInvalidation, dateValue }) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(text);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (dateValue) {
      setValue(moment(dateValue).format('YYYY-MM-DD'));
    } else {
      setValue(text);
    }
  }, [text, dateValue]);

  const onValueSubmit = async () => {
    if (required && !value) {
      onRequiredInvalidation && onRequiredInvalidation();
      return;
    }

    setDisabled(true);
    onSubmit && (await onSubmit(value));
    setDisabled(false);
    setEditMode(false);
  };

  return editMode ? (
    <div className="d-flex align-items-center">
      {preFix}
      <div className="flex-grow-1 smallFont">
        {!dateValue ? (
          <TextInput size={'sm'} disabled={disabled} value={value} onChange={e => setValue(e.target.value)} />
        ) : (
          <Form.Group>
            <Form.Control
              size={'sm'}
              disabled={disabled}
              type="date"
              value={value}
              onChange={e => setValue(e.target.value)}
            />
          </Form.Group>
        )}
      </div>
      <div>
        <Check
          className={`ml-2 ${disabled ? 'text-muted' : 'text-success hover-light'}`}
          size={16}
          onClick={() => {
            if (!disabled) onValueSubmit();
          }}
        />
        <X
          className={`ml-2 ${disabled ? 'text-muted' : 'text-danger hover-light'}`}
          size={16}
          onClick={() => {
            if (!disabled) setEditMode(false);
          }}
        />
      </div>
    </div>
  ) : (
    <>
      {preFix}
      {text}
      <Pen
        className={`ml-2 ${disabled ? 'text-muted' : 'text-info hover-light'}`}
        size={12}
        onClick={() => {
          if (!disabled) setEditMode(true);
        }}
      />
    </>
  );
};

export default TextWithEdit;
