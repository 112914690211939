import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, ListGroup } from 'react-bootstrap';
import { Pin } from 'react-bootstrap-icons';
import TextInput from '../../form-generator/components/TextInput';
import { contractSearchPaths, inventoryPaths } from '../../helpers/constants';
import { search } from '../../helpers/search';

const searchPaths = [...contractSearchPaths, ...inventoryPaths.map(p => `inventoryUnit/${p}`)];
const allSalesLocationObject = { _id: 'all', location: 'All' };

const ContractList = ({ contracts = [], activeId = '', onSelect, hideSalesLocationFilter, appChoices }) => {
  const [query, setQuery] = useState('');
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [selectedSaleLocation, setSelectedSaleLocation] = useState(allSalesLocationObject);

  const salesLocations = useMemo(
    () => [allSalesLocationObject, ...appChoices.find(a => a.key === 'salesLocation').values],
    [appChoices]
  );

  useEffect(() => {
    const contractsByLocation = contracts.filter(
      c =>
        selectedSaleLocation._id === 'all' ||
        (c['salesLocation']?._id || c['salesLocation']) === selectedSaleLocation._id
    );

    setFilteredContracts(search(contractsByLocation, searchPaths, query, '_id'));
  }, [query, selectedSaleLocation, contracts]);

  return (
    <div className="px-2">
      <div className="d-flex mb-1">
        <div className="flex-grow-1 mr-1">
          <TextInput size="sm" hint="Quick search..." onChange={e => setQuery(e.target.value)} />
        </div>
        {!hideSalesLocationFilter && (
          <Dropdown>
            <Dropdown.Toggle size="sm" block variant="outline-primary" id="dropdown-basic">
              <Pin className="mr-3 align-text-bottom" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {salesLocations.map(s => (
                <Dropdown.Item
                  className={`${s._id === selectedSaleLocation._id ? 'bg-primary text-white' : ''}`}
                  onClick={() => setSelectedSaleLocation(s)}
                  key={s._id}
                >
                  {s.location}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      {!hideSalesLocationFilter && (
        <h6 className="mb-1 tinyFont text-muted text-right">
          <span className="text-dark">Sales Location:</span> {selectedSaleLocation?.location}
        </h6>
      )}
      <ListGroup>
        {filteredContracts.length > 0 ? (
          filteredContracts.map((c, i) => (
            <ListGroup.Item
              style={{ fontSize: 14 }}
              className={`py-1 text-${c['_id'] === activeId ? 'white' : 'dark'}`}
              key={c['_id']}
              action
              active={c['_id'] === activeId}
              onClick={() => onSelect && onSelect(c)}
            >
              {c['buyer']}
            </ListGroup.Item>
          ))
        ) : (
          <h6 className="py-5 text-center border rounded smallFont">No contracts to show </h6>
        )}
      </ListGroup>
    </div>
  );
};

export default ContractList;
