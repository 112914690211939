import React, { useState } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import CachedResponse from '../common/CachedResponse';
import NotFound from '../NotFound';
import BrowseInventory from './BrowseInventory';
import CreateInventory from './CreateInventory';
import InventoryDetails from './InventoryDetails';
import SiteMap from './SiteMap';

const Inventory = () => {
  const [role] = useState(localStorage.getItem('user-role'));

  const getActivePage = () => {
    return (
      <Switch>
        <Route exact path="/inventory/create">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <CreateInventory appChoices={appChoices} />}
          />
        </Route>
        <Route exact path="/inventory/browse">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <BrowseInventory appChoices={appChoices} />}
          />
        </Route>
        <Route exact path="/inventory/site-map">
          <SiteMap />
        </Route>
        <Route exact path="/inventory/:id">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <InventoryDetails appChoices={appChoices} />}
          />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  };

  if (!['sales manager', 'sales', 'super admin', 'admin', 'service', 'inventory manager'].includes(role)) {
    return <NotFound text="You are not authorized to view this page" />;
  }

  return getActivePage();
};

export default withRouter(Inventory);
