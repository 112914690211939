import React from 'react';
import { Check, X, Dash } from 'react-bootstrap-icons';

const ThreeStateSwitch = ({ option, optionId, name, preValue = 'na', disabled }) => {
  return (
    <div className="switch-toggle  text-dark">
      <input
        disabled={disabled}
        id={`${optionId}-on`}
        name={name}
        type="radio"
        value="yes"
        defaultChecked={preValue === 'yes'}
      />
      <label className={`label-on ${disabled ? '' : 'label-on-hover'}`} htmlFor={`${optionId}-on`}>
        <Check size={20} className="align-text-bottom" />
      </label>

      <input
        disabled={disabled}
        id={`${optionId}-na`}
        name={name}
        type="radio"
        defaultChecked={preValue === 'na'}
        value="na"
      />
      <label className={`label-na ${disabled ? '' : 'label-na-hover'}`} htmlFor={`${optionId}-na`}>
        <Dash size={20} className="align-text-bottom" />
      </label>

      <input
        disabled={disabled}
        id={`${optionId}-off`}
        name={name}
        type="radio"
        defaultChecked={preValue === 'no'}
        value="no"
      />
      <label className={`label-off ${disabled ? '' : 'label-off-hover'}`} htmlFor={`${optionId}-off`}>
        <X size={20} className="align-text-bottom" />
      </label>
    </div>
  );
};

export default ThreeStateSwitch;
