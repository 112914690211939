import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Badge, FormControl } from 'react-bootstrap';
import { GripVertical } from 'react-bootstrap-icons';
import { APP_URL } from '../../helpers/constants';
import { groupEntityTableValues } from '../../helpers/global';
import AppTable from '../common/app-table/AppTable';

const reorder = (list, startIndex, endIndex, groupBy, groupValues = []) => {
  const result = cloneDeep(list);

  const itemAboveDestination = result[endIndex - 1];
  const previousItemAtDestination = result[endIndex];

  const [removed] = result.splice(startIndex, 1);

  if (groupBy) {
    const groupOfDestination = previousItemAtDestination.isHeader
      ? endIndex === 0 || endIndex > startIndex
        ? //we are moving below
          previousItemAtDestination.content
        : //we are going above
          itemAboveDestination?.[groupBy]
      : previousItemAtDestination[groupBy];

    if (!groupOfDestination || !groupValues.includes(groupOfDestination)) {
      removed[groupBy] = '';
      result.splice(endIndex, 0, removed);
    } else {
      result.splice(endIndex, 0, { ...removed, [groupBy]: groupOfDestination });
    }
  } else {
    result.splice(endIndex, 0, removed);
  }

  return result.filter(r => !r.isHeader);
};

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? '#f7ebd6' : 'white',
  ...draggableStyle
});

const getValue = (admin, header) => {
  let value = header.key === 'salesLocationName' ? admin.salesLocation?.location : admin[header.key];

  if (header.type !== 'boolean' && !value) return '-';
  if (Array.isArray(value) && !value.length) return '-';

  if (Array.isArray(value) && header.key !== 'videoUrls') {
    value = value.join(', ');
  }

  let returnElement = typeof value === 'object' ? value[header.objectKey] : value;

  if (header.key === 'videoUrls') {
    value = value.map((e, i) => {
      return (
        <a href={e} target="_blank">
          {i !== 0 && ','}Link {i + 1}{' '}
        </a>
      );
    });

    return value;
  }

  switch (header.type) {
    case 'email':
      returnElement = (
        <a onClick={e => e.stopPropagation()} href={`mailto:${value}`}>
          {value}
        </a>
      );

      break;
    case 'phone':
      returnElement = (
        <a onClick={e => e.stopPropagation()} href={`tel:${value}`}>
          {value}
        </a>
      );
      break;
    case 'date':
      returnElement = moment(value).format('MMM DD YYYY');
      break;
    case 'boolean':
      returnElement = returnElement ? 'Yes' : 'No';
      break;
    case 'number':
      returnElement = returnElement.toFixed(header.fixedDigits || 2);
      break;
    case 'appLink':
      returnElement = (
        <a target="_blank" href={`${APP_URL}${value}`}>
          {value?.toString()?.replace('[a-f\\d]{24}', '<id>')}
        </a>
      );
      break;
  }

  if (header.showAsBadge) {
    returnElement = (
      <Badge className="p-1 px-2" style={{ fontSize: 12 }} variant={header.badgeVariant || 'primary'}>
        {returnElement}
      </Badge>
    );
  }

  if (header.linkToKey) {
    let url = admin[header.linkToKey] || '';

    if (header.key !== 'videoUrls') {
      if (!url?.startsWith('https://') && !url.startsWith('http://')) {
        url = 'https://' + url;
      }
    }

    returnElement = (
      <a target="_blank" href={url}>
        {returnElement}
      </a>
    );
  }

  return returnElement;
};

const EntityTable = ({
  headers,
  data = [],
  rowKeyField,
  searchQueryKeys = [],
  actionCell,
  additionalComponents,
  additionalHeaderComponentForGroupValues,
  toggleShowMoreSection = null,
  groupBy,
  groupValues,
  draggable,
  onListSort,
  expandedRowKeys = [],
  renderExpandedRow,
  onRowClick,
  quickSearch = true,
  showActions = true,
  salesLocation
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [query, setQuery] = useState('');
  const role = localStorage.getItem('user-role');

  console.log('sales location', data);

  const effectiveHeaders = useMemo(() => {
    let updatedHeaders = headers;
    if (!salesLocation) {
      updatedHeaders = [...updatedHeaders, { name: 'Sales Location Name', key: 'salesLocationName' }];
    }
    return showActions ? updatedHeaders : updatedHeaders.filter(header => header.key !== 'actions');
  }, [headers, salesLocation, showActions]);

  const allowDragging = useMemo(() => draggable && !query.trim(), [draggable, query]);

  useEffect(() => {
    if (!salesLocation) {
      const updatedData = data.map(item => {
        const salesLocationName = item?.salesLocation?.location;
        return {
          ...item,
          salesLocationName
        };
      });
      setFilteredData(updatedData);
    } else {
      setFilteredData(data);
    }
  }, [data, salesLocation]);

  useEffect(() => {
    const q = query.trim().toLowerCase();

    if (q.length === 0) {
      setFilteredData(groupEntityTableValues(data, groupBy, groupValues));
      return;
    }

    const filteredDatabyQuery =
      data &&
      data.filter(d =>
        searchQueryKeys.some(
          k =>
            d[k] &&
            d[k]
              .trim()
              .toLowerCase()
              .includes(q)
        )
      );

    setFilteredData(groupEntityTableValues(filteredDatabyQuery, groupBy, groupValues));
  }, [query, data]);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    let movedItems = reorder(filteredData, result.source.index, result.destination.index, groupBy, groupValues);
    setFilteredData(groupEntityTableValues(movedItems, groupBy, groupValues));
    onListSort && onListSort(movedItems);
  };

  return (
    <>
      <div className="d-flex mb-1">
        {quickSearch && (
          <div className="flex-grow-1">
            <FormControl
              size={'sm'}
              placeholder="Quick search..."
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        )}
        {additionalComponents && <div className="ml-2">{additionalComponents()}</div>}
      </div>
      <AppTable>
        <thead>
          <tr className="text-white bg-dark">
            {allowDragging && <th></th>}
            {headers.map(({ name, minWidth = 100 }) => (
              <th style={{ minWidth }} key={name}>
                {name}
              </th>
            ))}
            {showActions && actionCell && <th style={{ minWidth: 100 }}>Actions</th>}
          </tr>
        </thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable isDropDisabled={!allowDragging} droppableId="droppable">
            {provided => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {filteredData?.length > 0 ? (
                  <>
                    {filteredData.map((dataObj, index) => {
                      return (
                        <>
                          {(!groupBy ||
                            dataObj.isHeader ||
                            (toggleShowMoreSection && toggleShowMoreSection[dataObj[groupBy] || 'Others'])) && (
                            <Draggable
                              isDragDisabled={dataObj.isHeader || !allowDragging}
                              key={dataObj[rowKeyField]}
                              draggableId={'q-' + dataObj[rowKeyField]}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <tr
                                  ref={provided.innerRef}
                                  onClick={() => !dataObj.isHeader && onRowClick && onRowClick(dataObj)}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={
                                    dataObj.isHeader
                                      ? 'bg-primary-light text-dark'
                                      : `${onRowClick ? 'hover-light' : ''}`
                                  }
                                  style={{
                                    ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                    zIndex: 0
                                  }}
                                >
                                  {/* Group Header */}
                                  {dataObj.isHeader ? (
                                    <td colSpan={headers.length + (actionCell ? 1 : 0) + (allowDragging ? 1 : 0)}>
                                      {additionalHeaderComponentForGroupValues &&
                                        additionalHeaderComponentForGroupValues(dataObj.content)}
                                    </td>
                                  ) : (
                                    <>
                                      {allowDragging && (
                                        <td style={{ width: '5%' }}>
                                          <GripVertical />
                                        </td>
                                      )}
                                      {headers.map(header => (
                                        <td key={header.name}>{getValue(dataObj, header)}</td>
                                      ))}
                                      {showActions && actionCell && <td>{actionCell(dataObj)}</td>}
                                    </>
                                  )}
                                </tr>
                              )}
                            </Draggable>
                          )}
                          {expandedRowKeys.includes(dataObj[rowKeyField]) && (
                            <tr>
                              <td
                                colSpan={headers.length + (actionCell ? 1 : 0) + (allowDragging ? 1 : 0)}
                                className="bg-light bg-opacity-50 p-0"
                                style={{ zIndex: 0 }}
                              >
                                <div style={{ width: '90vw' }} className="">
                                  {renderExpandedRow && renderExpandedRow(dataObj)}
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={actionCell ? headers.length + 1 : headers.length}>
                      <h6 className="text-muted text-center mb-0">Nothing to show</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </AppTable>
    </>
  );
};

export default EntityTable;
