import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, inventorySearchFilterOptions, inventorySearchSortByOptions } from '../../helpers/constants';
import NotFound from '../NotFound';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import HorizontalProgress from '../common/HorizontalProgress';
import SmallButton from '../common/SmallButton';
import SearchBox from '../common/searchbox';
import CreateSideBar from './CreateSidebar';
import EditInventory from './EditInventory';
import InventorySearchItem from './InventorySearchItem';

const SearchInventory = ({
  fromPicker = false,
  onSelect,
  appChoices,
  fetchedInventories,
  excludedUnitIds = [],
  selectedUnits,
  fromMap
}) => {
  const [role] = useState(localStorage.getItem('user-role'));
  const [loading, setLoading] = useState(true);
  const [inventories, setInventories] = useState(fetchedInventories ? fetchedInventories : []);
  const [addInventoryPopupMeta, setAddInventoryPoputMeta] = useState(null);
  const [editingInventory, setEditingInventory] = useState(null);

  const onAddInventoryPopupMetaChange = data => {
    setAddInventoryPoputMeta(data);
  };

  const onAddInvetoryPopupMetaClose = () => {
    setAddInventoryPoputMeta(null);
  };

  const fetchInventory = async ({ sortBy, maxLimit, descSort, filters, query }) => {
    setLoading(true);
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.INVENTORY_SEARCH_NEW,
      requestBody: {
        filter: filters,
        query,
        pageSize: maxLimit === 'All' ? undefined : maxLimit,
        sort: { [sortBy]: descSort ? -1 : 1 },
        includeContract: true,
        includeContact: !fromPicker
      }
    });
    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setInventories(
      response.results
        .filter(u => !excludedUnitIds.includes(u._id))
        .map(i => ({
          ...i,
          inventoryStatus: i['contract'] ? 'Contracted' : i['isRSO']?.toLowerCase() === 'yes' ? 'RSO' : 'Stock'
        }))
    );
  };

  const onInventoryEditClick = inventory => {
    setEditingInventory({ ...inventory, ID: inventory['_id'] });
  };

  const onInventoryEdit = inventory => {
    const index = inventories.findIndex(c => c['_id'] === inventory['_id']);
    if (index !== -1) {
      inventories[index] = inventory;
      setInventories([...inventories]);
    }
  };
  const updateInventoryList = inventory => {
    if (inventory) {
      setInventories([inventory, ...inventories]);
      onAddInvetoryPopupMetaClose();
    }
  };

  const onInventoryDelete = inventoryId => {
    const index = inventories.findIndex(c => c['_id'] === inventoryId);
    if (index !== -1) {
      inventories.splice(index, 1);
      setInventories([...inventories]);
    }
    setEditingInventory(null);
  };

  console.log('inventories', inventories);

  const InventoryEditSideBar = () => {
    return (
      <SlidingSideBar
        fullScreen
        visible={editingInventory != null}
        onClose={onInventoryEditSideBarHide}
        title={'Inventory Details'}
      >
        {editingInventory && (
          <EditInventory
            id={editingInventory['_id']}
            inventoryFromSearch={editingInventory}
            appChoices={appChoices}
            fromSearch
            onInventoryDelete={onInventoryDelete}
            onInventoryEdit={onInventoryEdit}
            onSearchInventoryFileLoaded={onInventoryFileLoaded}
          />
        )}
      </SlidingSideBar>
    );
  };

  const onInventoryEditSideBarHide = () => {
    setEditingInventory(null);
  };

  const onInventoryFileLoaded = (inventoryId, fileNames) => {
    const index = inventories.findIndex(c => c['_id'] === inventoryId);
    if (index !== -1) {
      inventories[index]['fileNames'] = fileNames;
      setInventories([...inventories]);
    }
  };

  return (
    <>
      {/* Add Inventory SideBar */}

      <CreateSideBar
        onInventoryAdd={updateInventoryList}
        appChoices={appChoices}
        show={addInventoryPopupMeta !== null}
        onHide={() => onAddInvetoryPopupMetaClose()}
        prefillUnit={addInventoryPopupMeta}
        isDuplicating={addInventoryPopupMeta?.isDuplicating}
      />

      <Container fluid className={'h-100 ' + (fromPicker ? 'px-0' : 'py-3 px-md-3 ')}>
        {!fetchedInventories && (
          <>
            <SearchBox
              appChoices={appChoices}
              filterCreationOptions={inventorySearchFilterOptions}
              sortByOptions={inventorySearchSortByOptions}
              maxLimitOptions={[10, 20, 50, 100, 200, 'All']}
              defaultMaxLimit={fromPicker ? 50 : 100}
              onSearchOptionsChange={fetchInventory}
              disabled={loading}
            />
            {fromPicker && (
              <div className="d-flex justify-content-end">
                <SmallButton
                  onClick={() => onAddInventoryPopupMetaChange({})}
                  style={{ marginRight: '10px' }}
                  variant="success"
                >
                  Create New Unit
                </SmallButton>
              </div>
            )}

            {loading && <HorizontalProgress text={'Fetching units...'} />}
            <hr />
          </>
        )}
        <div className="px-md-3">
          {inventories.length > 0
            ? inventories.map((inventory, index) => {
                return (
                  <InventorySearchItem
                    key={index}
                    mini={fromPicker}
                    inventory={inventory}
                    onInventoryClick={() => {
                      if (fromPicker) {
                        onSelect(inventory);
                      } else {
                        onInventoryEditClick(inventory);
                      }
                    }}
                    selected={selectedUnits.map(i => i._id).includes(inventory._id)}
                    fromPicker={fromPicker}
                    role={role}
                    onAddInventoryPopupMetaChange={unit =>
                      onAddInventoryPopupMetaChange({ ...unit, isDuplicating: true })
                    }
                    fromMap={fromMap}
                  />
                );
              })
            : !loading && <NotFound text="No units found" />}
        </div>
        {!fromPicker && InventoryEditSideBar()}
      </Container>
    </>
  );
};

export default SearchInventory;
