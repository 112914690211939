import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FileEarmark, Folder2, Trash } from 'react-bootstrap-icons';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import FilePopup from './FilePopup';

const FileRow = ({ file, onFileClick, onFileDeleteClick }) => {
  return (
    <div onClick={onFileClick} className="p-1 hover-light">
      <div className="d-flex">
        <div className="flex-grow-1 midFont">
          <FileEarmark className="mr-2" />
          <span className="align-middle">
            {file.metadata && file.metadata.originalName ? file.metadata.originalName : file.filename}
          </span>
        </div>
        <div
          onClick={e => {
            e.stopPropagation();
            onFileDeleteClick();
          }}
          className="mx-1 hover-dark"
        >
          <Trash className="text-danger" />
        </div>
      </div>
      <h6 className="smallFont pl-4 mb-0 text-muted">{moment(file['uploadDate']).format('MMMM Do YYYY hh:mm a')}</h6>
      <hr className="my-2" />
    </div>
  );
};

const FileViewer = ({ files = [], show, onHide, sidebarZIndex, onFileDeleteClick, driveFolder, sections }) => {
  const [visibleFile, setVisibleFile] = useState();
  const reversedFiles = useMemo(() => [...files].reverse(), [files]);

  return (
    <>
      <SlidingSideBar zIndex={sidebarZIndex} visible={show} onClose={onHide} title={'View Files'}>
        {driveFolder && (
          <>
            <div className="text-right mb-1">
              <a target="_blank" href={`https://drive.google.com/drive/folders/${driveFolder}`}>
                <Button size="sm" className="px-2 py-1">
                  <Folder2 />
                  <span className="align-middle ml-2">Open Drive</span>
                </Button>
              </a>
            </div>
            <hr className="my-2" />
          </>
        )}

        {reversedFiles.length > 0 ? (
          reversedFiles.map((f, index) => (
            <>
              {f?.classifier && (
                <>
                  <h6>{f?.classifier}</h6>
                </>
              )}
              <FileRow
                key={f._id}
                file={f}
                onFileClick={() => setVisibleFile(f)}
                onFileDeleteClick={() => onFileDeleteClick({ ...f, payrollId: f?.payrollId })}
              />
            </>
          ))
        ) : (
          <p className="p-5 text-center">No files to show!</p>
        )}
        {/* {files?.length !== 0 &&
          sections &&
          allEmptySections.map(emptySection => (
            <h6>
              {emptySection?.title}
              {'  '}
              <span className="tinyFont">(No Files Found)</span>
            </h6>
          ))} */}
      </SlidingSideBar>
      {visibleFile && <FilePopup file={visibleFile} onHide={() => setVisibleFile()} />}
    </>
  );
};

export default FileViewer;
