import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Route, Switch, withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import NotFound from '../../components/NotFound';
import CachedResponse from '../common/CachedResponse';
import BillbackExplorer from './billback/BillbackExplorer';
import RecordBillbackReceipt from './billback/RecordBillbackReceipt';
import RequestBillback from './billback/RequestBillback';
import CashDeal from './CashDeal';
import DeliverySetupReports from './DeliverySetupReports';
import FloorplanUpdate from './FloorplanUpdate';
import FormT from './FormT';
import MHIT from './MHIT';
import PayrollRecord from './payroll/PayrollRecord';
import PayrollExplorer from './payroll/PayrollExplorer';
import RebateExplorer from './rebate/RebateExplorer';
import RebateReport from './rebate/RebateReport';
import RecordReceipt from './rebate/RecordReceipt';
import RequestRebate from './rebate/RequestRebate';
import SOL from './Sol';
import PnL from './PnL';
import Curtailments from './Curtailments';
import RequestACRebate from './ac-rebate/RequestACRebate';
import RecordACReceipt from './ac-rebate/RecordACReceipt';
import ACRebateReport from './ac-rebate/ACRebateReport';
import ACRebateExplorer from './ac-rebate/ACRebateExplorer';

const Reports = () => {
  const [role] = useState(localStorage.getItem('user-role'));
  const location = useLocation();

  if (
    !(location.pathname.includes('billback') ? ['super admin', 'admin', 'service'] : ['super admin', 'admin']).includes(
      role
    )
  ) {
    return <NotFound text="You are not authorized to view this page" />;
  }

  const getActivePage = () => {
    return (
      <Switch>
        <Route exact path="/reports/form-t">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <FormT appChoices={appChoices} />} />
        </Route>
        <Route exact path="/reports/sol">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <SOL appChoices={appChoices} />} />
        </Route>
        {/* Rebate */}
        <Route exact path="/reports/request-rebate">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <RequestRebate appChoices={appChoices} />}
          />
        </Route>
        <Route exact path="/reports/record-receipt">
          <RecordReceipt />
        </Route>
        <Route exact path="/reports/rebate-report">
          <RebateReport />
        </Route>
        <Route exact path="/reports/explore-rebate">
          <RebateExplorer />
        </Route>
        {/* AC Rebate */}
        <Route exact path="/reports/request-ac-rebate">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <RequestACRebate appChoices={appChoices} />}
          />
        </Route>
        <Route exact path="/reports/record-ac-receipt">
          <RecordACReceipt />
        </Route>
        <Route exact path="/reports/ac-rebate-report">
          <ACRebateReport />
        </Route>
        <Route exact path="/reports/explore-ac-rebate">
          <ACRebateExplorer />
        </Route>

        {/* Billback */}
        <Route exact path="/reports/request-billback">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <RequestBillback appChoices={appChoices} />}
          />
        </Route>
        <Route exact path="/reports/record-billback-receipt">
          <RecordBillbackReceipt />
        </Route>
        <Route exact path="/reports/explore-billback">
          <BillbackExplorer />
        </Route>
        <Route exact path="/reports/mhit-report">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <MHIT appChoices={appChoices} />} />
        </Route>
        <Route exact path="/reports/delivery-setup-report">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <DeliverySetupReports appChoices={appChoices} />}
          />
        </Route>
        <Route exact path="/reports/explore-payrolls">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <PayrollExplorer appChoices={appChoices} />}
          />
        </Route>
        <Route exact path="/reports/record-payroll">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <PayrollRecord appChoices={appChoices} />}
          />
        </Route>
        <Route exact path="/reports/floorplan-update">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <FloorplanUpdate appChoices={appChoices} />}
          />
        </Route>
        <Route exact path="/reports/curtailments">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <Curtailments appChoices={appChoices} />}
          />
        </Route>
        <Route exact path="/reports/cash-deal">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <CashDeal appChoices={appChoices} />} />
        </Route>
        <Route exact path="/reports/pnl">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <PnL appChoices={appChoices} />} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  };

  return (
    <>
      <Container fluid className="px-3 h-100">
        {getActivePage()}
      </Container>
    </>
  );
};

export default withRouter(Reports);
