import React from 'react';
import Select, { components } from 'react-select';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const compareValues = (a, b, type) => {
  if (a === null || a === undefined) return 1;
  if (b === null || b === undefined) return -1;

  switch (type) {
    case 'alpha':
      return a.localeCompare(b);
    case 'number':
      return a - b;
    case 'date':
      return new Date(a) - new Date(b);
    default:
      return 0;
  }
};

const getNestedValue = (obj, value, key) => {
  if (obj[value] === undefined || obj[value] === null) return null;

  return key ? obj[value][key] : obj[value];
};

export const multiSortArray = (arr, sortOptions) => {
  if (!sortOptions?.length) return arr;

  return arr.sort((a, b) => {
    for (const option of sortOptions) {
      let aValue = option.isObject ? getNestedValue(a, option.value, option.key) : a[option.value];
      let bValue = option.isObject ? getNestedValue(b, option.value, option.key) : b[option.value];
      if (option.defaultValue !== undefined) {
        if (aValue === null || aValue === undefined) aValue = option.defaultValue;
        if (bValue === null || bValue === undefined) bValue = option.defaultValue;
      }
      const comparison = compareValues(aValue, bValue, option.type);

      // Invert order for all types except 'alpha'
      if (option.type !== 'alpha' && comparison !== 0) {
        return -comparison;
      }

      if (comparison !== 0) {
        return comparison;
      }
    }

    return 0; // If all properties are equal
  });
};

function arrayMove(array, from, to) {
  const slicedArray = array.slice();
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);
  return slicedArray;
}

const SortableMultiValue = SortableElement(props => {
  const onMouseDown = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle(props => {
  // Retrieve the index of the current item and add 1

  return <span>{props.children}</span>;
});
const SortableSelect = SortableContainer(Select);

const MultiSelectSort = ({ disabled, sortOptions = [], selectedSortOptions = [], onSelectedSortOptionsChange }) => {
  const onChange = selectedOptions => onSelectedSortOptionsChange(selectedOptions);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selectedSortOptions, oldIndex, newIndex);
    onSelectedSortOptionsChange(newValue);
  };

  return (
    <SortableSelect
      useDragHandle
      isDisabled={disabled}
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti
      options={sortOptions}
      value={selectedSortOptions}
      onChange={onChange}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel
      }}
      closeMenuOnSelect={false}
    />
  );
};
export default MultiSelectSort;
