import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { ArrowLeft, ArrowRight, Pencil } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, numbersOnlyFields } from '../../helpers/constants';
import { copyToClipboard } from '../../helpers/global';
import Loader from '../Loader';
import ContractEditSideBar from '../main/ContractEditSidebar';
import ContractList from '../main/ContractList';
import NotFound from '../NotFound';

const getSolReportFromContracts = contracts => {
  const contractsWithCashLender = contracts;
  const solReportDataArray = new Array();
  for (let contract of contractsWithCashLender) {
    const solReportObjectForContract = new Object();
    solReportObjectForContract.contractId = contract._id;
    solReportObjectForContract['Buyer Name'] = [contract.buyer, contract.coBuyer]
      .filter(a => Boolean(a))
      .map(a => a.toString())
      .join(', ');
    solReportObjectForContract['Area (Sq.Ft)'] = contract.tradeSqFt;
    const huds = [contract.inventoryUnit.hudA, contract.inventoryUnit.hudB, contract.inventoryUnit.hudC].filter(a =>
      Boolean(a)
    );
    const serials = [
      contract.inventoryUnit.serialA,
      contract.inventoryUnit.serialB,
      contract.inventoryUnit.serialC
    ].filter(a => Boolean(a));
    const sizes = [contract.inventoryUnit.sizeA, contract.inventoryUnit.sizeB, contract.inventoryUnit.sizeC].filter(a =>
      Boolean(a)
    );
    const weights = [
      contract.inventoryUnit.weightA,
      contract.inventoryUnit.weightB,
      contract.inventoryUnit.weightC
    ].filter(a => Boolean(a));
    const numberOfSections = [huds.length, serials.length, sizes.length, weights.length].sort((a, b) => b - a)[0];
    solReportObjectForContract['Home Section Details'] = new Array(numberOfSections).fill().map((item, index) => {
      const thisHomeSectionDetailObject = new Object();
      thisHomeSectionDetailObject['Label Num'] = huds[index] ? huds[index] : 'N/A';
      thisHomeSectionDetailObject['Serial Num'] = serials[index] ? serials[index] : 'N/A';
      thisHomeSectionDetailObject['Weights'] = weights[index] ? weights[index] : 'N/A';
      const size = sizes[index] ? sizes[index].toString() : 'N/A x N/A';
      let [width, length] = size.split('x').map(a => a.trim());
      if (!length) length = 'N/A';
      thisHomeSectionDetailObject['Width'] = width;
      thisHomeSectionDetailObject['Length'] = length;
      return thisHomeSectionDetailObject;
    });
    const manufacturerObject = new Object();
    manufacturerObject['Mfr Name'] = contract.inventoryUnit.manufacturer?.name || 'N/A';
    manufacturerObject['Mfr License Num'] = contract.inventoryUnit.manufacturer?.licenseNumber || 'N/A';
    manufacturerObject['Mfr Model'] = contract.inventoryUnit.model || 'N/A';
    manufacturerObject['Mfr Date'] = contract.inventoryUnit.dateOfManufacture
      ? moment(contract.inventoryUnit.dateOfManufacture).format('MM/DD/YYYY')
      : 'N/A';
    solReportObjectForContract['Manufacturer'] = manufacturerObject;
    const salesLocationInfo = new Object();
    salesLocationInfo['Sales Location'] = contract.salesLocation?.location;
    salesLocationInfo['RBI'] = contract.salesLocation?.rbi;
    solReportObjectForContract['Sales Location'] = salesLocationInfo;
    const consumerObject = new Object();
    consumerObject['Cell Phone #'] = contract.cellPhone ? contract.cellPhone : 'N/A';
    consumerObject['Cell Phone #2'] = contract.cellPhone2 ? contract.cellPhone2 : 'N/A';
    consumerObject['Mailing Address'] = contract.mailingAddress ? contract.mailingAddress : 'N/A';
    consumerObject['Mailing City'] = contract.mailingCity ? contract.mailingCity : 'N/A';
    consumerObject['Mailing Zip'] = contract.mailingZip ? contract.mailingZip : 'N/A';
    consumerObject['Buyer Email'] = contract.buyerEmail ? contract.buyerEmail : 'N/A';
    solReportObjectForContract['Consumer'] = consumerObject;
    const homeSiteLocationObject = new Object();
    [
      ['propertyAddress', 'Site Address'],
      ['propertyCity', 'City'],
      ['propertyState', 'State'],
      ['zipCode', 'Zip'],
      ['propertyCounty', 'County']
    ].forEach(([dbKey, keyToSend]) => (homeSiteLocationObject[keyToSend] = contract[dbKey] ? contract[dbKey] : 'N/A'));
    solReportObjectForContract['Home Site Location'] = homeSiteLocationObject;
    const homeDetailsObject = new Object();
    homeDetailsObject['Home Status'] = contract.status;
    homeDetailsObject['Install Date'] = contract.setup.start
      ? moment(contract.setup.start).format('MM/DD/YYYY')
      : 'N/A'; //mm dd yyyy
    homeDetailsObject['Wind Zone'] = contract.inventoryUnit.windZone ? contract.inventoryUnit.windZone : 'N/A';
    solReportObjectForContract['Home Details'] = homeDetailsObject;
    if (!contract.installer) contract.installer = {};
    const installerObject = new Object();
    installerObject['Name'] = contract.installer.name ? contract.installer.name : 'N/A';
    installerObject['License #'] = contract.installer.licenseNumber ? contract.installer.licenseNumber : 'N/A';
    installerObject['Phone'] = contract.installer.phoneNumber ? contract.installer.phoneNumber : 'N/A';
    installerObject['Fax'] = contract.installer.faxNumber ? contract.installer.faxNumber : 'N/A';
    installerObject['Address'] = contract.installer.address ? contract.installer.address : 'N/A';
    installerObject['City'] = contract.installer.city ? contract.installer.city : 'N/A';
    installerObject['State'] = contract.installer.state ? contract.installer.state : 'N/A';
    installerObject['Zip'] = contract.installer.zip ? contract.installer.zip : 'N/A';
    solReportObjectForContract['Installer'] = installerObject;
    solReportDataArray.push(solReportObjectForContract);
  }

  return solReportDataArray;
};

const SOL = ({ appChoices }) => {
  const [loading, setLoading] = useState(true);
  const [solReport, setSolReport] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [copiedValues, setCopiedValues] = useState([]);
  const [submittingSOL, setSubmittingSOL] = useState(false);
  const [editingContract, setEditingContract] = useState(null);

  const lenders = useMemo(() => appChoices.find(c => c.key === 'lender').values, [appChoices]);

  const fetchSOLContracts = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH_NEW,
      requestBody: {
        filter: {
          'setup.start': { $exists: true, $ne: null },
          inventoryUnit: { $exists: true, $ne: null },
          lender: lenders.find(l => l.shortName === 'Cash'),
          dateSolReportFiled: null
        },
        sort: '-setup.start'
      },
      method: 'POST'
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setContracts(response.results);
  };

  useEffect(() => {
    fetchSOLContracts();
  }, []);

  useEffect(() => {
    setSolReport(getSolReportFromContracts(contracts));
  }, [contracts]);

  useEffect(() => {
    setCopiedValues([]);
  }, [activeIndex, solReport]);

  const prevContract = () => {
    if (activeIndex === 0) return;

    setActiveIndex(activeIndex - 1);
  };

  const nextContract = () => {
    if (activeIndex === solReport.length - 1) return;

    setActiveIndex(activeIndex + 1);
  };

  const copyContent = (label, value, sectionName, numbersOnly = false) => {
    const newValue = numbersOnly
      ? value
          .toString()
          .split('')
          .filter(char => /\d/.test(char))
          .join('')
      : value;
    copyToClipboard(newValue);

    const checkValue = label + '-' + sectionName;
    copiedValues.push(checkValue);
    setCopiedValues([...copiedValues]);

    toast.success(`Copied "${newValue}" to clipboard!`);
  };

  const SingleCol = ({ label, value, sectionName }) => {
    const checkValue = label + '-' + sectionName;

    return (
      <Col xs={6} md={3}>
        <Alert
          onClick={() => copyContent(label, value, sectionName, numbersOnlyFields.includes(label))}
          style={{ cursor: 'pointer', height: '90%' }}
          variant={copiedValues.includes(checkValue) ? 'warning' : 'info'}
          className="text-center p-1 mx-2 midFont"
        >
          <p className="mb-1">
            <b>{label}</b>
          </p>
          {value || 'N/A'}
        </Alert>
      </Col>
    );
  };

  const SingleRow = ({ name, obj, nameClass = '' }) => {
    return (
      <>
        <h6 className={'mb-3 ' + nameClass}>{name}</h6>
        <Row className="justify-content-center">
          {Object.keys(obj).map(objKey => (
            <SingleCol label={objKey} value={obj[objKey]} sectionName={name} />
          ))}
        </Row>
        <hr className="my-2" />
      </>
    );
  };

  const onContractEditClick = () => {
    const activeContract = contracts.find(c => c._id === solReport[activeIndex].contractId);
    setEditingContract(activeContract);
  };

  const SOL = () => {
    const activeContract = solReport[activeIndex];
    //we use three keys for static elements and for rest of the keys we loop them
    // and create a same component
    const loopKeys = Object.keys(activeContract).filter(
      key => !['contractId', 'Buyer Name', 'Home Section Details', 'Area (Sq.Ft)'].includes(key)
    );

    return (
      <Card className="mt-2">
        <Card.Header>
          <div className="d-flex">
            <div className="flex-grow-1">
              <h5 className=" mb-0">{activeContract['Buyer Name']}</h5>
            </div>
            <div>
              <Pencil className="hover-light" onClick={onContractEditClick} />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="text-center mx-3">
          <h6 className="mb-3">Home Sections</h6>
          {activeContract['Home Section Details'].map((section, index) => {
            return <SingleRow name={`Section ${index + 1}`} obj={section} nameClass="text-muted text-left" />;
          })}

          {loopKeys.map(responseKey => (
            <SingleRow name={responseKey} obj={activeContract[responseKey]} />
          ))}
        </Card.Body>
        <Card.Footer className="text-right">
          <Button onClick={submitSOL} disabled={submittingSOL}>
            Done
          </Button>
        </Card.Footer>
      </Card>
    );
  };

  const submitSOL = async () => {
    toast.info('Please wait submitting SOL report...');

    setSubmittingSOL(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(solReport[activeIndex]['contractId']),
      requestBody: { dateSolReportFiled: new Date().toISOString() },
      method: 'PUT'
    });

    setSubmittingSOL(false);

    if (error) {
      toast.error(error);
      return;
    }

    solReport.splice(activeIndex, 1);
    if (solReport.length !== 0 && solReport.length < activeIndex + 1) {
      setActiveIndex(activeIndex - 1);
    }

    setSolReport([...solReport]);
    toast.success('SOL report submitted successfully');
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  const onContractSelect = contract => {
    const index = solReport.findIndex(c => c['contractId'] === contract['_id']);
    if (index !== -1) setActiveIndex(index);
  };

  const onContractEdit = contract => {
    const index = contracts.findIndex(c => c['_id'] === contract['_id']);
    if (index !== -1) {
      contracts[index] = contract;
      setContracts([...contracts]);
    }

    setEditingContract(null);
  };

  const onContractDelete = contractId => {
    const index = contracts.findIndex(c => c['_id'] === contractId);
    if (index !== -1) {
      contracts.splice(index, 1);
      setContracts([...contracts]);
    }
    setEditingContract(null);
  };

  const onContractEditSideBarHide = () => {
    setEditingContract(null);
  };

  return (
    <Container fluid className={'h-100  py-3 px-md-2 '}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {solReport.length > 0 ? (
            <>
              <Row className="h-100">
                <Col xs={4} md={2} className="p-0 pb-5 overflow-auto h-100">
                  <ContractList
                    contracts={solReport.map(c => ({
                      ...c,
                      _id: c['contractId'],
                      buyer: c['Buyer Name'],
                      salesLocation: { location: c['Sales Location']['Sales Location'] },
                      inventoryUnit: {
                        serialA: c['Home Section Details'][0] ? c['Home Section Details'][0]['Serial Num'] : '',
                        serialB: c['Home Section Details'][1] ? c['Home Section Details'][1]['Serial Num'] : '',
                        serialC: c['Home Section Details'][2] ? c['Home Section Details'][2]['Serial Num'] : ''
                      }
                    }))}
                    activeId={solReport[activeIndex]['contractId']}
                    onSelect={onContractSelect}
                    appChoices={appChoices}
                  />
                </Col>
                <Col xs={8} md={10} className="h-100 overflow-auto">
                  <>
                    <Row>
                      <Col className="text-right">
                        <>
                          {activeIndex > 0 && (
                            <Button size="sm" variant="primary" onClick={prevContract} disabled={submittingSOL}>
                              <ArrowLeft className="align-text-top" />
                            </Button>
                          )}
                          <h6 className="text-muted mb-0 d-inline-block mx-3">{activeIndex + 1}</h6>
                          {activeIndex < solReport.length - 1 && (
                            <Button size="sm" variant="primary" onClick={nextContract} disabled={submittingSOL}>
                              <ArrowRight className="align-text-top" />
                            </Button>
                          )}
                        </>
                      </Col>
                    </Row>
                    <SOL />
                  </>
                </Col>
              </Row>
              <ContractEditSideBar
                appChoices={appChoices}
                editingContract={editingContract}
                onHide={onContractEditSideBarHide}
                onContractEdit={onContractEdit}
                onContractDelete={onContractDelete}
              />
            </>
          ) : (
            !loading && <NotFound text="No contracts to show!" />
          )}
        </>
      )}
    </Container>
  );
};

export default SOL;
