import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, contractSearchFilterOptions, contractSearchSortByOptions } from '../../helpers/constants';
import NotFound from '../NotFound';
import FloatingButton from '../common/FloatingButton';
import HorizontalProgress from '../common/HorizontalProgress';
import SmallButton from '../common/SmallButton';
import SearchBox from '../common/searchbox';
import ContractEditSideBar from './ContractEditSidebar';
import ContractSearchItem from './ContractSearchItem';
import CreateSideBar from './CreateSidebar';
import RebateEditModal from './RebateEditModal';
import RequestRebateModal from './RequestRebateModal';

const SearchPage = ({ fromPicker = false, onSelect, appChoices, selectedContracts = [], excludedContractIds = [] }) => {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [rebateEditingContract, setRebateEditingContract] = useState(null);
  const [requestRebateContractID, setRequestRebateContractID] = useState(null);
  const [editingContract, setEditingContract] = useState(null);
  const [submittingContractRebate, setSubmittingContractRebate] = useState(false);
  const [createFormMeta, setCreateFormMeta] = useState(null);

  const fetchContracts = async ({ sortBy, maxLimit, descSort, filters, query }) => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH_NEW,
      requestBody: {
        filter: filters,
        query,
        pageSize: maxLimit === 'All' ? undefined : maxLimit,
        sort: { [sortBy]: descSort ? -1 : 1 }
      }
    });
    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setContracts(response.results.filter(r => !excludedContractIds.includes(r['_id'])));
  };

  const onContractEditClick = contract => {
    setEditingContract(contract);
  };

  const onRebateEditClick = contract => {
    setRebateEditingContract(contract);
  };

  const onRequestRebateClick = contract => {
    setRequestRebateContractID(contract['_id']);
  };

  const onRebateEditFormSubmit = async form => {
    setSubmittingContractRebate(true);

    const { response, error } = await makeApiRequests({
      requestBody: form,
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(rebateEditingContract['_id']),
      method: 'PUT'
    });

    setSubmittingContractRebate(false);

    if (error) {
      return toast.error(error);
    }

    toast(`Contract updated successfully!`, {
      type: 'success'
    });

    updateContracts(response);
    setRebateEditingContract(null);
  };

  window['onRebateEditFormSubmit'] = onRebateEditFormSubmit;

  const onRequestRebateFormSubmit = async form => {
    setSubmittingContractRebate(true);

    const { response, error } = await makeApiRequests({
      requestBody: form,
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(requestRebateContractID),
      method: 'PUT'
    });
    setSubmittingContractRebate(false);

    if (error) {
      return toast.error(error);
    }

    toast(`Rebate requested successfully!`, {
      type: 'success'
    });

    updateContracts(response);
    setRequestRebateContractID(null);
  };

  window['onRequestRebateFormSubmit'] = onRequestRebateFormSubmit;

  const onRebateEditModalHide = () => {
    setRebateEditingContract(null);
  };

  const onContractEdit = (contract, forFile) => {
    const index = contracts.findIndex(c => c['_id'] === contract['_id']);
    if (index !== -1) {
      contracts[index] = contract;
      setContracts([...contracts]);
    }

    if (!forFile) setEditingContract(null);
  };

  const onContractFileLoaded = (contractId, fileNames) => {
    const index = contracts.findIndex(c => c['_id'] === contractId);
    if (index !== -1) {
      contracts[index]['fileNames'] = fileNames;
      setContracts([...contracts]);
    }
  };

  const onContractDelete = contractId => {
    const index = contracts.findIndex(c => c['_id'] === contractId);
    if (index !== -1) {
      contracts.splice(index, 1);
      setContracts([...contracts]);
    }
    setEditingContract(null);
  };

  const onContractEditSideBarHide = () => {
    setEditingContract(null);
  };

  const updateContracts = contract => {
    const existingIndex = contracts.findIndex(c => c._id === contract._id);
    if (existingIndex !== -1) {
      contracts[existingIndex] = contract;
      setContracts([...contracts]);
    } else {
      setContracts([contract, ...contracts]);
    }

    setCreateFormMeta(null);
  };

  return (
    <>
      {/* <CreateSideBar
        onContractAdd={onContractAdd}
        show={addContractPopupMeta}
        onHide={onAddCotractPoputMetaClose}
        appChoices={appChoices}
      /> */}
      <Container fluid className={'h-100 ' + (fromPicker ? 'px-0' : 'pt-2 pb-3 px-md-2 ')}>
        <SearchBox
          appChoices={appChoices}
          filterCreationOptions={contractSearchFilterOptions}
          sortByOptions={contractSearchSortByOptions}
          maxLimitOptions={[10, 20, 50, 100, 200, 'All']}
          defaultMaxLimit={fromPicker ? 50 : 100}
          onSearchOptionsChange={fetchContracts}
          disabled={loading}
        />
        {fromPicker && (
          <div className="d-flex justify-content-end">
            <SmallButton onClick={() => setCreateFormMeta({})} style={{ marginRight: '10px' }} variant="success">
              Create New Contract
            </SmallButton>
          </div>
        )}

        {loading && <HorizontalProgress text={'Fetching contracts...'} />}
        <hr />

        {contracts.length > 0
          ? contracts.map(contract => (
              <ContractSearchItem
                key={contract['_id']}
                contract={contract}
                appChoices={appChoices}
                onContractUpdate={onContractEdit}
                onRebateEditClick={() => onRebateEditClick(contract)}
                onRequestRebateClick={() => onRequestRebateClick(contract)}
                onContractClick={() => {
                  if (fromPicker) {
                    onSelect(contract);
                  } else {
                    onContractEditClick(contract);
                  }
                }}
                selected={selectedContracts.map(c => c._id).includes(contract._id)}
                fromPicker={fromPicker}
                onDuplicateClick={() =>
                  setCreateFormMeta({ prefillContract: { ...contract, comments: '', fromContract: true } })
                }
              />
            ))
          : !loading && <NotFound text="No contracts to show!" />}

        <CreateSideBar
          onContractAdd={updateContracts}
          appChoices={appChoices}
          show={createFormMeta !== null}
          onHide={() => setCreateFormMeta(null)}
          prefillContract={createFormMeta?.prefillContract}
        />
        {!fromPicker && (
          <>
            <FloatingButton
              variant="success"
              text={'Add New Contract'}
              Icon={PlusCircle}
              onClick={() => setCreateFormMeta({})}
            />
            <RebateEditModal
              rebateEditingContract={rebateEditingContract}
              onHide={onRebateEditModalHide}
              submittingContractRebate={submittingContractRebate}
            />
            <RequestRebateModal
              requestRebateContractID={requestRebateContractID}
              submittingContractRebate={submittingContractRebate}
              onHide={() => setRequestRebateContractID(null)}
            />
            <ContractEditSideBar
              appChoices={appChoices}
              editingContract={editingContract}
              onHide={onContractEditSideBarHide}
              onContractEdit={onContractEdit}
              onContractDelete={onContractDelete}
              onContractFileLoaded={onContractFileLoaded}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default SearchPage;
