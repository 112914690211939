import React from 'react';
import { Modal } from 'react-bootstrap';
import FormGenerator from '../../form-generator/FormGenerator';
import HorizontalProgress from '../common/HorizontalProgress';
import { rebateEditFormJson } from './form';

const RebateEditModal = ({ rebateEditingContract, onHide, submittingContractRebate }) => {
  return (
    <Modal show={rebateEditingContract != null} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!submittingContractRebate}>
        <Modal.Title>
          <h6 className="mb-0">Update Rebate</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rebateEditingContract && (
          <FormGenerator formJson={rebateEditFormJson} formValues={{ rebateEdit: rebateEditingContract }} />
        )}

        {submittingContractRebate && <HorizontalProgress text="Updating contract..." />}
      </Modal.Body>
    </Modal>
  );
};

export default RebateEditModal;
