import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Route, Switch, withRouter } from 'react-router';
import NotFound from '../NotFound';
import CachedResponse from '../common/CachedResponse';
import BrowseRequests from './BrowseRequests';
import MyClaims from './MyClaims';
import NewRequests from './NewRequests';

const Requests = () => {
  const [role] = useState(localStorage.getItem('user-role'));

  const getActivePage = () => {
    return (
      <Switch>
        <Route exact path="/service-requests/browse">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <BrowseRequests appChoices={appChoices} />}
          />
        </Route>
        {role === 'service' && (
          <>
            <Route exact path="/service-requests/new">
              <NewRequests />
            </Route>
            <Route exact path="/service-requests/my-claims">
              <MyClaims />
            </Route>
          </>
        )}

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  };

  if (['tech', 'installer', 'inventory manager'].includes(role)) {
    return <NotFound text="You are not authorized to view this page" />;
  }

  return (
    <>
      <Container fluid className="h-100 pt-2 pb-3 px-md-2">
        {getActivePage()}
      </Container>
    </>
  );
};

export default withRouter(Requests);
