import * as qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { docHubSourceOptions, ENDPOINTS } from '../../helpers/constants';
import { getDocIdFromUrl } from '../../helpers/global';
import Loader from '../Loader';
import DocumentPrinter from './DocumentPrinter';
import SelectionBox from './PrintGroupSelectionBox';

const getSource = (role, sourceFromUrl) => {
  return ['super admin', 'admin', 'sales', 'sales manager', 'inventory manager'].includes(role) &&
    docHubSourceOptions.includes(sourceFromUrl)
    ? sourceFromUrl
    : docHubSourceOptions[role.includes('inventory') ? 2 : role.includes('sales') ? 1 : 0];
};

const getDocumentLists = (role, appChoices, docSource) => {
  let printGroupResults = [];
  const allPrintGroups = appChoices.find(ac => ac.key === 'printGroups').values;

  let filteredPrintGroups = allPrintGroups
    .filter(
      pg =>
        (['super admin', 'admin', 'sales', 'sales manager', 'inventory manager'].includes(role) ||
          pg.roles.map(r => r.toLowerCase()).includes(role)) &&
        pg.dataSources.includes(docSource)
    )
    .map(pg => ({ name: pg.name, url: pg.url, category: pg?.category || 'Others' }));

  const uniquePrintCategories = new Set(filteredPrintGroups.map(pg => pg?.category || 'Others'));

  Array.from(uniquePrintCategories).forEach(upc => {
    let printGroupByCategory = filteredPrintGroups.filter(fpg => fpg?.category === upc);
    printGroupByCategory.unshift({ categoryLabel: upc });

    printGroupResults.push(...printGroupByCategory);
  });

  return printGroupResults;
};

const getDocumentFieldsFromParams = params => {
  return {
    withData: params.filter(p => p.value),
    withoutData: params.filter(p => !p.value)
  };
};

const PrintGroups = ({ appChoices }) => {
  const location = useLocation();

  const {
    source: sourceFromUrl,
    contractId: contractIdFromUrl,
    contactId: contactIdFromUrl,
    document: documentFromUrl
  } = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  const [role] = useState(localStorage.getItem('user-role'));
  const [docSource, setDocSource] = useState(getSource(role, sourceFromUrl));
  const [documents, setDocuments] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState();
  const [loading, setLoading] = useState(false);

  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState();
  const [documentFieldsLoading, setDocumentFieldsLoading] = useState(false);
  const [documentFields, setDocumentFields] = useState(null);

  useEffect(() => {
    loadContractOrContactIfPreselected();
  }, []);

  useEffect(() => {
    const documentList = getDocumentLists(role, appChoices, docSource);

    setDocuments(documentList);
    setSelectedDocumentUrl(documentList[1]?.url);
    //setSelectedGroup(null);
  }, [docSource]);

  // we can pass contact or contract id as get params, in that case we preload that
  const loadContractOrContactIfPreselected = async () => {
    if ((docSource === 'Contract' && contractIdFromUrl) || (docSource === 'Contact' && contactIdFromUrl)) {
      loadDetails({
        source: docSource,
        contractId: contractIdFromUrl,
        contactId: contactIdFromUrl,
        preSelectedDocument: documentFromUrl
      });
    }
  };

  const loadDetails = async ({ source, contractId, contactId, preSelectedDocument }) => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: source !== 'Contact' ? ENDPOINTS.CONTRACTS_SEARCH : ENDPOINTS.CONTACTS_SEARCH,
      requestBody: { filter: { _id: source !== 'Contact' ? contractId : contactId } }
    });

    if (error || response.length === 0) {
      setLoading(false);
      return;
    }

    const details = response[0];
    setSelectedGroup(details);

    const existingDocument = getDocumentLists(role, appChoices, docSource).find(
      d => d._id && d.name === preSelectedDocument
    );
    if (existingDocument) {
      console.log({ existingDocument });
      setSelectedDocumentUrl(existingDocument.url);
      getDocumentFields(details['_id'], existingDocument.url);
    }

    setLoading(false);
  };

  const onGroupPicked = group => {
    setSelectedGroup(group);
    clearCurrentForm();
  };

  const onDocumentChange = url => {
    setSelectedDocumentUrl(url || '');
  };

  const onSourceChange = e => {
    const value = e ? e.target.value : document.getElementById('sourceSelect').value;
    setDocSource(value);
    setSelectedGroup(null);
  };

  useEffect(() => clearCurrentForm(), [docSource, selectedDocumentUrl]);

  const clearCurrentForm = () => {
    setDocumentFields(null);
  };

  const getDocumentFields = async (id = '', preSelectedDocumentUrl = '') => {
    clearCurrentForm();
    setDocumentFieldsLoading(true);

    const { error, response } = await makeApiRequests({
      endpoint: ENDPOINTS.DOCHUB_DOCUMENT_PARAMS(id || selectedGroup['_id']),
      requestBody: {
        docId: getDocIdFromUrl(preSelectedDocumentUrl || selectedDocumentUrl),
        dataSource: docSource
      }
    });

    setDocumentFieldsLoading(false);

    if (error) {
      return toast.error(error);
    }

    setDocumentFields(getDocumentFieldsFromParams(response.foundParams));
  };

  const onSwitchToExportedContractClick = () => {
    const exportedContract = selectedGroup?.exportedContract;
    if (!exportedContract) return;

    setDocSource('Contract');
    setSelectedGroup(exportedContract);
  };

  const isServiceRequest = useMemo(() => {
    if (documents) {
      const serviceRequestDocumentsUrls = documents.filter(d => d?.name?.includes('Service Request')).map(d => d.url);
      if (serviceRequestDocumentsUrls) {
        return serviceRequestDocumentsUrls.includes(selectedDocumentUrl);
      }
    }

    return false;
  }, [documents, selectedDocumentUrl]);

  return (
    <div className="px-2 px-md-5 py-2 ">
      {loading ? (
        <Loader />
      ) : (
        <>
          <SelectionBox
            showDocSourceSelector={['super admin', 'admin', 'sales', 'sales manager'].includes(role)}
            docSource={docSource}
            onDocSourceChange={onSourceChange}
            selectedGroup={selectedGroup}
            onGroupPicked={onGroupPicked}
            selectedDocument={selectedDocumentUrl}
            documents={documents}
            onDocumentChange={onDocumentChange}
            onPreviewClick={() => getDocumentFields()}
            onSwitchToExportedContractClick={onSwitchToExportedContractClick}
            documentFieldsLoading={documentFieldsLoading}
          />
          <hr />
          {documentFieldsLoading && <ProgressBar className="mb-2" now={100} animated label="Loading document..." />}
          {documentFields && (
            <DocumentPrinter
              appChoices={appChoices}
              docSource={docSource}
              documentId={getDocIdFromUrl(selectedDocumentUrl)}
              documentName={documents.find(d => d.url === selectedDocumentUrl).name}
              documentFields={documentFields}
              isServiceRequest={isServiceRequest}
              selectedGroup={selectedGroup}
              onServiceOrderReportUpdate={setSelectedGroup}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PrintGroups;
