import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, contactSearchSortByOptions, createFilterOptionsForContact } from '../../helpers/constants';
import NotFound from '../NotFound';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import FloatingButton from '../common/FloatingButton';
import HorizontalProgress from '../common/HorizontalProgress';
import SmallButton from '../common/SmallButton';
import SearchBox from '../common/searchbox';
import InventoryPicker from '../inventory/InventoryPicker';
import { default as CreateSidebar } from './CreateSidebar';
import CrmSearchItem from './CrmSearchItem';
import EditCrm from './EditCrm';

const ContactEditSideBar = ({
  editingContact,
  onContactEditSideBarHide,
  appChoices,
  onContactDelete,
  onContactEdit,
  onCRMFileLoaded
}) => {
  return (
    <SlidingSideBar
      fullScreen
      visible={editingContact != null}
      onClose={onContactEditSideBarHide}
      title={'Contact Details'}
    >
      {editingContact && (
        <EditCrm
          contactFromSearch={editingContact}
          appChoices={appChoices}
          fromSearch
          onContactDelete={onContactDelete}
          onContactEdit={onContactEdit}
          onSearchCRMFileLoaded={onCRMFileLoaded}
        />
      )}
    </SlidingSideBar>
  );
};

const SearchCrm = ({
  fromPicker = false,
  onSelect,
  appChoices,
  selectedContacts = [],
  excludedCrmIds = [],
  updateInventoryList
}) => {
  const [role] = useState(localStorage.getItem('user-role'));
  const [loading, setLoading] = useState(true);
  const [crms, setCrms] = useState([]);
  const [editingContact, setEditingContact] = useState(null);
  const [inventoryPopupMeta, setInventoryPopupMeta] = useState(null);
  const [createFormMeta, setCreateFormMeta] = useState(null);

  const history = useHistory();

  const fetchContacts = async ({ sortBy, maxLimit, descSort, filters, query }) => {
    setLoading(true);
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_SEARCH_NEW,
      requestBody: {
        filter: filters,
        query,
        pageSize: maxLimit === 'All' ? undefined : maxLimit,
        sort: { [sortBy]: descSort ? -1 : 1 }
      }
    });
    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setCrms(response.results.filter(r => !excludedCrmIds.includes(r?.['_id'])));
  };

  const onContactEditClick = contact => {
    setEditingContact(contact);
  };

  const updateContacts = (contact, forFile) => {
    const index = crms.findIndex(c => c['_id'] === contact['_id']);
    if (index !== -1) {
      crms[index] = contact;
    } else {
      crms.unshift(contact);
    }
    setCrms([...crms]);
    setCreateFormMeta(null);
    if (!forFile) setEditingContact(null);
  };

  const onContactDelete = contactId => {
    const index = crms.findIndex(c => c['_id'] === contactId);
    if (index !== -1) {
      crms.splice(index, 1);
      setCrms([...crms]);
    }
    setEditingContact(null);
  };

  const onContactEditSideBarHide = () => {
    setEditingContact(null);
  };

  const onCRMFileLoaded = (crmId, fileNames) => {
    const index = crms.findIndex(c => c['_id'] === crmId);
    if (index !== -1) {
      crms[index]['fileNames'] = fileNames;
      setCrms([...crms]);
    }
  };

  const onImportClick = crm => {
    if (crm['inventoryUnit'] && crm['inventoryUnit'].length > 1) {
      setInventoryPopupMeta({ crm });
      return;
    }

    history.push({
      pathname: '/contract/create',
      state: {
        preFillValues: crm,
        inventoryUnit: crm['inventoryUnit'] && crm['inventoryUnit'].length > 0 ? crm['inventoryUnit'][0] : undefined
      }
    });
  };

  const onDuplicateClick = crm => {
    setCreateFormMeta({ prefillContact: { ...crm } });
  };

  const onInventorySubmit = inventoryList => {
    const inventory = inventoryList[0];
    const { crm } = inventoryPopupMeta;

    history.push({
      pathname: '/contract/create',
      state: {
        preFillValues: crm,
        inventoryUnit: inventory
      }
    });
  };

  const onUnitSelect = unit => {
    setInventoryPopupMeta({ ...inventoryPopupMeta, selectedUnit: unit });
  };

  return (
    <>
      <Container fluid className={'h-100 ' + (fromPicker ? 'px-0' : 'py-3 px-md-3 ')}>
        <SearchBox
          appChoices={appChoices}
          filterCreationOptions={createFilterOptionsForContact(role)}
          sortByOptions={contactSearchSortByOptions}
          maxLimitOptions={[10, 20, 50, 100, 200, 'All']}
          defaultMaxLimit={fromPicker ? 50 : 100}
          onSearchOptionsChange={fetchContacts}
          disabled={loading}
        />
        {fromPicker && (
          <div className="d-flex justify-content-end">
            <SmallButton onClick={() => setCreateFormMeta({})} style={{ marginRight: '10px' }} variant="success">
              Create New Contact
            </SmallButton>
          </div>
        )}

        {loading && <HorizontalProgress text={'Fetching contacts...'} />}
        <hr />

        {crms.length > 0 ? (
          <>
            {crms.map(crm => (
              <CrmSearchItem
                key={crm['_id']}
                crm={crm}
                onContactClick={() => {
                  if (fromPicker) {
                    onSelect(crm);
                  } else {
                    onContactEditClick(crm);
                  }
                }}
                onCreateClick={() => setCreateFormMeta({})}
                onImportClick={() => onImportClick(crm)}
                onDuplicateClick={() => onDuplicateClick(crm)}
                selected={selectedContacts.map(c => c._id).includes(crm._id)}
                fromPicker={fromPicker}
                npsSales={crm['manufacturer'] && crm['manufacturer'].isNPS}
                role={role}
                mini={fromPicker}
              />
            ))}
            <InventoryPicker
              title="Select an inventory unit to be imported"
              selectedUnits={
                inventoryPopupMeta && inventoryPopupMeta.selectedUnit ? [inventoryPopupMeta.selectedUnit] : []
              }
              onUnitSelect={onUnitSelect}
              show={inventoryPopupMeta !== null}
              onSubmit={onInventorySubmit}
              fetchedInventories={inventoryPopupMeta && inventoryPopupMeta['crm']['inventoryUnit']}
              onInventoryPickerClose={() => setInventoryPopupMeta(null)}
              updateInventoryList={updateInventoryList}
              appChoices={appChoices}
            />
          </>
        ) : (
          !loading && <NotFound text="No contacts found" />
        )}
        <CreateSidebar
          onContactAdd={updateContacts}
          appChoices={appChoices}
          show={createFormMeta !== null}
          onHide={() => setCreateFormMeta(null)}
          prefillContact={createFormMeta?.prefillContact}
        />
        {!fromPicker && (
          <>
            <FloatingButton
              variant="success"
              text={'Add New Contact'}
              Icon={PlusCircle}
              onClick={() => setCreateFormMeta({})}
            />
            <ContactEditSideBar
              editingContact={editingContact}
              appChoices={appChoices}
              onContactEdit={updateContacts}
              onContactDelete={onContactDelete}
              onCRMFileLoaded={onCRMFileLoaded}
              onContactEditSideBarHide={onContactEditSideBarHide}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default SearchCrm;
