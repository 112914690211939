import { useEffect, useMemo } from 'react';

export default function useKeypress(keysFromUser, action) {
  const keys = useMemo(() => (!Array.isArray(keysFromUser) ? [keysFromUser] : keysFromUser), [keysFromUser]);

  let includesCtrlKey = keys.length === 2 && keys[0] === 'ctrl';

  useEffect(() => {
    function onKeyup(e) {
      if (keys.length === 1) {
        if (e.key === keys[0]) action();
      } else if (keys.length === 2) {
        if ((includesCtrlKey ? e[`${keys[0]}Key`] || e.metaKey : e[`${keys[0]}Key`]) && e.key === keys[1]) action();
      }
    }

    window.addEventListener('keydown', onKeyup);
    return () => window.removeEventListener('keydown', onKeyup);
  }, []);
}
