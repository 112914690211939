import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import EntityCRUD from '../common/EntityCRUD';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import { adminEntities } from './constants';

const EntitySelector = ({ onSelect, entityOptions = [] }) => {
  return (
    <Container fluid className="h-100 py-3 px-md-3">
      <Card>
        <Card.Body>
          <h6 className="large">Select an choice to update:</h6>
          <Row>
            {entityOptions.map(option => {
              return (
                <Col className="block mt-2 text-center no-float" xs={12} sm={6} md={3} key={option.name}>
                  <div
                    onClick={() => onSelect(option)}
                    className={`p-1 h-100 rounded hover-light border border-secondary`}
                  >
                    <p className={`mb-0 midFont`}>{option.name}</p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

const AppChoicesSidebar = ({ show, onHide, onAppChoicesUpdate, zIndex, entityToExclude = [] }) => {
  const [activeEntity, setActiveEntity] = useState();
  const [entityOptions, setEntityOptions] = useState([]);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  useEffect(() => {
    setEntityOptions(adminEntities.filter(e => !entityToExclude.includes(e.name)));
  }, [entityToExclude]);

  useEffect(() => {
    setActiveEntity();
    setButtonsDisabled(false);
  }, [show]);

  const onEntityUpdate = (entityAppChoiceKey, entityName, newValues) => {
    const oldAppChoicesString = localStorage.getItem('app-choices');
    let appChoices = [];
    try {
      appChoices = JSON.parse(oldAppChoicesString);
    } catch (e) {}

    const oldAppChoiceIndex = appChoices.findIndex(ac => ac.key === entityAppChoiceKey);
    if (oldAppChoiceIndex === -1) {
      appChoices.push({ key: entityAppChoiceKey, values: newValues });
    } else {
      appChoices[oldAppChoiceIndex] = { key: entityAppChoiceKey, values: newValues };
    }

    onAppChoicesUpdate && onAppChoicesUpdate(appChoices);
  };

  return (
    <SlidingSideBar zIndex={zIndex} fullScreen visible={show} onClose={onHide} title={'Update App Choices'}>
      {show ? (
        activeEntity ? (
          <>
            <div className="px-md-3">
              <Button size="sm" disabled={buttonsDisabled} onClick={() => setActiveEntity()}>
                <ArrowLeft className="mr-2" />
                <span className="align-middle">Back</span>
              </Button>
            </div>
            <EntityCRUD
              entityAppChoiceKey={activeEntity.appChoiceKey}
              entityName={activeEntity.name}
              endpoint={activeEntity.endpoint}
              entityTableHeaders={activeEntity.tableHeaders}
              entityTableSearchKeys={activeEntity.searchKeys}
              addFormRows={activeEntity.addRows}
              updateFormRows={activeEntity.updateRows}
              onProgressChange={setButtonsDisabled}
              onEntityUpdate={onEntityUpdate}
            />
          </>
        ) : (
          <EntitySelector onSelect={setActiveEntity} entityOptions={entityOptions} />
        )
      ) : (
        <></>
      )}
    </SlidingSideBar>
  );
};

export default AppChoicesSidebar;
