import React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import FormGenerator from '../../form-generator/FormGenerator';
import { requestRebateFormJson } from './form';

const RequestRebateModal = ({ requestRebateContractID, onHide, submittingContractRebate }) => {
  return (
    <Modal show={requestRebateContractID} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!submittingContractRebate}>
        <Modal.Title>
          <h6 className="mb-0">Request Rebate</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {requestRebateContractID && <FormGenerator formJson={requestRebateFormJson} />}
        {submittingContractRebate && <ProgressBar variant="dark" animated now={100} label="Requesting rebate..." />}
      </Modal.Body>
    </Modal>
  );
};

export default RequestRebateModal;
