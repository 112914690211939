import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, FormControl, OverlayTrigger, Popover, Row, Spinner } from 'react-bootstrap';
import { ArrowUpRightCircle, Check, Pencil, X } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import {
  getDateWithTimezone,
  getFormattedDate,
  getFormattedDateTime,
  validateEmail
} from '../form-generator/helpers/utility';
import { getObjectForPrefill } from '../helpers/formHelpers';
import { getValidUrl } from '../helpers/global';

const CustomField = ({ value, data, render }) => {
  const [showPopover, setShowPopover] = useState(false);

  const handleTogglePopover = () => {
    setShowPopover(!showPopover);
  };

  const handleClosePopover = () => {
    setShowPopover(false);
  };

  const popoverModal = (
    <Popover style={{ minWidth: 600 }} id="popover-positioned-left">
      <div>{render(data, handleClosePopover)}</div>
    </Popover>
  );

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="auto"
      overlay={popoverModal}
      show={showPopover}
      onToggle={handleTogglePopover}
    >
      <div className="d-flex align-items-center px-2 py-1 bg-primary-light rounded pointer">
        <p size="sm" className="mb-0 text-dark" variant="white" onClick={handleTogglePopover}>
          {value}
        </p>
        <Pencil className="ml-2" />
      </div>
    </OverlayTrigger>
  );
};

const OverViewColumns = ({
  data = {},
  numRows = [],
  dropdownFields = [],
  emailRows = [],
  priceFields = [],
  dateFields = [],
  dateTimeFields = [],
  fieldsToShow = [],
  urlFields = [],
  md = 4,
  mt = 1,
  className = '',
  fontSize = 'midFont',
  editable = false,
  showProgress = false,
  onToggleEditMode,
  isEditing,
  onSubmit
}) => {
  let [editingObject, setEditingObject] = useState({});
  useEffect(() => {
    if (!editable) return setEditingObject({});

    const newEditingObject = {};
    fieldsToShow.forEach(({ key }) => {
      newEditingObject[key] = data[key];
    });
    setEditingObject(getObjectForPrefill(newEditingObject));
  }, [data, fieldsToShow, editable]);

  const getValue = (key, isObject, objectKey) => {
    const value = isObject ? data[key]?.[objectKey] : data[key];
    if (typeof value !== 'boolean' && value !== 0 && !value) return 'N/A';

    if (urlFields.includes(key)) {
      return (
        <a
          onClick={e => e.stopPropagation()}
          className="text-secondary"
          href={getValidUrl(value)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Open Link <ArrowUpRightCircle size={10} className="mx-1" />
        </a>
      );
    }

    if (emailRows.includes(key)) {
      return (
        <a
          onClick={e => e.stopPropagation()}
          className="text-secondary"
          href={`mailto:${value}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </a>
      );
    }

    if (numRows.includes(key)) {
      return (
        <a onClick={e => e.stopPropagation()} className="text-secondary" href={`tel:${value}`}>
          {value}
        </a>
      );
    }
    if (dateFields.includes(key)) {
      return moment(value).format('MMMM Do YYYY');
    }
    if (dateTimeFields.includes(key)) {
      return moment(value).format('MMMM Do YYYY hh:mm a');
    }

    if (priceFields.includes(key)) {
      return `$${value}`;
    }
    return Array.isArray(value) ? value.join(', ') : value;
  };

  const getFormControlType = key => {
    if (priceFields.includes(key)) {
      return 'number';
    }
    if (emailRows.includes(key)) {
      return 'email';
    }
    if (dateFields.includes(key)) {
      return 'date';
    }
    if (dateTimeFields.includes(key)) {
      return 'datetime-local';
    }
    if (dropdownFields.includes(key)) {
      return 'dropdown';
    }
    return 'text';
  };

  const getFormControlValue = field => {
    const key = typeof field !== 'string' ? field.key : field;
    const isObject = field.isObject;
    const valueKey = field.valueKey || '_id';

    const formControlType = getFormControlType(key);
    const value = isObject && formControlType !== 'dropdown' ? editingObject[key]?.[valueKey] : editingObject[key];

    switch (formControlType) {
      case 'date':
        return value ? getFormattedDate(new Date(value)) : null;
      case 'datetime-local':
        return value ? getFormattedDateTime(new Date(value)) : null;
      case 'number':
      case 'price':
      case 'email':
      case 'text':
      default:
        return value || '';
    }
  };
  console.log({ editingObject });
  const setFormControlValue = (field, value) => {
    const key = typeof field !== 'string' ? field.key : field;

    const isObject = field.isObject;
    const fieldType = field?.type;
    const valueKey = field.valueKey || '_id';
    const onChangeFn = field.onChange;
    const formControlType = getFormControlType(key);

    if (fieldType === 'custom') {
      setEditingObject({ ...editingObject, [field?.key]: value });
      return;
    }

    let valueUpdated;
    switch (formControlType) {
      case 'date':
      case 'datetime-local':
        valueUpdated = value ? getDateWithTimezone(value, formControlType) : null;
        break;
      default:
        valueUpdated = value;
        break;
    }

    if (isObject && formControlType !== 'dropdown') {
      if (!editingObject[key]) {
        editingObject[key] = {};
      }

      editingObject[key][valueKey] = valueUpdated;
    } else {
      editingObject[key] = valueUpdated;
    }
    if (onChangeFn) {
      let otherUpdatedValues = onChangeFn(valueUpdated);
      editingObject = { ...editingObject, ...otherUpdatedValues };
    }

    setEditingObject({ ...editingObject });
  };

  const onSaveClick = () => {
    for (const field of fieldsToShow) {
      const value = getFormControlValue(field);
      const type = getFormControlType(field.key);

      if (field.required && !value) return toast.error(`${field.label} is a required field`);

      if (type === 'email' && value && !validateEmail(value)) {
        return toast.error(`${field.label} is a not a valid email address`);
      }
    }

    onSubmit && onSubmit(editingObject);
  };

  return (
    <>
      <Row className={className}>
        {editable && (
          <Col xs={12} className="text-right   smallFont">
            {showProgress ? (
              <span className="mx-2 text-dark" onClick={onSaveClick}>
                <Spinner animation="border" variant="dark" className="mx-1" size="sm" />
                Saving...
              </span>
            ) : isEditing ? (
              <>
                <span className="hover-underline mx-2 text-danger" onClick={onToggleEditMode}>
                  <X className="mx-1" size={10} />
                  Cancel
                </span>
                <span className="hover-underline text-success" onClick={onSaveClick}>
                  <Check className="mx-1" size={10} />
                  Save
                </span>
              </>
            ) : (
              <span className="hover-underline text-primary" onClick={onToggleEditMode}>
                <Pencil className="mx-1" size={10} />
                Edit
              </span>
            )}
          </Col>
        )}
        {fieldsToShow.map(field => {
          const key = typeof field !== 'string' ? field.key : field;
          const fieldType = field?.type;
          const label = typeof field !== 'string' ? field.label : field;
          const options = field.options || [];
          const required = field.required;
          const valueGetter = field.valueGetter;
          const renderCustomField = field.renderCustomField;
          return (
            <Col key={key} xs={12} md={md} className={`mt-${mt} px-2 ${fontSize}`}>
              <p className="mb-0 text-dark ">
                <span className="text-muted mid">{label}: </span>
                {required && isEditing && <span className="text-danger">* </span>}
              </p>
              {isEditing ? (
                <>
                  {fieldType === 'custom' ? (
                    <CustomField
                      value={valueGetter ? valueGetter(editingObject) : 'N/A'}
                      data={editingObject[field?.key]}
                      render={(data, closePopup) => renderCustomField(field, data, setFormControlValue, closePopup)}
                    />
                  ) : dropdownFields.includes(key) ? (
                    <select
                      disabled={showProgress}
                      value={getFormControlValue(field)}
                      onChange={e => setFormControlValue(field, e.target.value)}
                      style={{ fontSize: 12 }}
                      className="form-control form-control-sm mb-0  text-dark px-2 py-1 bg-primary-light rounded"
                    >
                      {!required && <option value={null}>Select one...</option>}
                      {options.map(o => (
                        <option key={o.value} value={o.value}>
                          {o.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <FormControl
                      disabled={showProgress}
                      style={{ fontSize: 12 }}
                      type={getFormControlType(key)}
                      value={getFormControlValue(field)}
                      onChange={e => setFormControlValue(field, e.target.value)}
                      className="mb-0  text-dark px-2 py-1 bg-primary-light rounded"
                      size="sm"
                    />
                  )}
                </>
              ) : (
                <p className="mb-0  text-dark px-2 py-1 bg-primary-light rounded">
                  {valueGetter
                    ? valueGetter(editingObject)
                    : data[key]
                    ? getValue(key, field.isObject, field.objectKey)
                    : 'N/A'}
                </p>
              )}
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default OverViewColumns;
