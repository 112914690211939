import React, { useState } from 'react';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { setAttribute } from '../../form-generator/helpers/utility';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { getObjectForPrefill } from '../../helpers/formHelpers';
import { makeReadyFormJson } from './form';

const MakeReadyCheckList = ({ contract, disabled = false }) => {
  const [form, setForm] = useState(makeReadyFormJson);

  const onMakeReadyFormSubmit = async form => {
    toast.info('Please wait, updating report...');

    const { response, error } = await makeApiRequests({
      requestBody: { makereadyReport: form },
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contract._id)
    });

    if (error) return toast.error(error);

    toast.success('Report updated successfully!');
    setTimeout(() => document.location.reload(), 1000);
  };

  const onApplianceCheckChange = e => {
    const checked = e ? e.target.checked : document.getElementById('appliances').checked;
    if (!checked) {
      document.getElementById('removingShipping').checked = false;
      document.getElementById('levelingAppliances').checked = false;
      document.getElementById('installingShelves').checked = false;
    }

    setAttribute(form, 'removingShipping', 'disabled', !checked);
    setAttribute(form, 'levelingAppliances', 'disabled', !checked);
    setAttribute(form, 'installingShelves', 'disabled', !checked);
    setForm({ ...form });
  };
  const onDryWallCheckChange = e => {
    const checked = e ? e.target.checked : document.getElementById('drywallCracksExcluding').checked;
    if (!checked) {
      document.getElementById('cracksLargeEnough').checked = false;
      document.getElementById('cracksInTheCornerOfRoom').checked = false;
    }

    setAttribute(form, 'cracksLargeEnough', 'disabled', !checked);
    setAttribute(form, 'cracksInTheCornerOfRoom', 'disabled', !checked);
    setForm({ ...form });
  };

  window['onDryWallCheckChange'] = onDryWallCheckChange;
  window['onApplianceCheckChange'] = onApplianceCheckChange;
  window['onMakeReadyFormSubmit'] = onMakeReadyFormSubmit;

  return (
    <FormGenerator
      formJson={makeReadyFormJson}
      formValues={{ 'Make Ready Form': getObjectForPrefill(contract.makereadyReport) }}
      formDisabled={disabled}
    />
  );
};

export default MakeReadyCheckList;
