import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, serviceSearchFilterOptions, serviceSearchSortByOptions } from '../../helpers/constants';
import HorizontalProgress from '../common/HorizontalProgress';
import SearchBox from '../common/searchbox';
import NotFound from '../NotFound';
import RequestItem from './RequestItem';

const BrowseRequests = ({ appChoices }) => {
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);

  const fetchRequests = async ({ sortBy, maxLimit, descSort, filters, query }) => {
    setLoading(true);

    const { error, response } = await makeApiRequests({
      endpoint: ENDPOINTS.SERVICE_REQUEST_LIST,
      requestBody: {
        filter: filters,
        query,
        maxLimit: maxLimit === 'All' ? undefined : maxLimit,
        sort: { [sortBy]: descSort ? -1 : 1 }
      }
    });
    setLoading(false);

    if (error) {
      return toast.error(error);
    }

    setRequests(response);
  };

  return (
    <>
      <SearchBox
        appChoices={appChoices}
        filterCreationOptions={serviceSearchFilterOptions}
        sortByOptions={serviceSearchSortByOptions}
        maxLimitOptions={[10, 20, 50, 100, 200, 'All']}
        onSearchOptionsChange={fetchRequests}
        disabled={loading}
      />
      {loading && <HorizontalProgress text={'Fetching requests...'} />}
      <hr />

      {requests.length > 0
        ? requests.map(request => <RequestItem key={request['_id']} request={request} fromBrowse />)
        : !loading && <NotFound text="No requests to show!" />}
    </>
  );
};

export default BrowseRequests;
