import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, ProgressBar } from 'react-bootstrap';
import FormGenerator from '../../form-generator/FormGenerator';

export default function SlotNumberModal({
  show,
  onHide,
  onSubmit,
  progressState,
  setOfficelBuilding,
  officeBuilding,
  initialSlotNumber = '',
  initialIsOfficeBuilding = false,
  modalAction = 'create'
}) {
  const [slotNumber, setSlotNumber] = useState('');

  useEffect(() => {
    if (show) {
      setSlotNumber(initialSlotNumber);
      setOfficelBuilding(initialIsOfficeBuilding);
    }
  }, [show, initialSlotNumber, initialIsOfficeBuilding, setOfficelBuilding]);

  const handleSlotNumberChange = e => {
    setSlotNumber(e.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (officeBuilding || slotNumber.trim()) {
      try {
        await onSubmit(slotNumber);
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }
  };

  const handleOfficeBuildingChange = e => {
    setOfficelBuilding(e.target.checked);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton={!progressState}>
        <Modal.Title>
          <h6 className="mb-0">
            {modalAction === 'edit' && 'Edit Slot'}
            {modalAction === 'clone' && 'Clone Slot'}
            {modalAction === 'create' && 'Create New Slot'}
          </h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Please provide a unique slot number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Slot number..."
              value={slotNumber}
              onChange={handleSlotNumberChange}
              required={!officeBuilding}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Is this slot in an office building?"
              checked={officeBuilding}
              onChange={handleOfficeBuildingChange}
            />
          </Form.Group>
          {progressState && <ProgressBar now={100} animated striped label={`Processing...`} className="my-1" />}
          <Button variant="primary" type="submit" disabled={progressState}>
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
