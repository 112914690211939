import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import CachedResponse from '../common/CachedResponse';
import NotFound from '../NotFound';
import CreateForm from './CreateForm';
import EditForm from './EditForm';
import InstallerReport from './InstallerReport';
import MakeReadyReport from './MakeReadyReport';
import SearchPage from './Search';
import WalkthroughReport from './WalkThroughReport';

const Contract = () => {
  const [role] = useState(localStorage.getItem('user-role'));

  const getActivePage = () => {
    return (
      <Switch>
        <Route exact path="/contract/search">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <SearchPage appChoices={appChoices} />} />
        </Route>
        {['super admin', 'admin'].includes(role) && (
          <Route exact path="/contract/create">
            <CachedResponse
              listEndpoint={'app-choices'}
              render={appChoices => <CreateForm appChoices={appChoices} />}
            />
          </Route>
        )}
        <Route exact path="/contract/:id">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <EditForm appChoices={appChoices} />} />
        </Route>
        <Route exact path="/contract/:id/report/walkthrough">
          <WalkthroughReport />
        </Route>
        <Route exact path="/contract/:id/report/makeReady">
          <MakeReadyReport />
        </Route>
        <Route exact path="/contract/:id/report/installer">
          <InstallerReport />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  };

  if (['inventory manager'].includes(role)) {
    return <NotFound text="You are not authorized to view this page" />;
  }

  return getActivePage();
};

export default Contract;
