import moment from 'moment';
import React, { useMemo } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

const Comments = ({ comments, disabled, onCommentSubmit, commentText, onCommentTextChange, onCommentDeleteClick }) => {
  const role = useMemo(() => localStorage.getItem('user-role'), []);

  return (
    <>
      <h6>Comments</h6>
      {comments && comments.length > 0 ? (
        comments.map(comment => {
          return (
            <div key={comment['_id']}>
              <div className="border bg-light rounded px-3 py-2 mt-1 midFont">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <b className="mb-0 text-info">{comment['userName']}</b>
                  </div>
                  {role === 'super admin' && onCommentDeleteClick && (
                    <div className="text-danger hover-underline tinyFont" onClick={() => onCommentDeleteClick(comment)}>
                      <Trash size={10} /> Delete
                    </div>
                  )}
                </div>
                {comment['comment']}
              </div>
              <p className=" text-right mb-1">
                <i className="text-muted d-block tinyFont">
                  On {moment(comment['timestamp']).format('MMMM Do YYYY, h:mm:ss a')}
                </i>
              </p>
            </div>
          );
        })
      ) : (
        <p className="text-secondary midFont my-1">No comments to show!</p>
      )}

      <hr className="my-2" />
      <FormControl
        id="comments"
        as="textarea"
        className="mt-4"
        size="sm"
        value={commentText}
        onChange={e => onCommentTextChange(e.target.value)}
        placeholder="Type your comment here..."
      ></FormControl>

      <div className="text-right">
        <Button disabled={disabled || commentText.length === 0} className="mt-2" size={'sm'} onClick={onCommentSubmit}>
          Add Comment
        </Button>
      </div>
    </>
  );
};

export default Comments;
