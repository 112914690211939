import React, { useEffect, useState } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, myClaimsSearchFilterOptions, newServiceSearchSortByOptions } from '../../helpers/constants';
import { getProfileFromLocalStorage } from '../../helpers/session';
import HorizontalProgress from '../common/HorizontalProgress';
import SearchBox from '../common/searchbox';
import NotFound from '../NotFound';
import RequestItem from './RequestItem';

const ResolutionMessageModal = ({
  activeRequest,
  onHide,
  resolutionMessage,
  onResolutionMessageChange,
  resolvingRequest,
  onResolveClick
}) => {
  return (
    <Modal show={activeRequest != null} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!resolvingRequest}>
        <Modal.Title>Add message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Please provide resolution message
        <FormControl
          value={resolutionMessage}
          as="textarea"
          className="mt-2"
          placeholder="add message here..."
          onChange={e => onResolutionMessageChange(e.target.value)}
        />
        {resolvingRequest && <HorizontalProgress text="Resolving request..." />}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="primary" onClick={onResolveClick} disabled={resolvingRequest}>
          Submit Resolution
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const MyClaims = () => {
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [activeRequest, setActiveRequest] = useState(null);
  const [resolvingRequest, setResolvingRequest] = useState(false);
  const [resolutionMessage, setResolutionMessage] = useState('');

  useEffect(() => {
    setResolutionMessage('');
  }, [activeRequest]);

  const fetchRequests = async ({ sortBy, filters, maxLimit, descSort, query }) => {
    setLoading(true);

    const { error, response } = await makeApiRequests({
      endpoint: ENDPOINTS.SERVICE_REQUEST_LIST,
      requestBody: {
        filter: {
          ...filters,
          claimedBy: getProfileFromLocalStorage()._id
        },
        query,
        maxLimit: maxLimit === 'All' ? undefined : maxLimit,
        sort: { [sortBy]: descSort ? -1 : 1 }
      }
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setRequests(response);
  };

  const handleCloseComment = () => {
    setActiveRequest(null);
  };

  const showResolveRequestDialog = request => {
    setActiveRequest(request);
  };

  const resolveRequest = async () => {
    if (!resolutionMessage) {
      toast.error('Please provide a resolution message!');
      return;
    }

    setResolvingRequest(true);

    const { error, response } = await makeApiRequests({
      endpoint: ENDPOINTS.SERVICE_REQUEST_RESOLVE,
      requestBody: { resolutionMessage: resolutionMessage, _id: activeRequest._id }
    });

    setResolvingRequest(false);

    if (error) {
      return toast.error(error);
    }

    toast.success('Succesfully resolved request!');

    const claimIndex = requests.findIndex(req => req['_id'] === activeRequest['_id']);
    requests[claimIndex] = response;
    setRequests([...requests]);
    setActiveRequest(null);
  };

  return (
    <>
      <SearchBox
        filterCreationOptions={myClaimsSearchFilterOptions}
        sortByOptions={newServiceSearchSortByOptions}
        maxLimitOptions={[10, 20, 50, 100, 200, 'All']}
        onSearchOptionsChange={fetchRequests}
        disabled={loading}
      />
      {loading && <HorizontalProgress text={'Fetching requests...'} />}
      <hr />

      {requests.length > 0 ? (
        <>
          {requests.map(request => (
            <RequestItem
              key={request['_id']}
              request={request}
              onResolveClick={() => showResolveRequestDialog(request)}
            />
          ))}
          <ResolutionMessageModal
            activeRequest={activeRequest}
            onHide={handleCloseComment}
            resolutionMessage={resolutionMessage}
            onResolutionMessageChange={setResolutionMessage}
            resolvingRequest={resolvingRequest}
            onResolveClick={resolveRequest}
          />
        </>
      ) : (
        !loading && <NotFound text="No unclaimed requests to show!" />
      )}
    </>
  );
};

export default MyClaims;
