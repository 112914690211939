import React from 'react';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import EditForm from './EditForm';

const ContractEditSideBar = ({
  onHide,
  editingContract,
  appChoices,
  onContractDelete,
  onContractEdit,
  onContractFileLoaded
}) => {
  return (
    <SlidingSideBar fullScreen visible={editingContract !== null} onClose={onHide} title={'Contract Details'}>
      {editingContract && (
        <EditForm
          contractFromSearch={editingContract}
          appChoices={appChoices}
          fromSearch
          onContractDelete={onContractDelete}
          onContractEdit={onContractEdit}
          onSearchContractFileLoaded={onContractFileLoaded}
        />
      )}
    </SlidingSideBar>
  );
};

export default ContractEditSideBar;
