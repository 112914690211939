import { uniqueId } from 'lodash';
import React from 'react';
import { Button } from 'react-bootstrap';
import Field from './Field';
import { appChoicesKeys } from '../../../helpers/constants';

const FormBuilder = ({ fields = [], onFieldsChange, activeModel, onSubmit, disabled }) => {
  const addField = field => {
    onFieldsChange([...fields, { ...field, model: activeModel, uuid: uniqueId() }]);
  };

  const changeField = (uuid, newField) => {
    onFieldsChange(fields.map(field => (field.uuid === uuid ? newField : field)));
  };

  const deleteField = uuid => {
    onFieldsChange(fields.filter(field => field.uuid !== uuid));
  };

  return (
    <div>
      <div className="d-flex mb-2 align-items-center">
        <div className="flex-grow-1">
          <h6 className="mb-0 midFont">Fields for {activeModel}</h6>
        </div>
      </div>
      <hr className="my-1" />

      <div>
        {fields.filter(f => f.model === activeModel).length > 0 ? (
          fields
            .filter(f => f.model === activeModel)
            .map((field, index) => (
              <Field
                fieldIndex={index}
                key={field.uuid}
                field={field}
                onChange={newField => changeField(field.uuid, newField)}
                onDelete={() => deleteField(field.uuid)}
              />
            ))
        ) : (
          <h6 className="text-center p-5 text-muted">Nothing to show!</h6>
        )}
      </div>
      <div className="text-right">
        <Button
          disabled={disabled}
          size="sm"
          variant="success"
          onClick={() =>
            addField({
              label: '',
              key: '',
              type: 'text',
              options: [],
              optionAppChoiceName: appChoicesKeys[0],
              takeOptionsFrom: 'appChoice'
            })
          }
        >
          Add New Field
        </Button>
        <Button disabled={disabled} size="sm" className="ml-2" onClick={onSubmit}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default FormBuilder;
