import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, FormControl, Row, Table } from 'react-bootstrap';
import { Pencil, Files, Trash } from 'react-bootstrap-icons';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import { deleteFileFromServer, makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS } from '../../../helpers/constants';
import { downloadFileFromBase64 } from '../../../helpers/global';
import AppChoicesSidebar from '../../admin/AppChoicesSidebar';
import DateRangePicker from '../../DateRangePicker';
import Loader from '../../Loader';
import PayrollSideBar from '../payroll/PayrollSidebar';
import FileViewer from '../../common/FileViewer';
import AlertModal from '../../AlertModal';
import FloatingButton from '../../common/FloatingButton';
import PayrollAddSidebar from './PayrollAddSidebar';

const headers = ['Buyer', 'Sales Location', 'Categories', 'Contractor', 'Contractor Type', 'Payroll Value'];
const headerKeys = ['buyer', 'salesLocation', 'categories', 'contractor', 'contractorType', 'payrollValue'];

const getInitialDateRange = () => {
  const today = new Date();
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 2);
  return { startDate: startDate.toISOString(), endDate: today.toISOString() };
};

const PayrollExplorer = ({
  appChoices: initAppChoices,
  activeContract,
  payrollBillMeta,
  onPayrollBillMetaChange,
  submitPayroll,
  updatePayroll,
  payrolls,
  onPayrollsChange,
  loading,
  deleteItem,
  deleteInProgress,
  setToBeDeletedBill,
  toBeDeletedBill,
  onPayrollAdd,
  showRecordPayrollSidebar,
  onShowRecordPayrollSidebarChange,
  onInternalPayrollFileDeletion
}) => {
  const [appChoices, setAppChoices] = useState(initAppChoices);
  const [query, setQuery] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [statusOptions] = useState(appChoices.find(ac => ac.key === 'Status').values);
  const [status, setSelectedStatus] = useState([...statusOptions]);

  const [salesLocationOptions] = useState(appChoices.find(ac => ac.key === 'salesLocation').values);
  const [salesLocation, setSelectedSalesLocation] = useState([...salesLocationOptions]);

  const [manufacturerOptions, setManufacturerOptions] = useState([
    ...appChoices.find(ac => ac.key === 'manufacturer').values
  ]);
  const [manufacturer, setSelectedManufacterer] = useState([...manufacturerOptions]);

  const [lenderOptions, setLenderOptions] = useState([...appChoices.find(ac => ac.key === 'lender').values]);
  const [lender, setSelectedLender] = useState([...lenderOptions]);

  const [floorplanLenderOptions, setFloorplanLenderOptions] = useState([
    ...appChoices.find(ac => ac.key === 'floorplanLender').values
  ]);
  const [floorplanLender, setSelectedFloorplanLender] = useState([...floorplanLenderOptions]);

  const [installerOptions, setInstallerOptions] = useState([...appChoices.find(ac => ac.key === 'installer').values]);
  const [installer, setSelectedInstaller] = useState([...installerOptions]);

  const [generalContractorOptions, setGeneralContractorOptions] = useState([
    ...appChoices.find(ac => ac.key === 'generalContractor').values
  ]);
  const [generalContractor, setSelectedGeneralContractor] = useState([...generalContractorOptions]);

  const [techOptions, setTechOptions] = useState([...appChoices.find(ac => ac.key === 'tech').values]);
  const [tech, setSelectedTech] = useState([...techOptions]);

  const [selectedDateRange, setSelectedDateRange] = useState(getInitialDateRange());

  const [filteredPayrolls, setFilteredPayrolls] = useState([]);
  const [excludedPayrolls, setExcludedPayrolls] = useState([]);
  const [showAppChoiceUpdatePopup, setShowAppChoiceUpdatePopup] = useState(false);
  const [fileViewerActiveUnit, setFileViewerActiveUnit] = useState(null);
  const [deleteFileModalMeta, setDeleteFileModalMeta] = useState(null);

  useEffect(() => {
    const manufacturerOptions = [...appChoices.find(ac => ac.key === 'manufacturer').values];
    setManufacturerOptions(manufacturerOptions);
    setSelectedManufacterer([...manufacturerOptions]);

    const lenderOptions = [...appChoices.find(ac => ac.key === 'lender').values];
    setLenderOptions(lenderOptions);
    setSelectedLender([...lenderOptions]);

    const floorplanLenderOptions = [...appChoices.find(ac => ac.key === 'floorplanLender').values];
    setFloorplanLenderOptions(floorplanLenderOptions);
    setSelectedFloorplanLender([...floorplanLenderOptions]);

    const installerOptions = [...appChoices.find(ac => ac.key === 'installer').values];
    setInstallerOptions(installerOptions);
    setSelectedInstaller([...installerOptions]);

    const generalContractorOptions = [...appChoices.find(ac => ac.key === 'generalContractor').values];
    setGeneralContractorOptions(generalContractorOptions);
    setSelectedGeneralContractor([...generalContractorOptions]);

    const techOptions = [...appChoices.find(ac => ac.key === 'tech').values];
    setTechOptions(techOptions);
    setSelectedTech([...techOptions]);
  }, [appChoices]);

  useEffect(() => {
    if (fileViewerActiveUnit) {
      setFileViewerActiveUnit(payrolls.find(u => u._id === fileViewerActiveUnit._id));
    }
  }, [payrolls]);

  const filterPayrolls = () => {
    const startDate = new Date(selectedDateRange.startDate).getTime();
    const endDate = new Date(selectedDateRange.endDate).getTime();

    const tempPayrolls =
      payrolls &&
      payrolls.filter(payroll => {
        if (!payroll.contract || !payroll.contract.buyer.toLowerCase().includes(query.trim().toLowerCase())) {
          return false;
        }

        const payrollDate = new Date(payroll.date)?.getTime();
        const updatedPayrollDate = new Date(payrollDate * 1000)?.setHours(0, 0, 0, 0);
        const payrollUnixTime12am = Math.floor(new Date(updatedPayrollDate).getTime() / 1000);

        const dateFilter = payrollUnixTime12am >= startDate && payrollUnixTime12am <= endDate;

        const contractFilter =
          dateFilter &&
          [
            { title: 'status', value: status },
            {
              title: 'salesLocation',
              value: salesLocation,
              isObject: true
            }
          ].every(({ title, value, isObject }) => {
            if (!Array.isArray(value)) value = [value];

            if (isObject) {
              value = value.map(v => v._id);
            }

            return value.includes(isObject ? payroll.contract[title]?._id : payroll.contract[title]);
          });

        return (
          contractFilter &&
          [
            {
              title: 'manufacturer',
              value: manufacturer,
              isObject: true
            },
            { title: 'lender', value: lender, isObject: true },
            {
              title: 'floorplanLender',
              value: floorplanLender,
              isObject: true
            },
            {
              title: 'installer',
              value: installer,
              isObject: true
            },
            {
              title: 'tech',
              value: tech,
              isObject: true
            },
            {
              title: 'generalContractor',
              value: generalContractor,
              isObject: true
            }
          ].some(({ title, value, isObject }) => {
            if (!Array.isArray(value)) value = [value];

            if (isObject) {
              value = value.map(v => v._id);
            }

            return value.includes(isObject ? payroll.contractor._id : payroll[title]);
          })
        );
      });

    const groupedPayrolls = [];

    for (const location of salesLocationOptions) {
      if (groupedPayrolls && tempPayrolls) {
        groupedPayrolls.push(...tempPayrolls.filter(p => p.contract.salesLocation._id === location._id));
      }
    }

    setFilteredPayrolls(groupedPayrolls);
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    setExcludedPayrolls([]);
    filterPayrolls();
  }, [
    payrolls,
    status,
    lender,
    floorplanLender,
    manufacturer,
    installer,
    generalContractor,
    tech,
    salesLocation,
    selectedDateRange,
    query
  ]);

  const SelectionBox = () => {
    return (
      <>
        <Card className="border rounded">
          <Card.Header className="bg-primary">
            <h5 className="mb-0 text-white">Explore Payrolls</h5>
          </Card.Header>
          <Card.Body className="py-3">
            <Row>
              {[
                ...(activeContract
                  ? []
                  : [
                      {
                        title: 'Status',
                        value: status,
                        options: statusOptions,
                        onChange: setSelectedStatus
                      },
                      {
                        title: 'Sales Location',
                        value: salesLocation,
                        options: salesLocationOptions,
                        onChange: setSelectedSalesLocation,
                        isObject: true,
                        objectLabelKey: 'location'
                      }
                    ]),
                {
                  title: 'Manufacturer',
                  value: manufacturer,
                  options: manufacturerOptions,
                  onChange: setSelectedManufacterer,
                  isObject: true,
                  objectLabelKey: 'name'
                },
                {
                  title: 'Lender',
                  value: lender,
                  options: lenderOptions,
                  onChange: setSelectedLender,
                  isObject: true,
                  objectLabelKey: 'lienholderCorporateName'
                },
                {
                  title: 'Floorplan Lender',
                  value: floorplanLender,
                  options: floorplanLenderOptions,
                  onChange: setSelectedFloorplanLender,
                  isObject: true,
                  objectLabelKey: 'name'
                },
                {
                  title: 'Installer',
                  value: installer,
                  options: installerOptions,
                  onChange: setSelectedInstaller,
                  isObject: true,
                  objectLabelKey: 'name'
                },
                {
                  title: 'General Contractor',
                  value: generalContractor,
                  options: generalContractorOptions,
                  onChange: setSelectedGeneralContractor,
                  isObject: true,
                  objectLabelKey: 'name'
                },
                {
                  title: 'Tech',
                  value: tech,
                  options: techOptions,
                  onChange: setSelectedTech,
                  isObject: true,
                  objectLabelKey: 'name'
                }
              ].map(dropdownMeta => (
                <Col key={dropdownMeta.title} xs={12} md={3} className="px-4 mt-3">
                  <h6 className="mb-2">{dropdownMeta.title}</h6>
                  <MultiSelect
                    showCheckbox
                    closeOnChangedValue={false}
                    className="midFont"
                    value={dropdownMeta.value.map(v =>
                      dropdownMeta.isObject
                        ? { label: v[dropdownMeta.objectLabelKey], value: v._id }
                        : { label: v, value: v }
                    )}
                    options={dropdownMeta.options.map(o =>
                      dropdownMeta.isObject
                        ? { label: o[dropdownMeta.objectLabelKey], value: o._id }
                        : { label: o, value: o === 'Unassigned' ? null : o }
                    )}
                    onChange={list =>
                      dropdownMeta.onChange(
                        list.map(listOption =>
                          dropdownMeta.isObject
                            ? dropdownMeta.options.find(o => o._id === listOption.value)
                            : listOption.value
                        )
                      )
                    }
                  />
                </Col>
              ))}
              <Col xs={12} className="px-4 mt-3">
                <h6 className="mb-2">Select Date Range</h6>
                <DateRangePicker defaultDates={selectedDateRange} onDateChange={setSelectedDateRange} />
              </Col>
              <Col xs={12} className="mt-3 text-right">
                <Button onClick={printDocument} disabled={submitting || filteredPayrolls.length === 0}>
                  Print
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onExcludePayrollChange = (id, checked) => {
    if (checked) {
      const exisiting = excludedPayrolls.indexOf(id);
      if (exisiting !== -1) excludedPayrolls.splice(exisiting, 1);
    } else {
      if (!excludedPayrolls.includes(id)) excludedPayrolls.push(id);
    }
    setExcludedPayrolls([...excludedPayrolls]);
  };

  const printDocument = async () => {
    toast.info('Submitting report, please wait...');
    setSubmitting(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.DOCHUB_PRINT_TABLE,
      requestBody: {
        table: [
          headers,
          ...filteredPayrolls
            .filter(p => !excludedPayrolls.includes(p['_id']))
            .map(p =>
              headerKeys.map(headerKey => {
                if (headerKey === 'payrollValue')
                  return p.items.reduce((prevValue, item) => prevValue + (item.invoiceAmount || 0), 0).toFixed(2);

                if (headerKey === 'buyer') return p.contract.buyer;
                if (headerKey === 'categories') return p.items.map(i => i.category).join(', ');
                if (headerKey === 'status') return p.contract.status;
                if (headerKey === 'salesLocation') return p.contract.salesLocation.location;

                if (!p[headerKey]) return '';

                if (headerKey === 'contractor') return p.contractor.lienholderCorporateName || p.contractor.name;

                return typeof p[headerKey] === 'object' ? p[headerKey].name : p[headerKey];
              })
            )
        ],
        fileName: `payroll-${new Date().toDateString()}.pdf`
      }
    });

    setSubmitting(false);
    if (error) return toast.error(error);

    toast.success('Document created successfully, file is being downloaded...');
    const { fileName, data, mimeType } = response;
    downloadFileFromBase64({ fileName, data, mimeType });
  };

  const onAppChoicesUpdate = appChoices => {
    setAppChoices(appChoices);
    setShowAppChoiceUpdatePopup(false);
  };

  const onFileDeleteClick = file => {
    setDeleteFileModalMeta({ file });
  };

  const deleteFile = async () => {
    const { file: toBeDeletedFile } = deleteFileModalMeta;
    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: true });

    const newFileArray = fileViewerActiveUnit.files.filter(f => f._id !== toBeDeletedFile._id).map(f => f._id);

    const { response, error } = await makeApiRequests({
      requestBody: { files: newFileArray },
      endpoint: ENDPOINTS.PAYROLLS_WITH_ID(fileViewerActiveUnit._id),
      method: 'PUT'
    });

    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: false });
    if (error) {
      return toast.error(error);
    }

    setDeleteFileModalMeta(null);
    updatePayroll(response);
    deleteFileFromServer(toBeDeletedFile);
    onInternalPayrollFileDeletion(fileViewerActiveUnit?._id, toBeDeletedFile?._id);
  };

  const onRecordPayrollClick = () => {
    onShowRecordPayrollSidebarChange(true);
  };

  return (
    <div className="px-0 px-md-4 py-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          {SelectionBox()}
          <hr />
          <h6>Payrolls:</h6>
          <FormControl
            size={'sm'}
            placeholder="Search by buyer..."
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
          <Table style={{ fontSize: 12 }} responsive bordered className="text-center mt-2">
            <tr className="bg-dark text-light">
              <th>Print?</th>
              {headers.map(h => (
                <th key={`header-${h}`}>{h}</th>
              ))}
              <th>Action</th>
            </tr>
            {filteredPayrolls.length > 0 ? (
              <>
                {filteredPayrolls.map(p => (
                  <tr key={p['_id']}>
                    <td>
                      <FormControl
                        checked={!excludedPayrolls.includes(p['_id'])}
                        style={{ width: 16, height: 16 }}
                        type="checkbox"
                        onChange={e => onExcludePayrollChange(p['_id'], e.target.checked)}
                      />
                    </td>
                    <td>{p.contract.buyer}</td>
                    <td>{p.contract.salesLocation.location}</td>

                    <td>{p.items.map(i => i.category).join(',')}</td>
                    <td>{p['contractor'].lienholderCorporateName || p['contractor'].name}</td>
                    <td>{p['contractorType']}</td>
                    <td>${p.items.reduce((prevValue, item) => prevValue + (item.invoiceAmount || 0), 0).toFixed(2)}</td>
                    <td>
                      <Files className="hover-light mr-2" onClick={() => setFileViewerActiveUnit(p)} />
                      <Pencil
                        className="hover-light mr-2"
                        onClick={() =>
                          onPayrollBillMetaChange({
                            contract: p,
                            payrollBill: cloneDeep(p)
                          })
                        }
                      />
                      <Trash className="text-danger hover-light mr-2" onClick={() => setToBeDeletedBill(p)} />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={headers.length} className="text-right">
                    <b>Total</b>
                  </td>
                  <td>
                    $
                    {filteredPayrolls
                      .flatMap(c => c.items)
                      .reduce((prevValue, item) => prevValue + (item.invoiceAmount || 0), 0)
                      .toFixed(2)}
                  </td>
                  <td></td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={headers.length + 2}>No payrolls to show</td>
              </tr>
            )}
          </Table>
          {filteredPayrolls.length > 0 && (
            <>
              <PayrollSideBar
                zIndex={2000}
                show={payrollBillMeta !== null}
                payrollBill={payrollBillMeta && payrollBillMeta.payrollBill}
                appChoices={appChoices}
                onHide={() => onPayrollBillMetaChange(null)}
                onPayrollBillChange={payrollBill => onPayrollBillMetaChange({ ...payrollBillMeta, payrollBill })}
                buttonsDisabled={payrollBillMeta && payrollBillMeta.submittingPayroll}
                onPayrollSubmit={submitPayroll}
                onNewContractorAddClick={() => setShowAppChoiceUpdatePopup(true)}
              />
              <AppChoicesSidebar
                zIndex={3000}
                entityToExclude={['Print Groups', 'Sales Locations']}
                onAppChoicesUpdate={onAppChoicesUpdate}
                show={showAppChoiceUpdatePopup}
                onHide={() => setShowAppChoiceUpdatePopup(false)}
              />
              <FileViewer
                sidebarZIndex={2000}
                show={fileViewerActiveUnit !== null}
                onHide={() => setFileViewerActiveUnit(null)}
                files={fileViewerActiveUnit ? fileViewerActiveUnit['files'] : []}
                onFileDeleteClick={onFileDeleteClick}
                driveFolder={fileViewerActiveUnit ? fileViewerActiveUnit['driveFolder'] : fileViewerActiveUnit}
              />
              <AlertModal
                show={deleteFileModalMeta !== null}
                alertText={`Are you sure to delete this file (${
                  deleteFileModalMeta
                    ? deleteFileModalMeta.file.metadata && deleteFileModalMeta.file.metadata.originalName
                      ? deleteFileModalMeta.file.metadata.originalName
                      : deleteFileModalMeta.file.filename
                    : ''
                }). You will lose access to this file permanently?`}
                showProgress={deleteFileModalMeta && deleteFileModalMeta.deletingFile}
                progressText="Deleting file..."
                continueButtonText="Delete"
                onDismissClick={() => setDeleteFileModalMeta(null)}
                onHide={() => setDeleteFileModalMeta(null)}
                onContinueClick={deleteFile}
              />
              <AlertModal
                show={toBeDeletedBill !== null}
                alertText={`Are you sure you want to delete this payroll bill? This action cannot be undone!`}
                onHide={() => setToBeDeletedBill(null)}
                onDismissClick={() => setToBeDeletedBill(null)}
                onContinueClick={deleteItem}
                progressText={`Deleting payroll...`}
                showProgress={deleteInProgress}
              />
            </>
          )}
          <h6 className="text-right text-muted mb-5">
            Total Reports: <span className="text-dark">{filteredPayrolls.length}</span>
          </h6>
          <PayrollAddSidebar
            show={showRecordPayrollSidebar}
            activeContract={activeContract}
            onHide={() => onShowRecordPayrollSidebarChange(false)}
            onPayrollAdd={onPayrollAdd}
          />
          <FloatingButton text={'Record New Payroll'} onClick={onRecordPayrollClick} variant="success" />
        </>
      )}
    </div>
  );
};

export default PayrollExplorer;
