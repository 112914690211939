import React, { useEffect, useState } from 'react';
import { Card, Container, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import Loader from '../Loader';
import ContractSearchItem from '../main/ContractSearchItem';
import MakeReadyCheckList from './MakeReadyCheckList';
import WalkThroughCheckList from './WalkThroughCheckList';

const OpsDetails = () => {
  const { id } = useParams();
  const [key, setKey] = useState('walkThroughCheckList');
  const [formLoading, setFormLoading] = useState(true);
  const [contract, setContract] = useState();

  const loadContractData = async () => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH,
      requestBody: {
        filter: {
          _id: id
        }
      }
    });

    setFormLoading(false);

    if (error) {
      return toast.error(error);
    }

    if (response.length === 0) {
      return toast.error('No contract found!');
    }

    setContract(response[0]);
  };

  useEffect(() => {
    loadContractData();
  }, []);

  const Component = ({ tab }) => {
    switch (tab) {
      case 'makeReadyChecklist': {
        return <MakeReadyCheckList contract={contract} />;
      }
      case 'walkThroughCheckList': {
        return <WalkThroughCheckList contract={contract} />;
      }
    }
  };

  return (
    <Container fluid className="py-2 px-md-5">
      {formLoading || !contract ? (
        <Loader />
      ) : (
        <>
          <ContractSearchItem contract={contract} fromOpsDetails />

          <Tabs activeKey={key} onSelect={key => setKey(key)}>
            <Tab eventKey="walkThroughCheckList" title="Walk-Through Checklist" />
            <Tab eventKey="makeReadyChecklist" title="Make Ready Checklist" />
          </Tabs>
          <Card style={{ borderTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            <Card.Body>
              <Component tab={key} />
            </Card.Body>
          </Card>
        </>
      )}
    </Container>
  );
};

export default OpsDetails;
