import React, { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import Loader from '../Loader';
import ContractSearchItem from '../main/ContractSearchItem';
import InstallerForm from './InstallerForm';

const InstallerDetails = () => {
  const { id } = useParams();
  const [formLoading, setFormLoading] = useState(true);
  const [contract, setContract] = useState();

  const loadContractData = async () => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH,
      requestBody: {
        filter: {
          _id: id
        }
      }
    });

    setFormLoading(false);

    if (error) {
      return toast.error(error);
    }

    if (response.length === 0) {
      return toast.error('No contract found!');
    }

    setContract(response[0]);
  };

  useEffect(() => {
    loadContractData();
  }, []);

  return (
    <>
      <Container fluid className="py-2 px-md-5 h-100">
        {formLoading || !contract ? (
          <Loader />
        ) : (
          <>
            <ContractSearchItem contract={contract} fromOpsDetails />
            <Card>
              <Card.Body>
                <InstallerForm contract={contract} />
              </Card.Body>
            </Card>
          </>
        )}
      </Container>
    </>
  );
};

export default InstallerDetails;
