import { normalizeId } from './utility';

export const fieldToKeyValue = (field, formObj) => {
  switch (field.type) {
    case 'table-input': {
      return getTableData(field, formObj);
    }
    case 'text': {
      return getInputData(field, formObj);
    }
    case 'datalist-text': {
      return getInputData(field, formObj);
    }
    case 'text-area': {
      return getInputData(field, formObj);
    }
    case 'number': {
      return getInputData(field, formObj);
    }
    case 'multi-text': {
      return getMultiInputData(field, formObj);
    }
    case 'multi-text-area': {
      return getMultiInputData(field, formObj, 'textarea');
    }
    case 'radio': {
      return getRadioGroupData(field, formObj);
    }
    case 'checkbox-group': {
      return getCheckBoxGroupData(field, formObj);
    }
    case 'radio-score': {
      return getRadioScoreData(field, formObj);
    }
    case 'checkbox': {
      return getCheckBoxData(field, formObj);
    }
    case 'dropdown': {
      return getInputData(field, formObj);
    }
    case 'file': {
      return getFileData(field, formObj);
    }
    case 'key-value': {
      return getKeyValueData(field, formObj);
    }
    case 'key-value-switch': {
      return getKeyValueSwitchData(field, formObj);
    }
    case 'key-value-switch-free': {
      return getFreeKeyValueSwitchData(field, formObj);
    }
    case 'three-state-switch': {
      return getThreeStateSwitchData(field, formObj);
    }
    case 'multiselect-dropdown': {
      return getMultiSelectDropDownData(field, formObj);
    }
  }
  return null;
};

const getTableData = (table, formObj) => {
  const tableName = table.id || table.title;
  const tableId = table.id || normalizeId(table.title);

  var tbodyRowCount = document.getElementById(tableId).tBodies[0]
    ? document.getElementById(tableId).tBodies[0].rows.length
    : 0;
  const tableRows = [];

  for (let rowIndex = 0; rowIndex < tbodyRowCount; rowIndex++) {
    const row = [];
    table.tableFields.forEach(field => {
      row.push(formObj[normalizeId(tableId + '-' + (field.key || field.title) + '-' + rowIndex)]);
    });
    tableRows.push(row);
  }

  return {
    key: tableName,
    value: tableRows
  };
};

const getInputData = (field, formObj) => {
  const inputName = field.id || field.title;
  const inputId = field.id || normalizeId(field.title);
  const fieldType = field.type;

  if (fieldType === 'dropdown' && formObj[inputId] === 'dropdown-placeholder') {
    formObj[inputId] = null;
  }

  return {
    key: field.key || inputName,
    value:
      (field.variant === 'date' || field.variant === 'datetime-local') && formObj[inputId]
        ? getDateWithTimezone(formObj[inputId], field.variant)
        : formObj[inputId]
  };
};

const getFileData = (field, formObj) => {
  const inputName = field.id || field.title;
  const inputId = field.id || normalizeId(field.title);

  const files = formObj[inputId];

  return {
    key: field.key || inputName,
    value: files
  };
};

const getMultiInputData = (field, formObj, tagName = 'input') => {
  const inputName = field.id || field.title;
  const inputContainerId = field.id || normalizeId(field.title);

  const inputCount = document.getElementById(inputContainerId).getElementsByTagName(tagName).length;

  const inputRows = [];

  for (let rowIndex = 0; rowIndex < inputCount; rowIndex++) {
    const row = formObj[inputContainerId + '-' + rowIndex];
    if (row) {
      inputRows.push(row);
    }
  }

  return {
    key: field.key || inputName,
    value: inputRows
  };
};

const getRadioGroupData = field => {
  const inputName = field.id || field.title;
  const inputID = field.id || field.title;
  const radioGroup = [...document.getElementsByName(inputID)];
  const checkedRadio = radioGroup.filter(radio => radio.checked)[0];

  return {
    key: field.key || inputName,
    value: checkedRadio ? checkedRadio.value : null
  };
};

const getCheckBoxGroupData = field => {
  const inputName = field.id || field.title;
  const inputID = field.id || field.title;
  const radioGroup = [...document.getElementsByName(inputID)];
  const checkedRadio = radioGroup.filter(radio => radio.checked).map(radio => radio.value);

  return {
    key: field.key || inputName,
    value: checkedRadio
  };
};

const getRadioScoreData = (field, formObj) => {
  const inputName = field.id || field.title;
  const value = {};

  field.scoreFields.forEach(scoreField => {
    const radioGroup = [...document.getElementsByName(scoreField)];
    const checkedRadio = radioGroup.filter(radio => radio.checked)[0].value;
    value[scoreField] = parseInt(checkedRadio);
  });

  return {
    key: field.key || inputName,
    value
  };
};

const getCheckBoxData = (field, formObj) => {
  const inputName = field.id || field.title;
  const inputId = field.id || normalizeId(field.title);

  return {
    key: field.key || inputName,
    value: formObj[inputId]
  };
};

const getBlockData = (field, formObj) => {
  const inputName = field.id || field.title;
  const inputId = field.id || normalizeId(field.title);

  const selectedValues = [...document.getElementById(`${inputId}-blocks`).getElementsByClassName('block')]
    .filter(block => block.getAttribute('data-selected') === 'true')
    .map(block => block.getAttribute('data-value'));

  return {
    key: field.key || inputName,
    value: field.multiple ? selectedValues : selectedValues.length > 0 ? selectedValues[0] : ''
  };
};

const getFileWithCheckedData = (field, formObj) => {
  const inputName = field.id || field.title;
  const inputId = field.id || normalizeId(field.title);

  const files = formObj[inputId];

  return {
    key: field.key || inputName,
    value: files
  };
};

const getMultiSelectDropDownData = field => {
  const inputName = field.id || field.title;
  const inputId = field.id;
  const values = document.getElementById(inputId).getAttribute('data-selections');

  return {
    key: field.key || inputName,
    value: values ? values.split(',') : []
  };
};

const getKeyValueData = (field, formObj) => {
  const inputId = field.id;

  const keyValueContainer = document.getElementById(inputId);

  const checkedKeys = field.includeUnselectedKeys
    ? [...keyValueContainer.getElementsByTagName('input')].filter(input => input.type === 'checkbox')
    : [...keyValueContainer.getElementsByTagName('input')].filter(input => input.type === 'checkbox' && input.checked);

  return {
    key: field.key || inputId,
    value: checkedKeys
      .map(input => ({ checked: input.checked, value: input.value }))
      .map(key => ({
        checked: key.checked,
        key: key.value,
        value: document.getElementById(`${inputId}-${normalizeId(key.value)}-key-value`).value
      }))
  };
};

const getKeyValueSwitchData = (field, formObj) => {
  const inputId = field.id;
  const title = field.title;
  const keyValueContainer = document.getElementById(inputId);

  const labels = [...keyValueContainer.getElementsByClassName('option-label')]
    .filter(input => input.tagName.toLowerCase() === 'label')
    .map(input => input.innerHTML.trim());

  return {
    key: field.key || inputId,
    value: [
      ...labels.map(key => ({
        key,
        value: [...document.getElementsByName(`${title}-${key}`)].filter(
          input => input.type === 'radio' && input.checked
        )[0].value
      })),
      { key: 'notes', value: document.getElementById(inputId + '-notes').value }
    ]
  };
};

const getFreeKeyValueSwitchData = (table, formObj) => {
  const tableName = table.id || table.title;
  const tableId = table.id || normalizeId(table.title);

  var tbodyRowCount = document.getElementById(tableId) ? document.getElementById(tableId).tBodies[0].rows.length : 0;
  const tableRows = [];

  for (let rowIndex = 0; rowIndex < tbodyRowCount; rowIndex++) {
    const key = document.getElementById(normalizeId(tableId + '-' + 'Key' + '-' + rowIndex)).value;

    const value = [...document.getElementsByName('Value' + '-' + rowIndex)].filter(
      input => input.type === 'radio' && input.checked
    )[0].value;

    const row = { key, value };
    tableRows.push(row);
  }

  tableRows.push({ key: 'notes', value: document.getElementById(tableId + '-notes').value });

  return {
    key: table.key || tableName,
    value: tableRows
  };
};

const getThreeStateSwitchData = (field, formObj) => {
  const inputId = field.id;

  const keyValueContainer = document.getElementById(inputId);

  const checkedKeys = [...keyValueContainer.getElementsByTagName('input')]
    .filter(input => input.type === 'checkbox' && input.checked)
    .map(input => input.value);

  return {
    key: field.key || inputId,
    value: checkedKeys.map(key => ({ key, value: document.getElementById(`${normalizeId(key)}-key-value`).value }))
  };
};

const getDateWithTimezone = (dateString, variant) => {
  try {
    if (variant === 'datetime-local') {
      const dateTime = dateString.split('T');
      const date = new Date(dateTime[0].split('-').join('/'));
      const hourMin = dateTime[1].split(':');
      date.setHours(Number(hourMin[0]), Number(hourMin[1]), 0, 0);
      return date.toISOString();
    }
    return new Date(dateString.split('-').join('/')).toISOString();
  } catch (e) {
    console.log(dateString, e);
  }
};
