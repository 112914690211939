import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, newServiceSearchSortByOptions } from '../../helpers/constants';
import HorizontalProgress from '../common/HorizontalProgress';
import SearchBox from '../common/searchbox';
import ContractsPicker from '../ContractsPicker';
import NotFound from '../NotFound';
import RequestItem from './RequestItem';

const NewRequests = () => {
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [activeRequest, setActiveRequest] = useState(null);
  const [claimSubmitting, setClaimSubmitting] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);

  useEffect(() => {
    setSelectedContract(null);
  }, [activeRequest]);

  const fetchRequests = async ({ sortBy, maxLimit, descSort, query }) => {
    setLoading(true);

    const { error, response } = await makeApiRequests({
      endpoint: ENDPOINTS.SERVICE_REQUEST_LIST,
      requestBody: {
        filter: {
          status: 'Pending'
        },
        query,
        maxLimit: maxLimit === 'All' ? undefined : maxLimit,
        sort: { [sortBy]: descSort ? -1 : 1 }
      }
    });
    setLoading(false);

    if (error) {
      return toast.error(error);
    }

    setRequests(response);
  };

  const onContractPickerClose = () => {
    setActiveRequest(null);
  };

  const submitClaim = async contracts => {
    setClaimSubmitting(true);
    const { error, response } = await makeApiRequests({
      endpoint: ENDPOINTS.SERVICE_REQUEST_CLAIM,
      requestBody: { contract: contracts[0]._id, _id: activeRequest._id }
    });

    setClaimSubmitting(false);

    if (error) {
      return toast.error(error);
    }

    toast('Succesfully claimed request!', {
      type: 'success'
    });
    requests.splice(
      requests.findIndex(req => req['_id'] === activeRequest['_id']),
      1
    );
    setRequests([...requests]);
    setActiveRequest(null);
  };

  const claimRequest = request => {
    setActiveRequest(request);
  };

  return (
    <>
      <SearchBox
        sortByOptions={newServiceSearchSortByOptions}
        maxLimitOptions={[10, 20, 50, 100, 200, 'All']}
        onSearchOptionsChange={fetchRequests}
        disabled={loading}
      />
      {loading && <HorizontalProgress text={'Fetching requests...'} />}
      <hr />

      {requests.length > 0 ? (
        <>
          {requests.map(request => (
            <RequestItem key={request['_id']} request={request} onClaimClick={() => claimRequest(request)} />
          ))}
          <ContractsPicker
            show={activeRequest !== null}
            onContractPickerClose={onContractPickerClose}
            submitting={claimSubmitting}
            onSubmit={submitClaim}
            onContractSelect={c => setSelectedContract(c)}
            selectedContracts={selectedContract ? [selectedContract] : []}
          />
        </>
      ) : (
        !loading && <NotFound text="No unclaimed requests to show!" />
      )}
    </>
  );
};

export default NewRequests;
