import React from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import InventorySearchItem from './InventorySearchItem';

const NoUnitContracted = ({ onLinkUnitClick, disabled }) => {
  return (
    <div className="text-center ">
      <h6 className="smallFont">
        No unit has been linked to this contract.
        {!disabled && 'Tap on the button below to link one:'}
      </h6>
      {!disabled && onLinkUnitClick && (
        <Button className="tinyFont" variant="success" size="sm" onClick={onLinkUnitClick}>
          Link Unit
        </Button>
      )}
    </div>
  );
};

const ContractedUnitOverview = ({
  inventoryUnit,
  onRefreshUnitClick,
  onLinkUnitClick,
  onRemoveUnitClick,
  onChangeUnitClick,
  onEditUnitClick,
  refreshing,
  disabled
}) => {
  return (
    <>
      <div className="d-flex mb-2">
        <div className="flex-grow-1 align-self-center">
          <h6 className="mb-0">Contracted Unit</h6>
        </div>

        {onRefreshUnitClick && !disabled && (
          <div className="w-25 align-self-center text-right">
            {!refreshing ? (
              <Button variant="outline-primary" size="sm" className="ml-1 tinyFont" onClick={onRefreshUnitClick}>
                <ArrowClockwise className="" size={13} />
              </Button>
            ) : (
              <ProgressBar variant="primary" now={100} animated striped label="Refreshing" />
            )}
          </div>
        )}
      </div>

      <div className="border rounded p-3 mb-2">
        {inventoryUnit ? (
          <InventorySearchItem
            inventory={inventoryUnit}
            onRemoveClick={onRemoveUnitClick}
            onChangeClick={onChangeUnitClick}
            onEditClick={onEditUnitClick}
            mini
            disabled={disabled}
          />
        ) : (
          <NoUnitContracted disabled={disabled} onLinkUnitClick={onLinkUnitClick} />
        )}
      </div>
    </>
  );
};

export default ContractedUnitOverview;
