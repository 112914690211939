import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS } from '../../../helpers/constants';
import AppChoicesSidebar from '../../admin/AppChoicesSidebar';
import PayrollBill from './PayrollBill';

const PayrollRecord = ({ appChoices: initAppChoices, onPayrollAdd, activeContract, fromSidebar }) => {
  const [appChoices, setAppChoices] = useState(initAppChoices);
  const [payrollBill, setPayrollBill] = useState(null);
  const [showAppChoiceUpdatePopup, setShowAppChoiceUpdatePopup] = useState(false);
  const [submittingPayroll, setSubmittingPayroll] = useState(false);

  const submitPayroll = async (payroll, files) => {
    const newPayroll = {
      ...payroll,
      files
    };

    toast.info('Adding payroll bill...');
    setSubmittingPayroll(true);

    const { response, error } = await makeApiRequests({
      requestBody: newPayroll,
      endpoint: ENDPOINTS.PAYROLLS_BASE
    });

    if (error) {
      setSubmittingPayroll(false);
      return toast.error(error);
    }

    toast.success('Payroll bill added successfully...');

    if (onPayrollAdd) {
      onPayrollAdd(response);
    } else {
      setTimeout(() => window.location.reload(), 2000);
    }
  };

  const onAppChoicesUpdate = appChoices => {
    setAppChoices(appChoices);
    setShowAppChoiceUpdatePopup(false);
  };

  return (
    <Container fluid className={`px-0 px-md-4 ${fromSidebar ? 'py-1' : 'py-4'}`}>
      {!fromSidebar && <h5>Add New Payroll Bill</h5>}
      <PayrollBill
        activeContract={activeContract}
        payrollBill={payrollBill}
        appChoices={appChoices}
        onPayrollBillChange={setPayrollBill}
        buttonsDisabled={submittingPayroll}
        onPayrollSubmit={submitPayroll}
        onNewContractorAddClick={() => setShowAppChoiceUpdatePopup(true)}
      />
      <AppChoicesSidebar
        entityToExclude={['Print Groups', 'Sales Locations']}
        onAppChoicesUpdate={onAppChoicesUpdate}
        show={showAppChoiceUpdatePopup}
        zIndex={3000}
        onHide={() => setShowAppChoiceUpdatePopup(false)}
      />
    </Container>
  );
};

export default PayrollRecord;
