import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { XCircleFill } from 'react-bootstrap-icons';
import { DropDownInput, NumberInput, TextInput } from '../../form-generator/components';
import BlockSelectInput from '../../form-generator/components/BlockSelectInput';
import { causeOptions, descOptions, serviceOrderOptions, repairOptions } from '../../helpers/constants';

const ServiceOrderRow = ({ row, position, onCrossClick, showCloseButton, onRowChange }) => {
  const [description, setDescription] = useState(row['Description']);
  const [descriptionOther, setDescriptionOther] = useState(row['Description Other']);
  const [cause, setCause] = useState(row['Cause']);
  const [causeOther, setCauseOther] = useState(row['Cause Other']);
  const [repairMethod, setRepairMethod] = useState(row['Repair Method']);
  const [repairOther, setRepairOther] = useState(row['Repair Method Other']);
  const [part, setPart] = useState(row['Part']);
  const [location, setLocation] = useState(row['Location']);
  const [hour, setHour] = useState(row['Hours']);
  const [callOnChange, setCallOnChange] = useState(false);

  const onDescriptionChange = e => {
    if (!e) return;
    const desc = e.target.value;
    setDescription(desc);
    setPart(
      desc === 'Other'
        ? ''
        : serviceOrderOptions[desc] && serviceOrderOptions[desc]['parts'].length > 0
        ? serviceOrderOptions[desc]['parts'][0]
        : ''
    );
    setLocation([]);
  };

  const onDescriptionOtherChange = e => {
    if (!e) return;
    setDescriptionOther(e.target.value);
  };

  const onPartChange = e => {
    if (!e) return;
    setPart(e.target.value);
  };

  const onLocationTextChange = e => {
    if (!e) return;
    setLocation([e.target.value]);
  };

  const onLocationBlockChange = (e, selectedBlocks) => {
    setLocation(selectedBlocks);
  };

  const onHourChange = e => {
    if (!e) return;
    setHour(e.target.value);
  };

  const onCauseChange = e => {
    if (!e) return;
    setCause(e.target.value);
  };

  const onCauseOtherChange = e => {
    if (!e) return;
    setCauseOther(e.target.value);
  };

  const onRepairMethodChange = e => {
    if (!e) return;
    setRepairMethod(e.target.value);
  };

  const onRepairMethodOtherChange = e => {
    if (!e) return;
    setRepairOther(e.target.value);
  };

  useEffect(() => {
    if (!callOnChange) {
      setCallOnChange(true);
      return;
    }

    const newRow = {
      Description: description,
      'Description Other': description === 'Other' ? descriptionOther : '',
      Part: part,
      Location: location,
      'Repair Method': repairMethod,
      'Repair Method Other': repairMethod === 'Other' ? repairOther : '',
      Cause: cause,
      'Cause Other': cause === 'Other' ? causeOther : '',
      Hours: hour
    };
    onRowChange(newRow);
  }, [description, descriptionOther, location, part, hour, repairMethod, repairOther, cause, causeOther]);

  return (
    <div className="border border-primary rounded p-2 px-3 mb-2">
      <div className="d-flex">
        <div className="flex-grow-1">
          <h6 className="smallFont text-muted mb-1">
            <b>{position}.</b> Description:
          </h6>
        </div>
        {showCloseButton && <XCircleFill className="hover-icon text-danger" onClick={onCrossClick} />}
      </div>

      <Row>
        <Col xs={12} md={description === 'Other' ? 6 : 12}>
          <DropDownInput
            id={`desc-${position}`}
            size="sm"
            value={description}
            onChangeFunction={onDescriptionChange}
            options={[...descOptions, 'Other']}
          />
        </Col>
        {description === 'Other' && (
          <Col className="align-self-end" xs={12} md={6}>
            {description === 'Other' && (
              <TextInput
                id={`desc-other-${position}`}
                onChange={onDescriptionOtherChange}
                hint="Provide description..."
                size="sm"
                value={descriptionOther}
              />
            )}
          </Col>
        )}
        <Col className="mt-2" xs={12}>
          <h6 className="smallFont text-muted mb-1">Part:</h6>
          {description === 'Other' ||
          !serviceOrderOptions[description] ||
          serviceOrderOptions[description]['parts'].length === 0 ? (
            <TextInput id={`part-${position}`} onChange={onPartChange} size="sm" value={part} />
          ) : (
            <DropDownInput
              id={`part-${position}`}
              onChangeFunction={onPartChange}
              size="sm"
              value={part}
              options={serviceOrderOptions[description]['parts']}
            />
          )}
        </Col>
        <Col className="mt-2" xs={12}>
          <h6 className="smallFont text-muted mb-1">Location:</h6>
          {description === 'Other' ||
          !serviceOrderOptions[description] ||
          serviceOrderOptions[description]['location'].length === 0 ? (
            <TextInput id={`location-${position}`} onChange={onLocationTextChange} size="sm" value={location[0]} />
          ) : (
            <BlockSelectInput
              size="sm"
              multiple
              value={location}
              blockWidth={3}
              id={`location-${position}`}
              options={serviceOrderOptions[description]['location']}
              onChangeFunction={onLocationBlockChange}
              preventInitialOnChange={true}
            />
          )}
        </Col>
        <Col className="mt-2" xs={12} md={4}>
          <h6 className="smallFont text-muted mb-1">Repair Method:</h6>
          <DropDownInput
            id={`repair-${position}`}
            size="sm"
            onChangeFunction={onRepairMethodChange}
            value={repairMethod}
            options={[...repairOptions, 'Other']}
          />
          {repairMethod === 'Other' && (
            <TextInput
              id={`repair-other-${position}`}
              onChange={onRepairMethodOtherChange}
              size="sm"
              value={repairOther}
              hint="Other repair method..."
            />
          )}
        </Col>
        <Col className="mt-2" xs={12} md={4}>
          <h6 className="smallFont text-muted mb-1">Cause:</h6>
          <DropDownInput
            id={`cause-${position}`}
            size="sm"
            onChangeFunction={onCauseChange}
            value={cause}
            options={[...causeOptions, 'Other']}
          />
          {cause === 'Other' && (
            <TextInput
              id={`cause-other-${position}`}
              hint="Other cause..."
              onChange={onCauseOtherChange}
              size="sm"
              value={causeOther}
            />
          )}
        </Col>
        <Col className="mt-2" xs={12} md={4}>
          <h6 className="smallFont text-muted mb-1">Hours:</h6>
          <NumberInput id={`hour-${position}`} onChange={onHourChange} size="sm" preValue={hour} />
        </Col>
      </Row>
    </div>
  );
};

export default ServiceOrderRow;
