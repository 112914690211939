import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, ProgressBar, Alert, Modal, Button } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router';
import FormGenerator from '../form-generator/FormGenerator';
import { emailForm, forgetPaswordForm, loginForm, registerForm, resetPasswordForm } from '../helpers/forms';
import { makeApiRequests } from '../helpers/api';

import { highlightError } from '../form-generator/helpers/utility';
import { toast } from 'react-toastify';
import Logo from '../components/common/Logo';
import { BRAND_NAME, ENDPOINTS } from '../helpers/constants';
import CircularProgressBar from '../components/common/circular-progress';
import { saveUserToLocal } from '../helpers/session';
import SupportTicket from '../components/common/icons/SupportTicket';
import { ArrowRight } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const redirectAfterAuth = ({ authResult, history }) => {
  saveUserToLocal(authResult, true);
  history.push('/');
};

const OTPSent = ({ onResendClick }) => (
  <div className="mx-3 alert border-primary-light bg-primary-light p-2">
    <h6 className="smallFont text-dark mb-1">
      A One Time Password has been sent to your email address! Please check your inbox and spam folders.
    </h6>
    <div className=" text-right">
      <h6 className="d-inline-block tinyFont text-primary mb-0 hover-light" onClick={onResendClick}>
        Didn't receive an email? Tap here to resend
      </h6>
    </div>
  </div>
);

const Login = () => {
  const [signInError, setSignInError] = useState(false);
  const [loginMode, setLoginMode] = useState(true);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [showForgotPassModal, setShowForgotPassModal] = useState(false);
  const [forgotPasswordSubmitting, setForgotPasswordSubmitting] = useState(false);
  const [toBeRegisteredUser, setToBeRegisteredUser] = useState(null);
  const [forceHideForm, setForceHideForm] = useState(true);
  const [resettingPasswordEmail, setResettingPasswordEmail] = useState(null);

  const history = useHistory();

  useEffect(() => {
    setSignInError('');
    setForceHideForm(true);
    setTimeout(() => setForceHideForm(false), 300);
  }, [toBeRegisteredUser, loginMode]);

  const userToken = localStorage.getItem('user-token');
  if (userToken) {
    return <Redirect from="/login" to="/" />;
  }

  const auth = async ({ authEndpoint, requestBody }) => {
    setFormSubmitting(true);

    try {
      setSignInError('');
      const { response: authResult, error } = await makeApiRequests({
        endpoint: authEndpoint,
        requestBody: requestBody
      });

      setFormSubmitting(false);
      if (error) {
        setSignInError(error);
        return;
      }

      redirectAfterAuth({ history, authResult });
    } catch (e) {
      setFormSubmitting(false);
      setSignInError('Something went wrong! Please try again');
      console.log(e);
    }
  };

  const onLoginFormSubmit = async form => {
    setSignInError('');
    const { email, password } = form;

    auth({ authEndpoint: ENDPOINTS.LOGIN, requestBody: { email, password } });
  };

  const onRegisterFormSubmit = async form => {
    setSignInError('');

    const { name, phone, email, password, confirmPassword, otp } = form;
    if (password !== confirmPassword) {
      highlightError(document.getElementById('confirmPassword'), 'Passwords do not match');
      return;
    }

    auth({
      authEndpoint: ENDPOINTS.REGISTER,
      requestBody: { email: toBeRegisteredUser.email, password, temporaryKey: otp, name, phone }
    });
  };

  const onForgotPasswordFormSubmit = async form => {
    const { forgetPasswordEmail: email } = form;
    setForgotPasswordSubmitting(true);
    try {
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.FORGOT_PASSWORD,
        requestBody: { email }
      });
      setForgotPasswordSubmitting(false);

      if (error) {
        toast.error(error);
        return;
      }

      setResettingPasswordEmail(email);
    } catch (e) {
      setForgotPasswordSubmitting(false);
      toast.error('Something went wrong! Please try again');
      console.log(e);
    }
  };

  const onCheckEmailFormSubmit = async form => {
    setSignInError('');
    const { email, forResend } = form;
    if (forResend) setForceHideForm(true);
    setFormSubmitting(true);

    try {
      const { response: user, error } = await makeApiRequests({
        endpoint: ENDPOINTS.GENERATE_REGISTER_OTP,
        requestBody: { email }
      });

      setFormSubmitting(false);
      if (error) {
        setSignInError(error);
        //only in case of error, if successful useeffect for toberegistered user triggers
        setForceHideForm(false);
        return;
      }

      setToBeRegisteredUser(user);
    } catch (e) {
      setFormSubmitting(false);
      setSignInError('Something went wrong! Please try again');
      console.log(e);
    }
  };

  const onResetPasswordFormSubmit = async form => {
    const { otp, newPassword, confirmPassword } = form;

    if (newPassword !== confirmPassword) {
      highlightError(document.getElementById('confirmPassword'), 'Passwords do not match');
      return;
    }

    setForgotPasswordSubmitting(true);

    try {
      const { response: authResult, error } = await makeApiRequests({
        endpoint: ENDPOINTS.RESET_PASSWORD,
        requestBody: { email: resettingPasswordEmail, newPassword, temporaryKey: otp }
      });

      setForgotPasswordSubmitting(false);

      if (error) {
        toast.error(error);
        return;
      }

      setResettingPasswordEmail(null);
      setShowForgotPassModal(false);
      toast.success('Password reset successfully! You can now login with your new password');
      history.push('/login');
    } catch (e) {
      setForgotPasswordSubmitting(false);
      toast.error('Something went wrong! Please try again');
      console.log(e);
    }
  };

  window['onResetPasswordFormSubmit'] = onResetPasswordFormSubmit;
  window['onCheckEmailFormSubmit'] = onCheckEmailFormSubmit;
  window['onForgotPasswordFormSubmit'] = onForgotPasswordFormSubmit;
  window['onLoginFormSubmit'] = onLoginFormSubmit;
  window['onRegisterFormSubmit'] = onRegisterFormSubmit;

  const onForgotPasswordModalHide = () => {
    setResettingPasswordEmail(false);
    setShowForgotPassModal(false);
  };

  const ForgotPasswordModal = () => {
    return (
      <Modal show={showForgotPassModal} onHide={onForgotPasswordModalHide} centered backdrop="static">
        <Modal.Header closeButton={!forgotPasswordSubmitting}>
          <Modal.Title>Forgot password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!forgotPasswordSubmitting ? (
            <div className="fade-in">
              <FormGenerator prefix="fp" formJson={resettingPasswordEmail ? resetPasswordForm : forgetPaswordForm} />
              {resettingPasswordEmail && (
                <OTPSent
                  onResendClick={() => onForgotPasswordFormSubmit({ forgetPasswordEmail: resettingPasswordEmail })}
                />
              )}
            </div>
          ) : (
            <div className="p-5">
              <div className="p-5 text-center">
                <CircularProgressBar size={5} />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  const LoginForm = () => {
    return (
      <div className="fade-in">
        {ForgotPasswordModal()}
        <FormGenerator formJson={loginForm} />
        <p
          style={styles.loginSignupText}
          className={`text-right mx-2 ${formSubmitting ? 'text-muted' : ''}`}
          onClick={() => {
            if (formSubmitting) return;

            setShowForgotPassModal(true);
          }}
        >
          Forgot password?
        </p>
        <hr />
        <p
          style={styles.loginSignupText}
          className={`text-center mx-2 ${formSubmitting ? 'text-muted' : ''}`}
          onClick={() => {
            if (formSubmitting) return;

            setToBeRegisteredUser(null);
            setLoginMode(false);
          }}
        >
          Don't have an account? Click here to register
        </p>
      </div>
    );
  };

  const RegisterForm = () => {
    return (
      <div className="fade-in">
        <FormGenerator
          formJson={registerForm}
          formValues={{ register: { Name: toBeRegisteredUser.name, 'Phone Number': toBeRegisteredUser.phone } }}
        />
        <p
          style={styles.loginSignupText}
          className={`text-center ${formSubmitting ? 'text-muted' : ''}`}
          onClick={() => {
            if (formSubmitting) return;

            setToBeRegisteredUser(null);
            setLoginMode(true);
          }}
        >
          Already have an account? Click here to login
        </p>
      </div>
    );
  };

  const EmailRegisterForm = () => {
    return (
      <div className="fade-in">
        <FormGenerator formJson={emailForm} />
        <p
          style={styles.loginSignupText}
          className={`text-center ${formSubmitting ? 'text-muted' : ''}`}
          onClick={() => {
            if (formSubmitting) return;

            setToBeRegisteredUser(null);
            setLoginMode(true);
          }}
        >
          Already have an account? Click here to login
        </p>
      </div>
    );
  };

  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col xs={1} className="bg-dark d-block"></Col>
        <Col xs={11} className="bg-light h-100 d-flex flex-column p-0">
          <div className="bg-white border-bottom p-2 text-right">
            <Link to={'/request_service'}>
              <Button variant="outline-dark" size="sm" className="px-3">
                <SupportTicket className="mr-2" />
                <span className="align-middle mr-2">Are you a customer? Open a new service request</span>
                <ArrowRight />
              </Button>
            </Link>
          </div>
          <Row className="justify-content-center flex-grow-1 py-2 px-0 px-md-5">
            <Col xs={10} md={6} className="align-self-center">
              <Card className="">
                <Card.Header className="p-0 bg-primary-light">
                  <div className="text-center p-3">
                    <Logo />
                  </div>
                </Card.Header>
                <Card.Body>
                  {!forceHideForm ? (
                    <>
                      <h6 className="text-muted mx-2 my-1">
                        <span className="text-primary">Hello!</span> Welcome to {BRAND_NAME} team login. <br />
                      </h6>
                      <h6 className="text-muted mx-2 my-1">
                        <b className="text-dark">
                          {toBeRegisteredUser !== null
                            ? `Creating an account for ${toBeRegisteredUser.email}${
                                ['Super Admin', 'Admin'].includes(toBeRegisteredUser.role)
                                  ? ` [${toBeRegisteredUser.role}]`
                                  : ''
                              }`
                            : loginMode
                            ? 'Sign in to continue'
                            : 'Please provide your email address to continue'}
                        </b>
                      </h6>
                      <hr />
                      {toBeRegisteredUser !== null ? RegisterForm() : loginMode ? LoginForm() : EmailRegisterForm()}
                      {toBeRegisteredUser !== null && (
                        <OTPSent
                          onResendClick={() =>
                            onCheckEmailFormSubmit({ email: toBeRegisteredUser.email, forResend: true })
                          }
                        />
                      )}
                      {formSubmitting && (
                        <ProgressBar
                          className="mt-1"
                          striped
                          animated
                          variant="dark"
                          now={100}
                          label={
                            loginMode
                              ? 'Logging in...'
                              : toBeRegisteredUser === null
                              ? 'Checking email...'
                              : 'Creating account...'
                          }
                        />
                      )}
                    </>
                  ) : (
                    <div className="p-5">
                      <div className="p-5 text-center">
                        <CircularProgressBar size={5} />
                      </div>
                    </div>
                  )}

                  {signInError && (
                    <Alert style={{ fontSize: 14 }} className="mx-2 p-2" variant="danger">
                      {signInError}
                    </Alert>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
  loginSignupText: {
    fontSize: 12,
    cursor: 'pointer',
    textDecoration: 'underline'
  }
};

export default Login;
