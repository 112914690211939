import React from 'react';
import { Button } from 'react-bootstrap';

const FloatingButton = ({ text, variant = 'dark', disabled = false, Icon, onClick }) => {
  return (
    <Button
      variant={variant}
      style={{ position: 'fixed', bottom: 0, right: 0 }}
      className="m-4"
      disabled={disabled}
      size="sm"
      onClick={onClick}
    >
      {Icon && <Icon className="mr-2" />}
      <span className="align-middle">{text}</span>
    </Button>
  );
};

export default FloatingButton;
