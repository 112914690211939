import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Modal, ProgressBar } from 'react-bootstrap';
import { Calculator, Pen, PlusCircle, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { getObjectForPrefill } from '../../helpers/formHelpers';
import AlertModal from '../AlertModal';
import EntityTable from '../common/EntityTable';
import Loader from '../Loader';
import { salesLocationAddRows, salesLocationHeaders, salesLocationUpdateRows } from './constants';
import { getDateFromInputString } from '../../form-generator/helpers/utility';
import SalesLocationSites from './SalesLocationSites';

const salesLocationTableSearchKeys = ['location', 'generalManager', 'name'];

const taxFactorRows = [
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'taxFactors',
          type: 'table-input',
          title: 'Tax Factors',
          defaultHideOptions: false,
          showHideToggle: false,
          tableFields: [
            { title: 'Effective From', key: 'effectiveDate', type: 'text', variant: 'date', required: true },
            { title: 'Value', key: 'value', type: 'number', required: true }
          ]
        }
      }
    ]
  }
];

const getForm = ({ rows, type = 'Add' }) => {
  const form = {
    forms: [
      {
        name: `${type} Sales Location`,
        markCompulsoryFields: true,
        hideFormName: true,
        compact: true,
        submit: {
          name: type,
          show: true,
          onSubmit: type === 'Add' ? 'onNewLocationFormSubmit' : 'onUpdateLocationFormSubmit'
        },
        rows
      }
    ]
  };

  return form;
};

const AddEditModal = ({
  show,
  toBeEditedSalesLocation,
  onHide,
  showProgress,
  addForm,
  updateForm,
  taxFactorUpdate
}) => (
  <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
    <Modal.Header closeButton={!showProgress}>
      <Modal.Title>
        <h6 className="mb-0">
          {toBeEditedSalesLocation
            ? taxFactorUpdate
              ? 'Update Tax Factors'
              : 'Update Sales Location'
            : 'Add New Sales Location'}
        </h6>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="overflow-auto px-1">
      <FormGenerator
        formJson={toBeEditedSalesLocation ? updateForm : addForm}
        formValues={
          toBeEditedSalesLocation ? { 'Update Sales Location': getObjectForPrefill(toBeEditedSalesLocation) } : {}
        }
      />
      {showProgress && (
        <ProgressBar
          now={100}
          animated
          striped
          label={toBeEditedSalesLocation ? `Updating Sales Location...` : `Saving Sales Location....`}
        />
      )}
    </Modal.Body>
  </Modal>
);

const SalesLocations = () => {
  const [loading, setLoading] = useState(false);
  const [salesLocations, setSalesLocations] = useState([]);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [toBeDeletedSalesLocation, setToBeDeletedSalesLocation] = useState(null);
  const [salesLocationActionMeta, setSalesLocationActionMeta] = useState(null);
  const [salesLocationAddingUpdating, setSalesLocationAddingUpdating] = useState(false);
  const [expandedSalesLocation, setExpandedSalesLocation] = useState(null);
  const role = localStorage.getItem('user-role');

  const fetchSalesLocations = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SALES_LOCATION_LIST,
      method: 'POST'
    });

    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setSalesLocations(response);
  };

  useEffect(() => {
    fetchSalesLocations();
  }, []);

  const deleteSalesLocation = async () => {
    setDeleteInProgress(true);

    const { response, error } = await makeApiRequests({
      endpoint: `${ENDPOINTS.SALES_LOCATION_BASE}/${toBeDeletedSalesLocation._id}`,
      method: 'DELETE'
    });

    setDeleteInProgress(false);
    if (error) {
      toast.error(error);
      return;
    }

    salesLocations.splice(
      salesLocations.findIndex(i => i._id === toBeDeletedSalesLocation._id),
      1
    );
    setSalesLocations([...salesLocations]);
    setToBeDeletedSalesLocation(null);
  };

  const onNewLocationFormSubmit = async form => {
    setSalesLocationAddingUpdating(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SALES_LOCATION_BASE,
      requestBody: form
    });

    setSalesLocationAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    setSalesLocations([response, ...salesLocations]);
    setSalesLocationActionMeta(null);
  };

  const onUpdateLocationFormSubmit = async form => {
    let objectToUpdate = form;

    if (salesLocationActionMeta.mode === 'taxfactor-edit') {
      const { taxFactors = [] } = form;
      objectToUpdate = {
        taxFactors: taxFactors.map(tx => ({
          effectiveDate: getDateFromInputString(tx[0]),
          value: tx[1]
        }))
      };
    }

    setSalesLocationAddingUpdating(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SALES_LOCATION_WITH_ID(salesLocationActionMeta.salesLocation._id),
      requestBody: { ...objectToUpdate },
      method: 'PUT'
    });

    setSalesLocationAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    const salesLocationIndex = salesLocations.findIndex(i => i._id === response._id);
    salesLocations[salesLocationIndex] = { ...salesLocations[salesLocationIndex], ...response };
    setSalesLocations([...salesLocations]);
    setSalesLocationActionMeta(null);
  };

  const onTableRowClick = salesLocation => {
    setExpandedSalesLocation(expandedSalesLocation?._id === salesLocation._id ? null : salesLocation);
  };

  window['onNewLocationFormSubmit'] = onNewLocationFormSubmit;
  window['onUpdateLocationFormSubmit'] = onUpdateLocationFormSubmit;

  const actionCell = salesLocation => {
    if (!['admin', 'super admin'].includes(role)) return null;

    return (
      <div>
        <Button
          variant="outline-primary"
          className="px-1 py-0 mr-1"
          onClick={() =>
            setSalesLocationActionMeta({
              salesLocation: {
                ...salesLocation,
                taxFactors: salesLocation.taxFactors.map(tx => [tx['effectiveDate'], tx['value']])
              },
              mode: 'taxfactor-edit'
            })
          }
        >
          <Calculator size={10} />
        </Button>

        <Button
          variant="outline-primary"
          className="px-1 py-0 mr-1"
          onClick={() => setSalesLocationActionMeta({ salesLocation, mode: 'edit' })}
        >
          <Pen size={10} />
        </Button>
        <Button
          variant="outline-danger"
          className="px-1 py-0"
          onClick={() => setToBeDeletedSalesLocation(salesLocation)}
        >
          <Trash size={10} />
        </Button>
      </div>
    );
  };

  return (
    <Container fluid className="h-100 py-3 px-md-3">
      <Card>
        <Card.Body>
          <h6 className='mb-0'>Sales Locations</h6>
          <p className='mb-0 smallFont text-muted'>Click on one of the location to view sites within.</p>
          <hr className="my-3" />
          {loading ? (
            <Loader />
          ) : (
            <>
              <EntityTable
                rowKeyField={'_id'}
                data={salesLocations}
                headers={salesLocationHeaders}
                searchQueryKeys={salesLocationTableSearchKeys}
                additionalComponents={() =>
                  ['admin', 'super admin'].includes(role) && (
                    <Button size="sm" variant="success" onClick={() => setSalesLocationActionMeta({ mode: 'add' })}>
                      <PlusCircle className="mr-1" /> New Sales Location
                    </Button>
                  )
                }
                showActions={['admin', 'super admin'].includes(role)}
                actionCell={actionCell}
                expandedRowKeys={expandedSalesLocation ? [expandedSalesLocation?._id] : []}
                renderExpandedRow={salesLocation => <SalesLocationSites salesLocation={salesLocation} />}
                onRowClick={onTableRowClick}
              />
              {salesLocations.length > 0 && (
                <AlertModal
                  show={toBeDeletedSalesLocation !== null}
                  alertText={`Are you sure you want to delete this sales location? This action cannot be undone!`}
                  onHide={() => setToBeDeletedSalesLocation(null)}
                  onDismissClick={() => setToBeDeletedSalesLocation(null)}
                  onContinueClick={deleteSalesLocation}
                  progressText={`Deleting sales location...`}
                  showProgress={deleteInProgress}
                />
              )}
              <AddEditModal
                show={salesLocationActionMeta !== null}
                onHide={() => setSalesLocationActionMeta(null)}
                toBeEditedSalesLocation={salesLocationActionMeta ? salesLocationActionMeta.salesLocation : null}
                showProgress={salesLocationAddingUpdating}
                addForm={getForm({ rows: salesLocationAddRows })}
                updateForm={getForm({
                  rows:
                    salesLocationActionMeta && salesLocationActionMeta.mode === 'taxfactor-edit'
                      ? taxFactorRows
                      : salesLocationUpdateRows,

                  type: 'Update'
                })}
                taxFactorUpdate={salesLocationActionMeta?.mode === 'taxfactor-edit'}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SalesLocations;
