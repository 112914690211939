import React, { useState } from 'react';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { mapFieldToElement } from '../helpers/TypeToElemMapper';
import { DashCircleFill, PlusCircleFill } from 'react-bootstrap-icons';
import { normalizeId } from '../helpers/utility';
import TextInput from './TextInput';

const FreeKeyValueSwitch = ({ id, initialRowCount = 1, className = '', preValue, disabled }) => {
  const [rowCount, setRowCount] = useState(
    preValue && preValue['rows'].length !== 0 ? preValue['rows'].length : initialRowCount
  );
  const [fields] = useState([
    { title: 'Key', type: 'text', disabled: disabled },
    { title: 'Value', type: 'three-state-switch', disabled: disabled }
  ]);
  const tableId = id;

  const removeRow = () => {
    if (rowCount > 1) {
      setRowCount(rowCount - 1);
    }
  };
  const addRow = () => {
    setRowCount(rowCount + 1);
  };

  const createTableRows = rowCount => {
    let tempRows = [];
    for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
      tempRows.push(createSingleRow(rowIndex));
    }

    return tempRows;
  };

  const createSingleRow = rowIndex => {
    return (
      <tr key={rowIndex}>
        {fields.map((field, index) => (
          <td key={index}>
            {mapFieldToElement({
              ...field,
              id: normalizeId(tableId + '-' + field.title + '-' + rowIndex),
              name: field.title + '-' + rowIndex,
              optionId: tableId + '-' + rowIndex,

              preValue:
                preValue && preValue['rows'][rowIndex]
                  ? index === 0
                    ? preValue['rows'][rowIndex]['key']
                    : preValue['rows'][rowIndex]['value']
                  : index === 0
                  ? null
                  : 'na'
            })}
          </td>
        ))}
      </tr>
    );
  };

  return (
    <>
      {disabled && (!preValue || preValue['rows'].length === 0) ? (
        <h6 className="text-center px-3 py-5 text-muted">Nothing to show</h6>
      ) : (
        <>
          <Table id={tableId} bordered className={'rounded ' + className} responsive>
            {/* mapping header */}
            <thead style={{ fontSize: 14 }}>
              <tr className="bg-primary text-white">
                {fields.map((field, index) => (
                  <th style={{ padding: 8 }} key={index} className={`text-center`}>
                    {field.title}
                  </th>
                ))}
              </tr>
            </thead>
            {/* mapping inputs */}
            <tbody>{createTableRows(rowCount)}</tbody>
          </Table>
          {!disabled && (
            <Row>
              <Col xs={12} className="text-right">
                <Button
                  size="sm"
                  variant="outline-success"
                  onClick={() => {
                    addRow();
                  }}
                >
                  <PlusCircleFill className="align-text-top" /> Add a row
                </Button>
                {rowCount > 1 && (
                  <Button
                    className="ml-md-3 mt-0"
                    variant="outline-danger"
                    size="sm"
                    onClick={() => {
                      removeRow();
                    }}
                  >
                    <DashCircleFill className="align-text-top" /> Delete a row
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </>
      )}
      <Row>
        <Col xs={12}>
          <h6 className="my-2">Notes</h6>
          <TextInput
            disabled={disabled}
            variant="text-area"
            id={id + '-notes'}
            preValue={preValue ? preValue['notes'] : undefined}
          />
        </Col>
      </Row>
    </>
  );
};

export default FreeKeyValueSwitch;
