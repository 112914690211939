import React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import CachedResponse from '../common/CachedResponse';
import HorizontalProgress from '../common/HorizontalProgress';
import SearchInventory from './SearchInventory';

const InventoryPicker = ({
  show,
  title = 'Select unit to be assigned',
  onInventoryPickerClose,
  submitting = false,
  onSubmit,
  submitButtonText = 'Select',
  fetchedInventories,
  excludedUnitIds = [],
  selectedUnits = [],
  onUnitSelect,
  fromContracts,
  fromMap
}) => {
  return (
    <>
      <Modal
        dialogClassName="h-100 my-0"
        contentClassName="h-100"
        show={show}
        size="lg"
        onHide={onInventoryPickerClose}
        backdrop="static"
      >
        {/* Add Inventory SideBar */}

        <Modal.Header>
          <Modal.Title>
            <h6 className="mb-0"> {title}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto p-1">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => (
              <SearchInventory
                excludedUnitIds={excludedUnitIds}
                fetchedInventories={fetchedInventories}
                selectedUnits={selectedUnits}
                fromPicker
                onSelect={onUnitSelect}
                appChoices={appChoices}
                fromMap={fromMap}
              />
            )}
          />
        </Modal.Body>

        <Modal.Footer>
          <Container fluid>
            <Row>
              {submitting ? (
                <Col xs={12}>
                  <HorizontalProgress className="mb-2" text="Assigning unit..." />
                </Col>
              ) : (
                fromContracts &&
                selectedUnits.length > 0 &&
                selectedUnits[0]['contacts'] &&
                selectedUnits[0]['contacts'].length > 0 && (
                  <Col xs={12}>
                    <h6 className="smallFont">
                      Selecting this unit will remove the following potential contacts from it:{' '}
                      <b className="text-muted"> {selectedUnits[0]['contacts'].map(c => c['buyer']).join(', ')}</b>
                    </h6>
                  </Col>
                )
              )}
              <Col xs={12} className="text-right mt-2">
                <Button
                  className="mr-3"
                  size="sm"
                  variant="secondary"
                  onClick={onInventoryPickerClose}
                  disabled={submitting}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  variant="info"
                  disabled={selectedUnits.length === 0 || submitting}
                  onClick={() => onSubmit(selectedUnits)}
                >
                  {submitButtonText}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InventoryPicker;
