import moment from 'moment';
import React, { useState } from 'react';
import { Badge, Button, Col, Dropdown, Row } from 'react-bootstrap';
import {
  ArrowUpRightCircle,
  ClipboardCheck as CB,
  Envelope,
  Geo,
  Globe,
  Person,
  Phone,
  Pin,
  Printer
} from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { contractToggleOptions, ENDPOINTS } from '../../helpers/constants';
import { copyContractAddress, copyContractDetails, copyToClipboard, openLinkInNewTab } from '../../helpers/global';
import QuickToggles from '../common/QuickToggles';

const ContractSearchItem = ({
  contract = {},
  onContractClick,
  selected,
  fromPicker = false,
  fromOpsDetails = false,
  onRebateEditClick,
  onRequestRebateClick,
  onContractUpdate,
  mini,
  onChangeClick,
  onRemoveClick,
  onDuplicateClick,
  appChoices
}) => {
  const [role] = useState(localStorage.getItem('user-role'));
  const [toggling, setToggling] = useState(false);

  const toggleContract = async (label, key, value) => {
    setToggling(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contract._id),
      requestBody: { [key]: value },
      method: 'PUT'
    });

    setToggling(false);
    if (error) {
      toast.error(error);
      return;
    }

    onContractUpdate(response);

    //if status has been changed, show status info as well
    if (key === 'status') {
      toast.info(`Contract ${response['buyer']} moved to ${response['status']}.`);
    } else {
      toast.success(`Contract's ${label} updated successfully!`);
    }

    setToggling(false);
  };

  const getRebateBadge = () => {
    let badgeText = '';

    if (contract['dateRebateRequested']) {
      badgeText = 'Rebate Requested';
    }

    if (contract['dateRebateReceived']) {
      badgeText = 'Rebate Received';
    }

    if (contract['rebateReportedDate']) {
      badgeText = 'Rebate Reported';
    }

    return badgeText ? (
      <Badge className="align-text-bottom mr-2" variant={'warning'}>
        {badgeText}
      </Badge>
    ) : (
      ''
    );
  };

  const getACRebateBadge = () => {
    let badgeText = '';

    if (contract['dateACRebateRequested']) {
      badgeText = 'AC Rebate Requested';
    }

    if (contract['dateACRebateReceived']) {
      badgeText = 'AC Rebate Received';
    }

    if (contract['acRebateReportedDate']) {
      badgeText = 'AC Rebate Reported';
    }

    return badgeText ? (
      <Badge className="align-text-bottom ml-2" variant={'warning'}>
        {badgeText}
      </Badge>
    ) : (
      ''
    );
  };

  return (
    <div
      style={{ fontSize: 14 }}
      className={`d-flex mb-3 ${!onContractClick ? '' : 'hover-light'} p-2 rounded ${
        selected ? 'border border-primary' : ''
      }`}
      onClick={
        onContractClick
          ? e => {
              const cellText = document.getSelection();
              if (cellText.toString().length > 0) {
                return;
              }

              onContractClick && onContractClick();
            }
          : undefined
      }
    >
      <div className="text-center mb-3 p-2 rounded d-none d-md-block">
        <Person size={mini ? 25 : 34} />
      </div>
      <div className="flex-grow-1 pl-2">
        <div className="d-flex">
          <div className="flex-grow-1 align-self-center">
            <h6 className="d-inline-block mr-2 mb-0">
              <Link
                className=" text-dark"
                to={'/contract/' + contract?.['_id']}
                target="_blank"
                onClick={e => e.stopPropagation()}
              >
                {contract?.['buyer']}
                {contract?.['coBuyer'] ? `, ${contract?.['coBuyer']}` : ''} <ArrowUpRightCircle size={14} />
              </Link>
            </h6>

            {mini && fromPicker && (
              <Badge className="align-text-bottom mr-2" variant={'primary'}>
                {contract?.['status']}
              </Badge>
            )}
            {contract['inventoryUnit'] &&
              contract['inventoryUnit']['manufacturer'] &&
              contract['inventoryUnit']['manufacturer'].isNPS && (
                <Badge className="align-text-bottom mr-2" variant={'primary'}>
                  NPS Sale
                </Badge>
              )}
            {contract['dateFormTSent'] && (
              <Badge className="align-text-bottom mr-2" variant={'warning'}>
                Form T Sent
              </Badge>
            )}
            {contract['dateSolReportFiled'] && (
              <Badge className="align-text-bottom mr-2" variant={'warning'}>
                Sol Filed
              </Badge>
            )}
            {getRebateBadge()}
            {getACRebateBadge()}
            {contract['stateInspection'] && contract['stateInspection'].toLowerCase().includes('yes') && (
              <Badge className="align-text-bottom mr-2" variant="dark">
                State Inspection
              </Badge>
            )}
            {contract['inventoryUnit'] &&
              contract['inventoryUnit']['manufacturer'] &&
              contract['inventoryUnit']['manufacturer'].isFactoryMakeReady && (
                <Badge className="align-text-bottom mr-2" variant="dark">
                  Factory Makeready
                </Badge>
              )}
            {contract['retailerInstallerProvideSkirting'] &&
              contract['retailerInstallerProvideSkirting'].toLowerCase().includes('yes') && (
                <Badge className="align-text-bottom mr-2" variant="dark">
                  Retailer Provides Skirting
                </Badge>
              )}
          </div>

          <div className="text-right d-none d-md-block">
            {!fromPicker && (
              <>
                {['super admin', 'admin', 'service'].includes(role) && (
                  <Link target="_blank" to={`/dochub/print-groups?contractId=${contract['_id']}`}>
                    <Button
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      className="mr-1"
                      variant="outline-dark"
                      size="sm"
                    >
                      <Printer className="align-text-top" />
                    </Button>
                  </Link>
                )}
              </>
            )}
            {!fromPicker && (
              <Dropdown className="d-inline-block" onClick={e => e.stopPropagation()}>
                <Dropdown.Toggle className="mr-1" variant="outline-dark" size="sm">
                  <CB className="align-text-top" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={e => {
                      copyContractDetails(contract);
                      toast.success('Copied Contract!');
                    }}
                  >
                    Contract
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => {
                      copyContractAddress(contract);
                      toast.success('Copied Address!');
                    }}
                  >
                    Address
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

            <div className="d-inline-block mr-1">
              <Button
                onClick={e => {
                  e.stopPropagation();
                  onDuplicateClick && onDuplicateClick();
                }}
                size="sm"
                variant="success"
                className={fromPicker && 'tinyFont'}
              >
                Duplicate
              </Button>
            </div>
            {!fromPicker && (
              <>
                {contract['pindropUrl'] && (
                  <div className="d-inline-block mr-1">
                    <Button
                      variant="danger"
                      size="sm"
                      style={styles.inputGroupLeft}
                      onClick={e => {
                        e.stopPropagation();
                        openLinkInNewTab(contract['pindropUrl'], true);
                      }}
                    >
                      Pindrop <Pin className="align-text-bottom" />
                    </Button>

                    <Button
                      onClick={e => {
                        e.stopPropagation();
                        copyToClipboard(contract['pindropUrl']);
                        toast.success('Link Copied!');
                      }}
                      className="ml-0"
                      variant="outline-danger"
                      size="sm"
                      style={styles.inputGroupRight}
                    >
                      <CB className="align-text-top" />
                    </Button>
                  </div>
                )}
              </>
            )}

            {!fromPicker && (
              <>
                {' '}
                {['super admin', 'admin', 'service'].includes(role) && !fromOpsDetails && (
                  <>
                    {!contract['dateRebateRequested'] && (
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          onRequestRebateClick && onRequestRebateClick();
                        }}
                        className="ml-2"
                        variant="outline-info"
                        size="sm"
                      >
                        Req. Rebate
                      </Button>
                    )}
                    {contract['dateRebateRequested'] && !contract['dateRebateReceived'] && (
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          onRebateEditClick && onRebateEditClick();
                        }}
                        className="ml-2"
                        variant="outline-info"
                        size="sm"
                      >
                        Update Rebate
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {!fromPicker && (
            <div className="ml-2 d-md-none d-flex">
              <Link target="_blank" to={`/dochub/print-groups?contractId=${contract['_id']}`}>
                <Button
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  className="mr-1"
                  variant="outline-dark"
                  size="sm"
                >
                  <Printer className="align-text-top" />
                </Button>
              </Link>
              <Dropdown
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Dropdown.Toggle className="d-inline-block" variant="outline-info" size="sm"></Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={e => {
                      copyContractDetails(contract);
                      toast.success('Copied Contract!');
                    }}
                  >
                    Copy Contract
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => {
                      copyContractAddress(contract);
                      toast.success('Copied Address!');
                    }}
                  >
                    Copy Contract Address
                  </Dropdown.Item>

                  {contract['pindropUrl'] && (
                    <>
                      <Dropdown.Item
                        onClick={e => {
                          e.stopPropagation();
                          openLinkInNewTab(contract['pindropUrl'], true);
                        }}
                      >
                        Go to pindrop url
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={e => {
                          copyToClipboard(contract['pindropUrl']);
                          toast.success('Link Copied!');
                        }}
                      >
                        Copy pindrop url
                      </Dropdown.Item>
                    </>
                  )}
                  {['super admin', 'admin', 'service'].includes(role) && !fromOpsDetails && !fromPicker && (
                    <>
                      {!contract['dateRebateRequested'] && (
                        <Dropdown.Item
                          onClick={e => {
                            onRequestRebateClick && onRequestRebateClick();
                          }}
                        >
                          Request Rebate
                        </Dropdown.Item>
                      )}

                      {contract['dateRebateRequested'] && !contract['dateRebateReceived'] && (
                        <Dropdown.Item
                          onClick={e => {
                            onRebateEditClick && onRebateEditClick();
                          }}
                        >
                          Update Rebate
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}

          {(onChangeClick || onRemoveClick) && (
            <div>
              <Dropdown onClick={e => e.stopPropagation()}>
                <Dropdown.Toggle size="sm" className="p-1 tinyFont" variant="outline-primary" />
                <Dropdown.Menu>
                  {onChangeClick && <Dropdown.Item onClick={onChangeClick}>Change Unit</Dropdown.Item>}
                  {onRemoveClick && <Dropdown.Item onClick={onRemoveClick}>Remove Unit</Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        {['super admin', 'admin', 'service'].includes(role) && appChoices && !mini && !fromPicker && (
          <QuickToggles
            data={contract}
            toggleOptions={contractToggleOptions({ contract, appChoices })}
            onToggle={toggleContract}
            toggling={toggling}
          />
        )}

        <Row className="mt-2">
          <Col xs={12} md={4}>
            <p className="mb-0  text-secondary">
              <span className="text-dark">Setup Start Date: </span>
              {contract['setup'] && contract['setup']['start']
                ? moment(contract['setup']['start']).format('MMMM Do YYYY')
                : 'N/A'}
            </p>
          </Col>

          <Col xs={12} md={4} className="mt-2 mt-md-0">
            <p className="mb-0  text-secondary">
              <span className="text-dark">Makeready Start Date: </span>
              {contract['makeready'] && contract['makeready']['start']
                ? moment(contract['makeready']['start']).format('MMMM Do YYYY')
                : 'N/A'}
            </p>
          </Col>

          <Col xs={12} md={4} className="mt-2 mt-md-0">
            <p className="mb-0  text-secondary">
              <span className="text-dark">Makeready End Date: </span>
              {contract['makeready'] && contract['makeready']['end']
                ? moment(contract['makeready']['end']).format('MMMM Do YYYY')
                : 'N/A'}
            </p>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={12} md={4}>
            <p className="mb-0 text-secondary">
              <Geo className="mr-1  text-dark" />
              {contract['salesLocation'] ? contract['salesLocation'].location : 'N/A'}
            </p>
          </Col>

          <Col xs={12} md={4}>
            <p className="mb-0 text-secondary">
              <span className="text-dark">Stock / RSO: </span>
              {contract['stockOrRso'] || 'N/A'}
            </p>
          </Col>

          <Col xs={12} md={4} className="mt-2 mt-md-0">
            <p className="mb-0  text-secondary">
              <span className="text-dark">Date Form T Sent: </span>
              {contract['dateFormTSent'] ? moment(contract['dateFormTSent']).format('MMMM Do YYYY') : 'N/A'}
            </p>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={12} md={4}>
            <p className="mb-0  text-secondary">
              <Globe className="mr-1  text-dark" />
              {`${contract['propertyAddress']}, ${contract['propertyCity']}, ${contract['propertyState']} ${
                contract['zipCode']
              } (${contract['propertyCounty']})`}
            </p>
          </Col>
          <Col xs={12} md={4} className="mt-2 mt-md-0">
            <a onClick={e => e.stopPropagation()} className="text-secondary" href={`tel:${contract['cellPhone']}`}>
              <Phone className="mr-1  text-dark" />
              {contract['cellPhone'] || 'N/A'}
            </a>
          </Col>

          <Col xs={12} md={4} className="mt-2 mt-md-0">
            <p className="mb-0  text-secondary">
              <Envelope className="mr-1  text-dark" />
              {contract['buyerEmail'] ? (
                <a onClick={e => e.stopPropagation()} href={`mailto:${contract['buyerEmail']}`}>
                  {contract['buyerEmail']}
                </a>
              ) : (
                'N/A'
              )}
            </p>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={12} md={4}>
            <p className="mb-0  text-secondary">
              <span className="text-dark">Date Signed: </span>
              {contract['dateSigned'] ? moment(contract['dateSigned']).format('MMMM Do YYYY') : 'N/A'}
            </p>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-0  text-secondary">
              <span className="text-dark">Sales Price: </span>
              {contract['salesPrice'] ? `$${contract['salesPrice']}` : 'N/A'}
            </p>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-0  text-secondary">
              <span className="text-dark">Down Payment: </span>${contract['downPayment'] || 'N/A'}
            </p>
          </Col>
        </Row>

        {!mini && !fromOpsDetails && (
          <>
            <Row className="mt-1">
              {contract['tech'] && (
                <Col xs={12} md={4}>
                  <p className="mb-0  text-secondary">
                    <span className="text-dark">Tech: </span>
                    {contract['tech'].name}
                  </p>
                </Col>
              )}
              {contract['amountRebateReceived'] && (
                <Col xs={12} md={4}>
                  <p className="mb-0  text-secondary">
                    <span className="text-dark">Rebate Amount: </span>${contract['amountRebateReceived']}
                  </p>
                </Col>
              )}
            </Row>
            {!fromPicker && contract['mostRecentComment'] && contract['mostRecentComment']['timeStamp'] && (
              <Row className="mt-3">
                <Col xs={12}>
                  <hr className="w-50 mt-0" />
                  <h6>Most Recent Comment:</h6>
                  <div className="border bg-light rounded px-3 py-2 mt-1">
                    <b className="mb-0 text-info d-block">By {contract['mostRecentComment']['userName']}</b>
                    {contract['mostRecentComment']['comment']}
                  </div>
                  <i style={{ fontSize: '14px' }} className="text-muted text-right d-block">
                    On {moment(contract['mostRecentComment']['timeStamp']).format('MMMM Do YYYY, h:mm:ss a')}
                  </i>
                </Col>
              </Row>
            )}
            <hr />
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  inputGroupLeft: {
    borderRadius: '0.25em 0 0 0.25em'
  },
  inputGroupRight: {
    borderRadius: '0 0.25em 0.25em 0'
  }
};

export default ContractSearchItem;
