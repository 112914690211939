import React, { useEffect, useState } from 'react';
import { Card, FormControl, Table } from 'react-bootstrap';
import { DropDownInput } from '../../form-generator/components';

const headers = ['Description', 'Unit Price', 'Quantity', 'Price'];

const DeliverySetCalculator = ({ appChoices = [] }) => {
  const [role] = useState(localStorage.getItem('user-role'));
  const [salesLocations] = useState(appChoices.find(ac => ac.key === 'salesLocation').values);
  const [selectedSalesLocation, setSelectedSalesLocation] = useState(
    ['super admin', 'admin'].includes(role)
      ? salesLocations[0]
      : JSON.parse(localStorage.getItem('user-sales-location'))
  );

  const [disabledItems, setDisabledItems] = useState([]);
  const [activeOptions, setActiveOptions] = useState();
  const [taxRate, setTaxRate] = useState(0);
  const [other, setOther] = useState(0);

  const [summaryData, setSummaryData] = useState();

  useEffect(() => {
    const options = appChoices.find(ac => ac.key === 'lotSetOption').values;

    setDisabledItems([]);
    setActiveOptions(
      options.map(o => {
        const locationPrice = o.locationPrices.find(lp => lp.location === selectedSalesLocation._id);
        const unitPrice = locationPrice ? locationPrice.price : 0;
        return {
          _id: o._id,
          Quantity: 0,
          Price: '0.00',
          'Unit Price': unitPrice,
          Description: o.description
        };
      })
    );
    setTaxRate(0);
    setOther(0);
  }, [selectedSalesLocation]);

  useEffect(() => {
    if (activeOptions) {
      const taxRateNum = taxRate ? Number(taxRate) : 0;
      const otherNum = other ? Number(other) : 0;

      const subTotal = activeOptions.reduce((total, o) => total + Number(o.Price), 0);
      const salesTax = (taxRateNum * subTotal) / 100;
      setSummaryData({
        'Sub Total': subTotal.toFixed(2),
        'Sales Tax': salesTax.toFixed(2),
        Total: (subTotal + salesTax + otherNum).toFixed(2)
      });
    }
  }, [activeOptions, taxRate, other]);

  const onSalesLocationChange = e => {
    if (e) {
      const locationId = e.target.value;
      setSelectedSalesLocation(salesLocations.find(sl => sl._id === locationId));
    }
  };

  const onOptionsCheckedChange = (e, _id) => {
    if (e) {
      const checked = e.target.checked;
      if (!checked) {
        if (!disabledItems.includes(_id)) {
          disabledItems.push(_id);

          const activeOption = activeOptions.find(o => o['_id'] === _id);
          activeOption['Quantity'] = 0;
          activeOption['Price'] = 0;
          setActiveOptions([...activeOptions]);
        }
      } else {
        const existingIndex = disabledItems.indexOf(_id);
        if (existingIndex !== -1) {
          disabledItems.splice(existingIndex, 1);
        }
      }
      setDisabledItems([...disabledItems]);
    }
  };

  const onOptionQuantityChange = (optionIndex, quantity) => {
    const quantityNum = quantity ? Number(quantity) : 0;
    const option = activeOptions[optionIndex];

    option['Quantity'] = quantity;
    option['Price'] = (quantityNum * option['Unit Price']).toFixed(2);

    setActiveOptions([...activeOptions]);
  };

  const onTaxRateChange = e => {
    const value = e.target.value;
    setTaxRate(value);
  };

  const onOtherChange = e => {
    const value = e.target.value;
    setOther(value);
  };

  return (
    <Card>
      <Card.Header className="p-2 text-white bg-primary">Delivery Set Calculator</Card.Header>
      <Card.Body>
        <div className="mb-2 w-50">
          <h6>Select Sales Location: </h6>
          <DropDownInput
            size={'sm'}
            disabled={!['super admin', 'admin'].includes(role)}
            options={salesLocations.map(sl => sl.location)}
            optionValues={salesLocations.map(sl => sl._id)}
            value={selectedSalesLocation._id}
            onChangeFunction={onSalesLocationChange}
            required
          />
        </div>
        <hr />
        {activeOptions && (
          <>
            <h6>Items:</h6>
            <Table className="smallFont" responsive bordered>
              <thead>
                <tr className="bg-dark text-white">
                  {['', ...headers].map(h => (
                    <th key={h} style={h ? { minWidth: 120 } : { width: 40 }}>
                      {h}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {activeOptions.map((o, optionIndex) => (
                  <tr key={o._id}>
                    {['', ...headers].map(h => {
                      return (
                        <td key={`${o._id}-${h}`}>
                          {h ? (
                            <>
                              {h.includes('Price') && '$'}
                              {h === 'Quantity' ? (
                                <FormControl
                                  size="sm"
                                  onChange={e => onOptionQuantityChange(optionIndex, e.target.value)}
                                  value={o[h]}
                                  type="number"
                                  disabled={disabledItems.includes(o._id)}
                                />
                              ) : (
                                o[h]
                              )}
                            </>
                          ) : (
                            <FormControl
                              type="checkbox"
                              style={{ height: 16, width: 16 }}
                              checked={!disabledItems.includes(o._id)}
                              onChange={e => onOptionsCheckedChange(e, o._id)}
                            />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
                {/* SubTotal row */}
                {summaryData && (
                  <>
                    <tr>
                      <td colSpan={4} className="text-right">
                        <b>Sub Total</b>
                      </td>
                      <td>${summaryData['Sub Total']}</td>
                    </tr>

                    <tr>
                      <td colSpan={4} className="text-right">
                        <b>Tax Rate (%)</b>
                      </td>
                      <td>
                        <FormControl size="sm" type="number" value={taxRate} onChange={onTaxRateChange} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} className="text-right">
                        <b>Sales Tax</b>
                      </td>
                      <td>${summaryData['Sales Tax']}</td>
                    </tr>
                    <tr>
                      <td colSpan={4} className="text-right">
                        <b>Other</b>
                      </td>
                      <td>
                        <FormControl size="sm" type="number" value={other} onChange={onOtherChange} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} className="text-right">
                        <b>Total</b>
                      </td>
                      <td>${summaryData['Total']}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default DeliverySetCalculator;
