import React from 'react';
import { Button, Col, Modal, ProgressBar, Row } from 'react-bootstrap';
import ServiceOrderReport from '../service-order/ServiceOrderReport';

const ServiceReportModal = ({ show, onHide, serviceRowSubmitting, contract, onServiceOrderRowsChange, onSubmit }) => (
  <Modal
    dialogClassName="h-100 my-0"
    contentClassName="h-100"
    size="lg"
    show={show}
    onHide={onHide}
    centered
    backdrop="static"
  >
    <Modal.Header closeButton={!serviceRowSubmitting}>
      <Modal.Title>
        <h6 className="mb-0">Service Order Report</h6>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="overflow-auto">
      <ServiceOrderReport preFillReport={contract['serviceOrderReport']} onRowsChange={onServiceOrderRowsChange} />
    </Modal.Body>
    <Modal.Footer>
      <Row className="w-100 text-right">
        {serviceRowSubmitting && (
          <Col xs={12}>
            <ProgressBar className="mb-2" animated now={100} label="Saving report..." />
          </Col>
        )}
        <Col xs={12}>
          <Button size="sm" disabled={serviceRowSubmitting} variant="primary" onClick={onSubmit}>
            Save
          </Button>
        </Col>
      </Row>
    </Modal.Footer>
  </Modal>
);

export default ServiceReportModal;
