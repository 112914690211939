import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { normalizeId } from '../../form-generator/helpers/utility';
import { ABORT_ERROR, makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, contractMilestoneDates, typeOfTransactionOptions } from '../../helpers/constants';
import {
  calculateCommissionsSummary,
  calculateSalesSummary,
  getDocHubFormJSON,
  validateAndCleanupDocHubForm
} from '../../helpers/docHubFormHelpers';
import { convertKeyValueToFormObject, getObjectForPrefill } from '../../helpers/formHelpers';
import AlertModal from '../AlertModal';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import FilePopup from '../common/FilePopup';
import ServiceReportModal from '../common/ServiceReportModal';
import ContractedUnitOverview from '../inventory/ContractedUnitOverview';
import EditInventory from '../inventory/EditInventory';
import InventoryPicker from '../inventory/InventoryPicker';
import PotentialUnitsOverview from '../inventory/PotentialUnitsOverview';
import { v4 as uuid } from 'uuid';

const InventoryEditSideBar = ({ editingInventory, appChoices, onHide, onInventoryDelete, onInventoryEdit }) => {
  return (
    <SlidingSideBar fullScreen visible={editingInventory} onClose={onHide} title={'Inventory Details'}>
      {editingInventory && (
        <EditInventory
          id={editingInventory['_id']}
          inventoryFromSearch={editingInventory}
          appChoices={appChoices}
          fromSearch
          onInventoryDelete={onInventoryDelete}
          onInventoryEdit={onInventoryEdit}
        />
      )}
    </SlidingSideBar>
  );
};

const DocumentPrinter = ({
  appChoices,
  documentFields,
  docSource,
  selectedGroup,
  onServiceOrderReportUpdate,
  documentId,
  documentName,
  isServiceRequest,
  isMockPrint
}) => {
  const [formPreFill, setFormPreFill] = useState(null);
  const [formJson, setFormJson] = useState(null);
  const [inventoryUnitMeta, setInventoryUnitMeta] = useState(null);
  const [showServiceReportModal, setShowServiceReportModal] = useState(false);
  const [serviceOrderRows, setServiceOrderRows] = useState(null);
  const [serviceRowSubmitting, setServiceRowSubmitting] = useState(false);
  const [saveMode, setSaveMode] = useState(true);
  const [printingDocument, setPrintingDocument] = useState(false);
  const [savingData, setSavingData] = useState(false);

  const [viewingFile, setViewingFile] = useState(null);
  const [createdDocument, setCreatedDocument] = useState(null);
  const abortControllerRef = useRef(null);

  useEffect(() => {
    if (!documentFields) {
      setFormPreFill(null);
      setFormJson(null);
    }

    const tempDocumentFields = { ...documentFields };

    tempDocumentFields['withData'] && setFormPrefillValues(tempDocumentFields['withData']);
    setForm(tempDocumentFields);
  }, [documentFields]);

  useEffect(() => {
    try {
      calculateCommissionsSummary(inventoryUnitMeta?.pickedInventory?.invoice || 0);
    } catch (e) {
      console.log('calculateCommissionsSummary', e);
    }
  }, [inventoryUnitMeta, formJson]);

  const setFormPrefillValues = data => {
    //array to object
    data = data.reduce((prevValue, newObject) => ({ ...prevValue, [newObject.name]: newObject.value }), {});

    //convert contract important dates to linear fields
    contractMilestoneDates.forEach(({ key, start, end, emailField, options }) => {
      let milestoneObject = data[key];
      if (milestoneObject) {
        data[start] = milestoneObject.start;
        data[end] = milestoneObject.end;
        data[emailField] = milestoneObject.notificationEmails
          ? milestoneObject.notificationEmails.map(e => (e === data['buyerEmail'] ? 'buyer-mhc' : e))
          : [];
        data[options] = milestoneObject.options;
      }
    });

    //manage type of transaction
    if (
      data['typeOfTransaction'] &&
      ['Real |', 'Personal |'].some(v => data['typeOfTransaction'].includes(v)) &&
      !typeOfTransactionOptions.includes(data['typeOfTransaction'])
    ) {
      const typeOtherValue = data['typeOfTransaction'].split(' | ');
      data['typeOfTransaction'] = `${typeOtherValue[0]} | Other`;
      data['otherType'] = typeOtherValue[1];
    }

    convertKeyValueToFormObject(data, appChoices);
    setFormPreFill(getObjectForPrefill(data));
  };

  const setForm = (documentFields = []) => {
    //TODO check why timeout?
    setTimeout(() => {
      createNewForm(
        documentFields,
        ['Commission Sheet', 'Sales Agreement'].includes(documentName)
          ? documentName
          : documentFields.includedDocument?.find(n => ['Commission Sheet', 'Sales Agreement'].includes(n))
      );
    }, 200);
  };

  /**
   * Takes form fields from response
   * and renders a form to fill up variables required for this document
   * summaryFieldType [User for showing summary section below form]
   */
  const createNewForm = (initJson, summaryFieldType) => {
    //handle inventory unit (need to show inventory picker if document has inventory unit)
    handleInventoryUnit(initJson);
    setFormJson(getDocHubFormJSON(initJson, summaryFieldType, appChoices, docSource));
  };

  const handleInventoryUnit = ({ withData = [], withoutData = [] }) => {
    const inventoryUnitWithOutDataIndex = withoutData.findIndex(f => f.name === 'inventoryUnit');
    if (inventoryUnitWithOutDataIndex !== -1) {
      setInventoryUnitMeta(['Contract', 'Inventory'].includes(docSource) ? {} : { inventoryUnits: [] });
      withoutData.splice(inventoryUnitWithOutDataIndex, 1);
    }

    const inventoryUnitWithDataIndex = withData.findIndex(f => f.name === 'inventoryUnit');
    if (inventoryUnitWithDataIndex !== -1) {
      setInventoryUnitMeta(
        ['Contract', 'Inventory'].includes(docSource)
          ? { pickedInventory: withData[inventoryUnitWithDataIndex]['value'] }
          : { inventoryUnits: withData[inventoryUnitWithDataIndex]['value'] }
      );
      withData.splice(inventoryUnitWithDataIndex, 1);
    }
  };

  const onUnitSelect = unit => {
    setInventoryUnitMeta({ ...inventoryUnitMeta, selectedUnit: unit });
  };

  const onServiceOrderReportClick = () => {
    setShowServiceReportModal(true);
  };

  const handleServiceReportClose = () => {
    setServiceOrderRows(null);
    setShowServiceReportModal(false);
  };

  const onServiceOrderRowsChange = rows => {
    setServiceOrderRows(rows);
  };

  const submitServiceReport = async () => {
    setServiceRowSubmitting(true);
    const submitRows = serviceOrderRows.map(r => {
      const obj = {
        ...r,
        Description: r['Description'] === 'Other' ? r['Description Other'] : r['Description'],
        Cause: r['Cause'] === 'Other' ? r['Cause Other'] : r['Cause'],
        'Repair Method': r['Repair Method'] === 'Other' ? r['Repair Method Other'] : r['Repair Method']
      };

      ['Repair Method Other', 'Cause Other', 'Description Other'].forEach(f => delete obj[f]);

      return obj;
    });

    const { response, error } = await makeApiRequests({
      requestBody: {
        serviceOrderReport: submitRows
      },
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(selectedGroup._id)
    });

    if (error) {
      setServiceRowSubmitting(false);
      toast.error(error);
      return;
    }

    toast.success('Service Order Report submitted successfully!');
    setServiceRowSubmitting(false);
    onServiceOrderReportUpdate(response);
    handleServiceReportClose();
  };

  const onSaveClick = () => {
    setSaveMode(true);
    setTimeout(() => document.getElementById('document').click(), 200);
  };

  const cancelPrinting = () => {
    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    if (printingDocument.printSessionId)
      makeApiRequests({ endpoint: ENDPOINTS.DOCHUB_PRINT_DOCUMENT_CANCEL(printingDocument.printSessionId) });

    setPrintingDocument(false);
    setSavingData(false);
  };

  const onPrintClick = () => {
    if (printingDocument) {
      cancelPrinting();
      return;
    }

    setSaveMode(false);
    setTimeout(() => document.getElementById('document').click(), 200);
  };

  const onLinkUnitClick = replacingInventoryUnit => {
    setInventoryUnitMeta({ ...inventoryUnitMeta, showInventoryPicker: true, replacingInventoryUnit });
  };

  const removeInventoryUnitFromSelections = removingInventoryUnit => {
    const { inventoryUnits: previousUnits = [], pickedInventory } = inventoryUnitMeta;

    let newInventoryUnits;
    if (docSource === 'Contact') {
      newInventoryUnits = [...previousUnits];
      const removingIndex = newInventoryUnits.findIndex(u => u._id === removingInventoryUnit._id);
      newInventoryUnits.splice(removingIndex, 1);
    }

    setInventoryUnitMeta({
      ...inventoryUnitMeta,
      inventoryUnits: newInventoryUnits,
      pickedInventory:
        docSource === 'Contract' ||
        (docSource === 'Contact' && pickedInventory && pickedInventory['_id'] === removingInventoryUnit['_id'])
          ? undefined
          : pickedInventory,
      deletingUnit: false,
      toBeDeletedUnit: undefined
    });
  };

  const onRemoveUnitClick = removingInventoryUnit => {
    if (isMockPrint) {
      removeInventoryUnitFromSelections(removingInventoryUnit);
      return;
    }

    setInventoryUnitMeta({ ...inventoryUnitMeta, deletingUnit: false, toBeDeletedUnit: removingInventoryUnit || {} }); //{} for contact because there is only one unit
  };

  const updateContactOrContractInventory = async inventoryUnit => {
    setInventoryUnitMeta({ ...inventoryUnitMeta, assigningInventory: true });

    const { response, error } = await makeApiRequests({
      endpoint:
        docSource === 'Contact'
          ? ENDPOINTS.CONTACTS_WITH_ID(selectedGroup._id)
          : ENDPOINTS.CONTRACTS_WITH_ID(selectedGroup._id),
      requestBody: { inventoryUnit },
      method: 'PUT'
    });

    setInventoryUnitMeta({ ...inventoryUnitMeta, assigningInventory: false });

    if (error) {
      toast(error, {
        type: 'error'
      });
      return;
    }

    toast(`Inventory unit updated successfully!`, {
      type: 'success'
    });

    return true;
  };

  const onInventoryPicked = async inventoryUnits => {
    const inventoryUnit = inventoryUnits[0];

    const { inventoryUnits: previousUnits = [], pickedInventory, replacingInventoryUnit } = inventoryUnitMeta;

    let newInventoryUnits;
    if (docSource === 'Contact') {
      newInventoryUnits = [...previousUnits];
      if (replacingInventoryUnit) {
        const replacingIndex = newInventoryUnits.findIndex(u => u._id === replacingInventoryUnit._id);
        newInventoryUnits[replacingIndex] = inventoryUnit;
      } else {
        newInventoryUnits.push(inventoryUnit);
      }
    }

    //is mock print, no need to send a request
    if (!isMockPrint) {
      const success = await updateContactOrContractInventory(
        docSource === 'Contact' ? newInventoryUnits.map(u => u._id) : inventoryUnit._id
      );
      if (!success) return;
    }

    setInventoryUnitMeta({
      ...inventoryUnitMeta,
      inventoryUnits: newInventoryUnits,
      pickedInventory:
        docSource === 'Contract'
          ? inventoryUnit
          : pickedInventory && newInventoryUnits.map(u => u['_id']).includes(pickedInventory['_id'])
          ? pickedInventory
          : newInventoryUnits[0],
      showInventoryPicker: false
    });
  };

  const onInventoryPickerClose = () => {
    setInventoryUnitMeta({ ...inventoryUnitMeta, showInventoryPicker: false });
  };

  const removeInventoryUnit = async () => {
    setInventoryUnitMeta({ ...inventoryUnitMeta, deletingUnit: true });

    let inventoryUnitId;
    if (docSource === 'Contact') {
      let newUnitIds = inventoryUnitMeta['inventoryUnits'].map(u => u['_id']);
      const removingIndex = newUnitIds.indexOf(inventoryUnitMeta['toBeDeletedUnit']['_id']);
      newUnitIds.splice(removingIndex, 1);
      inventoryUnitId = newUnitIds;
    } else {
      inventoryUnitId = null;
    }

    const { response, error } = await makeApiRequests({
      endpoint:
        docSource === 'Contact'
          ? ENDPOINTS.CONTACTS_WITH_ID(selectedGroup._id)
          : ENDPOINTS.CONTRACTS_WITH_ID(selectedGroup._id),
      requestBody: { inventoryUnit: inventoryUnitId },
      method: 'PUT'
    });

    setInventoryUnitMeta({ ...inventoryUnitMeta, deletingUnit: false });

    if (error) {
      toast(error, {
        type: 'error'
      });
      return;
    }

    toast(`Inventory unit removed successfully!`, {
      type: 'success'
    });

    setInventoryUnitMeta({
      ...inventoryUnitMeta,
      inventoryUnits: docSource === 'Contact' ? response['inventoryUnit'] : undefined,
      pickedInventory:
        docSource === 'Contract' ||
        (docSource === 'Contact' &&
          inventoryUnitMeta['pickedInventory'] &&
          inventoryUnitMeta['pickedInventory']['_id'] === inventoryUnitMeta['toBeDeletedUnit']['_id'])
          ? undefined
          : inventoryUnitMeta['pickedInventory'],
      deletingUnit: false,
      toBeDeletedUnit: undefined
    });
  };

  const onRefreshUnitClick = async () => {
    setInventoryUnitMeta({ ...inventoryUnitMeta, refreshingInventory: true });

    const { response, error } = await makeApiRequests({
      endpoint: docSource === 'Contract' ? ENDPOINTS.CONTRACTS_SEARCH : ENDPOINTS.CONTACTS_SEARCH,
      requestBody: { filter: { _id: selectedGroup['_id'] } }
    });

    setInventoryUnitMeta({ ...inventoryUnitMeta, refreshingInventory: false });
    if (error) {
      return toast.error(error);
    }

    const details = response[0];

    setInventoryUnitMeta({
      ...inventoryUnitMeta,
      inventoryUnits: docSource === 'Contact' ? details['inventoryUnit'] : undefined,
      pickedInventory:
        docSource === 'Contract'
          ? details['inventoryUnit']
          : inventoryUnitMeta['pickedInventory'] &&
            details['inventoryUnit'].map(u => u['_id']).includes(inventoryUnitMeta['pickedInventory']['_id'])
          ? inventoryUnitMeta['pickedInventory']
          : undefined
    });
  };

  const onInventoryEdit = updatedInventory => {
    const { pickedInventory, inventoryUnits } = inventoryUnitMeta;
    //update pickedInventory and inventoryUnits

    if (pickedInventory && pickedInventory['_id'] === updatedInventory['_id']) {
      inventoryUnitMeta.pickedInventory = updatedInventory;
    }

    if (inventoryUnits) {
      const existingIndex = inventoryUnits.findIndex(u => u['_id'] === updatedInventory['_id']);
      if (existingIndex !== -1) {
        inventoryUnitMeta['inventoryUnits'][existingIndex] = updatedInventory;
      }
    }

    setInventoryUnitMeta({ ...inventoryUnitMeta, editingInventory: null });
  };
  const onInventoryDelete = deletedInventoryId => {
    const { pickedInventory, inventoryUnits } = inventoryUnitMeta;
    //update pickedInventory and inventoryUnits

    if (pickedInventory && pickedInventory['_id'] === deletedInventoryId) {
      inventoryUnitMeta.pickedInventory = undefined;
    }

    if (inventoryUnits) {
      const existingIndex = inventoryUnits.findIndex(u => u['_id'] === deletedInventoryId);
      if (existingIndex !== -1) {
        inventoryUnitMeta['inventoryUnits'].splice(existingIndex, 1);
      }
    }

    setInventoryUnitMeta({ ...inventoryUnitMeta, editingInventory: null });
  };

  const onPrintSubmit = async form => {
    if (!isMockPrint && !saveMode && inventoryUnitMeta) {
      if (docSource === 'Contract' && !inventoryUnitMeta['pickedInventory']) {
        return toast.error('Please link a inventory unit!');
      }

      if (docSource === 'Contact') {
        if (inventoryUnitMeta['inventoryUnits'].length === 0) {
          return toast.error('Please link potential units and pick one of them to be printed for this document!');
        }

        if (!inventoryUnitMeta['pickedInventory']) {
          return toast.error('Please pick one of the potential units to be printed for this document!');
        }
      }
    }

    const valid = validateAndCleanupDocHubForm(form);
    if (!valid) return;

    if (form.additionalFields) {
      const existingAdditionalFields = documentFields.withData.find(f => f.name === 'additionalFields')?.value || {};
      form.additionalFields = { ...existingAdditionalFields, ...form.additionalFields };
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    let requestOptions = isMockPrint
      ? {
          endpoint: saveMode
            ? docSource === 'Contract'
              ? ENDPOINTS.CONTRACTS_BASE
              : ENDPOINTS.CONTACTS_BASE
            : ENDPOINTS.DOCHUB_PRINT_DOCUMENT
        }
      : {
          endpoint: saveMode
            ? docSource === 'Contract'
              ? ENDPOINTS.CONTRACTS_WITH_ID(selectedGroup._id)
              : ENDPOINTS.CONTACTS_WITH_ID(selectedGroup._id)
            : ENDPOINTS.DOCHUB_PRINT_DOCUMENT_WITH_ID(selectedGroup._id),
          method: saveMode ? 'PUT' : 'POST'
        };

    let printSessionId;
    if (saveMode) {
      if (isMockPrint) {
        //need to send inventoryunit because mock print does not save it to contact or contract on pick
        form['inventoryUnit'] = inventoryUnitMeta
          ? docSource === 'Contact'
            ? inventoryUnitMeta['inventoryUnits'].map(u => u._id)
            : inventoryUnitMeta['pickedInventory']
            ? inventoryUnitMeta['pickedInventory']['_id']
            : undefined
          : undefined;
      }
      requestOptions.requestBody = form;
    } else {
      form['inventoryUnit'] =
        inventoryUnitMeta && inventoryUnitMeta['pickedInventory']
          ? inventoryUnitMeta['pickedInventory']['_id']
          : undefined;
      const documentParams = isServiceRequest
        ? { serviceOrder: selectedGroup['serviceOrderReport'] || [], ...form }
        : form;

      printSessionId = uuid();
      requestOptions.requestBody = {
        documentParams,
        docId: documentId,
        documentName,
        dataSource: isMockPrint ? 'Mock' : docSource,
        printSessionId
      };
    }

    if (saveMode) {
      setSavingData(true);
    } else {
      setPrintingDocument({ printSessionId });
    }

    toast(`Please wait, ${saveMode ? 'saving data' : 'creating document'}...`, {
      type: 'info'
    });

    setCreatedDocument(null);
    const { error, response } = await makeApiRequests({ ...requestOptions, signal });

    if (error === ABORT_ERROR) return;

    if (error) {
      toast(error, {
        type: 'error'
      });
    } else {
      if (saveMode) {
        toast.success(`${docSource} saved successfully`);
      } else {
        setCreatedDocument({ originalName: response['metadata']['originalName'], ...response });
        setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
      }
    }
    setPrintingDocument(false);
    setSavingData(false);
  };

  const autoFillAddress = (e, { stateKey, cityKey, countyKey }) => {
    try {
      const zipCode = e ? e.target.value : document.getElementById('mailingZip').value;
      const zipCodeFiltered = appChoices
        .find(a => a.key === 'zipCodes')
        ['values'].filter(zip => zipCode == zip['Zip Code']);

      if (zipCodeFiltered.length > 0) {
        document.getElementById(stateKey).value = zipCodeFiltered[0]['Property State'];
        document.getElementById(cityKey).value = zipCodeFiltered[0]['Property City'];
        document.getElementById(countyKey).value = zipCodeFiltered[0]['Property County'];
      }
    } catch (e) {}
  };

  window['autoFillMailingAddress'] = e =>
    autoFillAddress(e, { stateKey: 'mailingState', cityKey: 'mailingCity', countyKey: 'mailingCounty' });
  window['autoFillpropertyAddress'] = e =>
    autoFillAddress(e, { stateKey: 'propertyState', cityKey: 'propertyCity', countyKey: 'propertyCounty' });

  window['onPrintSubmit'] = onPrintSubmit;

  window['calculateSalesSummary'] = () => {
    try {
      calculateSalesSummary();
    } catch (e) {
      console.log('calculateSalesSummary', e);
    }
  };

  window['calculateCommissionsSummary'] = () => {
    try {
      calculateCommissionsSummary(inventoryUnitMeta?.pickedInventory?.invoice || 0);
    } catch (e) {
      console.log('calculateCommissionsSummary', e);
    }
  };

  const setDefaultValueToCheckedKeys = (inputId, appChoiceKey) => {
    try {
      const keyValueContainer = document.getElementById(inputId);

      const checkedKeys = [...keyValueContainer.getElementsByTagName('input')].filter(
        input => input.type === 'checkbox' && input.checked
      );

      checkedKeys.forEach(checkedKey => {
        const relatedTextBox = document.getElementById(`${inputId}-${normalizeId(checkedKey.value)}-key-value`);
        if (!relatedTextBox.value) {
          relatedTextBox.value =
            appChoices.find(c => c.key === appChoiceKey)?.values.find(c => c.name === checkedKey.value)?.defaultValue ||
            0;
        }
      });
    } catch (e) {}

    window['calculateCommissionsSummary']();
  };

  const onCommissionSheetOptionsChange = e => {
    if (!e) return;

    setDefaultValueToCheckedKeys('commissionSheetOptions', 'Commission Options');
  };

  window['onCommissionSheetOptionsChange'] = onCommissionSheetOptionsChange;

  const onCommissionSheetLessOptionsChange = e => {
    if (!e) return;

    setDefaultValueToCheckedKeys('commissionSheetLessOptions', 'Commission Less Options');
  };
  window['onCommissionSheetLessOptionsChange'] = onCommissionSheetLessOptionsChange;

  return (
    <>
      {formJson && (isMockPrint || formPreFill) && (
        <>
          {isServiceRequest && (
            <>
              <Alert variant="primary" className="p-2">
                <div className="d-flex">
                  <div className="flex-grow-1 align-self-center">
                    <h6 className="mb-0">
                      <b>Service Order Report</b>
                    </h6>
                  </div>
                  <div>
                    <Button variant="primary" size="sm" className=" m-0 py-0" onClick={onServiceOrderReportClick}>
                      {selectedGroup['serviceOrderReport'] ? 'View' : 'Start'}
                    </Button>
                  </div>
                </div>
              </Alert>
              <ServiceReportModal
                show={showServiceReportModal}
                onHide={handleServiceReportClose}
                serviceRowSubmitting={serviceRowSubmitting}
                contract={selectedGroup}
                onServiceOrderRowsChange={onServiceOrderRowsChange}
                onSubmit={submitServiceReport}
              />
              <hr />
            </>
          )}

          {inventoryUnitMeta !== null && (
            <>
              <div className="mb-3">
                {['Contract', 'Inventory'].includes(docSource) ? (
                  <ContractedUnitOverview
                    onRefreshUnitClick={!isMockPrint && docSource === 'Contract' ? onRefreshUnitClick : undefined}
                    onLinkUnitClick={docSource === 'Contract' && onLinkUnitClick}
                    onEditUnitClick={
                      docSource === 'Contract' &&
                      (() =>
                        setInventoryUnitMeta({
                          ...inventoryUnitMeta,
                          editingInventory: inventoryUnitMeta['pickedInventory']
                        }))
                    }
                    onRemoveUnitClick={docSource === 'Contract' && onRemoveUnitClick}
                    onChangeUnitClick={docSource === 'Contract' && onLinkUnitClick}
                    inventoryUnit={inventoryUnitMeta['pickedInventory']}
                    refreshing={inventoryUnitMeta['refreshingInventory']}
                  />
                ) : (
                  <PotentialUnitsOverview
                    onRefreshUnitClick={!isMockPrint ? onRefreshUnitClick : undefined}
                    onLinkNewUnitClick={onLinkUnitClick}
                    onRemoveUnitClick={onRemoveUnitClick}
                    onChangeUnitClick={onLinkUnitClick}
                    inventoryUnits={inventoryUnitMeta['inventoryUnits']}
                    refreshing={inventoryUnitMeta['refreshingInventory']}
                    activeUnitId={
                      inventoryUnitMeta['pickedInventory'] ? inventoryUnitMeta['pickedInventory']['_id'] : ''
                    }
                    onEditUnitClick={unit =>
                      setInventoryUnitMeta({
                        ...inventoryUnitMeta,
                        editingInventory: unit
                      })
                    }
                    onUnitSelect={unit => setInventoryUnitMeta({ ...inventoryUnitMeta, pickedInventory: unit })}
                  />
                )}
              </div>
              <InventoryPicker
                excludedUnitIds={
                  docSource === 'Contract'
                    ? inventoryUnitMeta['pickedInventory']
                      ? [inventoryUnitMeta['pickedInventory']['_id']]
                      : []
                    : docSource !== 'Inventory'
                    ? inventoryUnitMeta?.['inventoryUnits']
                      ? inventoryUnitMeta?.['inventoryUnits']?.map(u => u['_id'])
                      : []
                    : []
                }
                show={inventoryUnitMeta && inventoryUnitMeta['showInventoryPicker']}
                onSubmit={onInventoryPicked}
                submitting={inventoryUnitMeta && inventoryUnitMeta['assigningInventory']}
                fromContracts={docSource === 'Contracts'}
                onInventoryPickerClose={onInventoryPickerClose}
                selectedUnits={
                  inventoryUnitMeta && inventoryUnitMeta.selectedUnit ? [inventoryUnitMeta.selectedUnit] : []
                }
                onUnitSelect={onUnitSelect}
              />
              <InventoryEditSideBar
                appChoices={appChoices}
                onHide={() => setInventoryUnitMeta({ ...inventoryUnitMeta, editingInventory: null })}
                editingInventory={inventoryUnitMeta['editingInventory']}
                onInventoryEdit={onInventoryEdit}
                onInventoryDelete={onInventoryDelete}
              />

              <AlertModal
                show={inventoryUnitMeta['toBeDeletedUnit']}
                alertText={`Are you sure to remove this inventory unit from this ${docSource}?`}
                showProgress={inventoryUnitMeta['deletingUnit']}
                progressText="Removing unit..."
                onDismissClick={() => setInventoryUnitMeta({ ...inventoryUnitMeta, toBeDeletedUnit: undefined })}
                onHide={() => setInventoryUnitMeta({ ...inventoryUnitMeta, toBeDeletedUnit: undefined })}
                onContinueClick={removeInventoryUnit}
              />
            </>
          )}
          <Card>
            <Card.Header className="bg-primary-light">
              <h6 className="mb-0">{documentName}</h6>
            </Card.Header>
            <Card.Body className="p-2">
              <FormGenerator formJson={formJson} formValues={{ document: formPreFill }} />
            </Card.Body>
            <Card.Footer className="bg-primary-light text-right">
              {docSource !== 'Inventory' && (
                <Button
                  disabled={printingDocument || savingData}
                  size="sm"
                  variant="success"
                  className="mr-2"
                  onClick={onSaveClick}
                >
                  Save
                </Button>
              )}
              <Button
                disabled={savingData}
                size="sm"
                variant={printingDocument ? 'danger' : 'primary'}
                onClick={onPrintClick}
              >
                {printingDocument ? 'Printing document... Tap again to cancel' : 'Print'}
              </Button>
            </Card.Footer>
          </Card>
        </>
      )}
      {createdDocument && (
        <>
          <Alert variant="success" className="my-3">
            <p>
              The document "{createdDocument.originalName}" has been created successfully.{' '}
              {isMockPrint
                ? ''
                : `This has been added to the
              respective ${docSource} folder.`}{' '}
              You may quickly access the file here.
            </p>

            <Button className="mt-1" variant="success" onClick={() => setViewingFile(createdDocument)}>
              Open File
            </Button>
          </Alert>
          {viewingFile && <FilePopup file={viewingFile} onHide={() => setViewingFile(null)} />}
        </>
      )}
    </>
  );
};

export default DocumentPrinter;
