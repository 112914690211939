import React, { useState } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import CachedResponse from '../common/CachedResponse';
import NotFound from '../NotFound';
import CRMPipeline from './CRMPipeline';
import InventoryPipeline from './InventoryPipeline';
import Pipeline from './Pipeline';
import { useLocation } from 'react-router-dom';

const PipelineRoutes = () => {
  const [role] = useState(localStorage.getItem('user-role'));
  const location = useLocation();

  const getActivePage = () => {
    return (
      <Switch>
        <Route exact path="/pipeline">
          <CachedResponse listEndpoint="app-choices" render={appChoices => <Pipeline appChoices={appChoices} />} />
        </Route>
        <Route exact path="/pipeline/crm">
          <CachedResponse listEndpoint="app-choices" render={appChoices => <CRMPipeline appChoices={appChoices} />} />
        </Route>
        <Route exact path="/pipeline/inventory">
          <CachedResponse
            listEndpoint="app-choices"
            render={appChoices => <InventoryPipeline appChoices={appChoices} />}
          />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  };

  if (
    !(
      ['super admin', 'admin', 'service', 'sales', 'sales manager', 'installer'].includes(role) ||
      (role === 'inventory manager' && location.pathname.includes('/pipeline/inventory'))
    )
  ) {
    return <NotFound text="You are not authorized to view this page" />;
  }

  return getActivePage();
};

export default withRouter(PipelineRoutes);
