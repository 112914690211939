import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, installerFilterOptions, installerSortByOptions } from '../../helpers/constants';
import HorizontalProgress from '../common/HorizontalProgress';
import SearchBox from '../common/searchbox';
import ContractSearchItem from '../main/ContractSearchItem';
import NotFound from '../NotFound';

const SearchInstaller = ({ appChoices }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);

  const fetchContracts = async ({ sortBy, maxLimit, descSort, filters = {}, query }) => {
    let installerId = '';
    try {
      installerId = JSON.parse(localStorage.getItem('installer'))._id;
    } catch (e) {}

    if (!installerId) {
      setLoading(false);
      toast.error('Cannot retreive your installer, please consult your admin!');
      return;
    }

    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH,
      requestBody: {
        filter: { ...filters, installer: installerId },
        query,
        maxLimit: maxLimit === 'All' ? undefined : maxLimit,
        sort: { [sortBy]: descSort ? -1 : 1 }
      }
    });
    setLoading(false);

    if (error) {
      return toast.error(error);
    }

    setContracts(response);
  };

  return (
    <Container fluid className={'h-100 pt-2 pb-3 px-md-2 '}>
      <SearchBox
        appChoices={appChoices}
        filterCreationOptions={installerFilterOptions}
        sortByOptions={installerSortByOptions}
        maxLimitOptions={[10, 20, 50, 100, 200, 'All']}
        onSearchOptionsChange={fetchContracts}
        disabled={loading}
      />
      {loading && <HorizontalProgress text={'Fetching contracts...'} />}
      <hr />

      {contracts.length > 0
        ? contracts.map(contract => (
            <ContractSearchItem
              key={contract['_id']}
              contract={contract}
              appChoices={appChoices}
              onContractClick={() => history.push('/setup/' + contract['_id'])}
            />
          ))
        : !loading && <NotFound text="No contracts found" />}
    </Container>
  );
};

export default SearchInstaller;
