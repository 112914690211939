import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const SubmitButtonRow = ({ bottom, showGoToDocHub = true, disabled, onGoToDocHubClick, onSaveClick }) => (
  <Row>
    <Col xs={12} className={`text-right ${bottom ? 'my-4' : ' mb-3 mt-2'}`}>
      {showGoToDocHub && (
        <Button disabled={disabled} onClick={onGoToDocHubClick} size="sm" variant="dark">
          Save and Go to Doc Hub
        </Button>
      )}

      <Button disabled={disabled} onClick={onSaveClick} size="sm" className="ml-2">
        Save Record
      </Button>
    </Col>
  </Row>
);

export default SubmitButtonRow;
