import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Modal, ProgressBar } from 'react-bootstrap';
import { Pen, PlusCircle, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import AlertModal from '../AlertModal';
import EntityTable from '../common/EntityTable';
import Loader from '../Loader';
import { getRows } from './constants';

const createEntityTableHeaders = locations => {
  return [
    { name: 'Description', key: 'description' },
    ...locations.map(l => ({ name: l.location, key: l._id, type: 'number' }))
  ];
};

const createFormRows = locations => {
  return getRows([
    { name: 'Description', key: 'description' },
    ...locations.map(l => ({ name: l.location, key: l._id, type: 'number' }))
  ]);
};

const createTableRowFromLotSetResponse = l => {
  const lotSetObj = { _id: l._id, description: l.description };
  l.locationPrices.forEach(lp => {
    if (lp.location) lotSetObj[lp.location._id] = lp.price;
  });
  return lotSetObj;
};

const getForm = ({ rows, type = 'Add' }) => ({
  forms: [
    {
      name: `${type} lotset`,
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: type,
        show: true,
        onSubmit: type === 'Add' ? 'onNewLotSetFormSubmit' : 'onUpdateLotSetFormSubmit'
      },
      rows
    }
  ]
});

const AddEditModal = ({ show, toBeEditedLotSet, onHide, showProgress, addForm, updateForm }) => (
  <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
    <Modal.Header closeButton={!showProgress}>
      <Modal.Title>
        <h6 className="mb-0">{toBeEditedLotSet ? `Update Lotset` : `Add New Lotset`}</h6>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="overflow-auto px-1">
      <FormGenerator
        formJson={toBeEditedLotSet ? updateForm : addForm}
        formValues={toBeEditedLotSet ? { 'Update lotset': toBeEditedLotSet } : {}}
      />
      {showProgress && (
        <ProgressBar now={100} animated striped label={toBeEditedLotSet ? `Updating Lotset...` : `Saving Lotset....`} />
      )}
    </Modal.Body>
  </Modal>
);

const LotSets = ({ salesLocations = [] }) => {
  const [loading, setLoading] = useState(false);
  const [lotSets, setLotSets] = useState([]);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [toBeDeletedLotSet, setToBeDeletedLotSet] = useState(null);
  const [lotSetActionMeta, setLotSetActionMeta] = useState(null);
  const [lotSetAddingUpdating, setLotSetAddingUpdating] = useState(false);
  const [entityTableHeaders] = useState(createEntityTableHeaders(salesLocations));
  const [formRows] = useState(createFormRows(salesLocations));

  const fetchLotSets = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: `${ENDPOINTS.LOTSET_BASE}/list`,
      method: 'POST'
    });

    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setLotSets(response.map(createTableRowFromLotSetResponse));
  };

  useEffect(() => {
    fetchLotSets();
  }, []);

  const deleteLotSet = async () => {
    setDeleteInProgress(true);

    const { response, error } = await makeApiRequests({
      endpoint: `${ENDPOINTS.LOTSET_BASE}/${toBeDeletedLotSet._id}`,
      method: 'DELETE'
    });

    setDeleteInProgress(false);
    if (error) {
      toast.error(error);
      return;
    }

    lotSets.splice(lotSets.findIndex(i => i._id === toBeDeletedLotSet._id), 1);
    setLotSets([...lotSets]);
    setToBeDeletedLotSet(null);
  };

  const onNewLotSetFormSubmit = async form => {
    const { description, ...salesLocationPrices } = form;

    const locationPrices = Object.keys(salesLocationPrices).map(id => {
      return {
        price: salesLocationPrices[id],
        location: id
      };
    });

    setLotSetAddingUpdating(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.LOTSET_BASE,
      requestBody: {
        description,
        locationPrices
      }
    });

    setLotSetAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    setLotSets([createTableRowFromLotSetResponse(response), ...lotSets]);
    setLotSetActionMeta(null);
  };

  const onUpdateLotSetFormSubmit = async form => {
    setLotSetAddingUpdating(true);

    const { description, ...salesLocationPrices } = form;

    const locationPrices = Object.keys(salesLocationPrices).map(id => {
      return {
        price: salesLocationPrices[id],
        location: id
      };
    });

    const { response, error } = await makeApiRequests({
      endpoint: `${ENDPOINTS.LOTSET_BASE}/${lotSetActionMeta.lotSet._id}`,
      requestBody: {
        description,
        locationPrices
      },
      method: 'PUT'
    });

    setLotSetAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    const lotSetIndex = lotSets.findIndex(i => i._id === response._id);
    lotSets[lotSetIndex] = createTableRowFromLotSetResponse(response);
    setLotSets([...lotSets]);
    setLotSetActionMeta(null);
  };

  window['onNewLotSetFormSubmit'] = onNewLotSetFormSubmit;
  window['onUpdateLotSetFormSubmit'] = onUpdateLotSetFormSubmit;

  return (
    <Container fluid className="h-100 py-3 px-md-3">
      <Card>
        <Card.Body>
          <h6>Lot-Set Options</h6>
          <hr className="my-3" />
          {loading ? (
            <Loader />
          ) : (
            <>
              <EntityTable
                rowKeyField={'_id'}
                data={lotSets}
                headers={entityTableHeaders}
                searchQueryKeys={['description']}
                additionalComponents={() => (
                  <Button size="sm" variant="success" onClick={() => setLotSetActionMeta({ mode: 'add' })}>
                    <PlusCircle className="mr-1" /> New Option
                  </Button>
                )}
                actionCell={lotSet => (
                  <div>
                    <Button
                      variant="outline-primary"
                      className="px-1 py-0 mr-1"
                      onClick={() => setLotSetActionMeta({ lotSet, mode: 'edit' })}
                    >
                      <Pen size={10} />
                    </Button>
                    <Button
                      variant="outline-danger"
                      className="px-1 py-0 "
                      onClick={() => setToBeDeletedLotSet(lotSet)}
                    >
                      <Trash size={10} />
                    </Button>
                  </div>
                )}
              />
              {lotSets.length > 0 && (
                <AlertModal
                  show={toBeDeletedLotSet !== null}
                  alertText={`Are you sure you want to delete this lotset? This action cannot be undone!`}
                  onHide={() => setToBeDeletedLotSet(null)}
                  onDismissClick={() => setToBeDeletedLotSet(null)}
                  onContinueClick={deleteLotSet}
                  progressText={`Deleting lotset...`}
                  showProgress={deleteInProgress}
                />
              )}
              <AddEditModal
                show={lotSetActionMeta !== null}
                onHide={() => setLotSetActionMeta(null)}
                toBeEditedLotSet={lotSetActionMeta ? lotSetActionMeta.lotSet : null}
                showProgress={lotSetAddingUpdating}
                addForm={getForm({ rows: formRows })}
                updateForm={getForm({ rows: formRows, type: 'Update' })}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default LotSets;
