import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Route, Switch, withRouter } from 'react-router';
import CachedResponse from '../common/CachedResponse';
import NotFound from '../NotFound';
import OpsDetails from './OpsDetails';
import SearchOps from './SearchOps';

const Ops = () => {
  const [role] = useState(localStorage.getItem('user-role'));

  const getActivePage = () => {
    return (
      <Switch>
        <Route exact path="/walkthrough/search">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <SearchOps appChoices={appChoices} />} />
        </Route>
        <Route exact path="/walkthrough/:id">
          <OpsDetails />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  };

  if (role !== 'tech') {
    return <NotFound text="You are not authorized to view this page" />;
  }

  return (
    <>
      <Container fluid className="px-3 h-100">
        {getActivePage()}
      </Container>
    </>
  );
};

export default withRouter(Ops);
