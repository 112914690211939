import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, FormControl, Row, Table } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import DropDownInput from '../../form-generator/components/DropDownInput';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import DateRangePicker from '../DateRangePicker';
import Loader from '../Loader';
import Toggler from '../Toggler';

const getInitialDateRange = () => {
  const today = new Date();
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 2);
  return { startDate: startDate.toISOString(), endDate: today.toISOString() };
};

const SelectionBox = ({
  selectedType,
  selectedDateRange,
  selectedSalesLocation,
  typeOptions,
  salesLocations,
  onTypeChange,
  onLocationChange,
  onDateRangeChange,
  manufacturerAssigned,
  selectedManufacturer,
  setSelectedManufacturer,
  setModalText
}) => {
  const manufacturerOptions = manufacturerAssigned
    .filter(m => m)
    .map(m => ({
      label: m?.name,
      value: m?._id
    }));

  const handleManufacturerChange = selectedItems => {
    const selectedIds = selectedItems.map(item => item.value);
    setSelectedManufacturer(selectedIds);
  };

  return (
    <>
      <Card className="border rounded">
        <Card.Header className="bg-primary">
          <h5 className="mb-0 text-white">Delivery Setup Reports</h5>
        </Card.Header>
        <Card.Body className="py-3">
          <Row>
            <Col xs={12} md={3} className="px-4 mt-3">
              <h6 className="mb-2">Report Type</h6>
              <DropDownInput
                id="reportTypeSelect"
                size={'sm'}
                value={selectedType}
                options={typeOptions}
                onChangeFunction={onTypeChange}
                required
              />
            </Col>
            <Col xs={12} md={3} className="px-4 mt-3">
              <h6 className="mb-2">Sales Location</h6>
              <DropDownInput
                id="locationSelect"
                size={'sm'}
                value={selectedSalesLocation._id}
                options={salesLocations.map(l => l.location)}
                optionValues={salesLocations.map(l => l._id)}
                onChangeFunction={onLocationChange}
                required
              />
            </Col>
            <Col xs={12} md={3} className="px-4 mt-3">
              <h6 className="mb-2">Manufacturer</h6>
              <MultiSelect
                showCheckbox
                closeOnChangedValue={false}
                id="manufacturer"
                size="sm"
                value={selectedManufacturer.map(id => ({
                  label: manufacturerOptions.find(m => m.value === id)?.label || '',
                  value: id
                }))}
                options={manufacturerOptions}
                onChange={handleManufacturerChange}
                required
              />
            </Col>
            <Col xs={12} md={3} className="px-4 mt-3">
              <h6 className="mb-2">Model</h6>
              <FormControl placeholder="Enter here..." onChange={e => setModalText(e.target.value)} />
            </Col>
            {selectedType === 'Monthly' && (
              <Col xs={12} className="px-4 mt-3">
                <h6 className="mb-2">Select Date Range</h6>
                <DateRangePicker defaultDates={selectedDateRange} onDateChange={onDateRangeChange} />
              </Col>
            )}

            {/*  <Col xs={12} className="mt-3 text-right">
              <Button
                onClick={printDocument}
                disabled={submitting || (selectedType === 'Monthly' && filteredContracts.length === 0)}
              >
                Print
              </Button>
            </Col> */}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

const DeliverySetupReports = ({ appChoices }) => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typeOptions] = useState(['Monthly', 'Annual']);
  const [selectedType, setSelectedType] = useState('Monthly');
  const [excludedContracts, setExcludedContracts] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState([]);
  const [modelText, setModalText] = useState('');

  const [salesLocations] = useState([
    { location: 'All', _id: 'All' },
    ...appChoices.find(ac => ac.key === 'salesLocation').values
  ]);

  const [selectedSalesLocation, setSelectedSalesLocation] = useState(salesLocations[0]);
  const [selectedDateRange, setSelectedDateRange] = useState(getInitialDateRange());
  const [submitting, setSubmitting] = useState(false);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [exteriorSidingOptions] = useState(appChoices.find(ac => ac.key === 'Exterior Siding').values);
  const manufacturerAssigned = contracts.map(t => t.inventoryUnit?.manufacturer);

  const fetchContracts = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        sort: '-setup.start',
        filter: { 'setup.start': { $ne: null } }
      },
      endpoint: ENDPOINTS.CONTRACTS_SEARCH_NEW
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setContracts(
      response.results
        .filter(c => c['salesLocation'])
        .map(c => {
          const { inventoryUnit } = c;
          if (!inventoryUnit) return c;

          inventoryUnit['inventoryId'] = inventoryUnit['_id'];
          delete inventoryUnit['_id'];
          return { ...c, ...inventoryUnit };
        })
    );
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const filterContracts = () => {
    if (selectedType === 'Annual') {
      setFilteredContracts([]);
      return;
    }

    let tempFilter =
      selectedSalesLocation._id === 'All'
        ? contracts
        : contracts.filter(c => c.salesLocation._id === selectedSalesLocation._id);

    if (selectedDateRange) {
      const startDate = new Date(selectedDateRange.startDate).getTime();
      const endDate = new Date(selectedDateRange.endDate).getTime();

      tempFilter = tempFilter.filter(c => {
        const dateSetup = new Date(c.setup.start);
        return dateSetup.getTime() >= startDate && dateSetup.getTime() <= endDate;
      });
    }

    if (selectedManufacturer.length > 0) {
      tempFilter = tempFilter.filter(c => selectedManufacturer.includes(c.inventoryUnit?.manufacturer?._id));
    }

    if (modelText !== '') {
      tempFilter = tempFilter.filter(c => c.model?.toLowerCase().includes(modelText.toLowerCase()));
    }

    setFilteredContracts(
      tempFilter.map(c => {
        const salesPrice = c['salesPrice'] || 0;
        const invoice = c['invoice'] || 0;

        return {
          _id: c['_id'],
          inventoryId: c['inventoryId'],
          Status: c['status'],
          dateOfSale: moment(c.setup.start).format('MMMM Do YYYY'),
          invoiceDate: c.invoiceDate ? moment(c.invoiceDate).format('MMMM Do YYYY') : '',
          applicationNumber: c.applicationNumber || '',
          'Size Overall': c['sizeOverall'],
          Year: c['year'],
          'Floorplan Lender': c['floorplanLender'] ? c['floorplanLender'].name : '',
          Lender: c['lender'] ? c['lender'].lienholderCorporateName : '',
          Manufacturer: c['manufacturer'] ? c['manufacturer'].name : '',
          'Serial # A': c['serialA'],
          Buyer: c['buyer'],
          'Type of Unit': 'MH',
          Invoice: invoice,
          'Property Address': c['propertyAddress'],
          'Property City': c['propertyCity'],
          'Property State': c['propertyState'],
          'Zip Code': c['zipCode'],
          'Property County': c['propertyCounty'],
          'Exterior Siding': c['exteriorSiding'],
          'Sales Price': salesPrice,
          Model: c['model'] ? c['model'] : ''
        };
      })
    );
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    setExcludedContracts([]);
    filterContracts();
  }, [contracts, selectedSalesLocation, selectedType, selectedDateRange, selectedManufacturer, modelText]);

  const onTypeChange = () => {
    const type = document.getElementById('reportTypeSelect').value;
    setSelectedType(type);
  };

  const onLocationChange = () => {
    const locationId = document.getElementById('locationSelect').value;
    setSelectedSalesLocation(salesLocations.find(l => l._id === locationId));
  };

  //TODO
  /*const printDocument = async () => {
    setCreatedDocument(null);
    toast.info('Submitting report, please wait...');
    setSubmitting(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        formData: {
          salesLocation: selectedSalesLocation,
          reportData:
            selectedType === 'Annual'
              ? undefined
              : filteredContracts
                  .filter(c => !excludedContracts.includes(c['_id']))
                  .map(c => ({ ...c, 'Sales Price': c['Adjusted Sales Price'] })),
          reportType: selectedType
        }
      },
      requestType: 'printMHITReport'
    });

    setSubmitting(false);
    if (error) return toast.error(error);

    setCreatedDocument({ fileName: response['fileName'], fileURL: response['fileURL'] });
  }; */

  const updateContract = (contract, field, value) => {
    const existingContract = contracts.find(c => c['_id'] === contract['_id']);
    if (existingContract) {
      existingContract[field] = value;
    }

    setContracts([...contracts]);
  };

  const onExcludeContractChange = (id, checked) => {
    if (checked) {
      const exisiting = excludedContracts.indexOf(id);
      if (exisiting !== -1) excludedContracts.splice(exisiting, 1);
    } else {
      if (!excludedContracts.includes(id)) excludedContracts.push(id);
    }
    setExcludedContracts([...excludedContracts]);
  };

  return (
    <div className="px-0 px-md-3 py-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          <SelectionBox
            selectedDateRange={selectedDateRange}
            selectedSalesLocation={selectedSalesLocation}
            selectedType={selectedType}
            typeOptions={typeOptions}
            salesLocations={salesLocations}
            onTypeChange={onTypeChange}
            onLocationChange={onLocationChange}
            onDateRangeChange={setSelectedDateRange}
            submitting={submitting}
            filteredContracts={filteredContracts}
            manufacturerAssigned={manufacturerAssigned}
            selectedManufacturer={selectedManufacturer}
            setSelectedManufacturer={setSelectedManufacturer}
            setModalText={setModalText}
          />
          <hr />

          {selectedType === 'Monthly' && (
            <>
              <h6>Preview Report:</h6>
              <Table responsive bordered className="text-center midFont">
                <tr style={{ fontSize: 14 }} className="bg-dark text-light">
                  {/* <th>Print?</th> */}
                  <th style={{ minWidth: 100 }}>Setup Start Date</th>
                  <th>Exterior Siding</th>
                  <th>Size Overall</th>
                  <th>Floorplan Lender</th>
                  <th>Model</th>
                  <th>Lender</th>
                  <th>Make</th>
                  <th>Unit of Manufactured Housing Identification/Serial Number</th>
                  <th>Purchaser's Name</th>
                  <th>Sales Price</th>
                  <th>Invoice</th>
                  <th>Invoice Date</th>
                  <th>Property Address</th>
                  <th>City</th>
                  <th>Application Number</th>
                </tr>
                {filteredContracts.length > 0 ? (
                  filteredContracts.map(c => (
                    <tr key={c['_id']}>
                      {/* <td>
                        <FormControl
                          checked={!excludedContracts.includes(c['_id'])}
                          style={{ width: 16, height: 16 }}
                          type="checkbox"
                          onChange={e => onExcludeContractChange(c['_id'], e.target.checked)}
                        />
                      </td> */}
                      <td>{c['dateOfSale']}</td>
                      <td>
                        {c['inventoryId'] ? (
                          <Toggler
                            onFieldUpdate={(field, value) => updateContract(c, field, value)}
                            fieldName="exteriorSiding"
                            value={c['Exterior Siding']}
                            options={exteriorSidingOptions}
                            toggleEndpoint={ENDPOINTS.INVENTORY_WITH_ID(c['inventoryId'])}
                          />
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>{c['Size Overall']}</td>
                      <td>{c['Floorplan Lender']}</td>
                      <td>{c['Model']}</td>
                      <td>{c['Lender']}</td>
                      <td>{c['Manufacturer']}</td>
                      <td>{c['Serial # A']}</td>
                      <td>{c['Buyer']}</td>
                      <td>${c['Sales Price']}</td>
                      <td>${c['Invoice']}</td>
                      <td>{c['invoiceDate']}</td>
                      <td>{c['Property Address']}</td>
                      <td>{c['Property City']}</td>
                      <td>{c['applicationNumber']}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={13}>No contracts to show</td>
                  </tr>
                )}
              </Table>
              <h6 className="text-right text-muted">
                Total Reports: <span className="text-dark">{filteredContracts.length}</span>
              </h6>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DeliverySetupReports;
