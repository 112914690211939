import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, FormControl, Row, Table } from 'react-bootstrap';
import {
  ArrowsAngleContract,
  ArrowsAngleExpand,
  Clipboard,
  ClipboardPlus,
  Download,
  Files,
  Pencil,
  PlusCircle
} from 'react-bootstrap-icons';
import { MultiSelect } from 'react-multi-select-component';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CheckBoxInput, TextInput } from '../../form-generator/components';
import DropDownInput from '../../form-generator/components/DropDownInput';
import { deleteFileFromServer, makeApiRequests } from '../../helpers/api';
import {
  ENDPOINTS,
  contactSearchPaths,
  contractSearchPaths,
  inventoryPaths,
  inventoryToggleOptions
} from '../../helpers/constants';
import { copyInventoryDetails, downloadFileFromBase64, downloadFileFromString, tableToCSV } from '../../helpers/global';
import { search } from '../../helpers/search';
import AlertModal from '../AlertModal';
import Loader from '../Loader';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import TextWithEdit from '../TextWithEdit';
import Toggler from '../Toggler';
import FileViewer from '../common/FileViewer';
import FloatingButton from '../common/FloatingButton';
import MultipleSort, { multiSortArray } from '../common/MultipleSort';
import CreateSideBar from './CreateSidebar';
import EditInventory from './EditInventory';
import QuickToggles from '../common/QuickToggles';

const sortByOptions = [
  { label: 'Unit Location', value: 'unitLocation', isObject: true, key: 'location', type: 'alpha' },
  { label: 'Manufacturer', value: 'manufacturer', isObject: true, key: 'factoryShortName', type: 'alpha' },
  { label: 'Invoice', value: 'invoice', type: 'number', defaultValue: 0 },
  { label: 'Invoice Date', value: 'invoiceDate', type: 'date' },
  { label: 'Date of manufacture', value: 'dateOfManufacture', type: 'date' },
  { label: 'Date Created', value: 'createdAt', type: 'date' },
  { label: 'Last Updated', value: 'updatedAt', type: 'date' }
];

const headerKeys = {
  'Inventory Status': 'inventoryStatus',
  'Unit Location': { key: 'unitLocation', width: 100 },
  Manufacturer: { key: 'manufacturer', width: 100 },
  Model: 'model',
  'Max Sales Price': 'maxSalesPrice',
  Serials: 'serials',
  'Size Overall': 'sizeOverall',
  Bedrooms: { key: 'bedRooms', width: 50 },
  Bathrooms: { key: 'bathRooms', width: 50 },
  'Floorplan Lender': { key: 'floorplanLender', width: 120 },
  'Date of manufacture': 'dateOfManufacture',
  'Invoice Date': 'invoiceDate',
  Invoice: { key: 'invoice', width: 120 }
};

const getHeaders = (includeContractHeaders, includeContactHeaders) => [
  'Inventory Status',
  ...(includeContractHeaders ? ['Contracted Buyer', 'Contract Status'] : []),
  ...(includeContactHeaders ? ['Potential Buyers'] : []),
  'Unit Location',
  'Manufacturer',
  'Model',
  'Max Sales Price',
  'Serials',
  'Size Overall',
  'Bedrooms',
  'Bathrooms',
  'Floorplan Lender',
  'Date of manufacture',
  'Invoice Date',
  'Invoice',
  'Slot'
];

const getTable = (headers, hiddenHeaderIndices, excludedInventories, filteredInventories) => {
  const headersToConsider = headers.filter((h, idx) => !hiddenHeaderIndices.includes(idx));
  return [
    headersToConsider,
    ...filteredInventories
      .filter(c => !excludedInventories.includes(c['_id']))
      .map(c =>
        headersToConsider.map(h => {
          if (h === 'Contracted Buyer') return c['contract'] ? c['contract']['buyer'] : '';
          if (h === 'Contract Status') return c['contract'] ? c['contract']['status'] : '';
          if (h === 'Potential Buyers') return c['contacts'] ? c['contacts'].map(c => c.buyer).join(', ') : '';

          let headerKey = headerKeys[h];
          if (typeof headerKey === 'object') headerKey = headerKey.key;

          if (headerKey === 'serials') return `${c['serialA']}${c['serialB'] ? `/${c['serialB']}` : ''}`;

          if (!headerKey || !c[headerKey]) return '';

          if (headerKey === 'unitLocation') return c[headerKey].location;

          return typeof c[headerKey] === 'object' ? c[headerKey].name : c[headerKey];
        })
      )
  ];
};

const InventoryEditSideBar = ({
  editingInventory,
  appChoices,
  onHide,
  onInventoryDelete,
  onInventoryEdit,
  onInventoryFileLoaded
}) => {
  return (
    <SlidingSideBar fullScreen visible={editingInventory} onClose={onHide} title={'Inventory Details'}>
      {editingInventory && (
        <EditInventory
          id={editingInventory['_id']}
          inventoryFromSearch={editingInventory}
          appChoices={appChoices}
          fromSearch
          onInventoryFileLoaded={onInventoryFileLoaded}
          onInventoryDelete={onInventoryDelete}
          onInventoryEdit={onInventoryEdit}
        />
      )}
    </SlidingSideBar>
  );
};

const searchPaths = [
  ...inventoryPaths,
  ...contractSearchPaths.map(p => `contract/${p}`),
  ...contactSearchPaths.map(p => `contacts[]/${p}`)
];

const BrowseInventory = ({ appChoices }) => {
  const [inventories, setInventories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [statusOptions] = useState(['Stock', 'RSO', 'Contracted'].map(v => ({ option: v, value: v })));
  const [status, setSelectedStatus] = useState(['Stock']);

  const [salesLocationOptions] = useState(
    appChoices.find(ac => ac.key === 'salesLocation').values.map(v => ({ option: v.location, value: v._id }))
  );
  const [salesLocation, setSelectedSalesLocation] = useState(salesLocationOptions.map(o => o.value));

  const [manufacturerOptions] = useState(
    appChoices.find(ac => ac.key === 'manufacturer').values.map(v => ({ option: v.factoryShortName, value: v._id }))
  );
  const [manufacturer, setSelectedManufacterer] = useState(manufacturerOptions.map(o => o.value));

  const [floorplanLenderOptions] = useState(
    appChoices.find(ac => ac.key === 'floorplanLender').values.map(v => ({ option: v.name, value: v._id }))
  );
  const [floorplanLender, setSelectedFloorplanLender] = useState(floorplanLenderOptions.map(o => o.value));

  const [bedroomCountOptions] = useState(
    Array.from({ length: 10 }, (_, i) => i + 1).map(v => ({ option: v, value: v }))
  );
  const [bedRoomCount, setBedRoomCount] = useState(bedroomCountOptions.map(o => o.value));

  const [exteriorSidingOptions] = useState(
    appChoices.find(ac => ac.key === 'Exterior Siding').values.map(v => ({ option: v, value: v }))
  );
  const [exteriorSiding, setExteriorSiding] = useState(exteriorSidingOptions.map(o => o.value));

  const [sortBy, setSortBy] = useState([sortByOptions[0]]);

  const [excludedInventories, setExcludedInventories] = useState([]);
  const [filteredInventories, setFilteredInventories] = useState([]);
  const [editingInventory, setEditingInventory] = useState(null);
  const [headers, setHeaders] = useState(getHeaders(false, true));
  const [hiddenHeaderIndices, setHiddenHeaderIndices] = useState([]);

  const [showUnsoldContractedUnits, setShowUnsoldContractedUnit] = useState(false);
  const [showCurtailmentUnitsOnly, setShowCurtailmentUnitsOnly] = useState(false);
  const [createSidebarMeta, setCreateSidebarMeta] = useState(null);
  const [fileViewerActiveUnit, setFileViewerActiveUnit] = useState(null);
  const [deleteFileModalMeta, setDeleteFileModalMeta] = useState(null);
  const [quickSearchQuery, setQuickSearchQuery] = useState('');
  const [toggling, setToggling] = useState(false);

  const fetchInventories = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.INVENTORY_SEARCH
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setInventories(
      response.map(i => ({
        ...i,
        inventoryStatus: i['contract'] ? 'Contracted' : i['isRSO']?.toLowerCase() === 'yes' ? 'RSO' : 'Stock'
      }))
    );
  };

  useEffect(() => {
    fetchInventories();
  }, []);

  const filterInventories = () => {
    let tempInventories = search(inventories, searchPaths, quickSearchQuery, '_id');
    tempInventories = tempInventories.filter(c => {
      return [
        {
          title: 'inventoryStatus',
          value: status,
          multiple: true
        },
        {
          title: 'unitLocation',
          value: salesLocation.length === salesLocationOptions.length ? 'All' : salesLocation,
          multiple: true
        },
        {
          title: 'manufacturer',
          value: manufacturerOptions.length === manufacturer.length ? 'All' : manufacturer,
          multiple: true
        },
        {
          title: 'floorplanLender',
          value: floorplanLender.length === floorplanLenderOptions.length ? 'All' : floorplanLender,
          multiple: true
        },
        {
          title: 'bedRooms',
          value: bedRoomCount.length === bedroomCountOptions.length ? 'All' : bedRoomCount,
          multiple: true
        },
        {
          title: 'exteriorSiding',
          value: exteriorSiding.length === exteriorSidingOptions.length ? 'All' : exteriorSiding,
          multiple: true
        }
      ].every(a => {
        if (a.title === 'inventoryStatus') {
          if (a.value.length === 1 && ['Stock', 'RSO'].includes(a.value[0]) && showUnsoldContractedUnits) {
            //show units which do not have contracts && which have contracts with no date set up and date delivered
            return !c['contract'] || (c['contract'] && !c['contract']['setup'] && !c['contract']['delivery']);
          } else {
            return a['value'].includes(c['inventoryStatus']);
          }
        }

        if (a['value'] === 'All') return true;

        if (['exteriorSiding', 'bedRooms'].includes(a.title)) {
          console.log({ [a.title]: c[a['title']], values: a['value'] });
          return a['value'].includes(c[a['title']]);
        }

        return a.multiple
          ? c[a['title']] && a['value'].includes(c[a.title]._id)
          : c[a['title']] && a['value'] === c[a['title']]._id;
      });
    });

    if (showCurtailmentUnitsOnly) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const isCurtailment = i => {
        if (!i['invoiceDate']) return false;

        // Calculate the date 360 days ago
        const daysAgo = new Date();
        daysAgo.setHours(0, 0, 0, 0);
        daysAgo.setDate(today.getDate() - 360);

        // Set daysAgo to the last day of that month
        daysAgo.setMonth(daysAgo.getMonth() + 1);
        daysAgo.setDate(0);

        // Get invoice date and set time to start of day
        const invoiceDate = new Date(i['invoiceDate']);
        invoiceDate.setHours(0, 0, 0, 0);

        // If invoice date is after or equal to daysAgo, it is a curtailment
        return invoiceDate <= daysAgo;
      };

      tempInventories = tempInventories.filter(isCurtailment);
    }

    setFilteredInventories(multiSortArray(tempInventories, sortBy));
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    filterInventories();
  }, [
    inventories,
    status,
    manufacturer,
    salesLocation,
    sortBy,
    showUnsoldContractedUnits,
    quickSearchQuery,
    showCurtailmentUnitsOnly,
    floorplanLender,
    bedRoomCount,
    exteriorSiding
  ]);

  useEffect(() => {
    if (fileViewerActiveUnit) {
      setFileViewerActiveUnit(inventories.find(u => u._id === fileViewerActiveUnit._id));
    }
  }, [inventories]);

  useEffect(
    () =>
      setHeaders(
        getHeaders(
          filteredInventories.some(i => i['contract']),
          ['Stock', 'RSO'].some(s => status.includes(s))
        )
      ),
    [filteredInventories]
  );

  const downloadCSV = () => {
    const csv = tableToCSV(getTable(headers, hiddenHeaderIndices, excludedInventories, filteredInventories));
    downloadFileFromString({ data: csv, fileName: `Inventory-${new Date().toDateString()}.csv` });
  };

  const SelectionBox = () => {
    return (
      <>
        <Card className="border rounded">
          <Card.Header className="bg-primary">
            <h5 className="mb-0 text-white">Explore Inventory</h5>
          </Card.Header>
          <Card.Body className="p-2">
            <Row>
              {[
                {
                  title: 'Inventory Status',
                  value: status,
                  options: statusOptions,
                  onChange: setSelectedStatus,
                  multiple: true
                },
                {
                  title: 'Unit Location',
                  value: salesLocation,
                  options: salesLocationOptions,
                  onChange: setSelectedSalesLocation,
                  multiple: true
                },
                {
                  title: 'Manufacturer',
                  value: manufacturer,
                  options: manufacturerOptions,
                  onChange: setSelectedManufacterer,
                  multiple: true
                },
                {
                  title: 'Floorplan Lender',
                  value: floorplanLender,
                  options: floorplanLenderOptions,
                  onChange: setSelectedFloorplanLender,
                  multiple: true
                },
                {
                  title: 'Bedroom Count',
                  value: bedRoomCount,
                  options: bedroomCountOptions,
                  onChange: setBedRoomCount,
                  multiple: true
                },
                {
                  title: 'Exterior Siding',
                  value: exteriorSiding,
                  options: exteriorSidingOptions,
                  onChange: setExteriorSiding,
                  multiple: true
                }
              ].map(dropdownMeta => (
                <Col key={dropdownMeta.title} xs={12} md={dropdownMeta.col || 3} className="px-4 mt-3">
                  <h6 className="mb-2">{dropdownMeta.title}</h6>
                  {dropdownMeta.multiple ? (
                    <MultiSelect
                      showCheckbox
                      closeOnChangedValue={false}
                      className="midFont"
                      valueRenderer={(selected, options) =>
                        selected.length === options.length
                          ? 'All Selected'
                          : selected
                              .map(s => dropdownMeta.options.find(o => o.value === s.value)?.option || 'N/A')
                              .join(', ')
                      }
                      value={dropdownMeta.value.map(o => ({ label: o, value: o }))}
                      options={dropdownMeta.options.map(o => ({ label: o.option, value: o.value }))}
                      onChange={list => dropdownMeta.onChange(list.map(o => o.value))}
                    />
                  ) : (
                    <DropDownInput
                      value={dropdownMeta.value}
                      options={dropdownMeta.options.map(o => o.option)}
                      optionValues={dropdownMeta.options.map(o => o.value)}
                      onChangeFunction={e => {
                        if (!e) return;
                        dropdownMeta.onChange(e.target.value);
                      }}
                      required
                    />
                  )}
                </Col>
              ))}

              <Col xs={12} md={6} className="px-4 mt-3">
                <h6 className="mb-2">Sort By:</h6>
                <MultipleSort
                  disabled={loading}
                  sortOptions={sortByOptions}
                  selectedSortOptions={sortBy}
                  onSelectedSortOptionsChange={setSortBy}
                />
              </Col>
              {!status.includes('Contracted') && (
                <Col xs={12} md={4} className="mt-4 px-4">
                  <CheckBoxInput
                    size={'sm'}
                    onChangeFunction={e => e && setShowUnsoldContractedUnit(e.target.checked)}
                    checked={showUnsoldContractedUnits}
                    label="Show Undelivered Contracted Units"
                    showLabel
                  />
                </Col>
              )}

              <Col xs={12} md={!status.includes('Contracted') ? 4 : 8} className="mt-4 px-4">
                <CheckBoxInput
                  size={'sm'}
                  onChangeFunction={e => e && setShowCurtailmentUnitsOnly(e.target.checked)}
                  checked={showCurtailmentUnitsOnly}
                  label="Show Curtailments Only"
                  showLabel
                />
              </Col>

              <Col xs={12} md={4} className="mt-4 text-right px-4">
                <Button size="sm" className="mr-2" onClick={downloadCSV} disabled={filteredInventories.length === 0}>
                  <Download />
                  <span className="align-middle ml-2">Download CSV</span>
                </Button>
                <Button size="sm" onClick={printDocument} disabled={submitting || filteredInventories.length === 0}>
                  Print
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onInvoiceRequiredInvalidation = inventory => {
    toast.error('Invoice Amount is required!');
  };

  const onInvoiceAmountSubmit = async (inventory, amount) => {
    const parsedAmount = Number(amount);
    toast.info('Please wait updating amount...');

    const { response, error } = await makeApiRequests({
      requestBody: {
        invoice: parsedAmount
      },
      method: 'PUT',
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(inventory._id)
    });

    if (error) {
      toast(error, {
        type: 'error'
      });
      return;
    }

    const index = inventories.findIndex(c => c['_id'] === inventory['_id']);
    if (index !== -1) {
      inventories[index]['invoice'] = parsedAmount;
    }

    setInventories([...inventories]);
    toast.success(`Invoice updated successfully`);
  };

  const onExcludeInventoryChange = (id, checked) => {
    if (checked) {
      const exisiting = excludedInventories.indexOf(id);
      if (exisiting !== -1) excludedInventories.splice(exisiting, 1);
    } else {
      if (!excludedInventories.includes(id)) excludedInventories.push(id);
    }
    setExcludedInventories([...excludedInventories]);
  };

  const printDocument = async () => {
    toast.info('Submitting report, please wait...');
    setSubmitting(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.DOCHUB_PRINT_TABLE,
      requestBody: {
        table: getTable(headers, hiddenHeaderIndices, excludedInventories, filteredInventories),
        fileName: `inventory-${new Date().toDateString()}.pdf`
      }
    });
    setSubmitting(false);
    if (error) {
      return toast.error(error);
    }

    toast.success('Document created successfully, file is being downloaded...');
    const { fileName, data, mimeType } = response;
    downloadFileFromBase64({ fileName, data, mimeType });
  };

  const updateInventoryList = (newInventory, forFile) => {
    const inventoryToUpdate = {
      ...newInventory,
      inventoryStatus: newInventory['contract']
        ? 'Contracted'
        : newInventory['isRSO']?.toLowerCase() === 'yes'
        ? 'RSO'
        : 'Stock'
    };

    const inventoryIndex = inventories.findIndex(c => c['_id'] === newInventory['_id']);
    if (inventoryIndex !== -1) {
      inventories[inventoryIndex] = inventoryToUpdate;
      setInventories([...inventories]);
    } else {
      setInventories([inventoryToUpdate, ...inventories]);
    }

    setCreateSidebarMeta(null);
    if (!forFile) setEditingInventory(null);
  };

  const onInventoryDelete = deletedInventoryId => {
    const existingIndex = inventories.findIndex(i => i['_id'] === deletedInventoryId);
    if (existingIndex !== -1) {
      inventories.splice(existingIndex, 1);
    }

    setInventories([...inventories]);

    setEditingInventory(null);
  };

  const updateContract = (contract, field, value) => {
    contract[field] = value;
    setInventories([...inventories]);
  };

  const onInventoryFileLoaded = (inventoryId, fileNames) => {
    const index = inventories.findIndex(c => c['_id'] === inventoryId);
    if (index !== -1) {
      inventories[index]['fileNames'] = fileNames;
      setInventories([...inventories]);
    }
  };

  const onFileDeleteClick = file => {
    setDeleteFileModalMeta({ file });
  };

  const deleteFile = async () => {
    const { file: toBeDeletedFile } = deleteFileModalMeta;
    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: true });

    const newFileArray = fileViewerActiveUnit.files.filter(f => f._id !== toBeDeletedFile._id).map(f => f._id);

    const { response, error } = await makeApiRequests({
      requestBody: { files: newFileArray },
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(fileViewerActiveUnit._id),
      method: 'PUT'
    });

    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: false });
    if (error) {
      return toast.error(error);
    }

    setDeleteFileModalMeta(null);
    updateInventoryList(response);
    deleteFileFromServer(toBeDeletedFile);
  };

  const toggleInventory = async (inventoryId, label, key, value) => {
    setToggling(inventoryId);
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(inventoryId),
      requestBody: { [key]: value },
      method: 'PUT'
    });

    setToggling(null);
    if (error) {
      toast.error(error);
      return;
    }

    toast.success(`${label} updated successfully!`);
    updateInventoryList(response);

    setToggling(null);
  };

  return (
    <Container fluid className={'h-100 pt-2 pb-3 px-md-2'}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {SelectionBox()}
          <hr />
          <div className="d-flex">
            <div className="flex-grow-1">
              <h6>Inventory:</h6>
            </div>
            <div>
              <h6 className="text-right text-muted smallFont">
                Total Reports: <span className="text-dark mr-4">{filteredInventories.length}</span>
                Current View Total:{' '}
                <span className="text-dark mr-4">
                  $
                  {filteredInventories.length > 0
                    ? filteredInventories
                        .map(c => (c['invoice'] ? Number(c['invoice']) : 0))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2)
                    : 0}
                </span>
                Stock Total:{' '}
                <span className="text-dark mr-2">
                  $
                  {inventories.length > 0
                    ? inventories
                        .filter(i => !i['contract'])
                        .map(c => (c['invoice'] ? Number(c['invoice']) : 0))
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2)
                    : 0}
                </span>
              </h6>
            </div>
          </div>
          {/* Quick Search */}
          <div className="input-group my-3 px-1">
            <TextInput
              id="searchText"
              hint="Quick Search..."
              value={quickSearchQuery}
              onChange={e => {
                setQuickSearchQuery(e.target.value);
              }}
              size="sm"
            />
          </div>
          <Table style={{ fontSize: 12 }} responsive bordered className="text-center">
            <thead>
              <tr className="bg-dark text-light">
                <th>Print?</th>
                <th style={{ minWidth: 140 }}>Action</th>

                {headers.map((h, idx) => (
                  <th
                    style={
                      !hiddenHeaderIndices.includes(idx)
                        ? { minWidth: h.includes('Buyer') ? 120 : headerKeys[h]?.width }
                        : undefined
                    }
                    key={h}
                  >
                    <div
                      className="hover-dark"
                      onClick={() => {
                        if (hiddenHeaderIndices.includes(idx)) {
                          setHiddenHeaderIndices([...hiddenHeaderIndices.filter(i => i !== idx)]);
                        } else {
                          hiddenHeaderIndices.push(idx);
                          setHiddenHeaderIndices([...hiddenHeaderIndices]);
                        }
                      }}
                    >
                      {!hiddenHeaderIndices.includes(idx) ? (
                        <ArrowsAngleContract size={10} />
                      ) : (
                        <ArrowsAngleExpand size={10} className="mx-1" />
                      )}
                    </div>
                    {!hiddenHeaderIndices.includes(idx) ? h : `${h.slice(0, 3)}...`}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredInventories.length > 0 ? (
                <>
                  {filteredInventories.map(unit => (
                    <tr key={unit['_id']}>
                      <td>
                        <FormControl
                          checked={!excludedInventories.includes(unit['_id'])}
                          style={{ width: 16, height: 16 }}
                          type="checkbox"
                          onChange={e => onExcludeInventoryChange(unit['_id'], e.target.checked)}
                        />
                      </td>
                      <td>
                        <Pencil className="hover-light mr-2" onClick={() => setEditingInventory(unit)} />
                        <Clipboard
                          className="hover-light mr-2"
                          onClick={() => {
                            copyInventoryDetails(unit);
                            toast.success('Copied!');
                          }}
                        />
                        <Files className="hover-light mr-2" onClick={() => setFileViewerActiveUnit(unit)} />
                        <ClipboardPlus
                          className="hover-light mr-2"
                          onClick={() =>
                            setCreateSidebarMeta({
                              prefillUnit: {
                                ...unit,
                                serialA: '',
                                serialB: '',
                                serialC: '',
                                hudA: '',
                                hudB: '',
                                hudC: ''
                              },
                              isDuplicating: true
                            })
                          }
                        />
                      </td>
                      {[
                        { value: unit['inventoryStatus'] },
                        ...(headers.includes('Contracted Buyer')
                          ? [
                              {
                                value: unit['contract'] ? (
                                  <Link target="_blank" to={`/contract/${unit['contract']['_id']}`}>
                                    {unit['contract']['buyer']}
                                  </Link>
                                ) : (
                                  ''
                                )
                              },
                              {
                                value: unit['contract'] ? (
                                  <Toggler
                                    id={unit['contract']['_id']}
                                    fieldName="status"
                                    fieldLabel="Status"
                                    value={unit['contract']['status']}
                                    options={appChoices.find(ac => ac.key === 'Status').values}
                                    onFieldUpdate={(field, value) => updateContract(unit['contract'], field, value)}
                                  />
                                ) : (
                                  ''
                                )
                              }
                            ]
                          : []),
                        ...(headers.includes('Potential Buyers')
                          ? [
                              {
                                value: unit['contacts']
                                  ? unit['contacts'].map((contact, index) => (
                                      <React.Fragment key={contact['_id']}>
                                        <Link target="_blank" to={`/crm/${contact['_id']}`}>
                                          {contact['buyer']}
                                        </Link>
                                        {index + 1 !== unit['contacts'].length && ', '}
                                      </React.Fragment>
                                    ))
                                  : ''
                              }
                            ]
                          : []),
                        { value: unit['unitLocation'] ? unit['unitLocation'].location : '-' },
                        { value: unit['manufacturer'] ? unit['manufacturer'].factoryShortName : '-' },
                        { value: unit['model'] },
                        { value: `$${unit['maxSalesPrice'] || '-'}` },
                        { value: unit['serialA'] + (unit['serialB'] ? `/${unit['serialB']}` : '') },
                        { value: unit['sizeOverall'] },
                        { value: unit['bedRooms'] },
                        { value: unit['bathRooms'] },
                        { value: unit['floorplanLender'] ? unit['floorplanLender'].name : '-' },
                        {
                          value: unit['dateOfManufacture']
                            ? moment(unit['dateOfManufacture']).format('MMMM Do YYYY')
                            : 'N/A'
                        },
                        { value: unit['invoiceDate'] ? moment(unit['invoiceDate']).format('MMMM Do YYYY') : 'N/A' },
                        {
                          value: (
                            <TextWithEdit
                              preFix="$"
                              text={unit['invoice'] || 0}
                              onSubmit={value => onInvoiceAmountSubmit(unit, value)}
                              required
                              onRequiredInvalidation={() => onInvoiceRequiredInvalidation(unit)}
                            />
                          )
                        }
                      ].map((cell, idx) => (
                        <td key={idx}>{hiddenHeaderIndices.includes(idx) ? '' : cell.value}</td>
                      ))}
                      <td>
                        <QuickToggles
                          data={unit}
                          toggleOptions={inventoryToggleOptions({
                            inventory: unit,
                            appChoices,
                            includeManufacturer: false
                          })}
                          onToggle={(label, key, value) => toggleInventory(unit._id, label, key, value)}
                          toggling={toggling === unit?._id}
                          showLabel={false}
                        />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={headers.length + 1}>No Inventories to show</td>
                </tr>
              )}
            </tbody>
          </Table>
          {filteredInventories.length > 0 && (
            <>
              <FileViewer
                show={fileViewerActiveUnit !== null}
                onHide={() => setFileViewerActiveUnit(null)}
                files={fileViewerActiveUnit ? fileViewerActiveUnit['files'] : []}
                driveFolder={fileViewerActiveUnit ? fileViewerActiveUnit['driveFolder'] : []}
                onFileDeleteClick={onFileDeleteClick}
              />
              <AlertModal
                show={deleteFileModalMeta !== null}
                alertText={`Are you sure to delete this file (${
                  deleteFileModalMeta
                    ? deleteFileModalMeta.file.metadata && deleteFileModalMeta.file.metadata.originalName
                      ? deleteFileModalMeta.file.metadata.originalName
                      : deleteFileModalMeta.file.filename
                    : ''
                }). You will lose access to this file permanently?`}
                showProgress={deleteFileModalMeta && deleteFileModalMeta.deletingFile}
                progressText="Deleting file..."
                continueButtonText="Delete"
                onDismissClick={() => setDeleteFileModalMeta(null)}
                onHide={() => setDeleteFileModalMeta(null)}
                onContinueClick={deleteFile}
              />
              <InventoryEditSideBar
                appChoices={appChoices}
                onHide={() => setEditingInventory(null)}
                editingInventory={editingInventory}
                onInventoryEdit={updateInventoryList}
                onInventoryDelete={onInventoryDelete}
                onInventoryFileLoaded={onInventoryFileLoaded}
              />
            </>
          )}
          <CreateSideBar
            onInventoryAdd={updateInventoryList}
            appChoices={appChoices}
            show={createSidebarMeta}
            onHide={() => setCreateSidebarMeta(null)}
            prefillUnit={createSidebarMeta?.prefillUnit}
            isDuplicating={createSidebarMeta?.prefillUnit}
          />
          <FloatingButton
            variant="success"
            text={'Add New Unit'}
            Icon={PlusCircle}
            onClick={() => setCreateSidebarMeta({})}
          />
        </>
      )}
    </Container>
  );
};

export default BrowseInventory;
