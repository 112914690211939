import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, FormControl, Row, Table } from 'react-bootstrap';
import { PlusCircleFill, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { getDateObjectFromInputString, getFormattedDate } from '../../../form-generator/helpers/utility';
import File from '../../common/file';
import ContractsPicker from '../../ContractsPicker';
import TextButton from '../../TextButton';
import CategorizedDropdown from '../../common/CategorizedDropdown';

const contractorTypes = [
  { key: 'manufacturer', label: 'Manufacturer' },
  { key: 'lender', label: 'Lender' },
  { key: 'installer', label: 'Installer' },
  { key: 'floorplanLender', label: 'Floorplan Lender', modelKey: 'FloorplanLender' },
  { key: 'generalContractor', label: 'General Contractor', modelKey: 'GeneralContractor' },
  { key: 'tech', label: 'Tech' }
];

const tableHeaders = ['Category', 'Amount'];

const newItem = (categoryOptions = []) => {
  return {
    category: categoryOptions[0],
    id: uniqueId()
  };
};

const TopRow = ({
  payrollBill,
  onPayrollBillChange,
  contractorOptions = [],
  onContractButtonClick,
  onNewContractorAddClick,
  disableContractButton
}) => {
  const getSelectedContractorName = () => {
    const contractor = contractorOptions.find(c => c._id === payrollBill.contractor);
    if (!contractor) return 'N/A';

    return contractor.lienholderCorporateName || contractor.name || contractor.label;
  };

  return (
    <>
      <Row>
        {[
          {
            title: 'Invoice Number',
            inputType: 'text',
            onChange: e => onPayrollBillChange({ ...payrollBill, invoiceNum: e.target.value }),
            value: payrollBill.invoiceNum,
            hint: '#....'
          },
          {
            title: 'Date',
            inputType: 'date',
            onChange: e => onPayrollBillChange({ ...payrollBill, date: getDateObjectFromInputString(e.target.value) }),
            value: getFormattedDate(new Date(payrollBill.date))
          },
          {
            title: 'Due Date',
            inputType: 'date',
            onChange: e =>
              onPayrollBillChange({ ...payrollBill, dueDate: getDateObjectFromInputString(e.target.value) }),
            value: getFormattedDate(new Date(payrollBill.dueDate))
          }
        ].map(({ title, inputType, onChange, value, options = [], hint = '' }) => (
          <Col xs={4} key={title} className="mt-1">
            <h6 className="midFont mb-1">{title}</h6>
            <FormControl placeholder={hint} type={inputType} value={value} onChange={onChange} size="sm" required />
          </Col>
        ))}
      </Row>

      <hr className="my-3" />
      <Row>
        <Col xs={6}>
          <h6 className="midFont mb-1">Contract</h6>
          <Button
            disabled={disableContractButton}
            size="sm"
            variant="outline-info"
            block
            onClick={onContractButtonClick}
          >
            {payrollBill.contract ? payrollBill.contract.buyer : 'Tap to select'}
          </Button>
        </Col>
        <Col xs={6}>
          <div className="d-flex mb-1 align-items-center">
            <div className="flex-grow-1">
              <h6 className="midFont mb-0">Contractor</h6>
            </div>
            <div>
              <TextButton variant="success" text={'Add New'} Icon={PlusCircleFill} onClick={onNewContractorAddClick} />
            </div>
          </div>

          <CategorizedDropdown
            activeOptionToView={getSelectedContractorName()}
            options={contractorOptions}
            toBeActiveKey={'_id'}
            activeOption={payrollBill?.contractor}
            parentKey={'isLabel'}
            onOptionChange={option =>
              onPayrollBillChange({
                ...payrollBill,
                contractor: option._id,
                contractorType: option.modelKey || option.label
              })
            }
            childKeys={['lienholderCorporateName', 'name', 'label']}
          />
        </Col>
      </Row>
    </>
  );
};

const ItemsTable = ({
  role,
  categoryOptions = [],
  items = [],
  onItemChange,
  onNewItemClick,
  onDeleteItemClick,
  disabled,
  onNewContractorAddClick
}) => {
  const [totalValue, setTotalValue] = useState('');

  useEffect(() => {
    setTotalValue(items.reduce((prevValue, item) => prevValue + (item.invoiceAmount || 0), 0).toFixed(2));
  }, [items]);

  return (
    <div className="m-2 my-4 mid">
      <div className="mb-3">
        <h6 className="text-dark midFont mb-0">Select Item</h6>
        <h6 className="text-muted tinyFont">Please provide value of all the items.</h6>
        <Table className="mb-1" bordered responsive>
          <thead>
            <tr className="bg-dark text-white">
              {tableHeaders.map(h => (
                <th className="p-2 text-center" key={h}>
                  <span className="align-middle">{h}</span>
                  {['super admin', 'admin'].includes(role) && h === 'Contractor' && (
                    <Button
                      variant="outline-light"
                      size="sm"
                      className="px-2 py-0 ml-2"
                      onClick={onNewContractorAddClick}
                    >
                      <PlusCircleFill size={10} />
                      <span className="smallFont ml-1 align-middle">Update</span>
                    </Button>
                  )}
                </th>
              ))}
              {!disabled && <th></th>}
            </tr>
          </thead>
          <tbody>
            {items.length > 0 ? (
              items.map((item, index) => (
                <ItemRow
                  key={item['_id'] || item['id']}
                  item={item}
                  categoryOptions={categoryOptions}
                  disabled={disabled}
                  onItemChange={(field, value) => onItemChange(index, field, value)}
                  onItemRemove={() => onDeleteItemClick(index)}
                />
              ))
            ) : (
              <tr>
                <td colSpan={tableHeaders.length + 1} className="p-2 text-center text-muted">
                  <b>No items Added</b>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {!disabled && (
          <div className="text-right">
            <Button variant="outline-success" size="sm" className="px-2 py-0 mt-1" onClick={onNewItemClick}>
              <PlusCircleFill size={12} />
              <span className="smallFont ml-1">New item</span>
            </Button>
          </div>
        )}
      </div>
      <hr />
      <div className="text-right large mb-2">
        Total Value:
        <b className="ml-2">${totalValue}</b>
      </div>
    </div>
  );
};

const ItemRow = ({ item, categoryOptions, onItemRemove, onItemChange, disabled }) => {
  return (
    <tr>
      <td className="p-0">
        <FormControl
          as={'select'}
          size={'sm'}
          value={item.category}
          onChange={e => onItemChange('category', e.target.value)}
          className="rounded-0 border-0"
          disabled={disabled}
        >
          {[item.category, ...categoryOptions].map(o => (
            <option key={o} value={o} disabled={['Improvements', 'Other'].includes(o)}>
              {o}
            </option>
          ))}
        </FormControl>
      </td>

      <td className="p-0">
        <FormControl
          placeholder={'Contractor Invoice Amount'}
          className="rounded-0 border-0"
          size={'sm'}
          type="number"
          disabled={disabled}
          value={item.invoiceAmount}
          onChange={e => onItemChange('invoiceAmount', e.target.value ? Number(e.target.value) : undefined)}
        />
      </td>

      {!disabled && (
        <td className="p-0">
          <div className="d-flex my-2 justify-content-center">
            <Trash size={15} className="text-danger hover-light" onClick={onItemRemove} />
          </div>
        </td>
      )}
    </tr>
  );
};

const PayrollBill = ({
  appChoices,
  activeContract,
  payrollBill,
  onPayrollBillChange,
  buttonsDisabled,
  onPayrollSubmit,
  onNewContractorAddClick
}) => {
  const [role] = useState(localStorage.getItem('user-role'));
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [contractorOptions, setContractorOptions] = useState([]);
  const [contractPickerMeta, setContractPickerMeta] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    //initial setup
    if (categoryOptions.length > 0 && contractorOptions.length > 0) {
      if (!payrollBill) {
        onPayrollBillChange({
          date: new Date().toISOString(),
          dueDate: new Date().toISOString(),
          invoiceNum: '',
          contractor: contractorOptions[1]._id,
          contractorType: contractorOptions[1].label,
          items: [newItem(categoryOptions)],
          contract: activeContract
        });
      } else {
        //if contractor is prefilled, then use only it's id as value
        if (typeof payrollBill.contractor === 'object') {
          onPayrollBillChange({
            ...payrollBill,
            contractor: payrollBill.contractor._id
          });
        }
      }
    }
  }, [categoryOptions, contractorOptions, payrollBill]);

  useEffect(() => {
    const allContractors = contractorTypes.flatMap(({ key, label, modelKey }) => {
      const values = appChoices.find(ac => ac.key === key)?.values;
      if (!values || values.length === 0) return [];

      return [{ isLabel: true, label: label }, ...values.map(v => ({ key, label, modelKey, ...v }))];
    });

    setContractorOptions(allContractors);
  }, [appChoices]);

  useEffect(() => {
    const existingOptions = payrollBill ? payrollBill.items.map(i => i.category) : [];

    const payrollOptions = appChoices
      .find(ac => ac.key === 'Payroll Categories')
      .values.filter(o => !existingOptions.includes(o));

    setCategoryOptions([...new Set(payrollOptions)]); //remove duplicates
  }, [appChoices, payrollBill]);

  const onItemChange = (index, field, value) => {
    const oldItems = payrollBill.items;
    oldItems[index][field] = value;

    onPayrollBillChange({ ...payrollBill, items: [...oldItems] });
  };

  const onNewItemClick = () => {
    if (categoryOptions.length === 0) return toast.error('No categories to add!');

    const oldItems = payrollBill.items;
    onPayrollBillChange({
      ...payrollBill,
      items: [...oldItems, newItem(categoryOptions)]
    });
  };

  const onDeleteItemClick = index => {
    const oldItems = payrollBill.items;
    oldItems.splice(index, 1);

    onPayrollBillChange({ ...payrollBill });
  };

  const onPicked = contract => {
    onPayrollBillChange({ ...payrollBill, contract: contract[0] });
    onPickerClose();
  };

  const onPickerClose = () => {
    setContractPickerMeta(null);
  };

  const onPayrollSubmitClick = () => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    if (!payrollBill.date || !payrollBill.dueDate) {
      return toast.error('Please provide both the dates');
    }

    if (!payrollBill.contract) {
      return toast.error('Please select a contract for this bill!');
    }

    if (payrollBill.items.length == 0) {
      return toast.error('Please add atleast one item to the bill');
    }

    if (payrollBill.items.some(i => !i.invoiceAmount || i.invoiceAmount <= 0)) {
      return toast.error('Please provide valid amount to every item in the bill');
    }

    onPayrollSubmit(
      { ...payrollBill, contract: payrollBill.contract._id },
      uploadedFiles.map(f => f.id)
    );
  };

  return (
    <Card>
      <Card.Body>
        {payrollBill && (
          <>
            <ContractsPicker
              submitButtonText="Select"
              show={contractPickerMeta !== null}
              selectedContracts={
                contractPickerMeta && contractPickerMeta.selectedData ? [contractPickerMeta.selectedData] : []
              }
              onContractSelect={contract => setContractPickerMeta({ ...contractPickerMeta, selectedData: contract })}
              onContractPickerClose={onPickerClose}
              onSubmit={onPicked}
            />
            <TopRow
              disableContractButton={activeContract}
              onContractButtonClick={() => setContractPickerMeta({})}
              payrollBill={payrollBill}
              onPayrollBillChange={onPayrollBillChange}
              contractorOptions={contractorOptions}
              onNewContractorAddClick={onNewContractorAddClick}
            />

            <ItemsTable
              role={role}
              categoryOptions={categoryOptions}
              contractorOptions={contractorOptions}
              items={payrollBill.items}
              onItemChange={onItemChange}
              onNewItemClick={onNewItemClick}
              onDeleteItemClick={onDeleteItemClick}
            />
            <File
              uploadedFiles={uploadedFiles}
              onUploadedFilesChange={setUploadedFiles}
              containerClassName="mt-3 mx-2"
            />

            <div className="text-right mt-4">
              <Button size="sm" disabled={buttonsDisabled} variant="primary" onClick={onPayrollSubmitClick}>
                Save bill
              </Button>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default PayrollBill;
