import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import DropDownInput from '../../form-generator/components/DropDownInput';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import Loader from '../Loader';
import TextWithEdit from '../TextWithEdit';
import Toggler from '../Toggler';

const CashDeal = ({ appChoices }) => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [statusOptions] = useState(appChoices.find(ac => ac.key === 'Status').values);
  const [status, setSelectedStatus] = useState([...statusOptions]);

  const [fundingStatusOptions] = useState(['Unassigned', ...appChoices.find(ac => ac.key === 'Funding Status').values]);
  const [fundingStatus, setSelectedFundingStatus] = useState(
    fundingStatusOptions.map(o => (o === 'Unassigned' ? null : o))
  );

  const [salesLocationOptions] = useState([
    { location: 'All', _id: 'All' },
    ...appChoices.find(ac => ac.key === 'salesLocation').values
  ]);

  const [salesLocation, setSelectedSalesLocation] = useState(salesLocationOptions[0]);

  const [manufacturerOptions] = useState([
    { name: 'All', _id: 'All' },
    ...appChoices.find(ac => ac.key === 'manufacturer').values
  ]);
  const [manufacturer, setSelectedManufacterer] = useState(manufacturerOptions[0]);

  const [lenderOptions] = useState([
    { lienholderCorporateName: 'All', _id: 'All' },
    ...appChoices.find(ac => ac.key === 'lender').values
  ]);
  const [lender, setSelectedLender] = useState(lenderOptions[0]);

  const [cashPaidOptions] = useState(['Yes', 'No']);
  const [cashPaidFilterOptions] = useState(['All', ...cashPaidOptions]);
  const [cashPaid, setCashPaid] = useState('No');

  const [filteredContracts, setFilteredContracts] = useState([]);

  const fetchContracts = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH
    });

    setLoading(false);

    if (error) {
      return toast.error(error);
    }

    setContracts(
      response
        .filter(c => c['salesLocation'])
        .map(c => {
          const { inventoryUnit } = c;
          if (!inventoryUnit) return c;

          inventoryUnit['inventoryId'] = inventoryUnit['_id'];
          delete inventoryUnit['_id'];
          return { ...c, ...inventoryUnit };
        })
    );
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const filterContracts = () => {
    const tempContracts = contracts.filter(c => {
      return [
        { title: 'cashPaid', value: cashPaid },
        { title: 'status', value: status },

        {
          title: 'fundingStatus',
          value: fundingStatus
        },
        {
          title: 'salesLocation',
          value: salesLocation,
          isObject: true
        },
        {
          title: 'manufacturer',
          value: manufacturer,
          isObject: true
        },
        { title: 'lender', value: lender, isObject: true }
      ].every(({ title, value, isObject }) => {
        if (title === 'cashPaid' && value === 'No') {
          return !c['cashPaid'] || c['cashPaid'] === 'No';
        }

        if (!Array.isArray(value)) value = [value];

        if (isObject) {
          value = value.map(v => v._id);
        }

        return value.includes('All') || value.includes(isObject ? c[title]?._id : c[title]);
      });
    });

    const groupedContracts = [];
    for (const location of salesLocationOptions) {
      groupedContracts.push(
        ...tempContracts
          .filter(c => c['salesLocation']._id === location._id)
          .sort((a, b) => {
            return a['dateSigned'] && b['dateSigned'] ? Date.parse(b['dateSigned']) - Date.parse(a['dateSigned']) : -1;
          })
      );
    }

    setFilteredContracts(groupedContracts);
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    filterContracts();
  }, [contracts, status, lender, manufacturer, fundingStatus, salesLocation, cashPaid]);

  const SelectionBox = () => {
    return (
      <>
        <Card className="border rounded">
          <Card.Header className="bg-primary">
            <h5 className="mb-0 text-white">Explore Cash Deal</h5>
          </Card.Header>
          <Card.Body className="py-3">
            <Row>
              {[
                { title: 'Status', value: status, options: statusOptions, onChange: setSelectedStatus, multiple: true },
                {
                  title: 'Funding Status',
                  value: fundingStatus,
                  options: fundingStatusOptions,
                  onChange: setSelectedFundingStatus,
                  multiple: true
                },
                {
                  title: 'Sales Location',
                  value: salesLocation,
                  options: salesLocationOptions,
                  onChange: setSelectedSalesLocation,
                  isObject: true,
                  objectLabelKey: 'location'
                },
                {
                  title: 'Manufacturer',
                  value: manufacturer,
                  options: manufacturerOptions,
                  onChange: setSelectedManufacterer,
                  isObject: true,
                  objectLabelKey: 'name'
                },
                {
                  title: 'Lender',
                  value: lender,
                  options: lenderOptions,
                  onChange: setSelectedLender,

                  isObject: true,
                  objectLabelKey: 'lienholderCorporateName'
                },
                { title: 'Cash Paid', value: cashPaid, options: cashPaidFilterOptions, onChange: setCashPaid }
              ].map(dropdownMeta => (
                <Col key={dropdownMeta.title} xs={12} md={4} className="px-4 mt-3">
                  <h6 className="mb-2">{dropdownMeta.title}</h6>
                  {dropdownMeta.multiple ? (
                    <MultiSelect
                      showCheckbox
                      closeOnChangedValue={false}
                      className="midFont"
                      value={dropdownMeta.value.map(v =>
                        dropdownMeta.isObject
                          ? { label: v[dropdownMeta.objectLabelKey], value: v._id }
                          : { label: v, value: v }
                      )}
                      options={dropdownMeta.options.map(o =>
                        dropdownMeta.isObject
                          ? { label: o[dropdownMeta.objectLabelKey], value: o._id }
                          : { label: o, value: o === 'Unassigned' ? null : o }
                      )}
                      onChange={list =>
                        dropdownMeta.onChange(
                          list.map(listOption =>
                            dropdownMeta.isObject
                              ? dropdownMeta.options.find(o => o._id === listOption.value)
                              : listOption.value
                          )
                        )
                      }
                    />
                  ) : (
                    <DropDownInput
                      value={dropdownMeta.isObject ? dropdownMeta.value._id : dropdownMeta.value}
                      options={
                        dropdownMeta.isObject
                          ? dropdownMeta.options.map(o => o[dropdownMeta.objectLabelKey])
                          : dropdownMeta.options
                      }
                      optionValues={
                        dropdownMeta.isObject
                          ? dropdownMeta.options.map(o => o._id)
                          : dropdownMeta.options.map(o => (o === 'Unassigned' ? null : o))
                      }
                      onChangeFunction={e => {
                        if (!e) return;
                        dropdownMeta.onChange(
                          dropdownMeta.isObject
                            ? dropdownMeta.options.find(o => o._id === e.target.value)
                            : e.target.value
                        );
                      }}
                      required
                    />
                  )}
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onInvoiceRequiredInvalidation = contract => {
    toast.error('Invoice Amount is required!');
  };

  const onInvoiceAmountSubmit = async (contract, amount) => {
    const parsedAmount = Number(amount);
    toast.info('Please wait updating amount...');

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(contract.inventoryId),
      requestBody: {
        invoice: parsedAmount
      },
      method: 'PUT'
    });

    if (error) {
      toast.error(error);
      return;
    }

    const index = contracts.findIndex(c => c['_id'] === contract['_id']);
    if (index !== -1) {
      contracts[index]['invoice'] = parsedAmount;
    }

    setContracts([...contracts]);
    toast.success(`Invoice for buyer ${contract['buyer']} updated successfully`);
  };

  const updateContract = (contract, field, value) => {
    const existingContract = contracts.find(c => c['_id'] === contract['_id']);
    if (existingContract) {
      existingContract[field] = value;
    }

    setContracts([...contracts]);
  };

  return (
    <div className="px-0 px-md-4 py-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          {SelectionBox()}
          <hr />

          <h6>Deals:</h6>
          <Table style={{ fontSize: 12 }} responsive bordered className="text-center">
            <tr className="bg-dark text-light">
              <th>Buyer</th>
              <th>Sales Location</th>
              <th>Manufacturer</th>
              <th>Serial # A</th>
              <th>Status</th>
              <th>Funding Status</th>
              <th>Floorplan Lender</th>
              <th>Lender</th>
              <th>Cash Paid</th>
              <th>Invoice</th>
            </tr>
            {filteredContracts.length > 0 ? (
              <>
                {filteredContracts.map(c => (
                  <tr key={c['_id']}>
                    <td>{c['buyer']}</td>
                    <td>{c['salesLocation'].location}</td>
                    <td>{c['manufacturer']?.name || '-'}</td>
                    <td>{c['serialA']}</td>
                    <td>{c['status']}</td>
                    <td>{c['fundingStatus']}</td>
                    <td style={{ minWidth: 150 }}>{c['floorplanLender']?.name || '-'}</td>
                    <td>{c['lender']?.lienholderCorporateName || '-'}</td>
                    <td className="px-1">
                      <Toggler
                        id={c['_id']}
                        fieldName="cashPaid"
                        value={c['cashPaid']}
                        options={cashPaidOptions}
                        onFieldUpdate={(field, value) => updateContract(c, field, value)}
                      />
                    </td>
                    <td style={{ minWidth: 120 }}>
                      {c['inventoryId'] ? (
                        <TextWithEdit
                          preFix="$"
                          text={c['invoice'] || 0}
                          onSubmit={value => onInvoiceAmountSubmit(c, value)}
                          required
                          onRequiredInvalidation={() => onInvoiceRequiredInvalidation(c)}
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={9} className="text-right">
                    <b>Current View Total</b>
                  </td>
                  <td>
                    $
                    {filteredContracts
                      .map(c => c['invoice'] || 0)
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td colSpan={9} className="text-right">
                    <b>Grand Total</b>
                  </td>
                  <td>
                    $
                    {contracts
                      .map(c => c['invoice'] || 0)
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2)}
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={10}>No contracts to show</td>
              </tr>
            )}
          </Table>
          <h6 className="text-right text-muted">
            Total Reports: <span className="text-dark">{filteredContracts.length}</span>
          </h6>
        </>
      )}
    </div>
  );
};

export default CashDeal;
