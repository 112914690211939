import { cloneDeep } from 'lodash';
import { keyValueFieldConfigs } from './constants';

// Converts populated object to _id, to select appropriate option in FormGenerator
export const getObjectForPrefill = (initialObject = {}) => {
  const newObject = {};
  Object.keys(initialObject).forEach(k => {
    if (!initialObject[k]) return initialObject[k];

    if (k === 'additionalFields' && initialObject[k]) {
      Object.keys(initialObject[k]).forEach(ak => {
        newObject[`${ak}-additionalDochubField`] = initialObject[k][ak];
      });
    }

    if (typeof initialObject[k] === 'object') {
      if (
        Array.isArray(initialObject[k]) &&
        initialObject[k].length > 0 &&
        typeof initialObject[k][0] === 'object' &&
        initialObject[k][0].hasOwnProperty('_id')
      ) {
        newObject[k] = initialObject[k].map(obj => obj._id);
      } else if (initialObject[k].hasOwnProperty('_id')) {
        newObject[k] = initialObject[k]['_id'];
      } else {
        newObject[k] = initialObject[k];
      }
    } else {
      newObject[k] = initialObject[k];
    }
  });

  return newObject;
};

export const getAllDefaultCommissionOptionPreFill = appChoices => {
  const commissionOptions = appChoices.find(a => a.key === 'Commission Options').values;

  return {
    commissionSheetOptions: commissionOptions
      .filter(o => o.defaultValue)
      .map(o => ({ key: o.name, value: o.defaultValue }))
  };
};

export const convertFormToKeyValueObject = submittedForm => {
  keyValueFieldConfigs.forEach(({ name, isTableInput, defaultValue, shouldBeChecked, areKeysFixedOnDocument }) => {
    if (!submittedForm[name]) return;

    if (areKeysFixedOnDocument) {
      submittedForm[name] = submittedForm[name].map(keyValueField =>
        keyValueField.checked ? keyValueField.value || 'Inc' : ''
      );
      return;
    }

    submittedForm[name] = submittedForm[name]
      .filter(value => isTableInput || !shouldBeChecked || value.checked)
      .map(value => ({
        key: value[isTableInput ? 0 : 'key'],
        value: value[isTableInput ? 1 : 'value'] || defaultValue
      }));
  });
};

export const convertKeyValueToFormObject = (object, appChoices = []) => {
  keyValueFieldConfigs.forEach(({ name, label, isTableInput, defaultValue, areKeysFixedOnDocument }) => {
    if (!object[name]) return;

    //for fixed keys, it is stored as array, so converting to object form
    if (areKeysFixedOnDocument) {
      try {
        const keys = appChoices.find(a => a.key === label).values.filter((_, index) => object[name][index]);
        object[name] = object[name]
          .filter(value => value)
          .map((value, index) => ({ key: keys[index], value: value === defaultValue ? '' : value }));
      } catch (e) {}
      return;
    }

    //ONLY FOR KEY VALUE FIELDS
    object[name] = object[name].map(value =>
      isTableInput
        ? [value.key, value.value === defaultValue ? '' : value.value]
        : {
            key: value.key,
            value: value.value === defaultValue ? '' : value.value
          }
    );
  });
};

export const addDocHubFormFieldsToForm = ({ formJson, appChoices = [], model = 'Contract' }) => {
  const newFormJson = cloneDeep(formJson);

  const additionalFields = getAdditionalFieldsColumns({ appChoices, model });

  if (additionalFields.length > 0) {
    newFormJson.forms[0].rows.push({
      collapsable: true,
      defaultCollapsed: true,
      rowName: 'Additional Doc Hub Fields',
      columns: [
        {
          default: 12,
          field: {
            type: 'custom-html',
            html: '<h6 class="mt-2 mb-0 "><b>Additional Doc Hub Fields</b></h6><hr class=\'mb-1\'/>'
          }
        },
        ...additionalFields
      ]
    });
  }

  return newFormJson;
};

export const getAdditionalFieldsColumns = ({ appChoices, model = 'Contract', keysToUse }) => {
  const additionalFields = (appChoices.find(a => a.key === 'formFields')?.values || []).filter(
    v => v.model === model && (!keysToUse || keysToUse.includes(v.key))
  );

  return additionalFields.map(({ label, key, type, options = [] }) => {
    const keyToUse = `${key}-additionalDochubField`;
    const typeToUse = type === 'date' ? 'text' : type;
    const variantToUse = type === 'date' ? type : undefined;

    return {
      default: 6,
      xs: 12,
      field: {
        id: keyToUse,
        key: keyToUse,
        title: label,
        type: typeToUse,
        variant: variantToUse,
        options: options.map(o => o.label),
        optionValues: options.map(o => o.value),
        label,
        showLabel: true
      }
    };
  });
};
