import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import Loader from '../Loader';
import Tabs from '../common/Tabs';
import FormBuilder from '../common/form-builder/FormBuilder';

const models = ['Contract', 'Contact', 'Inventory'];

const validateFields = fields => {
  const labels = {};
  const keys = {};

  for (let field of fields) {
    field.label = field.label.trim();

    // Check if all required fields are present
    if (!field.label || !field.key || !field.model) {
      return 'Please provide label for all the fields';
    }

    // Initialize Set objects for the model if they don't already exist
    labels[field.model] = labels[field.model] || new Set();
    keys[field.model] = keys[field.model] || new Set();

    // Check if labels and keys are unique within the model
    if (labels[field.model].has(field.label)) {
      return `Label '${field.label}' is not unique in model '${field.model}'`;
    }

    if (keys[field.model].has(field.key)) {
      return `Key '${field.key}' is not unique in model '${field.model}'`;
    }

    labels[field.model].add(field.label);
    keys[field.model].add(field.key);

    // Check if options are required and present
    if (['dropdown', 'checkbox_group'].includes(field.type)) {
      if (!field.takeOptionsFrom) {
        return `Field '${field.label}' in model '${field.model}' requires an option type`;
      }

      if (field.takeOptionsFrom === 'manual' && (!field.options || field.options.length === 0)) {
        return `Field '${field.label}' in model '${field.model}' requires at least one option`;
      }

      // Validate options
      if (field.takeOptionsFrom === 'manual') {
        const options = new Set();

        for (let option of field.options) {
          // Check if option label and value are present
          if (!option.label.trim() || !option.value.trim()) {
            return `Option in field '${field.label}' in model '${field.model}' requires a label and a value`;
          }

          // Check if option values are unique
          const optionValue = option.value.trim();
          if (options.has(optionValue)) {
            return `Option value '${optionValue}' in field '${field.label}' in model '${field.model}' is not unique`;
          }

          options.add(optionValue);
        }
      }
    }
  }

  // No errors found
  return null;
};

// Helper function to compare _id values
const byId = _id => item => item._id === _id;

const findChanges = (originalFields, currentFields) => {
  const newItems = currentFields.filter(item => !item._id);
  const updatedItems = currentFields.filter(item => item._id);
  const deletedItems = originalFields.filter(item => !currentFields.some(byId(item._id))).map(item => item._id);

  return {
    newItems,
    updatedItems,
    deletedItems
  };
};

const setUUIDs = response =>
  response.map(r => ({
    ...r,
    uuid: uniqueId(),
    options: r.options.map(o => ({ ...o, uuid: uniqueId() }))
  }));

const DocHubFields = () => {
  const [loading, setLoading] = useState(true);
  const [updatingFields, setUpdatingFields] = useState(false);
  const [activeModel, setActiveModel] = useState(models[0]);
  const [formFields, setFormFields] = useState([]);
  const [editingFormFields, setEditingFormFields] = useState([]);

  const fetchFields = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.FORM_FIELDS_LIST,
      method: 'POST'
    });

    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setFormFields(setUUIDs(response));
  };

  useEffect(() => {
    fetchFields();
  }, []);

  useEffect(() => {
    setEditingFormFields(formFields);
  }, [formFields]);

  const onFieldsSubmit = async () => {
    const errorMessage = validateFields(editingFormFields);
    if (errorMessage) return toast.error(errorMessage);

    toast.info('Please wait, updating fields...');
    const changes = findChanges(formFields, editingFormFields);

    setUpdatingFields(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.FORM_FIELDS_UPDATE_MANY,
      requestBody: changes,
      method: 'PUT'
    });

    setUpdatingFields(false);
    if (error) {
      toast.error(error);
      return;
    }

    toast.success('Fields updated successfully');
    setFormFields(setUUIDs(response));
  };

  useEffect(() => {
    if (!formFields) return;
    //update localstorage
    const oldAppChoicesString = localStorage.getItem('app-choices');
    let appChoices = [];
    try {
      appChoices = JSON.parse(oldAppChoicesString);
    } catch (e) {}

    const oldAppChoiceIndex = appChoices.findIndex(ac => ac.key === 'formFields');
    if (oldAppChoiceIndex === -1) {
      appChoices.push({ key: 'formFields', values: formFields });
    } else {
      appChoices[oldAppChoiceIndex] = { key: 'formFields', values: formFields };
    }

    localStorage.setItem('app-choices', JSON.stringify(appChoices));
  }, [formFields]);

  return (
    <Container fluid className="h-100 py-3 px-md-3">
      <Card>
        <Card.Body>
          <h6>Dochub Additional Fields</h6>
          <hr className="my-3" />
          {loading ? (
            <Loader />
          ) : (
            <>
              <Tabs tabs={models} activeTab={activeModel} onTabSelect={setActiveModel} />
              <hr />
              <FormBuilder
                disabled={loading || updatingFields}
                activeModel={activeModel}
                fields={editingFormFields}
                onFieldsChange={setEditingFormFields}
                onSubmit={onFieldsSubmit}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default DocHubFields;
