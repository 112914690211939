import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { DropDownInput, NumberInput, TextInput } from '../../../form-generator/components';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS, RETURN_PATHS } from '../../../helpers/constants';
import Loader from '../../Loader';
import NotFound from '../../NotFound';

const RecordACReceipt = () => {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [rebateAmount, setRebateAmount] = useState('');
  const [rebateDate, setRebateDate] = useState('');
  const [query, setQuery] = useState('');

  const [submittingRebate, setSubmittingRebate] = useState(false);

  const fetchRebates = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        filter: {
          dateACRebateReceived: null
        },
        query: '',
        returnPaths: RETURN_PATHS.CONTRACT
      },
      endpoint: ENDPOINTS.CONTRACTS_SEARCH_NEW
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    const results = response.results
      .filter(c => c['salesLocation'])
      .map(c => {
        const { inventoryUnit } = c;
        if (!inventoryUnit) return c;

        delete inventoryUnit['_id'];
        return { ...c, ...inventoryUnit };
      });

    setContracts(results);
    setFilteredContracts(results);
  };

  useEffect(() => {
    fetchRebates();
  }, []);

  useEffect(() => {
    setActiveIndex(0);
    const contractSelect = document.getElementById('contractSelect');
    if (contractSelect) contractSelect.selectedIndex = 0;

    if (!query) {
      setFilteredContracts(contracts);
      return;
    }

    setFilteredContracts(
      contracts.filter(contract => {
        const serials = ['serialA', 'serialB', 'serialC'];
        return serials.some(serial => contract[serial] && contract[serial].toLowerCase().includes(query.toLowerCase()));
      })
    );
  }, [query, contracts]);

  const submitReceipt = async () => {
    toast.info('Please wait submitting receipt...');

    setSubmittingRebate(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(filteredContracts[activeIndex]['_id']),
      requestBody: {
        dateACRebateReceived: new Date(rebateDate.split('-').join('/')).toISOString(),
        amountACRebateReceived: rebateAmount
      },
      method: 'PUT'
    });

    setSubmittingRebate(false);

    if (error) {
      toast.error(error);
      return;
    }

    const removedContract = filteredContracts[activeIndex];
    const mainIndex = contracts.findIndex(c => c['_id'] === removedContract['_id']);
    if (mainIndex !== -1) {
      contracts.splice(mainIndex, 1);
    }

    setContracts([...contracts]);

    setQuery('');
    document.getElementById('query').value = '';
    toast.success('Receipt submitted successfully');
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  const onContractSelect = () => {
    const contractId = document.getElementById('contractSelect').value;
    const index = filteredContracts.findIndex(c => c['_id'] === contractId);
    if (index !== -1) setActiveIndex(index);
  };

  return (
    <Container fluid className={'h-100  py-3 px-md-5 '}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {contracts.length > 0 ? (
            <Card className="mx-5">
              <Card.Header className="bg-primary text-white">
                <h5>Record Receipt</h5>
              </Card.Header>
              <Card.Body>
                <Row className="mt-2 mb-3">
                  <Col xs={12}>
                    <TextInput
                      id="query"
                      hint="Search by serial number..."
                      onChange={e => {
                        setQuery(e.target.value.trim());
                      }}
                    />
                  </Col>
                </Row>
                <hr />
                <h5>Matched Contracts</h5>
                <Row className="my-4">
                  <Col xs={12} md={4}>
                    <h6>Contract</h6>
                    {filteredContracts.length > 0 ? (
                      <DropDownInput
                        id="contractSelect"
                        size="sm"
                        options={filteredContracts.map(
                          contract => `${contract['buyer']}-${contract['salesLocation'].location}`
                        )}
                        optionValues={filteredContracts.map(contract => contract['_id'])}
                        onChangeFunction={onContractSelect}
                        required
                      />
                    ) : (
                      <h6 className="form-control form-control-sm mb-0">No matching contracts found!</h6>
                    )}
                  </Col>
                  <Col xs={12} md={4}>
                    <h6>AC Rebate Amount</h6>
                    <NumberInput
                      size="sm"
                      hint="Enter rebate amount"
                      onChange={e => {
                        if (e) setRebateAmount(e.target.value);
                      }}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <h6>Date AC Rebate Received</h6>
                    <TextInput
                      size="sm"
                      variant="date"
                      onChange={e => {
                        setRebateDate(e.target.value);
                      }}
                      value={rebateDate}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-right">
                <Button
                  onClick={submitReceipt}
                  disabled={!rebateAmount || !rebateDate || filteredContracts.length === 0 || submittingRebate}
                >
                  Submit
                </Button>
              </Card.Footer>
            </Card>
          ) : (
            !loading && <NotFound text="No contracts to show!" />
          )}
        </>
      )}
    </Container>
  );
};

export default RecordACReceipt;
