import { APP_URL } from './constants';
import moment from 'moment';

export const downloadFileFromString = ({ data, fileName }) => {
  var downloadLink = document.createElement('a');
  var blob = new Blob(['\ufeff', data]);
  var url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = fileName;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const downloadFileFromBase64 = ({ data, fileName, mimeType }) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = mimeType ? `data:${mimeType};base64,${data}` : data;
  downloadLink.target = '_self';
  downloadLink.download = fileName;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
export const SLOT_STATUS = ['Operational', 'Closed'];
export const copyContractDetails = contract => {
  const address = `${contract['propertyAddress']}, ${contract['propertyCity']}, ${contract['propertyState']} ${contract['zipCode']} (${contract['propertyCounty']})`;

  const inventoryCopyElems = [
    { label: 'Unit Location', key: 'unitLocation', objectKey: 'location' },
    { label: 'Manufacturer', key: 'manufacturer', objectKey: 'name' },
    { label: 'Model', key: 'model' },
    { label: 'Year', key: 'year' },
    { label: 'Serial #A', key: 'serialA' },
    { label: 'Serial #B', key: 'serialB' },
    { label: 'HUD #A', key: 'hudA' },
    { label: 'HUD #B', key: 'hudB' },
    { label: 'Size Overall', key: 'sizeOverall' }
  ];

  const copyElem = [
    { label: 'Sales Location', key: 'salesLocation', objectKey: 'location' },
    { label: 'Salesperson', key: 'salesperson' },
    { label: 'Buyer', key: 'buyer' },
    { label: 'Co-Buyer', key: 'coBuyer' },
    { label: 'Address', key: 'addr' },
    { label: 'Pindrop Url', key: 'pindropUrl' },
    { label: 'Cell Phone #', key: 'cellPhone' },
    { label: 'Cell Phone #2', key: 'cellPhone2' },
    { label: 'Buyer Email', key: 'buyerEmail' },
    ...inventoryCopyElems
  ]
    .map(({ label, key, objectKey }) => {
      if (key === 'addr') {
        return `Address: ${address}`;
      }

      if (inventoryCopyElems.map(e => e.label).includes(label)) {
        if (!contract['inventoryUnit']) return `${label}: N/A`;

        let value = contract['inventoryUnit'][key];
        if (value && typeof value === 'object') {
          value = value[objectKey];
        }
        return `${label}: ${value || 'N/A'}`;
      }

      let value = contract[key];
      if (value && typeof value === 'object') {
        value = value[objectKey];
      }

      return `${label}: ${value || 'N/A'}`;
    })
    .filter(item => !item.includes('N/A'))
    .join('\n');

  copyToClipboard(copyElem);
};

export const copyContractAddress = contract => {
  const address = `${contract['propertyAddress']}, ${contract['propertyCity']}, ${contract['propertyState']} ${contract['zipCode']}`;

  copyToClipboard(address);
};

export const copyServiceDetails = service => {
  const copyElem = [
    'homeManufacturer',
    'salesLocation',
    'salesperson',
    'name',
    'address',
    'phoneNumber',
    'emailAddress',
    'serviceIssue'
  ]
    .map(label => {
      return `${label}: ${service[label] || 'N/A'}`;
    })
    .filter(item => !item.includes('N/A'))
    .join('\n');

  copyToClipboard(copyElem);
};

export const copyInventoryDetails = contract => {
  const inventoryCopyElems = [
    { label: 'Unit Location', key: 'unitLocation', objectKey: 'location' },
    { label: 'Manufacturer', key: 'manufacturer', objectKey: 'name' },
    { label: 'Model', key: 'model' },
    { label: 'Year', key: 'year' },
    { label: 'Serial #A', key: 'serialA' },
    { label: 'Serial #B', key: 'serialB' },
    { label: 'HUD #A', key: 'hudA' },
    { label: 'HUD #B', key: 'hudB' },
    { label: 'Size Overall', key: 'sizeOverall' },
    { label: 'Invoice', key: 'invoice', preFix: '$' }
  ]
    .map(({ label, key, objectKey, preFix = '' }) => {
      let value = contract[key];
      if (value && typeof value === 'object') {
        value = value[objectKey];
      }

      return `${label}: ${value || value === 0 ? `${preFix}${value}` : 'N/A'}`;
    })
    .filter(item => !item.includes('N/A'))
    .join('\n');

  copyToClipboard(inventoryCopyElems);
};

export const copyToClipboard = textToCopy => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
};

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function isISODateString(str) {
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  return typeof str === 'string' && isoRegex.test(str);
}

export const sortByDate = (a, b) => {
  if (!a || !isValidDate(a)) return 1;
  if (!b || !isValidDate(b)) return -1;
  return Number(b) - Number(a);
};

export const tableToCSV = table => {
  return table
    .map(row => {
      return row
        .map(col => {
          // Use the extracted function to check for ISO date string
          if (isISODateString(col)) {
            // Format the ISO string using moment
            return `"${moment(col).format('MMMM Do YYYY')}"`;
          }
          return `"${col}"`;
        })
        .join(',');
    })
    .join('\n');
};

export const getValidUrl = link => {
  if (!link.startsWith('https://') && !link.startsWith('http://')) {
    link = 'https://' + link;
  }

  return link;
};

export const openLinkInNewTab = (link, absolute = false) => {
  if (absolute && !link.startsWith('https://') && !link.startsWith('http://')) {
    link = 'https://' + link;
  }
  window.open(absolute ? link : `${APP_URL}${link}`, '_blank');
};

export const getDocIdFromUrl = (url = '') => url.match(/[-\w]{25,}/)[0];

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const getRedirectinLinkForFields = field => {
  if (field === 'contactResults') return '/crm/search?q=';
  if (field === 'inventoryResults') return '/inventory/browse?q=';
  if (field === 'contractResults') return '/contract/search?q=';
};

// Function to group entities into their respective groups
export const groupEntityTableValues = (data, groupBy, groupValues) => {
  if (!groupBy || !groupValues) return [...data];
  // Initialize an object to store grouped items
  const groupedItems = [...groupValues, 'Others'].reduce((obj, group) => {
    return { ...obj, [group]: [] };
  }, {});

  // Iterate over each item in the data array
  data.forEach(item => {
    // If the item has the groupBy property and its value exists in groupValues, add it to the corresponding group
    if (item[groupBy] && groupValues.includes(item[groupBy])) {
      groupedItems[item[groupBy]].push({ ...item });
    } else {
      // If the groupBy property is not present or its value is not in groupValues, add it to the "Others" group
      groupedItems['Others'].push({ ...item });
    }
  });

  // Convert the grouped items into a single array, including group headers
  let finalArray = [];
  for (let group in groupedItems) {
    if (groupedItems[group].length > 0) {
      finalArray.push({ _id: group, content: group, isHeader: true }, ...groupedItems[group]); // Add group header
    }
  }

  return finalArray;
};

export const findSpecificSectionVideo = (videoUrls = [], route) => {
  return videoUrls.find(
    paramRoute => paramRoute?.name !== 'Featured Videos' && getRegexForPath(paramRoute?.path).test(route)
  );
};

export const matchIdParameter = route => {
  return /[a-f\d]{24}/.test(route);
};

export const getRegexForPath = path => {
  const routeRegEx = new RegExp(path);
  return routeRegEx;
};

export const parseHTML = htmlString => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;
  return tempElement.firstChild;
};

export const sortEntityItemsAccordingToPositions = items => {
  return items.sort((item1, item2) => Number(item1?.position) - Number(item2?.position));
};

export const fillSizesInInventory = (overallSizeOfInventory, sections) => {
  const pattern = /^\d+x\d+$/;
  const numberOfSections = sections || Number(document.getElementById('numberOfSections')?.value);
  const overallSize = document.getElementById('sizeOverall')?.value || overallSizeOfInventory || '';

  let allBValues = ['sizeB', 'serialB', 'hudB'];
  let allAValues = ['sizeA', 'serialA', 'hudA'];
  let allCValues = ['sizeC', 'serialC', 'hudC'];

  [...allAValues, ...allBValues, ...allCValues].forEach(id => {
    const column = document.getElementById(`${id}-col-container`);

    if (column) {
      column.style.display = 'block';
    }
  });

  if (numberOfSections) {
    [...(numberOfSections === 1 ? allBValues : []), ...(numberOfSections <= 2 ? allCValues : [])].forEach(id => {
      const colToBeRemoved = document.getElementById(`${id}-col-container`);

      if (colToBeRemoved) {
        colToBeRemoved.style.display = 'none';
      }
    });
  }

  const splittedOverallSize = overallSize.split('x');
  const leftHandOfOverallSize = parseInt(Number(splittedOverallSize[0]) / numberOfSections);

  const elementA = document.getElementById('sizeA');
  const elementB = document.getElementById('sizeB');
  const elementC = document.getElementById('sizeC');

  const allElements = [elementA, elementB, elementC];
  if (pattern.test(overallSize)) {
    if (isNaN(leftHandOfOverallSize) || leftHandOfOverallSize === 0) {
      allElements.forEach(element => {
        if (element) {
          element.value = '';
        }
      });
    } else {
      allElements.forEach(element => {
        if (element) {
          element.value = `${leftHandOfOverallSize}x${splittedOverallSize[1]}`;
        }
      });
    }
  } else {
    allElements.forEach(element => {
      if (element) {
        element.value = '';
      }
    });
  }
};

export const updateComunsAccordingToSections = noOfSections => {
  if (!noOfSections || noOfSections === 1 || noOfSections === 3) {
    return 4;
  }
  if (noOfSections === 2) {
    return 6;
  }

  return 6;
};

export const isObjectIncomplete = (object, pathToCompare = []) => {
  return !object || Object.keys(object).length <= pathToCompare.length + 5; //allowing some slack;
};
