import React from 'react';
import EntityCRUD from '../common/EntityCRUD';
import {
  sectionVideoAddRows,
  sectionVideoHeaders,
  sectionVideosearchKeys,
  sectionVideosSecondaryUpdateRows,
  sectionVideoUpdateRows
} from './constants';

const SectionsVideo = () => {
  return (
    <>
      <EntityCRUD
        entityAppChoiceKey="sectionVideos"
        entityName="Section Videos"
        endpoint={'/section-videos'}
        entityTableHeaders={sectionVideoHeaders}
        entityTableSearchKeys={sectionVideosearchKeys}
        addFormRows={sectionVideoAddRows}
        updateFormRows={sectionVideoUpdateRows}
        addNewButton={false}
        deleteButton={false}
        secondaryUpdateRows={sectionVideosSecondaryUpdateRows}
      />
    </>
  );
};

export default SectionsVideo;
