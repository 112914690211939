import React from 'react';
import { Alert, Badge } from 'react-bootstrap';
import { ArrowRightCircle } from 'react-bootstrap-icons';

const StatusTabs = ({ statuses, activeStatusObject, onStatusClick }) => (
  <Alert className="my-2  px-2 py-1 text-center" variant="primary">
    {statuses.map((s, index) => (
      <div className="mt-1 d-inline-block" key={s.name}>
        {index !== 0 && <ArrowRightCircle size={10} className="mx-1" />}
        <Badge
          variant={activeStatusObject && activeStatusObject.name === s.name ? 'primary' : 'light'}
          className={`${s.count === 0 ? 'text-muted' : 'hover-dark'}  p-1 tinyFont`}
          onClick={() => {
            if (s.count === 0) return;

            onStatusClick(s);
          }}
        >
          {s.name}
          {s.count !== undefined ? ` (${s.count})` : ''}
        </Badge>
      </div>
    ))}
  </Alert>
);

export default StatusTabs;
