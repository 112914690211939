import React from 'react';
import { Button, Col, Container, Modal, ProgressBar, Row } from 'react-bootstrap';
import CachedResponse from './common/CachedResponse';
import SearchPage from './main/Search';

const ContractsPicker = ({
  show,
  onContractPickerClose,
  submitting = false,
  submittingText = 'Claiming request...',
  onSubmit,
  submitButtonText = 'Claim',
  excludedContractIds = [],
  selectedContracts = [],
  onContractSelect,
  updateInventoryList
}) => {
  return (
    <Modal
      dialogClassName="h-100 my-0"
      contentClassName="h-100"
      show={show}
      size="lg"
      onHide={onContractPickerClose}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Select contract to be assigned</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto py-1 px-2">
        <CachedResponse
          listEndpoint={'app-choices'}
          render={appChoices => (
            <SearchPage
              selectedContracts={selectedContracts}
              excludedContractIds={excludedContractIds}
              fromPicker
              onSelect={onContractSelect}
              appChoices={appChoices}
              fromInventory
              updateInventoryList={updateInventoryList}
            />
          )}
        />
      </Modal.Body>

      <Modal.Footer>
        <Container fluid>
          <Row>
            {submitting && (
              <Col xs={12}>
                <ProgressBar className="mb-2" now={100} animated label={submittingText} />
              </Col>
            )}
            <Col xs={12} className="text-right">
              <Button
                className="mr-3"
                size="sm"
                variant="secondary"
                onClick={onContractPickerClose}
                disabled={submitting}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                variant="info"
                disabled={selectedContracts.length === 0 || submitting}
                onClick={() => onSubmit(selectedContracts)}
              >
                {submitButtonText}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default ContractsPicker;
