import React from 'react';
import DataTable from '../DataTable';
import EditInventory from '../inventory/EditInventory';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';

const inventoryIgnoreFields = ['mostRecentComment'];

const InventoryInfoSideBar = ({
  onInventoryInfoSideBarHide,
  inventory,
  sidebarMode,
  onInventoryDelete,
  onInventoryEdit,
  appChoices
}) => {
  return (
    <SlidingSideBar
      fullScreen
      visible={sidebarMode !== null}
      onClose={onInventoryInfoSideBarHide}
      title={'Inventory Details'}
    >
      {inventory && sidebarMode !== null && (
        <>
          {sidebarMode === 'view' ? (
            <DataTable data={inventory} ignoreFields={inventoryIgnoreFields} />
          ) : (
            <EditInventory
              inventoryFromSearch={inventory}
              appChoices={appChoices}
              fromSearch
              onInventoryDelete={onInventoryDelete}
              onInventoryEdit={onInventoryEdit}
            />
          )}
        </>
      )}
    </SlidingSideBar>
  );
};

export default InventoryInfoSideBar;
