import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { DropDownInput, NumberInput, TextInput } from '../../../form-generator/components';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS } from '../../../helpers/constants';
import Loader from '../../Loader';
import NotFound from '../../NotFound';

const RecordBillbackReceipt = () => {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [billbackAmount, setBillbackAmount] = useState('');
  const [billbackDate, setBillbackDate] = useState('');
  const [query, setQuery] = useState('');
  const [submittingBillback, setSubmittingBillback] = useState(false);

  const fetchBillbacks = async () => {
    setLoading(true);
    const { response, error } = await makeApiRequests({
      requestBody: {
        query: ''
      },
      endpoint: ENDPOINTS.CONTRACTS_SEARCH
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    const contractResponse = response
      .filter(c => c['salesLocation'] && c['billbacks'] && c['billbacks'].length > 0)
      .map(c => {
        const { inventoryUnit } = c;
        if (!inventoryUnit) return c;

        delete inventoryUnit['_id'];
        return { ...c, ...inventoryUnit };
      });

    const contractWithBillBackRequest = [];

    for (var contract of contractResponse) {
      const billbacks = contract['billbacks'];
      billbacks.forEach((b, index) => {
        if (!b['dateBillbackReceived']) {
          contractWithBillBackRequest.push({ ...contract, Billback: { ...b, index } });
        }
      });
    }
    setContracts(contractWithBillBackRequest);
    setFilteredContracts(contractWithBillBackRequest);
  };

  useEffect(() => {
    fetchBillbacks();
  }, []);

  useEffect(() => {
    setActiveIndex(0);
    const contractSelect = document.getElementById('contractSelect');
    if (contractSelect) contractSelect.selectedIndex = 0;

    if (!query) {
      setFilteredContracts(contracts);
      return;
    }

    setFilteredContracts(
      contracts.filter(contract => {
        const serials = ['serialA', 'serialB', 'serialC'];
        return serials.some(serial => contract[serial] && contract[serial].toLowerCase().includes(query.toLowerCase()));
      })
    );
  }, [query, contracts]);

  const submitReceipt = async () => {
    toast.info('Please wait submitting billback...');

    const activeContract = filteredContracts[activeIndex];
    const newBillBacks = [...activeContract['billbacks']];

    newBillBacks[activeContract['Billback']['index']]['dateBillbackReceived'] = new Date(
      billbackDate.split('-').join('/')
    ).toISOString();

    newBillBacks[activeContract['Billback']['index']]['amountBillbackReceived'] = billbackAmount
      ? Number(billbackAmount)
      : 0;

    setSubmittingBillback(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(filteredContracts[activeIndex]['_id']),
      requestBody: {
        billbacks: newBillBacks
      },
      method: 'PUT'
    });

    setSubmittingBillback(false);

    if (error) {
      toast.error(error);
      return;
    }

    const removedContract = filteredContracts[activeIndex];
    const mainIndex = contracts.findIndex(
      c => c['_id'] === removedContract['_id'] && c['Billback']['index'] == removedContract['Billback']['index']
    );
    if (mainIndex !== -1) {
      contracts.splice(mainIndex, 1);
    }

    setContracts(
      contracts.map(c => ({ ...c, billbacks: c['_id'] === activeContract['_id'] ? newBillBacks : c['billbacks'] }))
    );
    setSubmittingBillback(false);

    setQuery('');
    document.getElementById('query').value = '';
    toast.success('Receipt submitted successfully');
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  const onContractSelect = () => {
    const [contractId, billbackIndex] = document.getElementById('contractSelect').value.split(':');
    const index = filteredContracts.findIndex(c => c['_id'] === contractId && c['Billback']['index'] == billbackIndex);
    if (index !== -1) setActiveIndex(index);
  };

  return (
    <Container fluid className={'h-100  py-3 px-md-5 '}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {contracts.length > 0 ? (
            <Card className="mx-5">
              <Card.Header className="bg-primary text-white">
                <h5>Record Receipt</h5>
              </Card.Header>
              <Card.Body>
                <Row className="mt-2 mb-3">
                  <Col xs={12}>
                    <TextInput
                      id="query"
                      hint="Search by serial number..."
                      onChange={e => {
                        setQuery(e.target.value.trim());
                      }}
                    />
                  </Col>
                </Row>
                <hr />
                <h5>Matched Contracts</h5>
                <Row className="my-4">
                  <Col xs={12} md={4}>
                    <h6>Contract</h6>
                    {filteredContracts.length > 0 ? (
                      <DropDownInput
                        id="contractSelect"
                        size="sm"
                        options={filteredContracts.map(
                          contract =>
                            `${contract['buyer']}-${contract['salesLocation'].location}(${contract['Billback'][
                              'index'
                            ] + 1})`
                        )}
                        optionValues={filteredContracts.map(
                          contract => `${contract['_id']}:${contract['Billback']['index']}`
                        )}
                        onChangeFunction={onContractSelect}
                        required
                      />
                    ) : (
                      <h6 className="form-control form-control-sm mb-0">No matching contracts found!</h6>
                    )}
                  </Col>
                  <Col xs={12} md={4}>
                    <h6>Billback Amount</h6>
                    <NumberInput
                      size="sm"
                      hint="Enter billback amount"
                      onChange={e => {
                        if (e) setBillbackAmount(e.target.value);
                      }}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <h6>Date Billback Received</h6>
                    <TextInput
                      size="sm"
                      variant="date"
                      onChange={e => {
                        setBillbackDate(e.target.value);
                      }}
                      value={billbackDate}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-right">
                <Button
                  onClick={submitReceipt}
                  disabled={!billbackAmount || !billbackDate || filteredContracts.length === 0 || submittingBillback}
                >
                  Submit
                </Button>
              </Card.Footer>
            </Card>
          ) : (
            !loading && <NotFound text="No contracts to show!" />
          )}
        </>
      )}
    </Container>
  );
};

export default RecordBillbackReceipt;
