import React from 'react';
import EditCrm from '../crm/EditCrm';
import DataTable from '../DataTable';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';

const contactIgnoreFields = ['mostRecentComment'];

const ContactInfoSideBar = ({
  sidebarMode,
  onContactInfoSideBarHide,
  contact,
  appChoices,
  onContactEdit,
  onContactDelete,
  onAddInventoryPopupMetaChange,
  addInvenroryPopupMeta,
  onAddInvetoryPopupMetaClose
}) => {
  return (
    <SlidingSideBar
      fullScreen
      visible={sidebarMode !== null}
      onClose={onContactInfoSideBarHide}
      title={'Contact Details'}
    >
      {contact && sidebarMode !== null && (
        <>
          {sidebarMode === 'view' ? (
            <DataTable data={contact} ignoreFields={contactIgnoreFields} />
          ) : (
            <EditCrm
              contactFromSearch={contact}
              appChoices={appChoices}
              fromSearch
              onContactDelete={onContactDelete}
              onContactEdit={onContactEdit}
              onAddInventoryPopupMetaChange={onAddInventoryPopupMetaChange}
              addInvenroryPopupMeta={addInvenroryPopupMeta}
              onAddInvetoryPopupMetaClose={onAddInvetoryPopupMetaClose}
            />
          )}
        </>
      )}
    </SlidingSideBar>
  );
};

export default ContactInfoSideBar;
