import React from 'react';
import { ArrowRightCircleFill, ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { camelCaseToTitle } from '../../helpers/docHubFormHelpers';
import { getRedirectinLinkForFields } from '../../helpers/global';
import CrmSearchItem from '../crm/CrmSearchItem';
import InventorySearchItem from '../inventory/InventorySearchItem';
import ContractSearchItem from '../main/ContractSearchItem';

const GlobalSearchResults = ({
  globalSearchResults,
  globalSearchQuery,
  sectionsVisibility,
  onSectionsVisibilityChange
}) => {
  return (
    <div className="mt-4">
      {Object.keys(globalSearchResults).map(result => {
        return (
          <div className="mt-2 border rounded px-2" key={result}>
            <div className="d-flex mt-1 justify-content-between align-items-center w-100 ">
              <div
                className="d-flex align-items-center flex-grow-1 pointer"
                onClick={() => onSectionsVisibilityChange(result, !sectionsVisibility[result])}
              >
                <h6 className="largeFont mb-0">{camelCaseToTitle(result)}</h6>

                {sectionsVisibility[result] ? (
                  <ChevronUp className="ml-2" title="Show Less" size={12} />
                ) : (
                  <ChevronDown className="ml-2" title="Show More" size={12} />
                )}
              </div>
              {/* Show More Button */}
              {globalSearchResults[result]?.results?.length ? (
                <div className="d-flex justify-content-end align-items-center">
                  <Link
                    to={`${getRedirectinLinkForFields(result)}${globalSearchQuery}`}
                    target="_blank"
                    className="smallFont"
                  >
                    View More <ArrowRightCircleFill size={13} />
                  </Link>
                </div>
              ) : null}
            </div>
            <hr className="my-1" />
            {sectionsVisibility[result] && !globalSearchResults[result]?.results.length && (
              <h6 className="smallFont mb-0 text-center text-muted py-2">{`No Results For ${
                camelCaseToTitle(result).split(' ')[0]
              }`}</h6>
            )}
            {globalSearchResults &&
              globalSearchResults[result]?.results.map(value => {
                return (
                  <div key={value?._id}>
                    {sectionsVisibility[result] && (
                      <div className="fade-in">
                        <>
                          {result === 'contactResults' && <CrmSearchItem crm={value} mini />}
                          {result === 'inventoryResults' && <InventorySearchItem inventory={value} mini fromPicker />}
                          {result === 'contractResults' && <ContractSearchItem contract={value} mini fromPicker />}
                        </>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

export default GlobalSearchResults;
