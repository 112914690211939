import React, { useEffect, useState } from 'react';
import { Alert, Card, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { addDocHubFormFieldsToForm, getObjectForPrefill } from '../../helpers/formHelpers';
import File from '../common/file';
import SubmitButtonRow from '../common/SubmitButtonRow';
import { addChoicesToInventoryForm, formJson, validateAndCleanupForm } from './form';
import { fillSizesInInventory } from '../../helpers/global';

const CreateInventory = ({ appChoices, onInventoryAdd, prefillUnit, isDuplicating }) => {
  const [form, setForm] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [importingUnit] = useState(prefillUnit ? getObjectForPrefill(prefillUnit) : undefined);
  const [numberOfSections, setNumberOfSections] = useState(1);

  useEffect(() => {
    setUpForm();
  }, [numberOfSections]);

  const onNumberOfSectionsChange = number => {
    setNumberOfSections(Number(number));
  };

  const setUpForm = () => {
    const newFormJson = addDocHubFormFieldsToForm({
      formJson: formJson(numberOfSections, onNumberOfSectionsChange),
      appChoices,
      model: 'Inventory'
    });
    addChoicesToInventoryForm({ appChoices, form: newFormJson });
    setForm(newFormJson);
  };

  useEffect(() => {
    fillSizesInInventory('', numberOfSections);
  }, [form]);

  const onInventoryFormSubmit = async form => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    //checks
    const valid = validateAndCleanupForm(form);
    if (!valid) return;

    form['files'] = uploadedFiles.map(f => f.id);
    form.numberOfSections = Number(form?.numberOfSections);
    toast.info('Please wait, creating an inventory item...');
    setButtonsDisabled(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.INVENTORY_BASE,
      requestBody: form
    });
    setButtonsDisabled(false);

    if (error) {
      return toast.error(error);
    }

    toast(`Inventory created successfully!`, {
      type: 'success'
    });

    if (onInventoryAdd) {
      onInventoryAdd(response);
    } else {
      setTimeout(() => document.location.reload(), 2000);
    }
  };

  window['onInventoryFormSubmit'] = onInventoryFormSubmit;

  return (
    <Container fluid className="py-2 px-md-3">
      {isDuplicating && (
        <Alert variant="primary" className="px-2 py-1 font-weight-bold midFont">
          Only duplicate if you are creating a new unit!
        </Alert>
      )}
      <Card>
        <Card.Body>
          {form && (
            <>
              <FormGenerator prefix="inventory" formJson={form} formValues={{ addInventory: importingUnit }} />
              <File uploadedFiles={uploadedFiles} onUploadedFilesChange={setUploadedFiles} containerClassName="mx-3" />
              <SubmitButtonRow
                disabled={buttonsDisabled}
                onSaveClick={() => document.getElementById('addinventory').click()}
                showGoToDocHub={false}
                bottom
              />
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CreateInventory;
