import React from 'react';
import PayrollBill from './PayrollBill';
import SlidingSideBar from '../../SlidingSideBar/SlidingSideBar';

const PayrollSideBar = ({
  show,
  appChoices,
  onHide,
  payrollBill,
  onPayrollBillChange,
  buttonsDisabled,
  onPayrollSubmit,
  onNewContractorAddClick,
  zIndex
}) => {
  return (
    <SlidingSideBar zIndex={zIndex} fullScreen visible={show} onClose={onHide} title={'Payroll Bill Details'}>
      {show && (
        <PayrollBill
          appChoices={appChoices}
          payrollBill={payrollBill}
          onPayrollBillChange={onPayrollBillChange}
          buttonsDisabled={buttonsDisabled}
          onPayrollSubmit={onPayrollSubmit}
          onNewContractorAddClick={onNewContractorAddClick}
        />
      )}
    </SlidingSideBar>
  );
};

export default PayrollSideBar;
