import React from 'react';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { Search, Funnel, ArrowDown, ArrowUp } from 'react-bootstrap-icons';
import { TextInput } from '../../../form-generator/components';

const SearchArea = ({
  query,
  onQueryChange,
  onSearchClick,
  disabled,
  maxLimit,
  maxLimitOptions,
  onMaxLimitOptionChange,
  sortBy,
  sortByOptions,
  onSortByOptionChange,
  descSort,
  onDescSortButtonClick,
  onFilterButtonClick
}) => {
  const handleReturn = e => {
    if (!disabled && e.key === 'Enter') {
      onSearchClick && onSearchClick();
    }
  };

  return (
    <Row className="px-md-3 mt-1">
      <Col xs={12} md={12} lg={7}>
        <div className="input-group mb-1">
          <TextInput
            id="searchText"
            hint="Leave empty to view recent results..."
            onKeyPress={handleReturn}
            preValue={query}
            onChange={e => onQueryChange(e.target.value)}
            size="sm"
          />
          <div className="input-group-append">
            <Button size="sm" variant="primary" disabled={disabled} onClick={onSearchClick}>
              <Search className="align-text-top mr-2" size={16} />
              Search
            </Button>
          </div>
        </div>
      </Col>
      <Col xs={12} md={{ span: 4, offset: 2 }} lg={{ span: 2, offset: 0 }} className="pl-md-0">
        <Dropdown className="mb-1 mb-md-0">
          <Dropdown.Toggle size="sm" disabled={disabled} block variant="outline-primary" id="dropdown-basic">
            Showing
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {maxLimitOptions.map(option => (
              <Dropdown.Item active={option === maxLimit} key={option} onClick={() => onMaxLimitOptionChange(option)}>
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col
        xs={onFilterButtonClick ? 8 : 10}
        md={onFilterButtonClick ? 4 : 5}
        lg={{ span: onFilterButtonClick ? 1 : 2, offset: 0 }}
        className={`pr-0 pr-md-0  pl-md-0`}
      >
        <Dropdown className="mb-1 mb-md-0">
          <Dropdown.Toggle size="sm" disabled={disabled} block variant="outline-primary" id="dropdown-basic">
            Sort By
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {sortByOptions.map(option => (
              <Dropdown.Item
                active={option.key === sortBy}
                key={option.key}
                onClick={() => onSortByOptionChange(option.key)}
              >
                {option.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs={2} md={1} lg={{ span: 1, offset: 0 }} className="pl-1">
        <Button variant="primary" block size="sm" disabled={disabled} onClick={onDescSortButtonClick}>
          {descSort ? <ArrowDown className="text-align-top" /> : <ArrowUp className="text-align-top" />}
        </Button>
      </Col>

      {onFilterButtonClick && (
        <Col xs={2} md={1} lg={1}>
          <Button size="sm" variant="primary" block onClick={onFilterButtonClick} disabled={disabled}>
            <Funnel className="align-text-top" />
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default SearchArea;
