import { setAttribute } from '../../form-generator/helpers/utility';
import { additionalDocHubfieldTypesWithDropdowns } from '../../helpers/constants';
import { convertFormToKeyValueObject } from '../../helpers/formHelpers';
import { sectionCol } from '../../helpers/forms';

const headerRow = title => {
  return [
    {
      columns: [
        {
          default: 6,
          xs: 12,
          className: 'align-self-center',
          field: {
            type: 'custom-html',
            html: `<h3 class="mb-0"><b>${title}</b></h3><hr class="d-md-none"/>`
          }
        },
        {
          default: 6,
          xs: 12,
          className: 'bg-primary text-white rounded p-2',
          field: {
            id: 'status',
            title: 'Contact Status',
            type: 'dropdown',
            required: true,
            options: []
          }
        }
      ]
    },
    {
      columns: [
        {
          default: 12,
          field: {
            type: 'custom-html',
            html: '<hr class="my-1"/>'
          }
        }
      ]
    }
  ];
};

const mainFieldRows = (createMode, fromPipeline = false) => [
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'buyer',
          title: 'Buyer',
          type: 'text',
          required: true
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'buyerEmail',
          title: 'Buyer Email',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'followUpDate',
          title: 'Follow Up Date',
          type: 'text',
          variant: 'date',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'coBuyer',
          title: 'Co-Buyer',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'coBuyerEmail',
          title: 'Co-Buyer Email',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'salesLocation',
          title: 'Sales Location',
          type: 'dropdown',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'propertyAddress' + (fromPipeline ? 'Pipeline' : ''),
          key: 'propertyAddress',
          title: 'Property Address',
          type: 'text',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'zipCode' + (fromPipeline ? 'Pipeline' : ''),
          key: 'zipCode',
          title: 'Zip Code',
          type: 'text',
          required: false,
          onTextChange: 'autoFillAddress'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'propertyState' + (fromPipeline ? 'Pipeline' : ''),
          key: 'propertyState',
          title: 'Property State',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'propertyCity' + (fromPipeline ? 'Pipeline' : ''),
          key: 'propertyCity',
          title: 'Property City',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'propertyCounty' + (fromPipeline ? 'Pipeline' : ''),
          key: 'propertyCounty',
          title: 'Property County',
          type: 'text',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'sameMailingAddress' + (fromPipeline ? 'Pipeline' : ''),
          key: 'sameMailingAddress',
          title: 'Same Mailing Address?',
          label: 'Same as Property Address',
          showLabel: true,
          type: 'checkbox',
          required: false,
          defaultChecked: createMode,
          dependentElems: fromPipeline
            ? [
                'mailingAddressPipeline',
                'mailingZipCodePipeline',
                'mailingStatePipeline',
                'mailingCityPipeline',
                'mailingCountyPipeline'
              ]
            : ['mailingAddress', 'mailingZip', 'mailingState', 'mailingCity', 'mailingCounty'],
          showDependentOn: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'mailingAddress' + (fromPipeline ? 'Pipeline' : ''),
          key: 'mailingAddress',
          title: 'Mailing Address',
          type: 'text',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'mailingZip' + (fromPipeline ? 'Pipeline' : ''),
          key: 'mailingZip',
          title: 'Mailing Zip',
          type: 'text',
          required: false,
          onTextChange: 'autoFillMailingAddress'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'mailingState' + (fromPipeline ? 'Pipeline' : ''),
          key: 'mailingState',
          title: 'Mailing State',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'mailingCity' + (fromPipeline ? 'Pipeline' : ''),
          key: 'mailingCity',
          title: 'Mailing City',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'mailingCounty' + (fromPipeline ? 'Pipeline' : ''),
          key: 'mailingCounty',
          title: 'Mailing County',
          type: 'text',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'cellPhone',
          title: 'Cell Phone #',
          type: 'text',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'cellPhone2',
          title: 'Cell Phone #2',
          type: 'text',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        xs: 12,
        field: {
          id: 'newOrUsed',
          title: 'New / Used',
          type: 'dropdown',
          options: ['New', 'Used'],
          required: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'lender',
          title: 'Lender',
          type: 'dropdown',
          options: []
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'insuranceCompany',
          title: 'Insurance Company',
          type: 'dropdown',
          options: []
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'stockOrRso',
          title: 'Stock / RSO',
          type: 'dropdown',
          options: ['Stock', 'RSO'],
          required: true
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'consultant',
          title: 'Consultant',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'salesPerson',
          key: 'salesperson',
          title: 'Salesperson',
          type: 'text',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'leadSource',
          title: 'Lead Source',
          type: 'dropdown',
          options: [],
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'initialContactDate',
          title: 'Initial Contact Date',
          type: 'text',
          variant: 'date',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'lastContactDate',
          title: 'Last Contact Date',
          type: 'text',
          variant: 'date',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        xs: 12,
        field: {
          id: 'consultantsFollowUpNotes',
          title: "Consultant's Follow Up Notes",
          type: 'text-area',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        xs: 12,
        field: {
          id: 'personalNotes',
          title: 'Personal Notes',
          type: 'text-area',
          required: false
        }
      }
    ]
  }
];

const costFields = [
  { key: 'adminCosts', label: 'Admin' },
  { key: 'serviceCosts', label: 'Service' },
  { key: 'npsCosts', label: 'NPS' },
  { key: 'floodCertificationCosts', label: 'Flood Certification' },
  { key: 'appraisalCosts', label: 'Appraisal' },
  { key: 'insuranceCosts', label: 'Insurance' },
  { key: 'improvementCosts', label: 'Improvement' },
  { key: 'formTCosts', label: 'Form T' },
  { key: 'titleFeesCosts', label: 'Title Fees' },
  { key: 'skirtingCosts', label: 'Skirting' },
  { key: 'stepsCosts', label: 'Steps' },
  { key: 'deliveryCosts', label: 'Delivery/Setup' },
  { key: 'newAcCosts', label: 'Air Conditioner' },
  { key: 'calicheBasePadCosts', label: 'Caliche Pad' },
  { key: 'hookupCosts', label: 'Hookups' },
  { key: 'houseCatCosts', label: 'House Cat' },
  { key: 'transliftCosts', label: 'Translift' },

  { key: 'otherCosts', label: 'Other' }
].sort((a, b) => (a.label === 'Other' || b.label === 'Other' ? 1 : a.label.localeCompare(b.label)));

const additionalFieldsRow = {
  collapsable: true,
  defaultCollapsed: true,
  rowName: 'Additional Fields',
  columns: [
    {
      default: 12,
      field: {
        type: 'custom-html',
        html: '<h6 class="mt-2 mb-0 "><b>Additional Fields</b></h6><hr class=\'mb-1\'/>'
      }
    },

    {
      default: 6,
      xs: 12,
      field: {
        id: 'optionalEquipmentAndAccessories',
        title: 'Optional Equipment And Accessories',
        key: 'optionalEquipmentAndAccessories',
        type: 'table-input',
        tableFields: [
          { title: 'Optional Equipment And Accessories', key: 'key', type: 'datalist-text', required: false },
          { title: 'Value', key: 'value', type: 'number', required: false }
        ],
        required: false,
        variant: '',
        hint: ' (Leave empty if included)',
        includeUnselectedKeys: false,
        toBeUpdated: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'retailerOptions',
        title: 'Retailer Options',
        key: 'retailerOptions',
        type: 'table-input',
        tableFields: [
          { title: 'Retailer Option', key: 'key', type: 'datalist-text', required: false },
          { title: 'Value', key: 'value', type: 'number', required: false }
        ],
        required: false,
        disabled: false,
        variant: '',
        hint: ' (Leave empty if included)',
        includeUnselectedKeys: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'commissionSheetOptions',
        title: 'Commission Sheet Options',
        key: 'commissionSheetOptions',
        onCheckedChange: 'onCommissionSheetOptionsChange',
        type: 'table-input',
        tableFields: [
          { title: 'Commission Sheet Option', key: 'key', type: 'datalist-text', required: false },
          { title: 'Value', key: 'value', type: 'number', required: false }
        ],
        required: false,
        variant: '',
        hint: '',
        includeUnselectedKeys: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'commissionSheetLessOptions',
        title: 'Commission Sheet Less Options',
        key: 'commissionSheetLessOptions',
        onCheckedChange: 'onCommissionSheetLessOptionsChange',
        type: 'table-input',
        tableFields: [
          { title: 'Commission Sheet Less Option', key: 'key', type: 'datalist-text', required: false },
          { title: 'Value', key: 'value', type: 'number', required: false }
        ],
        required: false,
        variant: '',
        hint: '',
        includeUnselectedKeys: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'dateOfDeposit',
        title: 'Date of Deposit',
        type: 'text',
        required: false,
        variant: 'date'
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'originationPoints',
        title: 'Origination Points',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'applicationNumber',
        key: 'applicationNumber',
        title: 'Application Number',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'downPayment',
        title: 'Down Payment',
        type: 'text',
        required: false
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'dateSigned',
        title: 'Date Signed',
        type: 'text',
        required: false,
        variant: 'date'
      }
    },

    sectionCol('Cost Fields:'),
    ...costFields.map(f => ({
      default: 6,
      xs: 12,
      field: {
        id: f.key,
        title: f.label,
        type: 'number',
        required: false
      }
    })),
    sectionCol(''),
    {
      default: 6,
      xs: 12,
      field: {
        id: 'taxFactorAdjustedSalesPrice',
        title: 'MHIT',
        type: 'number',
        required: false
      }
    }
  ]
};

export const formJson = {
  showButtonsAtTop: true,
  forms: [
    {
      name: 'Add New CRM Record',
      hideFormName: true,
      markCompulsoryFields: true,
      compact: true,
      submit: {
        name: 'Add',
        show: false,
        onSubmit: 'onCrmFormSubmit'
      },
      rows: [...headerRow('Add New Contact'), ...mainFieldRows(true), additionalFieldsRow]
    }
  ]
};

export const editFormJson = (role, fromPipeline = false) => ({
  showButtonsAtTop: true,
  forms: [
    {
      name: 'update',
      hideFormName: true,
      markCompulsoryFields: true,
      compact: true,
      submit: {
        name: 'Update',
        show: fromPipeline,
        onSubmit: 'onCrmEditFormSubmit'
      },
      rows: [
        ...(fromPipeline ? [] : headerRow('Update Contact')),
        ...mainFieldRows(false, fromPipeline),
        ...(['super admin', 'admin', 'sales manager'].includes(role)
          ? [
              {
                columns: [
                  {
                    default: 12,
                    field: {
                      id: 'contactOwner',
                      title: 'Contact Owner',
                      type: 'dropdown',
                      options: [],
                      required: false
                    }
                  }
                ]
              }
            ]
          : []),
        additionalFieldsRow
      ]
    }
  ]
});

export const appChoiceToKeyValue = (values = []) => {
  return values.map();
};

export const addChoicesToCRMForm = ({ appChoices, form, ignoreTableFields }) => {
  const requiredChoices = [
    { appChoicesKey: 'salesLocation', formKey: 'salesLocation', isObject: true, labelKey: 'location' },
    { appChoicesKey: 'Lead Source', formKey: 'leadSource' },
    { appChoicesKey: 'Type Of Unit', formKey: 'typeOfUnit' },
    { appChoicesKey: 'Contact Status', formKey: 'status' },
    { appChoicesKey: 'lender', formKey: 'lender', isObject: true, labelKey: 'lienholderCorporateName' },
    {
      appChoicesKey: 'insuranceCompanies',
      formKey: 'insuranceCompany',
      isObject: true,
      labelKey: 'lienholderCorporateName'
    },
    {
      appChoicesKey: 'Optional Equipment And Accessories',
      formKey: 'optionalEquipmentAndAccessories',
      type: 'table-input',
      formAttribute: 'value'
    },
    {
      appChoicesKey: 'Retailer Options',
      formKey: 'retailerOptions',
      type: 'table-input',
      formAttribute: 'value'
    },
    {
      appChoicesKey: 'Commission Options',
      formKey: 'commissionSheetOptions',
      type: 'table-input',
      formAttribute: 'value'
    },
    {
      appChoicesKey: 'Commission Less Options',
      formKey: 'commissionSheetLessOptions',
      type: 'table-input',
      formAttribute: 'value'
    }
  ];

  const additionalDocHubFieldsToTakeAppChoices = (appChoices.find(a => a.key === 'formFields')?.values || []).filter(
    v =>
      additionalDocHubfieldTypesWithDropdowns.includes(v.type) &&
      v.model === 'Contact' &&
      v.takeOptionsFrom === 'appChoice'
  );

  requiredChoices.push(
    ...additionalDocHubFieldsToTakeAppChoices.map(f => ({
      appChoicesKey: f.optionAppChoiceName,
      formKey: `${f.key}-additionalDochubField`
    }))
  );

  requiredChoices
    .filter(c => !ignoreTableFields || c.type !== 'table-input')
    .forEach(({ appChoicesKey, isObject, formKey, type, labelKey = 'name', formAttribute = 'options' }) => {
      const choice = appChoices?.find(a => a.key === appChoicesKey);

      if (!choice) return;

      if (isObject) {
        setAttribute(form, formKey, 'options', choice.values.map(c => c[labelKey]));
        setAttribute(form, formKey, 'optionValues', choice.values.map(c => c._id));
        return;
      }

      setAttribute(
        form,
        formKey,
        formAttribute,
        type
          ? choice?.values
              .map(value =>
                type === 'table-input'
                  ? [typeof value === 'object' ? value.name : value, '']
                  : { key: typeof value === 'object' ? value.name : value, type: 'number' }
              )
              ?.slice(0, type === 'table-input' ? 10 : choice?.values?.length)
          : choice.values,
        choice?.values
          ? choice.values.map(value =>
              type === 'table-input'
                ? [typeof value === 'object' ? value.name : value]
                : { key: typeof value === 'object' ? value.name : value, type: 'number' }
            )
          : choice.values
      );
    });
};

//used after submit
export const validateAndCleanupCRMForm = submittedForm => {
  if (submittedForm['sameMailingAddress']) {
    submittedForm['mailingAddress'] = submittedForm['propertyAddress'];
    submittedForm['mailingZip'] = submittedForm['zipCode'];
    submittedForm['mailingState'] = submittedForm['propertyState'];
    submittedForm['mailingCity'] = submittedForm['propertyCity'];
    submittedForm['mailingCounty'] = submittedForm['propertyCounty'];
  }

  convertFormToKeyValueObject(submittedForm);

  //for additional dochub fields created by user
  const additionalFields = Object.keys(submittedForm).filter(k => k.endsWith('-additionalDochubField'));
  submittedForm['additionalFields'] = additionalFields.reduce(
    (acc, key) => ({ ...acc, [key.replace('-additionalDochubField', '')]: submittedForm[key] }),
    {}
  );

  return true;
};
