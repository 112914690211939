import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { TextInput } from '../../form-generator/components';
import { makeApiRequests } from '../../helpers/api';
import { UNIVERSAL_SEARCH_ROUTE } from '../../helpers/constants';
import HorizontalProgress from '../common/HorizontalProgress';
import GlobalSearchResults from './GlobalSearchResults';

const Index = ({
  onShowGlobalSearchValueChange,
  showGlobalSearch,
  globalSearchResults,
  onGlobalSearchResultsChange
}) => {
  const [globalSearchInProgress, setGlobalSearchInProgress] = useState(false);

  const [globalSearchQuery, setGlobalSearchQuery] = useState('');

  const [sectionsVisibility, setSectionsVisibility] = useState({
    contactResults: true,
    inventoryResults: true,
    contractResults: true
  });

  useEffect(() => {
    if (showGlobalSearch && document.getElementById('searchText')) {
      document.getElementById('searchText').focus();
    }
  }, [showGlobalSearch]);

  const onExecuteGlobalSearch = async () => {
    if (!globalSearchQuery) return toast.info('Please Type Something...');
    setGlobalSearchInProgress(true);

    const { response, error } = await makeApiRequests({
      endpoint: UNIVERSAL_SEARCH_ROUTE,
      requestBody: { query: globalSearchQuery, pageSize: 10 },
      method: 'POST'
    });

    setGlobalSearchInProgress(false);

    if (error) {
      toast.error(error);
      return;
    }

    onGlobalSearchResultsChange({ ...response });
  };

  const onSectionsVisibilityChange = (key, value) => {
    sectionsVisibility[key] = value;
    setSectionsVisibility({ ...sectionsVisibility });
  };

  const onGlobalSearchSearchClose = () => {
    onShowGlobalSearchValueChange(false);
    setGlobalSearchQuery('');
    setGlobalSearchInProgress(false);
  };

  return (
    <Modal size="lg" show={showGlobalSearch} onHide={onGlobalSearchSearchClose}>
      <Modal.Header closeButton>
        <Modal.Title>Global Search</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="input-group mb-1">
          <TextInput
            disabled={globalSearchInProgress}
            id="searchText"
            hint="Type here..."
            value={globalSearchQuery}
            onKeyPress={e => e.key === 'Enter' && onExecuteGlobalSearch()}
            onChange={e => setGlobalSearchQuery(e.target.value)}
            size="sm"
          />
          <div className={`input-group-append`}>
            <Button size="sm" variant="primary" onClick={onExecuteGlobalSearch} disabled={globalSearchInProgress}>
              <Search className="align-text-top mr-2" size={16} />
              Search
            </Button>
          </div>
        </div>

        {/* Search Results */}

        {globalSearchInProgress && (
          <div className={`my-4 pb-5`}>
            <HorizontalProgress text="Loading..." />
          </div>
        )}

        {globalSearchResults ? (
          <>
            {!globalSearchInProgress && (
              <GlobalSearchResults
                globalSearchResults={globalSearchResults}
                globalSearchQuery={globalSearchQuery}
                sectionsVisibility={sectionsVisibility}
                onSectionsVisibilityChange={onSectionsVisibilityChange}
              />
            )}
          </>
        ) : (
          <>
            {!globalSearchInProgress && (
              <div className="my-4 pb-5">
                <h6 className="text-center">
                  Please Click On Search Icon or Press <kbd className="bg-light text-dark">Enter</kbd> To Search
                </h6>
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Index;
