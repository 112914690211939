import _, { uniqueId } from 'lodash'; // assuming you have lodash installed
import React, { useMemo } from 'react';
import { Button, Col, FormControl, Row } from 'react-bootstrap';
import { Clipboard, PlusCircle, Trash } from 'react-bootstrap-icons';
import {
  appChoicesKeys,
  additionalDocHubfieldTypes,
  additionalDocHubfieldTypesWithDropdowns
} from '../../../helpers/constants';
import TextButton from '../../TextButton';
import { copyToClipboard } from '../../../helpers/global';
import { toast } from 'react-toastify';

const Field = ({ field, onChange, onDelete, fieldIndex }) => {
  const addOption = () => {
    onChange({ ...field, options: [...field.options, { label: '', value: '', uuid: uniqueId() }] });
  };

  const changeOption = (uuid, newOption) => {
    onChange({ ...field, options: field.options.map(option => (option.uuid === uuid ? newOption : option)) });
  };

  const deleteOption = uuid => {
    onChange({ ...field, options: field.options.filter(option => option.uuid !== uuid) });
  };

  const changeOptionType = newType => {
    onChange({
      ...field,
      takeOptionsFrom: newType,
      options: [{ label: '', value: '', uuid: uniqueId() }],
      optionAppChoiceName: appChoicesKeys[0]
    });
  };

  const docsRefrence = useMemo(() => `{{ additionalFields | ${field.key || '-'} }}`, [field]);

  return (
    <div className="d-flex mt-2">
      <div className="px-1">
        <b>{fieldIndex + 1}. </b>
      </div>
      <div className="flex-grow-1 pr-2">
        <Row className="mx-0 mb-3 border rounded px-1 py-2">
          <Col md={3}>
            <h6 className="midFont">Dochub Reference:</h6>
            <div
              className="d-flex flex-wrap justify-content-between align-items-center bg-primary-light rounded p-2 pointer"
              onClick={() => {
                copyToClipboard(docsRefrence);
                toast.success('Copied to clipboard');
              }}
            >
              <h6 className="midFont mb-0">{docsRefrence}</h6>
              <Clipboard className="pointer" onClick={() => navigator.clipboard.writeText(docsRefrence)} />
            </div>
          </Col>
          <Col md={2}>
            <h6 className="midFont">Label:</h6>
            <FormControl
              size="sm"
              type="text"
              placeholder="Label"
              value={field.label}
              onChange={e => onChange({ ...field, label: e.target.value, key: _.camelCase(e.target.value) })}
            />
          </Col>
          <Col md={2}>
            <h6 className="midFont">Field Type:</h6>
            <FormControl
              size="sm"
              as={'select'}
              value={field.type}
              onChange={e => onChange({ ...field, type: e.target.value })}
            >
              {additionalDocHubfieldTypes.map(type => (
                <option key={type} value={type}>
                  {_.startCase(type)}
                </option>
              ))}
            </FormControl>
          </Col>

          {additionalDocHubfieldTypesWithDropdowns.includes(field.type) && (
            <Col md={3}>
              <h6 className="midFont">Take Options From:</h6>
              <FormControl
                size="sm"
                as={'select'}
                value={field.takeOptionsFrom}
                onChange={e => {
                  changeOptionType(e.target.value);
                }}
              >
                <option value="appChoice">AppChoice</option>
                <option value="manual">Manual</option>
              </FormControl>
            </Col>
          )}

          {additionalDocHubfieldTypesWithDropdowns.includes(field.type) && (
            <Col md={field.takeOptionsFrom === 'manual' ? 12 : 3}>
              {field.takeOptionsFrom === 'manual' ? (
                <>
                  <h6 className="midFont">Provide Options:</h6>
                  <div className="border rounded p-2 bg-light">
                    {field.options.length > 0 ? (
                      field.options.map((option, i) => (
                        <div key={option.uuid} className="d-inline-flex align-items-center mb-1 w-50">
                          <div className="px-2">{i + 1}.</div>
                          <div className="flex-grow-1">
                            <FormControl
                              className="d-inline-block w-50"
                              size="sm"
                              type="text"
                              placeholder="Label"
                              value={option.label}
                              onChange={e => changeOption(option.uuid, { ...option, label: e.target.value })}
                            />
                            <FormControl
                              className="d-inline-block w-50"
                              size="sm"
                              type="text"
                              placeholder="Value"
                              value={option.value}
                              onChange={e => changeOption(option.uuid, { ...option, value: e.target.value })}
                            />
                          </div>
                          <div className="px-2">
                            <Trash
                              size={14}
                              className="hover-light text-danger"
                              onClick={() => deleteOption(option.uuid)}
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <h6 className="midFont mb-0 text-center text-muted">No Options Provided!</h6>
                    )}
                  </div>
                  <div className="text-right">
                    <TextButton Icon={PlusCircle} text="Add Option" variant="success" onClick={addOption} />
                  </div>
                </>
              ) : (
                <>
                  <h6 className="midFont">App Choice Name:</h6>
                  <FormControl
                    as={'select'}
                    size="sm"
                    type="text"
                    placeholder="AppChoice"
                    value={field.optionAppChoiceName}
                    onChange={e => onChange({ ...field, optionAppChoiceName: e.target.value })}
                  >
                    {appChoicesKeys.map(a => (
                      <option key={a} value={a}>
                        {a}
                      </option>
                    ))}
                  </FormControl>
                </>
              )}
            </Col>
          )}
        </Row>
      </div>
      <div>
        <Button size="sm" variant="danger" onClick={onDelete}>
          <Trash />
        </Button>
      </div>
    </div>
  );
};

export default Field;
