import React from 'react';
import { Modal } from 'react-bootstrap';
import SiteMap from './SiteMap';

const SiteMapPopup = ({ site, show, onHide, onSiteUpdate }) => {
  return (
    <Modal className="fullscreen-modal" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header className="p-2" closeButton>
        <Modal.Title>
          <h6 className="mb-0">Site Map ({site?.name})</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">{site && <SiteMap site={site} onSiteUpdate={onSiteUpdate} />}</Modal.Body>
    </Modal>
  );
};

export default SiteMapPopup;
