import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Modal, ProgressBar } from 'react-bootstrap';
import { Pen, PlusCircle, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { makeApiRequests } from '../../helpers/api';
import AlertModal from '../AlertModal';
import Loader from '../Loader';
import EntityTable from '../common/EntityTable';
import Tabs from '../common/Tabs';
import { ENDPOINTS, appChoicesKeys, hasDefaulValues } from '../../helpers/constants';
import CircularProgressBar from '../circular-progress';

const getForm = ({ type = 'Add', key }) => ({
  forms: [
    {
      name: `${type} appchoice`,
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: type,
        show: true,
        onSubmit: type === 'Add' ? 'onNewAppChoiceFormSubmit' : 'onUpdateAppChoiceFormSubmit'
      },
      rows: [
        {
          columns: hasDefaulValues(key)
            ? [
                {
                  default: 6,
                  field: {
                    id: 'option',
                    title: `${type === 'Add' ? 'New' : 'Update'} Option`,
                    type: 'text',
                    required: true
                  }
                },
                {
                  default: 6,
                  field: {
                    id: 'defaultValue',
                    title: `${type === 'Add' ? 'New' : 'Update'} Default Value`,
                    type: 'number',
                    required: true
                  }
                }
              ]
            : [
                {
                  default: 12,
                  field: {
                    id: 'option',
                    title: `${type === 'Add' ? 'New' : 'Update'} Option`,
                    type: 'text',
                    required: true
                  }
                }
              ]
        }
      ]
    }
  ]
});

const AddEditModal = ({ show, toBeEditedOption, onHide, showProgress, addForm, updateForm, activeAppChoiceKey }) => (
  <Modal show={show} onHide={onHide} centered backdrop="static">
    <Modal.Header closeButton={!showProgress}>
      <Modal.Title>
        <h6 className="mb-0">
          {toBeEditedOption ? `Update Option For ${activeAppChoiceKey}` : `Add New Option For ${activeAppChoiceKey}`}
        </h6>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="overflow-auto px-1">
      <FormGenerator
        formJson={toBeEditedOption ? updateForm : addForm}
        formValues={toBeEditedOption ? { 'Update appchoice': toBeEditedOption } : {}}
      />
      {showProgress && (
        <ProgressBar
          now={100}
          animated
          striped
          label={toBeEditedOption ? `Updating ${activeAppChoiceKey}...` : `Saving ${activeAppChoiceKey}....`}
        />
      )}
    </Modal.Body>
  </Modal>
);

const AppChoices = () => {
  const [loading, setLoading] = useState(false);
  const [appChoices, setAppChoices] = useState([]);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [toBeDeletedOption, setToBeDeletedOption] = useState(null);
  const [appChoiceActionMeta, setAppChoiceActionMeta] = useState(null);
  const [appChoiceAddingUpdating, setAppChoiceAddingUpdating] = useState(false);
  const [activeKey, setActiveKey] = useState(appChoicesKeys[0]);
  const [activeOptions, setActiveOptions] = useState([]);
  const [isAppChoiceUpdating, setAppChoiceUpdating] = useState(false);

  const fetchAppChoices = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.APP_CHOICES_LIST,
      method: 'POST'
    });

    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setAppChoices(response);
  };

  useEffect(() => {
    fetchAppChoices();
  }, []);

  useEffect(() => {
    const appChoice = appChoices.find(ac => ac.key === activeKey);
    if (!appChoice) {
      setActiveOptions([]);
    } else {
      setActiveOptions(
        appChoice.values.map(v =>
          hasDefaulValues(activeKey)
            ? { option: v.name, defaultValue: v.defaultValue ? Number(v.defaultValue) : 0 }
            : { option: v }
        )
      );
    }
  }, [activeKey, appChoices]);

  useEffect(() => {
    //update cache
    if (appChoices.length > 0) {
      localStorage.setItem('app-choices', JSON.stringify(appChoices));
    }
  }, [appChoices]);

  const deleteAppChoice = async () => {
    setDeleteInProgress(true);

    const appChoiceObject = appChoices.find(ac => ac.key === activeKey);

    const newValues = appChoiceObject.values.filter(value =>
      hasDefaulValues(activeKey) ? value.name !== toBeDeletedOption.option : value !== toBeDeletedOption.option
    );
    const success = await updateAppChoice(appChoiceObject, newValues);
    if (!success) return;

    setToBeDeletedOption(null);
    toast.success('Option deleted!');
  };

  const onNewAppChoiceFormSubmit = async form => {
    const { option, defaultValue: defaultValueString } = form;
    const defaultValue = defaultValueString ? Number(defaultValueString) : 0;
    const appChoiceObject = appChoices.find(ac => ac.key === activeKey);

    if (
      hasDefaulValues(activeKey)
        ? appChoiceObject?.values.map(v => v.name).includes(option)
        : appChoiceObject?.values.includes(option)
    ) {
      return toast.error('Option already added!');
    }

    setAppChoiceAddingUpdating(true);

    const newValues = [
      ...(appChoiceObject?.values || []),
      hasDefaulValues(activeKey) ? { name: option, defaultValue } : option
    ];
    const success = await updateAppChoice(appChoiceObject, newValues);
    if (!success) return;

    setAppChoiceActionMeta(null);
    toast.success('Option added!');
  };

  const onUpdateAppChoiceFormSubmit = async form => {
    const { option, defaultValue: defaultValueString } = form;
    const defaultValue = defaultValueString ? Number(defaultValueString) : 0;
    const { option: optionToEdit } = appChoiceActionMeta;
    const appChoiceObject = appChoices.find(ac => ac.key === activeKey);

    if (
      hasDefaulValues(activeKey)
        ? appChoiceObject.values
            .map(v => v.name)
            .filter(v => v !== optionToEdit.option)
            .includes(option)
        : appChoiceObject.values.filter(v => v !== optionToEdit.option).includes(option)
    ) {
      return toast.error('Option already added!');
    }

    setAppChoiceAddingUpdating(true);

    const newValues = appChoiceObject.values.map(value =>
      hasDefaulValues(activeKey)
        ? value.name === optionToEdit.option
          ? { name: option, defaultValue }
          : value
        : value === optionToEdit.option
        ? option
        : value
    );
    const success = await updateAppChoice(appChoiceObject, newValues);
    if (!success) return;

    setAppChoiceActionMeta(null);
    toast.success('Option updated!');
  };

  const updateAppChoice = async (appChoiceObject, newValues) => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.APP_CHOICES_WITH_ID(appChoiceObject?._id),
      requestBody: {
        values: newValues
      },
      method: 'PUT'
    });

    setAppChoiceAddingUpdating(false);
    setDeleteInProgress(false);
    if (error) {
      toast.error(error);
      return;
    }

    appChoiceObject.values = response.values;
    setAppChoices([...appChoices]);
    return true;
  };

  window['onNewAppChoiceFormSubmit'] = onNewAppChoiceFormSubmit;
  window['onUpdateAppChoiceFormSubmit'] = onUpdateAppChoiceFormSubmit;

  const onAppChoiceSort = async sortedList => {
    const appChoiceObject = appChoices.find(ac => ac.key === activeKey);
    const appChoiceHasDefaultValues = hasDefaulValues(activeKey);

    setAppChoiceUpdating(true);

    const newValues = sortedList.map(({ option }) =>
      appChoiceObject.values.find(a => (appChoiceHasDefaultValues ? a.name === option : a === option))
    );
    const success = await updateAppChoice(appChoiceObject, newValues);
    setAppChoiceUpdating(false);

    if (!success) return;

    toast.success('Options sorted!');
  };

  return (
    <Container fluid className="h-100 py-3 px-md-3">
      <Card>
        <Card.Body>
          <h6>App Choices</h6>
          <hr className="my-3" />
          {loading ? (
            <Loader />
          ) : (
            <>
              <Tabs
                tabs={appChoicesKeys}
                activeTab={activeKey}
                onTabSelect={setActiveKey}
                scrollable
                numOfScrollableRows={2}
              />
              <EntityTable
                draggable
                onListSort={onAppChoiceSort}
                rowKeyField={'option'}
                data={activeOptions}
                headers={
                  hasDefaulValues(activeKey)
                    ? [
                        { name: 'Options', key: 'option' },
                        { name: 'Default Value', key: 'defaultValue', type: 'number' }
                      ]
                    : [{ name: 'Options', key: 'option' }]
                }
                searchQueryKeys={['option']}
                additionalComponents={() => (
                  <>
                    <Button
                      disabled={isAppChoiceUpdating}
                      size="sm"
                      variant="success"
                      onClick={() => setAppChoiceActionMeta({ mode: 'add' })}
                    >
                      <PlusCircle className="mr-1" />
                      Add New Option For {activeKey}
                    </Button>
                    {isAppChoiceUpdating && <CircularProgressBar />}
                  </>
                )}
                actionCell={option => (
                  <div>
                    <Button
                      disabled={isAppChoiceUpdating}
                      variant="outline-primary"
                      className="px-1 py-0 mr-1"
                      onClick={() => setAppChoiceActionMeta({ option, mode: 'edit' })}
                    >
                      <Pen size={10} />
                    </Button>
                    <Button
                      disabled={isAppChoiceUpdating}
                      variant="outline-danger"
                      className="px-1 py-0 "
                      onClick={() => setToBeDeletedOption(option)}
                    >
                      <Trash size={10} />
                    </Button>
                  </div>
                )}
              />
              {appChoices.length > 0 && (
                <AlertModal
                  show={toBeDeletedOption !== null}
                  alertText={`Are you sure you want to delete this option (${
                    toBeDeletedOption ? toBeDeletedOption.option : ''
                  })? This action cannot be undone!`}
                  onHide={() => setToBeDeletedOption(null)}
                  onDismissClick={() => setToBeDeletedOption(null)}
                  onContinueClick={deleteAppChoice}
                  progressText={`Deleting option...`}
                  showProgress={deleteInProgress}
                />
              )}
              <AddEditModal
                show={appChoiceActionMeta !== null}
                onHide={() => setAppChoiceActionMeta(null)}
                toBeEditedOption={appChoiceActionMeta ? appChoiceActionMeta.option : null}
                showProgress={appChoiceAddingUpdating}
                addForm={getForm({ type: 'Add', key: activeKey })}
                updateForm={getForm({ type: 'Update', key: activeKey })}
                activeAppChoiceKey={activeKey}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AppChoices;
