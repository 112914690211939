import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Download, X } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { readFile } from '../../helpers/api';
import { downloadFileFromBase64, humanFileSize } from '../../helpers/global';
import CircularProgressBar from './circular-progress';
import FileRenderer from './FileRenderer';

const FilePopup = ({ file, onHide }) => {
  const [fileHref, setFileHref] = useState();

  const getFileHREF = async () => {
    const { response, error } = await readFile({
      fileName: file.filename,
      mimeType: file.contentType
    });
    if (response) {
      setFileHref(response);
    } else {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (file) getFileHREF();
  }, [file]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'fixed',
        zIndex: 20000,
        left: 0,
        top: 0,
        overflowX: 'hidden'
      }}
      className="bg-light p-3 p-md-5 d-flex justify-content-center"
    >
      {!fileHref ? (
        <div className="align-self-center text-center">
          <CircularProgressBar size={4} />
          <h6 className="text-muted midFont mt-3">
            <i>Loading file ({humanFileSize(file.length)})...</i>
          </h6>
        </div>
      ) : (
        <FileRenderer fileType={file.contentType} src={fileHref} />
      )}

      <Button
        style={{
          position: 'absolute',
          right: 0,
          top: 0
        }}
        size="sm"
        className="m-2"
        onClick={() => {
          setFileHref();
          onHide();
        }}
      >
        <X size={18} />
      </Button>

      {fileHref && (
        <Button
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0
          }}
          size="sm"
          className="m-2"
          onClick={() => {
            downloadFileFromBase64({
              data: fileHref,
              fileName: file.metadata && file.metadata.originalName ? file.metadata.originalName : file.filename
            });
          }}
        >
          <Download size={18} />
          <span className="align-middle ml-2">Download</span>
        </Button>
      )}
    </div>
  );
};

export default FilePopup;
