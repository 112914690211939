import React, { useState } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import CachedResponse from '../common/CachedResponse';
import NotFound from '../NotFound';
import InstallerDetails from './InstallerDetails';
import SearchInstaller from './SearchInstaller';

const Installer = () => {
  const [role] = useState(localStorage.getItem('user-role'));

  const getActivePage = () => {
    return (
      <Switch>
        <Route exact path="/setup/search">
          <CachedResponse
            listEndpoint={'app-choices'}
            render={appChoices => <SearchInstaller appChoices={appChoices} />}
          />
        </Route>
        <Route exact path="/setup/:id">
          <InstallerDetails />
        </Route>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  };

  if (role !== 'installer') {
    return <NotFound text="You are not authorized to view this page" />;
  }

  return getActivePage();
};

export default withRouter(Installer);
