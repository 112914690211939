import React, { useState } from 'react';
import { Route, Switch } from 'react-router';
import NotFound from '../NotFound';
import CreateCrm from './CreateCrm';
import CRMDetails from './CrmDetails';
import SearchCrm from './SearchCrm';
import CachedResponse from '../common/CachedResponse';

const CRM = () => {
  const [role] = useState(localStorage.getItem('user-role'));

  const getActivePage = () => {
    return (
      <Switch>
        <Route exact path="/crm/create">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <CreateCrm appChoices={appChoices} />} />
        </Route>
        <Route exact path="/crm/search">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <SearchCrm appChoices={appChoices} />} />
        </Route>
        <Route exact path="/crm/:id">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <CRMDetails appChoices={appChoices} />} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  };

  if (!['sales manager', 'sales', 'super admin', 'admin'].includes(role)) {
    return <NotFound text="You are not authorized to view this page" />;
  }

  return getActivePage();
};

export default CRM;
