import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import FormGenerator from '../../form-generator/FormGenerator';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import File from '../common/file';
import { walkthroughFormJson as form } from './form';

const WalkThroughCheckList = ({ contract, fromConfirmation = false, confirmCheckList, hideButtons = false }) => {
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [preFillData, setPreFillData] = useState(null);
  const [clientApproved, setClientApproved] = useState(false);
  const [clientApprovalPending, setClientApprovalPending] = useState(false);
  const [status, setStatus] = useState('');

  const [finish, setFinish] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    if (!contract['walkthroughReport']) {
      setLoading(false);
      return;
    }

    setClientApproved(contract['walkthoughStatus'] === 'Client Approved');
    setClientApprovalPending(contract['walkthoughStatus'] === 'Client Approval Pending');
    setStatus(contract['walkthoughStatus']);

    const tempData = { ...contract['walkthroughReport'] };
    const otherRows = tempData['other'].filter(other => other.key !== 'notes');
    const otherNotes = tempData['other'].filter(other => other.key === 'notes')[0]['value'];

    tempData['other'] = { rows: otherRows, notes: otherNotes };
    tempData['Files'] = [];

    setPreFillData(tempData);

    setLoading(false);
  }, []);

  //TODO manage files
  const onWalkThroughCheckListSubmit = async report => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    toast.info('Please wait, updating report...');
    report['other'] = report['other'].filter(obj => obj['key'].trim());
    report['partsNeededForPendingService'] = report['partsNeededForPendingService'].filter(obj => obj.trim());

    const formData = {
      walkthroughReport: report,
      files: [...contract['files'].map(f => f._id), ...uploadedFiles.map(f => f.id)]
    };

    setButtonsDisabled(true);

    if (finish) {
      formData['uuid'] = uuidv4();
    }

    const { response, error } = await makeApiRequests({
      requestBody: formData,
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(id)
    });

    setButtonsDisabled(false);

    if (error) {
      return toast.error(error);
    }

    toast.success('Report updated successfully!');
    setTimeout(() => document.location.reload(), 1000);
  };

  window['onWalkThroughCheckListSubmit'] = onWalkThroughCheckListSubmit;

  if (loading) return '';

  const handleClose = () => {
    setShowConfirmModal(false);
  };

  const ConfirmModal = () => {
    return (
      <Modal show={showConfirmModal} onHide={handleClose} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to finish the report?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              setFinish(true);
              setShowConfirmModal(false);
              setTimeout(() => {
                document.getElementById('walkthroughchecklist').click();
              }, 100);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      {!fromConfirmation && ConfirmModal()}
      {status && (
        <>
          <Row className="text-center p-1">
            <Col>
              <b>Walkthrough Status: </b> {status}
            </Col>
          </Row>
          <hr />
        </>
      )}
      <FormGenerator
        formJson={form}
        formValues={{ 'WalkThrough Checklist': preFillData }}
        formDisabled={fromConfirmation}
      />
      <File
        metaData={{ walkthrough: true }}
        uploadedFiles={uploadedFiles}
        onUploadedFilesChange={setUploadedFiles}
        containerClassName="mx-3"
      />

      {!clientApproved && !hideButtons && (
        <Row>
          <Col xs={12} className="text-right">
            <Button
              onClick={async () => {
                if (fromConfirmation) {
                  setButtonsDisabled(true);
                  await confirmCheckList();
                  setButtonsDisabled(false);
                  return;
                }

                setFinish(false);
                setTimeout(() => {
                  document.getElementById('walkthroughchecklist').click();
                }, 100);
              }}
              variant="outline-info"
              className="mr-2"
              disabled={buttonsDisabled}
            >
              {fromConfirmation ? 'Confirm' : 'Save'}
            </Button>

            {!fromConfirmation && !clientApprovalPending && (
              <Button
                onClick={() => {
                  setShowConfirmModal(true);
                }}
                variant="primary"
                disabled={buttonsDisabled}
              >
                Finish
              </Button>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default WalkThroughCheckList;
