import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { TextInput } from '../form-generator/components';

const SearchShortcut = () => {
  const history = useHistory();
  const [query, setQuery] = useState('');

  const handleReturn = e => {
    if (e.key === 'Enter') {
      goToSearch();
    }
  };

  const goToSearch = () => {
    history.push(`/contract/search?q=${query}`);
  };

  return (
    <Row>
      <Col xs={12}>
        <div className="input-group mb-3">
          <TextInput
            id="seachText"
            size={'sm'}
            hint="Search more contracts..."
            onChange={e => {
              setQuery(e.target.value);
            }}
            onKeyPress={handleReturn}
          />
          <div className="input-group-append">
            <Button variant="outline-info" size="sm" onClick={goToSearch} disabled={query.length === 0}>
              <Search className="align-text-top mr-2" size={16} />
              Search
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SearchShortcut;
