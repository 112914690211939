import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useLocation, withRouter } from 'react-router';
import Admin from '../components/admin';
import CachedResponse from '../components/common/CachedResponse';
import VideoPlayer from '../components/common/VideoPlayer';
import CRM from '../components/crm';
import DocHub from '../components/doc-hub/DocHub';
import GlobalSearch from '../components/globalSearch/index';
import Header from '../components/Header';
import Installer from '../components/installer/Installer';
import Inventory from '../components/inventory';
import Contract from '../components/main';
import NotFound from '../components/NotFound';
import Ops from '../components/ops/Ops';
import PipelineRoute from '../components/pipeline';
import Profile from '../components/profile';
import Reports from '../components/reports/Reports';
import Requests from '../components/requests/Requests';
import Schedule from '../components/schedule/Schedule';
import Utilities from '../components/utilities/Utilities';
import { makeApiRequests } from '../helpers/api';
import { ENDPOINTS } from '../helpers/constants';
import { findSpecificSectionVideo } from '../helpers/global';
import { saveUserToLocal } from '../helpers/session';
import useKeypress from '../hooks/KeyPressHook';
import SalesLocations from '../components/admin/SalesLocation';
import SiteMap from '../components/inventory/SiteMap';

const Home = ({ appChoices }) => {
  const location = useLocation();
  const [currentSectionVideoMeta, setCurrentSectionVideoMeta] = useState({});
  const [openVideoplayer, setOpenVideoPlayer] = useState(null);
  const [showGlobalSearch, setShowGlobalSearch] = useState(false);
  const [globalSearchResults, setGlobalSearchResults] = useState(null);

  const onShowGlobalSearchValueChange = value => {
    setShowGlobalSearch(value);
    if (value === false) {
      setGlobalSearchResults(null);
    }
  };
  const onOpenVideoPlayerChange = value => {
    setOpenVideoPlayer(value);
  };

  const onOpenVideoPlayerClose = () => {
    setOpenVideoPlayer(null);
  };

  const getAuth = async () => {
    const { response: authResult, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_ME,
      method: 'GET'
    });

    if (authResult) {
      saveUserToLocal(authResult, false);
    }
  };

  const featuredVideoMeta = useMemo(() => {
    const videoUrls = appChoices
      ?.find(appChoice => appChoice.key === 'sectionVideos')
      ?.values?.find(videoData => videoData?.name === 'Featured Videos');

    return videoUrls;
  }, [location.pathname, appChoices]);

  useEffect(() => {
    if (localStorage.getItem('user-token')) getAuth();
  }, []);

  useEffect(() => {
    const videoUrls = appChoices.find(appChoice => appChoice.key === 'sectionVideos');
    setCurrentSectionVideoMeta(findSpecificSectionVideo(videoUrls?.values, location.pathname));
  }, [location.pathname]);

  // If two keys are clicked simultanously the first key must be [ctrl,alt,shift] key

  useKeypress(['ctrl', 'q'], () => {
    onShowGlobalSearchValueChange(true);
  });

  if (!localStorage.getItem('user-token')) {
    return <Redirect from="/" to="/login" />;
  }

  if (location.pathname === '/') {
    const role = localStorage.getItem('user-role');

    const roleToDefaultRoute = {
      service: '/service-requests/browse',
      sales: '/crm/search',
      'sales manager': '/crm/search',
      admin: '/pipeline',
      'super admin': '/pipeline'
    };

    return <Redirect from="/" to={roleToDefaultRoute[role] || '/contract/search'} />;
  }

  return (
    <>
      <GlobalSearch
        onShowGlobalSearchValueChange={onShowGlobalSearchValueChange}
        showGlobalSearch={showGlobalSearch}
        globalSearchResults={globalSearchResults}
        onGlobalSearchResultsChange={setGlobalSearchResults}
      />

      <Header
        currentSectionVideoMeta={currentSectionVideoMeta}
        featuredVideoMeta={featuredVideoMeta}
        onOpenVideoPlayerChange={onOpenVideoPlayerChange}
      />

      {openVideoplayer && (
        <VideoPlayer
          videoLinks={openVideoplayer === 'section' ? currentSectionVideoMeta?.videoUrls : featuredVideoMeta?.videoUrls}
          title={openVideoplayer === 'section' ? currentSectionVideoMeta?.name : 'About This App'}
          onPlayerClose={onOpenVideoPlayerClose}
          openVideoplayer={openVideoplayer}
        />
      )}

      <Switch>
        <Route exact path="/profile">
          <Profile />
        </Route>
        <Route path="/contract">
          <Contract />
        </Route>
        <Route path="/service-requests">
          <Requests />
        </Route>
        <Route path="/dochub">
          <DocHub />
        </Route>
        <Route path="/crm">
          <CRM />
        </Route>
        <Route path="/inventory">
          <Inventory />
        </Route>
        <Route path="/walkthrough">
          <Ops />
        </Route>

        <Route path="/setup">
          <Installer />
        </Route>
        <Route path="/reports">
          <Reports />
        </Route>
        {/* View This */}
        <Route path="/schedule">
          <CachedResponse listEndpoint={'app-choices'} render={a => <Schedule appChoices={a} />} />
        </Route>
        <Route path="/pipeline">
          <PipelineRoute />
        </Route>
        <Route path="/utilities">
          <Utilities />
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default withRouter(Home);
