import React from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import InventorySearchItem from './InventorySearchItem';

const NoUnitContacted = ({ onLinkUnitClick, disabled }) => {
  return (
    <div className="text-center ">
      <h6 className="smallFont">
        No unit has been linked to this contact.
        {!disabled && 'Tap on the button below to link one:'}
      </h6>
      {!disabled && (
        <Button className="tinyFont" variant="success" size="sm" onClick={onLinkUnitClick}>
          Link Unit
        </Button>
      )}
    </div>
  );
};

const PotentialUnitsOverview = ({
  inventoryUnits = [],
  onLinkNewUnitClick,
  onRemoveUnitClick,
  onChangeUnitClick,
  onEditUnitClick,
  activeUnitId,
  onUnitSelect,
  onRefreshUnitClick,
  refreshing,
  disabled,
  multiple = true
}) => {
  return (
    <>
      <div className="d-flex mb-2">
        <div className="flex-grow-1 align-self-center">
          <h6 className="mb-0">Potential Unit{multiple ? 's' : ''}</h6>
          {onUnitSelect && <h6 className="mb-0 text-muted smallFont">Tap on a unit to select.</h6>}
        </div>
        {multiple && inventoryUnits.length > 0 && (
          <div className="w-25 align-self-center text-right">
            {!refreshing ? (
              !disabled && (
                <>
                  {onRefreshUnitClick && (
                    <Button variant="outline-primary" size="sm" className="ml-1 tinyFont" onClick={onRefreshUnitClick}>
                      <ArrowClockwise className="" size={13} />
                    </Button>
                  )}
                  <Button className="tinyFont ml-1" variant="success" size="sm" onClick={() => onLinkNewUnitClick()}>
                    Link New Unit
                  </Button>
                </>
              )
            ) : (
              <ProgressBar variant="primary" now={100} animated striped label="Refreshing" />
            )}
          </div>
        )}
      </div>
      <div className="border rounded p-3 mb-2">
        {inventoryUnits.length > 0 ? (
          inventoryUnits.map(inventoryUnit => (
            <InventorySearchItem
              key={inventoryUnit['_id']}
              inventory={inventoryUnit}
              onEditClick={onEditUnitClick ? () => onEditUnitClick(inventoryUnit) : undefined}
              onRemoveClick={onRemoveUnitClick ? () => onRemoveUnitClick(inventoryUnit) : undefined}
              onChangeClick={onChangeUnitClick ? () => onChangeUnitClick(inventoryUnit) : undefined}
              onInventoryClick={onUnitSelect ? () => onUnitSelect(inventoryUnit) : undefined}
              selected={activeUnitId === inventoryUnit['_id']}
              mini
              disabled={disabled}
            />
          ))
        ) : (
          <NoUnitContacted
            disabled={disabled}
            onLinkUnitClick={onLinkNewUnitClick ? () => onLinkNewUnitClick() : undefined}
          />
        )}
      </div>
    </>
  );
};

export default PotentialUnitsOverview;
