import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, FormControl, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DropDownInput from '../../../form-generator/components/DropDownInput';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS, months } from '../../../helpers/constants';
import DateRangePicker from '../../DateRangePicker';
import Loader from '../../Loader';
import TextWithEdit from '../../TextWithEdit';

const getInitialDateRange = () => {
  const today = new Date();
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 2);
  return { startDate: startDate.toISOString(), endDate: today.toISOString() };
};

const BillbackExplorer = () => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typeOptions] = useState(['Requested', 'Received']);
  const [selectedType, setSelectedType] = useState('Requested');
  const [selectedDateRange, setSelectedDateRange] = useState(getInitialDateRange());
  const [query, setQuery] = useState('');

  const [filteredContracts, setFilteredContracts] = useState([]);

  const fetchContracts = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        filter: { 'billbacks.0': { $exists: true } }
      },
      endpoint: ENDPOINTS.CONTRACTS_SEARCH
    });

    setLoading(false);

    if (error) {
      return toast.error(error);
    }

    const contractResponse = response
      .filter(c => c['salesLocation'])
      .map(c => {
        const inventoryUnit = c['inventoryUnit'] || {};
        delete inventoryUnit['_id'];

        return { ...c, ...inventoryUnit };
      });

    const contractWithBillBack = [];

    for (var contract of contractResponse) {
      const billbacks = contract['billbacks'];
      billbacks.forEach((b, index) => {
        contractWithBillBack.push({ ...contract, Billback: { ...b, index } });
      });
    }
    setContracts(contractWithBillBack);
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const filterContracts = () => {
    const startDate = new Date(selectedDateRange.startDate).getTime();
    const endDate = new Date(selectedDateRange.endDate).getTime();

    const dateType = selectedType === typeOptions[0] ? 'dateBillbackRequested' : 'dateBillbackReceived';

    const tempContracts = contracts
      .filter(c => {
        if (!c['Billback'][dateType]) return false;

        if (!c.buyer.toLowerCase().includes(query.trim().toLowerCase())) {
          return false;
        }

        const billbackDate = new Date(c['Billback'][dateType]);
        return billbackDate.getTime() >= startDate && billbackDate.getTime() <= endDate;
      })
      .sort((a, b) => {
        const date1 = new Date(a[dateType]);
        const date2 = new Date(b[dateType]);

        return date1.getTime() - date2.getTime();
      });

    setFilteredContracts(tempContracts);
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    filterContracts();
  }, [contracts, selectedType, query, selectedDateRange]);

  const onTypeChange = () => {
    const type = document.getElementById('reportTypeSelect').value;
    setSelectedType(type);
  };

  const SelectionBox = () => {
    return (
      <>
        <Card className="border rounded">
          <Card.Header className="bg-primary">
            <h5 className="mb-0 text-white">Explore Billback</h5>
          </Card.Header>
          <Card.Body className="py-3">
            <Row>
              <Col xs={12} md={12} className="px-4 mt-3">
                <h6 className="mb-2">Report Type</h6>
                <DropDownInput
                  id="reportTypeSelect"
                  size="sm"
                  value={selectedType}
                  options={typeOptions}
                  onChangeFunction={onTypeChange}
                  required
                />
              </Col>
              <Col xs={12} className="px-4 mt-3">
                <h6 className="mb-2">Select Date Range ({selectedType})</h6>
                <DateRangePicker defaultDates={selectedDateRange} onDateChange={setSelectedDateRange} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onBillbackAmountRequiredInvalidation = contract => {
    toast.error('Amount is required!');
  };

  const onBillbackAmountSubmit = async (contract, amount) => {
    const parsedAmount = Number(amount);
    toast.info('Please wait updating amount...');

    const newBillBacks = [...contract['billbacks']];

    newBillBacks[contract['Billback']['index']]['amountBillbackReceived'] = parsedAmount;

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contract['_id']),
      requestBody: {
        billbacks: newBillBacks
      },
      method: 'PUT'
    });

    if (error) {
      toast.error(error);
      return;
    }

    const index = contracts.findIndex(
      c => c['_id'] === contract['_id'] && c['Billback']['index'] == contract['Billback']['index']
    );
    if (index !== -1) {
      contracts[index]['Billback'] = { ...contracts[index]['Billback'], amountBillbackReceived: parsedAmount };
      contracts[index]['billbacks'][contract['Billback']['index']]['amountBillbackReceived'] = parsedAmount;
    }

    setContracts(
      contracts.map(c => ({ ...c, billbacks: c['_id'] === contract['_id'] ? newBillBacks : c['billbacks'] }))
    );

    toast.success(`Amount for buyer ${contract['buyer']} updated successfully`);
  };

  return (
    <div className="px-0 px-md-5 py-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          {SelectionBox()}
          <hr />

          <h6>Billbacks:</h6>
          <FormControl
            size={'sm'}
            placeholder="Search by buyer..."
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
          <Table style={{ fontSize: 14 }} responsive bordered className="text-center mt-2">
            <tr className="bg-dark text-light">
              <th>Buyer</th>
              <td>Billback Number</td>
              <th>Sales Location</th>
              <th>Manufacturer</th>
              <th>Serial # A</th>
              <th>Serial # B</th>
              {selectedType === typeOptions[0] ? (
                <>
                  <th>Date Billback Requested</th>
                  <th>Received?</th>
                </>
              ) : (
                <>
                  <th>Date Billback Received</th>
                  <th>Amount Received</th>
                </>
              )}
            </tr>
            {filteredContracts.length > 0 ? (
              <>
                {filteredContracts.map(c => (
                  <tr>
                    <td>{c['buyer']}</td>
                    <td>{c['Billback']['index'] + 1}</td>
                    <td>{c['salesLocation']?.location}</td>
                    <td>{c['manufacturer'] ? c['manufacturer'].name : ''}</td>
                    <td>{c['serialA']}</td>
                    <td>{c['serialB']}</td>
                    {selectedType === typeOptions[0] ? (
                      <>
                        <td>{moment(c['Billback']['dateBillbackRequested']).format('MMMM Do YYYY')}</td>
                        <td>
                          {c['Billback']['dateBillbackReceived']
                            ? moment(c['Billback']['dateBillbackReceived']).format('MMMM Do YYYY')
                            : ''}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{moment(c['Billback']['dateBillbackReceived']).format('MMMM Do YYYY')}</td>
                        <td>
                          <TextWithEdit
                            preFix="$"
                            text={c['Billback']['amountBillbackReceived']}
                            onSubmit={value => onBillbackAmountSubmit(c, value)}
                            required
                            onRequiredInvalidation={() => onBillbackAmountRequiredInvalidation(c)}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                ))}
                {selectedType === typeOptions[1] && (
                  <tr>
                    <td colSpan={7} className="text-right">
                      <b>Total</b>
                    </td>
                    <td>
                      $
                      {filteredContracts
                        .map(c =>
                          c['Billback']?.amountBillbackReceived ? Number(c['Billback'].amountBillbackReceived) : 0
                        )
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2)}
                    </td>
                  </tr>
                )}
              </>
            ) : (
              <tr>
                <td colSpan={8}>No contracts to show</td>
              </tr>
            )}
          </Table>
          <h6 className="text-right text-muted">
            Total Reports: <span className="text-dark">{filteredContracts.length}</span>
          </h6>
        </>
      )}
    </div>
  );
};

export default BillbackExplorer;
