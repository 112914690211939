import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';

// Example

// activeOptionToView = info to be view on Dropdown (Generally activeOptionToView and activeOption might be same)
// activeOption = which Option Is Selected
// options = array of data to render
// toBeActiveKey = key from the data that becomes activeOption
// parentKey = to group according to parent

const CategorizedDropdown = ({
  activeOptionToView,
  activeOption,
  options,
  toBeActiveKey,
  parentKey,
  childKeys,
  onOptionChange
}) => {
  console.log({
    options,
    parentKey,
    childKeys
  });

  let findChildKey = useCallback(
    option => {
      if (!Array.isArray(childKeys)) {
        childKeys = [childKeys];
      }
      const foundKey = childKeys.find(childKey => Object.keys(option).includes(childKey));

      return foundKey ? option[foundKey] : '';
    },
    [childKeys]
  );
  return (
    <>
      <Dropdown onClick={e => e.stopPropagation()}>
        <Dropdown.Toggle block className="p-1" style={{ border: 0, fontSize: 12 }} size="sm">
          {activeOptionToView}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {options.map(option => (
            <Dropdown.Item
              active={option?.[toBeActiveKey] === activeOption}
              disabled={option?.[parentKey]}
              className={`smallFont py-1 ${option?.[parentKey] ? 'bg-primary-light text-dark px-2' : ''}`}
              key={option._id}
              onClick={e => {
                onOptionChange(option);
              }}
            >
              {option?.[parentKey] && <b>{option?.[parentKey]}</b>}

              <div> {findChildKey(option)}</div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CategorizedDropdown;
