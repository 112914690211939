import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Clipboard, Pencil, PinAngle, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS } from '../../../helpers/constants';
import { SLOT_STATUS } from '../../../helpers/global';
import AlertModal from '../../AlertModal';
import CustomMultiSelect from '../../common/CustomMultiSelect';
import GoogleMap from '../../common/google-map/GoogleMap';
import InventoryPicker from '../../inventory/InventoryPicker';
import InventorySearchItem from '../../inventory/InventorySearchItem';
import SlotNumberModal from '../../main/SlotNumberModal';

const SiteMap = ({ site, onSiteUpdate }) => {
  const [newMapProperties, setNewMapProperties] = useState(null);
  const [siteAddingUpdating, setSiteAddingUpdating] = useState(false);

  const [selectedPolygonInfo, setSelectedPolygonInfo] = useState(null);

  const [inventoryUnitMeta, setInventoryUnitMeta] = useState(null);
  const [deleteSlotMeta, setDeleteSlotMeta] = useState(null);

  const [createSlotModalMeta, setCreateSlotModalMeta] = useState(null);
  const [progressState, setProgressState] = useState(false);
  const [isOfficeBuilding, setIsOfficeBuilding] = useState(false);
  const role = localStorage.getItem('user-role');

  const onInventoryPickerClose = () => {
    setInventoryUnitMeta(null);
  };

  const updateSiteDefaultView = async () => {
    let objectToUpdate = {
      coordinates: newMapProperties.center,
      rotation: newMapProperties.heading,
      scale: newMapProperties.zoom
    };

    setSiteAddingUpdating(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SITE_WITH_ID(site._id),
      requestBody: { ...objectToUpdate },
      method: 'PUT'
    });

    setSiteAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    toast.success('Default view updated!');
    onSiteUpdate(response);
    setNewMapProperties(null);
  };

  const handlePolygonClick = (polygonIndex, event) => {
    let selectedSlot = site?.slots?.[polygonIndex] || null;
    setSelectedPolygonInfo({ polygonIndex, polygon: event, selectedSlot });
  };

  const onAddNewPolygon = async (paths, action = 'create') => {
    setCreateSlotModalMeta({
      paths,
      modalAction: action
    });
  };

  const cloneSlot = async selectedSlot => {
    if (!selectedSlot?.paths) return;

    const clonedPaths = selectedSlot.paths.map(path => ({
      lat: path.lat,
      lng: path.lng + 0.0002
    }));

    onAddNewPolygon(clonedPaths, 'clone');
  };

  const handleSlotNumberSubmit = async (slotNumber, existingSlot = null) => {
    let slotToAdd = {
      site: site._id,
      paths: createSlotModalMeta.paths || existingSlot?.paths,
      number: slotNumber,
      isOfficeBuilding: isOfficeBuilding,
      status: existingSlot?.status,
      inventory: existingSlot?.inventory
    };

    setProgressState(true);
    setSiteAddingUpdating(true);
    const { response, error } = await makeApiRequests({
      endpoint: existingSlot ? ENDPOINTS.SLOT_WITH_ID(existingSlot._id) : ENDPOINTS.SLOT_BASE,
      requestBody: { ...slotToAdd },
      method: existingSlot ? 'PUT' : 'POST'
    });

    setSiteAddingUpdating(false);
    if (error) {
      toast.error(error);
      setProgressState(false);
      return;
    }

    if (existingSlot) {
      toast.success('Updated slot');
      onSiteUpdate({
        ...site,
        slots: site.slots.map(s => (s._id === response._id ? response : s))
      });
      setProgressState(false);
      setCreateSlotModalMeta(null);
    } else {
      toast.success('Added a new slot');
      onSiteUpdate({ ...site, slots: [...site.slots, response] });
      setProgressState(false);
      setCreateSlotModalMeta(null);
    }
  };

  const onDeleteSlotClick = slotToDelete => {
    setDeleteSlotMeta({ slotToDelete });
  };

  const deleteSlot = async () => {
    setSiteAddingUpdating(true);
    let { slotToDelete: polygon } = deleteSlotMeta;
    setDeleteSlotMeta({ ...deleteSlotMeta, showProgress: true });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SLOT_WITH_ID(polygon._id),
      method: 'DELETE'
    });

    setSiteAddingUpdating(false);
    if (error) {
      setDeleteSlotMeta({ ...deleteSlotMeta, showProgress: false });
      toast.error(error);
      return;
    }

    toast.success('Slot has been deleted');
    onSiteUpdate({ ...site, slots: site.slots.filter(s => s._id !== polygon._id) });
    setSelectedPolygonInfo(null);
    setDeleteSlotMeta(null);
  };

  const onPolygonUpdate = async (polygon, callback) => {
    let slotToUpdate = {
      ...polygon
    };

    setSiteAddingUpdating(true);

    const { response: updatedSlot, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SLOT_WITH_ID(polygon._id),
      requestBody: slotToUpdate,
      method: 'PUT'
    });

    setSiteAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    toast.success('Updated slot');
    const updatedSlotInventoryUnit = updatedSlot?.inventory;
    onSiteUpdate({
      ...site,
      slots: site.slots.map(s =>
        s._id === updatedSlot._id
          ? updatedSlot
          : {
              ...s,
              inventory:
                //clear new inventory unit from other slots
                updatedSlotInventoryUnit && updatedSlotInventoryUnit._id === s.inventory?._id ? null : s.inventory
            }
      )
    });
    if (callback) {
      callback();
    }
  };

  const assignUnitToSlot = () => {
    let slotToUpdate = inventoryUnitMeta?.slot;
    let inventory = inventoryUnitMeta?.selectedUnit;

    onPolygonUpdate({ ...slotToUpdate, inventory: inventory?._id }, () => {
      setInventoryUnitMeta(null);
      // setSelectedPolygonInfo(null);
    });
  };

  const removeUnitFromSlot = slot => {
    onPolygonUpdate({ ...slot, inventory: null });
  };

  useEffect(() => {
    const fetchCompleteUnitDetails = async selectedSlot => {
      const unitId = selectedSlot?.inventory._id;

      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.INVENTORY_SEARCH_NEW,
        requestBody: {
          filter: {
            _id: unitId
          },
          includeContract: true
        }
      });

      if (error) {
        return;
      }

      const unit = response.results[0];
      unit.inventoryStatus = unit['contract'] ? 'Contracted' : unit['isRSO']?.toLowerCase() === 'yes' ? 'RSO' : 'Stock';
      onSiteUpdate({
        ...site,
        slots: site.slots.map(s =>
          s._id === selectedSlot._id
            ? {
                ...s,
                inventory: unit,
                inventoryFetched: true
              }
            : s
        )
      });
    };

    let selectedSlot = site?.slots?.[selectedPolygonInfo?.polygonIndex] || null;

    if (selectedSlot?.inventory && !selectedSlot?.inventoryFetched) {
      fetchCompleteUnitDetails(selectedSlot);
    }
  }, [selectedPolygonInfo, site]);

  const InfoWindowContent = useCallback(() => {
    let selectedSlot = site?.slots?.[selectedPolygonInfo?.polygonIndex] || null;

    if (selectedSlot) {
      selectedSlot = {
        ...selectedSlot,
        siteId: site.name
      };
    }

    return (
      <div className="pt-1 border-top">
        {selectedSlot?.isOfficeBuilding ? (
          <div style={{ width: 250 }}>
            <h6 className="smallFont py-2">This is an Office Building</h6>{' '}
            {['admin', 'super admin'].includes(role) && (
              <div className="border-top d-flex gap-2 justify-content-end mt-1 pt-2">
                <Button
                  variant="danger"
                  className="d-flex gap-1 align-items-center mr-1"
                  size="sm"
                  disabled={siteAddingUpdating}
                  onClick={() =>
                    setCreateSlotModalMeta({
                      paths: selectedSlot.paths,
                      existingSlot: selectedSlot,
                      modalAction: 'edit'
                    })
                  }
                >
                  <Pencil size={14} />
                  <h6 className="smallFont mx-1 mb-0">Edit</h6>
                </Button>

                <Button
                  variant="danger"
                  disabled={siteAddingUpdating}
                  className="d-flex gap-1 align-items-center"
                  size="sm"
                  onClick={() => onDeleteSlotClick(selectedSlot)}
                >
                  <Trash size={14} />
                  <h6 className="smallFont mx-1 mb-0">Delete</h6>
                </Button>
              </div>
            )}
          </div>
        ) : selectedSlot?.inventory ? (
          <>
            <h6 className="smallFont">Unit at this slot</h6>{' '}
            <div className="my-2 mx-3">
              {' '}
              <InventorySearchItem
                mini={true}
                inventory={selectedSlot?.inventory}
                slotNumber={selectedSlot?.number}
                siteId={selectedSlot?.siteId}
                showLocationDetails={true}
                selected
              />
            </div>
            <div className="border-top d-flex justify-content-end mt-1 pt-1">
              <Button
                variant="danger"
                className="d-flex gap-1 align-items-center mr-2"
                size="sm"
                disabled={siteAddingUpdating}
                onClick={() =>
                  setCreateSlotModalMeta({
                    paths: selectedSlot.paths,
                    existingSlot: selectedSlot,
                    modalAction: 'edit'
                  })
                }
              >
                <Pencil size={14} />
                <h6 className="smallFont mx-1 mb-0">Edit</h6>
              </Button>{' '}
              <Button
                variant="danger"
                className="d-flex gap-1 align-items-center mr-0"
                size="sm"
                disabled={siteAddingUpdating}
                onClick={() => cloneSlot(selectedSlot)}
              >
                <Clipboard size={14} />
                <h6 className="smallFont mx-1 mb-0">Clone</h6>
              </Button>{' '}
              <Button
                size="sm"
                className=" mx-1"
                disabled={siteAddingUpdating}
                variant="danger"
                onClick={() => removeUnitFromSlot(selectedSlot)}
              >
                <h6 className="smallFont mb-0">Remove Unit</h6>
              </Button>{' '}
              <Button
                size="sm"
                className=""
                disabled={siteAddingUpdating}
                onClick={() =>
                  setInventoryUnitMeta({
                    slot: selectedSlot
                  })
                }
              >
                <h6 className="smallFont mb-0">Assign another unit</h6>
              </Button>
            </div>
          </>
        ) : (
          <>
            <div style={{ width: 350 }} className="d-flex flex-column justify-content-center align-items-center my-5">
              <h6 className="smallFont">No any unit at this slot.</h6>
              <Button
                size="sm"
                className=""
                disabled={siteAddingUpdating}
                onClick={() =>
                  setInventoryUnitMeta({
                    slot: selectedSlot
                  })
                }
              >
                <h6 className="smallFont mb-0">Assign unit</h6>
              </Button>
            </div>{' '}
            {['admin', 'super admin'].includes(role) && (
              <div className="border-top d-flex justify-content-between mt-1 pt-1">
                <CustomMultiSelect
                  isMulti={false}
                  selectedItems={selectedSlot?.status}
                  items={SLOT_STATUS?.map(s => ({ label: s, value: s }))}
                  height="25px"
                  isLoading={siteAddingUpdating}
                  closeMenuOnSelect
                  onChange={status => onPolygonUpdate({ ...selectedSlot, status })}
                />
                <Button
                  variant="danger"
                  className="d-flex gap-1 align-items-center "
                  size="sm"
                  disabled={siteAddingUpdating}
                  onClick={() => cloneSlot(selectedSlot)}
                >
                  <Clipboard size={14} />
                  <h6 className="smallFont mx-1 mb-0">Clone</h6>
                </Button>{' '}
                <Button
                  variant="danger"
                  className="d-flex gap-1 align-items-center"
                  size="sm"
                  disabled={siteAddingUpdating}
                  onClick={() =>
                    setCreateSlotModalMeta({
                      paths: selectedSlot.paths,
                      existingSlot: selectedSlot,
                      modalAction: 'edit'
                    })
                  }
                >
                  <Pencil size={14} />
                  <h6 className="smallFont mx-1 mb-0">Edit</h6>
                </Button>
                <Button
                  variant="danger"
                  disabled={siteAddingUpdating}
                  className="d-flex gap-1 align-items-center"
                  size="sm"
                  onClick={() => onDeleteSlotClick(selectedSlot)}
                >
                  <Trash size={14} />
                  <h6 className="smallFont mx-1 mb-0">Delete</h6>
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }, [selectedPolygonInfo, site, siteAddingUpdating]);

  return (
    <div className="h-100 w-100 d-flex flex-column">
      <div className="flex-grow-1">
        <GoogleMap
          center={site.coordinates}
          zoom={site.scale}
          rotation={site.rotation}
          polygons={site.slots}
          handlePolygonClick={handlePolygonClick}
          infoWindowMeta={selectedPolygonInfo}
          setInfoWindowMeta={setSelectedPolygonInfo}
          disableDrawing={siteAddingUpdating}
          onMapPropertiesChange={setNewMapProperties}
          onAddNewPolygon={onAddNewPolygon}
          onPolygonUpdate={onPolygonUpdate}
          InfoWindowContent={InfoWindowContent}
        />
      </div>
      {['admin', 'super admin'].includes(role) && (
        <div className="text-right p-2">
          <Button
            size="sm"
            className="d-flex align-items-center float-right"
            variant="success"
            onClick={updateSiteDefaultView}
            disabled={!newMapProperties || siteAddingUpdating}
          >
            <PinAngle className="mx-1" />
            Set As Default View
          </Button>
        </div>
      )}{' '}
      <InventoryPicker
        show={inventoryUnitMeta}
        onSubmit={assignUnitToSlot}
        submitting={siteAddingUpdating}
        fromContracts={false}
        fromMap={true}
        onInventoryPickerClose={onInventoryPickerClose}
        selectedUnits={inventoryUnitMeta && inventoryUnitMeta.selectedUnit ? [inventoryUnitMeta.selectedUnit] : []}
        onUnitSelect={inv => setInventoryUnitMeta({ ...inventoryUnitMeta, selectedUnit: inv })}
      />
      <AlertModal
        alertText={'Are you sure you want to delete this slot?'}
        show={Boolean(deleteSlotMeta)}
        showProgress={deleteSlotMeta?.showProgress}
        onDismissClick={() => setDeleteSlotMeta(null)}
        onHide={() => setDeleteSlotMeta(null)}
        onContinueClick={deleteSlot}
      />
      <SlotNumberModal
        onSubmit={slotNumber => handleSlotNumberSubmit(slotNumber, createSlotModalMeta.existingSlot)}
        onHide={() => setCreateSlotModalMeta(null)}
        show={Boolean(createSlotModalMeta)}
        progressState={progressState}
        setOfficelBuilding={setIsOfficeBuilding}
        officeBuilding={isOfficeBuilding}
        initialSlotNumber={createSlotModalMeta?.existingSlot?.number}
        initialIsOfficeBuilding={createSlotModalMeta?.existingSlot?.isOfficeBuilding}
        modalAction={createSlotModalMeta?.modalAction || 'create'}
      />
    </div>
  );
};

export default SiteMap;
