import React from 'react';
import { FormControl } from 'react-bootstrap';
import { normalizeId, getFormattedDate, getFormattedDateTime } from '../helpers/utility';

const TextInput = ({
  id,
  title,
  onChange,
  onTextChange,
  onFormChange,
  value,
  preValue,
  className = '',
  textArea = false,
  hint = '',
  required = false,
  variant = 'text',
  onClick,
  disabled = false,
  onKeyPress,
  size,
  min,
  max,
  row = 2,
  style = {}
}) => {
  let defaultValue = value || preValue;
  if (['date', 'datetime-local'].includes(variant) && defaultValue) {
    const date = new Date(defaultValue);
    defaultValue = variant === 'date' ? getFormattedDate(date) : getFormattedDateTime(date);
  }

  return (
    <FormControl
      id={id || normalizeId(title)}
      type={variant}
      onChange={e => {
        if (onTextChange && window[onTextChange]) {
          window[onTextChange](e);
        }

        if (onChange) onChange(e);
        if (onFormChange) onFormChange(e);
      }}
      defaultValue={defaultValue}
      className={className}
      as={textArea ? 'textarea' : 'input'}
      placeholder={hint}
      required={required}
      onClick={onClick}
      disabled={disabled}
      onKeyPress={onKeyPress}
      size={size}
      min={min}
      max={max}
      rows={row}
      style={style}
    ></FormControl>
  );
};

export default TextInput;
