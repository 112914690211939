import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS, numbersOnlyFields } from '../../../helpers/constants';
import { copyToClipboard } from '../../../helpers/global';
import Loader from '../../Loader';
import ContractList from '../../main/ContractList';
import NotFound from '../../NotFound';

const billbackFields = {
  'Buyer Info': [{ label: 'Buyer', key: 'buyer' }, { label: 'Buyer Email', key: 'buyerEmail' }],
  Property: [
    { label: 'Property Address', key: 'propertyAddress' },
    { label: 'Property City', key: 'propertyCity' },
    { label: 'Property State', key: 'propertyState' },
    { label: 'Zip Code', key: 'zipCode' }
  ],
  Contact: [{ label: 'Cell Phone #', key: 'cellPhone' }, { label: 'Cell Phone #2', key: 'cellPhone2' }],
  Serial: [
    { label: 'Serial #A', key: 'serialA' },
    { label: 'Serial #B', key: 'serialB' },
    { label: 'Serial #C', key: 'serialC' }
  ],
  HUD: [{ label: 'HUD #A', key: 'hudA' }, { label: 'HUD #B', key: 'hudB' }, { label: 'HUD #C', key: 'hudC' }],
  Dates: [{ label: 'Setup Start Date', key: ['setup', 'start'] }, { label: 'Date signed', key: 'dateSigned' }]
};

const RequestBillback = ({ appChoices }) => {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [copiedValues, setCopiedValues] = useState([]);
  const [submittingBillback, setSubmittingBillback] = useState(false);

  const fetchBillbacks = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        query: ''
      },
      endpoint: ENDPOINTS.CONTRACTS_SEARCH
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setContracts(
      response
        .filter(c => c['salesLocation'])
        .map(c => {
          const { inventoryUnit } = c;
          if (!inventoryUnit) return c;

          delete inventoryUnit['_id'];
          return { ...c, ...inventoryUnit };
        })
        .sort((a, b) => a['salesLocation'].location.localeCompare(b['salesLocation'].location))
    );
  };

  useEffect(() => {
    fetchBillbacks();
  }, []);

  useEffect(() => {
    setCopiedValues([]);
  }, [activeIndex, contracts]);

  const prevContract = () => {
    if (activeIndex === 0) return;

    setActiveIndex(activeIndex - 1);
  };

  const nextContract = () => {
    setCopiedValues([]);
    if (activeIndex === contracts.length - 1) return;

    setActiveIndex(activeIndex + 1);
  };

  const copyContent = (label, value, sectionName, numbersOnly = false) => {
    const newValue = numbersOnly
      ? value
          .toString()
          .split('')
          .filter(char => /\d/.test(char))
          .join('')
      : value;
    copyToClipboard(newValue);

    const checkValue = label + '-' + sectionName;
    copiedValues.push(checkValue);
    setCopiedValues([...copiedValues]);

    toast.success(`Copied "${newValue}" to clipboard!`);
  };

  const SingleCol = ({ label, value, sectionName }) => {
    const checkValue = label + '-' + sectionName;

    return (
      <Col xs={6} md={3}>
        <Alert
          onClick={() => copyContent(label, value, sectionName, numbersOnlyFields.includes(label))}
          style={{ cursor: 'pointer', height: '90%' }}
          variant={copiedValues.includes(checkValue) ? 'warning' : 'info'}
          className="text-center p-1 mx-2"
        >
          <p className="mb-1">
            <b>{label}</b>
          </p>
          {value || 'N/A'}
        </Alert>
      </Col>
    );
  };

  const SingleRow = ({ name, obj, nameClass = '' }) => {
    return (
      <>
        <h6 className={'mb-3 ' + nameClass}>{name}</h6>
        <Row className="justify-content-center">
          {Object.keys(obj).map(objKey => (
            <SingleCol label={objKey} value={obj[objKey]} sectionName={name} />
          ))}
        </Row>
        <hr className="my-2" />
      </>
    );
  };

  const BillbackForm = () => {
    const activeContract = contracts[activeIndex];
    return (
      <Card className="mt-2">
        <Card.Header>
          <div className="d-flex">
            <div className="flex-grow-1">
              <h5 className=" mb-0">{activeContract['buyer']}</h5>
            </div>
            Sales Location: {activeContract['salesLocation'].location}
          </div>
        </Card.Header>
        <Card.Body className="text-center mx-3">
          {Object.keys(billbackFields).map(rowTitle => {
            const obj = {};

            billbackFields[rowTitle].forEach(({ label, key: objectPath }) => {
              if (!Array.isArray(objectPath)) objectPath = [objectPath];
              const value = objectPath.reduce((prevValue, k) => (prevValue ? prevValue[k] : ''), activeContract);
              obj[label] = value ? (rowTitle === 'Dates' ? moment(value).format('MMMM Do YYYY') : value) : 'N/A';
            });

            return <SingleRow name={rowTitle} obj={obj} />;
          })}
        </Card.Body>
        <Card.Footer className="text-right">
          <Button onClick={submitBillbackRequest} disabled={submittingBillback}>
            Done
          </Button>
        </Card.Footer>
      </Card>
    );
  };

  const submitBillbackRequest = async () => {
    toast.info('Please wait submitting request...');

    setSubmittingBillback(true);

    const newBillBack = { dateBillbackRequested: new Date().toISOString() };

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contracts[activeIndex]['_id']),
      requestBody: {
        billbacks: contracts[activeIndex]['billbacks']
          ? [...contracts[activeIndex]['billbacks'], newBillBack]
          : [newBillBack]
      },
      method: 'PUT'
    });

    setSubmittingBillback(false);

    if (error) {
      toast.error(error);
      return;
    }

    contracts.splice(activeIndex, 1);
    if (contracts.length !== 0 && contracts.length < activeIndex + 1) {
      setActiveIndex(activeIndex - 1);
    }

    setContracts([...contracts]);
    toast.success('Billback requested successfully');
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  const onContractSelect = contract => {
    const index = contracts.findIndex(c => c['_id'] === contract['_id']);
    if (index !== -1) setActiveIndex(index);
  };

  return (
    <Container fluid className={'h-100  py-3 px-md-2 '}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {contracts.length > 0 ? (
            <Row className="h-100">
              <Col xs={4} md={2} className="p-0 pb-5 overflow-auto h-100">
                <ContractList
                  contracts={contracts}
                  activeId={contracts[activeIndex]['_id']}
                  onSelect={onContractSelect}
                  appChoices={appChoices}
                />
              </Col>
              <Col xs={8} md={10} className="h-100 overflow-auto">
                <>
                  <Row>
                    <Col className="text-right">
                      <>
                        {activeIndex > 0 && (
                          <Button size="sm" variant="primary" onClick={prevContract} disabled={submittingBillback}>
                            <ArrowLeft className="align-text-top" />
                          </Button>
                        )}
                        <h6 className="text-muted mb-0 d-inline-block mx-3">{activeIndex + 1}</h6>
                        {activeIndex < contracts.length - 1 && (
                          <Button size="sm" variant="primary" onClick={nextContract} disabled={submittingBillback}>
                            <ArrowRight className="align-text-top" />
                          </Button>
                        )}
                      </>
                    </Col>
                  </Row>
                  <BillbackForm />
                </>
              </Col>
            </Row>
          ) : (
            !loading && <NotFound text="No contracts to show!" />
          )}
        </>
      )}
    </Container>
  );
};

export default RequestBillback;
