import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { normalizeId } from '../helpers/utility';

const getOptionsGroupedBy = (groupNames = [], optionValues) => {
  const groupedBy = {
    Other: []
  };

  optionValues.forEach((o, i) => {
    const groupItBelongsTo = groupNames[i];
    if (!groupItBelongsTo) {
      groupedBy.Other.push(o);
      return;
    }

    if (groupedBy[groupItBelongsTo]) {
      groupedBy[groupItBelongsTo].push(o);
    } else {
      groupedBy[groupItBelongsTo] = [o];
    }
  });

  return groupedBy;
};

const MultiSelectDropDown = ({
  id,
  options = [],
  optionValues,
  title,
  onChange = '',
  onChangeFunction,
  value: defaultValue = '',
  preValue,
  disabled = false,
  className = '',
  size,
  groupValues
}) => {
  if (preValue) defaultValue = preValue;

  const [selections, setSelections] = useState(defaultValue || []);
  const [showModal, setShowModal] = useState(false);
  const [groupedOptions] = useState(getOptionsGroupedBy(groupValues, options));

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSelection = e => {
    const checked = e.target.checked;
    const value = e.target.value;
    const index = selections.indexOf(value);

    let newSelections;
    if (checked) {
      if (index === -1) {
        newSelections = [...selections, value];

        setSelections(newSelections);
      }
    } else {
      if (index !== -1) selections.splice(index, 1);
      newSelections = [...selections];
      setSelections(newSelections);
    }

    if (onChangeFunction && newSelections) onChangeFunction(newSelections);
  };

  const SelectionModal = () => {
    return (
      <Modal size="lg" show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 15 }}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {groupedOptions &&
            Object.keys(groupedOptions)
              .sort((a, b) => (a === 'Other' ? 1 : b === 'Other' ? -1 : 0))
              .map((group, index, groups) => {
                return (
                  <div className="my-2">
                    {groups.length > 1 && <h6>{group}</h6>}
                    <Row>
                      {groupedOptions[group].map(option => {
                        const value = optionValues ? optionValues[options.indexOf(option)] : option;
                        const inputId = id + '-' + normalizeId(option);
                        return (
                          <Col key={value} xs={6}>
                            <div className={'form-check'}>
                              <input
                                id={inputId}
                                className="form-check-input"
                                type="checkbox"
                                style={styles.checkbox}
                                checked={selections.indexOf(value) !== -1}
                                onChange={handleSelection}
                                value={value}
                                data-option={option}
                                disabled={disabled}
                              />

                              <label htmlFor={inputId} className="ml-2 form-check-label my-1">
                                {option}
                              </label>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                );
              })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="primary" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const getSelections = () => {
    const selectionOptions = selections.map(s => options[optionValues ? optionValues.indexOf(s) : options.indexOf(s)]);
    if (selectionOptions.length < 2) {
      return selectionOptions[0];
    }

    return `${selectionOptions[0]} and ${selectionOptions.length - 1} others`;
  };

  return (
    <>
      {SelectionModal()}
      <div
        id={id}
        className={`form-control ${className} ${size === 'sm' ? 'form-control-sm' : ''}`}
        onClick={() => {
          setShowModal(true);
        }}
        data-selections={selections.join(',')}
      >
        {selections.length === 0 ? 'Select...' : getSelections()}
      </div>
    </>
  );
};

const styles = {
  checkbox: {
    height: 22,
    width: 22
  }
};

export default MultiSelectDropDown;
