import React from 'react';
import { Button, Col, Container, Modal, ProgressBar, Row } from 'react-bootstrap';
import CachedResponse from './common/CachedResponse';
import SearchCrm from './crm/SearchCrm';

const ContactsPicker = ({
  show,
  onContactPickerClose,
  submitting = false,
  submittingText = 'Claiming request...',
  onSubmit,
  submitButtonText = 'Claim',
  selectedContacts = [],
  onContactSelect,
  excludedCrmIds = [],
  updateInventoryList
}) => {
  return (
    <Modal
      dialogClassName="h-100 my-0"
      contentClassName="h-100"
      show={show}
      size="lg"
      onHide={onContactPickerClose}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Select contact to be assigned</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto py-1 px-2">
        <CachedResponse
          listEndpoint={'app-choices'}
          render={appChoices => (
            <SearchCrm
              selectedContacts={selectedContacts}
              fromPicker
              onSelect={onContactSelect}
              appChoices={appChoices}
              excludedCrmIds={excludedCrmIds}
              updateInventoryList={updateInventoryList}
            />
          )}
        />
      </Modal.Body>

      <Modal.Footer>
        <Container fluid>
          <Row>
            {submitting && (
              <Col xs={12}>
                <ProgressBar className="mb-2" now={100} animated label={submittingText} />
              </Col>
            )}
            <Col xs={12} className="text-right">
              <Button
                className="mr-3"
                size="sm"
                variant="secondary"
                onClick={onContactPickerClose}
                disabled={submitting}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                variant="info"
                disabled={selectedContacts.length === 0 || submitting}
                onClick={() => onSubmit(selectedContacts)}
              >
                {submitButtonText}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactsPicker;
