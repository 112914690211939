import React, { useEffect, useState } from 'react';
import { Button, Card, Col, FormCheck, FormControl, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DropDownInput from '../../form-generator/components/DropDownInput';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, months } from '../../helpers/constants';
import { downloadFileFromBase64 } from '../../helpers/global';
import Loader from '../Loader';

const getMonthList = () => {
  const currentMonth = new Date().getMonth();
  return months.slice(0, currentMonth + 1);
};

const getYearList = () => {
  const currentYear = new Date().getFullYear();
  return [currentYear, currentYear - 1, currentYear - 2];
};

const MHIT = ({ appChoices }) => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typeOptions] = useState(['Monthly', 'Annual']);
  const [selectedType, setSelectedType] = useState('Monthly');
  const [excludedContracts, setExcludedContracts] = useState([]);

  const [salesLocations] = useState([
    { location: 'All', _id: 'All' },
    ...appChoices.find(ac => ac.key === 'salesLocation').values
  ]);

  const [selectedSalesLocation, setSelectedSalesLocation] = useState(salesLocations[0]);
  const [yearOptions] = useState(getYearList());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [monthOptions, setMonthOptions] = useState(getMonthList());
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
  const [swValue, setSwValue] = useState('7000');
  const [dwValue, setDwValue] = useState('15750');

  const [submitting, setSubmitting] = useState(false);

  const [filteredContracts, setFilteredContracts] = useState([]);
  const [currentYear] = useState(new Date().getFullYear());

  const fetchContracts = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        sort: '-setup.start',
        filter: { 'setup.start': { $ne: null } },
        returnPaths: ['buyer', 'setup', 'salesPrice']
      },
      endpoint: ENDPOINTS.CONTRACTS_SEARCH_NEW
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setContracts(
      response.results
        .filter(c => c['salesLocation'])
        .map(c => {
          const { inventoryUnit } = c;
          if (!inventoryUnit) return c;

          delete inventoryUnit['_id'];
          return { ...c, ...inventoryUnit };
        })
    );
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const filterContracts = () => {
    let tempFilter =
      selectedSalesLocation._id === 'All'
        ? contracts
        : contracts.filter(c => c.salesLocation._id === selectedSalesLocation._id);

    tempFilter = tempFilter.filter(c => {
      const dateSetup = new Date(c.setup.start);
      const month = dateSetup.getMonth();
      return (selectedType === 'Annual' || selectedMonth === months[month]) && selectedYear === dateSetup.getFullYear();
    });

    setFilteredContracts(
      tempFilter.map(c => {
        let sectionCount = ['serialA', 'serialB', 'serialC'].filter(s => c[s]).length;

        const dateSetup = new Date(c.setup.start);
        const contractLocation = salesLocations.find(location => location._id === c.salesLocation._id);

        const taxFactor = contractLocation['taxFactors'].reduce(
          (acc, { effectiveDate, value }) => {
            const effectiveDateUnix = new Date(effectiveDate).getTime();
            if (effectiveDateUnix > dateSetup.getTime()) return acc;
            if (acc.effectiveDate > effectiveDateUnix) return acc;
            return { effectiveDate: effectiveDateUnix, value };
          },
          { effectiveDate: Number.MIN_SAFE_INTEGER, value: 1 }
        ).value;

        const salesPrice = c['salesPrice'] || 0;
        const adjustedValue = sectionCount === 1 ? swValue : dwValue;
        const adjustedSalesPrice = salesPrice - (adjustedValue ? Number(adjustedValue) : 0);
        const propertyTax = adjustedSalesPrice * taxFactor;

        const month = dateSetup.getMonth();

        return {
          _id: c['_id'],
          dateOfSale: `${months[month]} ${dateSetup.getFullYear()}`,
          Year: c['year'],
          Manufacturer: c['manufacturer'] ? c['manufacturer'].name : '',
          'Serial # A': c['serialA'],
          Buyer: c['buyer'],
          'Type of Unit': 'MH',
          'Sales Price': salesPrice,
          'Adjusted Sales Price': adjustedSalesPrice,
          propertyTax: propertyTax
        };
      })
    );
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    setExcludedContracts([]);
    filterContracts();
  }, [contracts, selectedSalesLocation, selectedMonth, selectedType, selectedYear, swValue, dwValue]);

  const onTypeChange = () => {
    const type = document.getElementById('reportTypeSelect').value;
    setSelectedType(type);
  };

  const onLocationChange = () => {
    const locationId = document.getElementById('locationSelect').value;
    setSelectedSalesLocation(salesLocations.find(l => l._id === locationId));
  };

  const onMonthChange = () => {
    const month = document.getElementById('monthSelect').value;
    setSelectedMonth(month);
  };

  const onYearChange = () => {
    const year = document.getElementById('yearSelect').value;
    setSelectedYear(parseInt(year));
  };

  useEffect(() => {
    if (selectedYear === new Date().getFullYear()) {
      setMonthOptions(getMonthList());
    } else {
      setMonthOptions([...months]);
    }
  }, [selectedYear]);

  const printDocument = async () => {
    if (selectedSalesLocation._id === 'All') {
      return toast.error("Cannot print mhit report when selected sales location is 'All'. ");
    }
    toast.info('Submitting report, please wait...');
    setSubmitting(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        salesLocation: selectedSalesLocation._id,
        reportData: filteredContracts
          .filter(c => !excludedContracts.includes(c['_id']))
          .map(c => ({ ...c, 'Sales Price': c['Adjusted Sales Price'] })),
        reportType: selectedType,
        month: selectedType === 'Annual' ? undefined : selectedMonth,
        year: selectedYear
      },
      endpoint: ENDPOINTS.DOCHUB_PRINT_MHIT
    });

    setSubmitting(false);
    if (error) {
      return toast.error(error);
    }

    toast.success('Document created successfully, file is being downloaded...');
    const { fileName, data, mimeType } = response['file'];
    downloadFileFromBase64({ fileName, data, mimeType });
  };

  const SelectionBox = () => {
    return (
      <>
        <Card className="border rounded">
          <Card.Header className="bg-primary">
            <h5 className="mb-0 text-white">MHIT Reports</h5>
          </Card.Header>
          <Card.Body className="py-3">
            <Row>
              <Col xs={12} md={6} className="px-4 mt-3">
                <h6 className="mb-2">Report Type</h6>
                <DropDownInput
                  size="sm"
                  id="reportTypeSelect"
                  value={selectedType}
                  options={typeOptions}
                  onChangeFunction={onTypeChange}
                  required
                />
              </Col>
              <Col xs={12} md={6} className="px-4 mt-3">
                <h6 className="mb-2">Sales Location</h6>
                <DropDownInput
                  size={'sm'}
                  id="locationSelect"
                  value={selectedSalesLocation._id}
                  options={salesLocations.map(s => s.location)}
                  optionValues={salesLocations.map(s => s._id)}
                  onChangeFunction={onLocationChange}
                  required
                />
              </Col>

              <Col xs={6} md={3} className="px-4 mt-3">
                <h6 className="mb-2">Select Year</h6>
                <DropDownInput
                  id="yearSelect"
                  size={'sm'}
                  value={selectedYear}
                  options={yearOptions}
                  onChangeFunction={onYearChange}
                  required
                />
              </Col>
              {selectedType === 'Monthly' && (
                <Col xs={6} md={3} className="px-4 mt-3">
                  <h6 className="mb-2">Select Month</h6>
                  <DropDownInput
                    id="monthSelect"
                    size={'sm'}
                    value={selectedMonth}
                    options={monthOptions}
                    onChangeFunction={onMonthChange}
                    required
                  />
                </Col>
              )}
              <Col xs={6} md={3} className="px-4 mt-3">
                <h6 className="mb-2">SW</h6>
                <FormControl size={'sm'} value={swValue} onChange={e => setSwValue(e.target.value)} type="number" />
              </Col>
              <Col xs={6} md={3} className="px-4 mt-3">
                <h6 className="mb-2">DW</h6>
                <FormControl size={'sm'} value={dwValue} onChange={e => setDwValue(e.target.value)} type="number" />
              </Col>

              <Col xs={12} className="mt-3 text-right">
                <Button onClick={printDocument} disabled={submitting}>
                  Print
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onExcludeContractChange = (id, checked) => {
    if (checked) {
      const exisiting = excludedContracts.indexOf(id);
      if (exisiting !== -1) excludedContracts.splice(exisiting, 1);
    } else {
      if (!excludedContracts.includes(id)) excludedContracts.push(id);
    }
    setExcludedContracts([...excludedContracts]);
  };

  return (
    <div className="px-0 px-md-5 py-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          {SelectionBox()}
          <hr />

          <h6>Preview Report:</h6>
          <Table responsive bordered className="text-center midFont">
            <tr style={{ fontSize: 14 }} className="bg-dark text-light">
              <th>Print?</th>
              <th>Date of Sale</th>
              <th>Model Year</th>
              <th>Make</th>
              <th>Unit of Manufactured Housing Identification/Serial Number</th>
              <th>Purchaser's Name</th>
              <th>Type of Sale</th>
              <th>Sales Price</th>
              <th>Adjusted Price</th>
              <th>Unit Property Tax</th>
            </tr>
            {filteredContracts.length > 0 ? (
              <>
                {filteredContracts.map(c => (
                  <tr key={c['_id']}>
                    <td>
                      <FormCheck
                        checked={!excludedContracts.includes(c['_id'])}
                        style={{ width: 16, height: 16 }}
                        type="checkbox"
                        onChange={e => onExcludeContractChange(c['_id'], e.target.checked)}
                      />
                    </td>
                    <td>{c['dateOfSale']}</td>
                    <td>{c['Year']}</td>
                    <td>{c['Manufacturer']}</td>
                    <td>{c['Serial # A']}</td>
                    <td>{c['Buyer']}</td>
                    <td>{c['Type of Unit']}</td>
                    <td>${c['Sales Price'].toFixed(2)}</td>
                    <td>${c['Adjusted Sales Price'].toFixed(2)}</td>
                    <td>${c['propertyTax'].toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={9} className="text-right">
                    Total
                  </td>
                  <td>${filteredContracts.reduce((prev, c) => prev + c['propertyTax'], 0).toFixed(2)}</td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={9}>No contracts to show</td>
              </tr>
            )}
          </Table>
          <h6 className="text-right text-muted">
            Total Reports: <span className="text-dark">{filteredContracts.length}</span>
          </h6>
        </>
      )}
    </div>
  );
};

export default MHIT;
