import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import ScrollToTop from './components/ScrollToTop';
import CachedResponse from './components/common/CachedResponse';
import useClientTitle from './hooks/useClientTitle';
import Home from './pages/home';
import Login from './pages/login';
import RequestService from './pages/service_request';
import WalkthroughConfirmation from './pages/walkthrough_confirmation';

const App = () => {
  useClientTitle();

  return (
    <Router>
      <ScrollToTop />
      {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/request_service">
          <RequestService />
        </Route>
        <Route exact path="/walkthrough_confirmation/:uuid">
          <WalkthroughConfirmation />
        </Route>
        <Route path="/">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <Home appChoices={appChoices} />} />
        </Route>
      </Switch>
      <ToastContainer autoClose={2000} />
    </Router>
  );
};

export default App;
