const walkthroughFieldRows = [
  {
    columns: [
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'homeExterior',
          title: 'Home Exterior',
          type: 'key-value-switch',
          options: [
            'Doors Front/Back',
            'Receptacles',
            'Light Fixture(s)',
            'Siding/Paint',
            'Shutters/Trim',
            'Windows',
            'House',
            'Roof/Shiners/Vents/Leaks',
            'Stairs/Steps'
          ]
        }
      },
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'livingDiningRoom',
          title: 'Living / Dining Room',
          type: 'key-value-switch',
          options: [
            'Door',
            'Switches/Receptacles',
            'Light Fixture(s)/Fan',
            'Walls',
            'Trim',
            'Windows',
            'Drapes/Blinds',
            'Carpet/Floor Covering',
            'Ceiling',
            'Air Registers',
            'Foyer',
            'Fireplace/Ent Center'
          ]
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'denFamilyRoom',
          title: 'Den/Family Room',
          type: 'key-value-switch',
          options: [
            'Door',
            'Switches/Receptacles',
            'Light Fixture(s)/Fan',
            'Walls',
            'Trim',
            'Windows',
            'Drapes/Blinds',
            'Carpet/Floor Covering',
            'Ceiling',
            'Air Registers',
            'Cabinets'
          ]
        }
      },
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'hallway',
          title: 'Hallway',
          type: 'key-value-switch',
          options: [
            'Switches/Receptacles',
            'Light Fixture(s)/Fan',
            'Walls',
            'Trim',
            'Windows',
            'Drapes/Blinds',
            'Carpet/Floor Covering',
            'Ceiling'
          ]
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'bedroom2',
          title: 'Bedroom 2',
          type: 'key-value-switch',
          options: [
            'Door',
            'Switches/Receptacles',
            'Light Fixture(s)/Fan',
            'Walls',
            'Trim',
            'Windows',
            'Drapes/Blinds',
            'Carpet/Floor Covering',
            'Ceiling',
            'Air Registers',
            'Closet Door(s)'
          ]
        }
      },
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'bedroom3/4',
          title: 'Bedroom 3/4',
          type: 'key-value-switch',
          options: [
            'Door',
            'Switches/Receptacles',
            'Light Fixture(s)/Fan',
            'Walls',
            'Trim',
            'Windows',
            'Drapes/Blinds',
            'Carpet/Floor Covering',
            'Ceiling',
            'Air Registers',
            'Closet Door(s)'
          ]
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'guestBath',
          title: 'Guest Bath',
          type: 'key-value-switch',
          options: [
            'Door',
            'Switches/Receptacles',
            'Light Fixture(s)/Fan',
            'Walls',
            'Trim',
            'Windows',
            'Drapes/Blinds',
            'Carpet/Floor Covering',
            'Ceiling',
            'Air Registers',
            'Countertops',
            'Cabinets',
            'Sink(s)/Faucets',
            'Mirror(s)',
            'Tub/Shower',
            'Commode'
          ]
        }
      },
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'utilityRoom',
          title: 'Utility Room',
          type: 'key-value-switch',
          options: [
            'Door',
            'Switches/Receptacles',
            'Light Fixture(s)/Fan',
            'Walls',
            'Trim',
            'Windows',
            'Drapes/Blinds',
            'Carpet/Floor Covering',
            'Ceiling',
            'Air Registers',
            'Countertops',
            'Cabinets',
            'Dryer Vented out',
            'Electrical Panels'
          ]
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'masterBedroom',
          title: 'Master Bedroom',
          type: 'key-value-switch',
          options: [
            'Door',
            'Switches/Receptacles',
            'Light Fixture(s)/Fan',
            'Walls',
            'Trim',
            'Windows',
            'Drapes/Blinds',
            'Carpet/Floor Covering',
            'Ceiling',
            'Closet Door(s)'
          ]
        }
      },
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'masterBath',
          title: 'Master Bath',
          type: 'key-value-switch',
          options: [
            'Door',
            'Switches/Receptacles',
            'Light Fixture(s)/Fan',
            'Walls',
            'Trim',
            'Windows',
            'Drapes/Blinds',
            'Carpet/Floor Covering',
            'Ceiling',
            'Air Registers',
            'Countertops',
            'Cabinets',
            'Sink(s)/Faucets',
            'Mirror(s)',
            'Tub/Shower',
            'Commode'
          ]
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'partsNeededForPendingService',
          title: 'Parts Needed for Pending Service',
          type: 'multi-text'
        }
      },
      {
        default: 6,
        xs: 12,
        alignStart: true,
        field: {
          id: 'other',
          title: 'Other',
          type: 'key-value-switch-free'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        xs: 12,
        alignStart: true,
        field: {
          id: 'more',
          title: 'More',
          type: 'table-input',
          tableFields: [
            { title: 'DESCRIPTION OF SERVICE COMPLETED DURING WALK THROUGH', type: 'text' },
            { title: 'Labor Hours', type: 'number' }
          ]
        }
      }
    ]
  }
];

export const walkthroughFormJson = {
  forms: [
    {
      name: 'WalkThrough Checklist',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Add',
        show: false,
        onSubmit: 'onWalkThroughCheckListSubmit'
      },
      rows: walkthroughFieldRows
    }
  ]
};

const makeReadyFieldRows = [
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'exteriorMarriageLine',
          label: 'Exterior Marriage line',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'roofShiners',
          label: 'Roof Shiners',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'interiorMarriageLine',
          label: 'Interior walls, floors & ceilings on the marriage line',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'adjusting',
          label: 'Adjusting or re-squaring doors, windows, cabinet doors & drawers',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'tightenLooseConnectors',
          label: 'Tighten loose connectors & P-Traps',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'secureLooseMolding',
          label:
            'Secure loose molding, repair or replace damaged molding. (Including trim, battens, cove & crown molding)',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'installingItems',
          label:
            'Installing items included in ship-loose that were not installed at factory due to transit reasons or being located on the marriage line',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 12,
        field: {
          id: 'drywallCracksExcluding',
          label: 'Drywall Cracks-Excluding',
          type: 'checkbox',
          showLabel: true,
          onChange: 'onDryWallCheckChange'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'cracksLargeEnough',
          label: 'Cracks large enough to fit a nickel in or from floor to ceiling',
          type: 'checkbox',
          showLabel: true,
          className: 'ml-5'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'cracksInTheCornerOfRoom',
          label: 'Cracks in the corners of room ',
          type: 'checkbox',
          showLabel: true,
          className: 'ml-5'
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 12,
        field: {
          id: 'crackedCaulkingGrout',
          label: 'Cracked caulking & grout ',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 12,
        field: {
          id: 'adjustCommodeWater',
          label: 'Adjust commode water float level',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 12,
        field: {
          id: 'appliances',
          label: 'Appliances',
          type: 'checkbox',
          showLabel: true,
          onChange: 'onApplianceCheckChange'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'removingShipping',
          label: 'Removing shipping brackets/straps/tape',
          type: 'checkbox',
          showLabel: true,
          className: 'ml-5'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'levelingAppliances',
          label: 'Leveling appliances',
          type: 'checkbox',
          showLabel: true,
          className: 'ml-5'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'installingShelves',
          label: 'Installing shelves/drawers',
          type: 'checkbox',
          showLabel: true,
          className: 'ml-5'
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 12,
        field: {
          id: 'functionTestForElectrical',
          label: 'Function Test for Electrical and Plumbing ',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'checkWaterHeaterFunction',
          label: 'Check Water Heater function (if water is hooked up if not note on bottom)',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'checkUnderBelly',
          label: 'Check Under Belly under the entire home for holes and tears, patch if needed.',
          type: 'checkbox',
          showLabel: true
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 12,
        field: {
          type: 'custom-html',
          html: `<h6 class='my-3 text-muted'>The Trim Out is a make ready program, the home needs to be completed, cleaned & ready for the homeowner to move into. </h6>`
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'notes',
          title: 'Notes',
          type: 'text-area'
        }
      }
    ]
  }
];

export const makeReadyFormJson = {
  forms: [
    {
      name: 'Make Ready Form',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Save',
        show: true,
        onSubmit: 'onMakeReadyFormSubmit'
      },
      rows: makeReadyFieldRows
    }
  ]
};
