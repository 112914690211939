import React from 'react';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { getObjectForPrefill } from '../../helpers/formHelpers';
import { installerFormJson } from './form';

const InstallerForm = ({ contract, disabled }) => {
  const onInstallerFormSubmit = async form => {
    toast.info('Please wait, updating installer report...');

    const { response, error } = await makeApiRequests({
      requestBody: { installerReport: form },
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contract._id)
    });

    if (error) {
      return toast.error(error);
    }

    toast.success('Report updated successfully!');
    setTimeout(() => document.location.reload(), 1000);
  };

  window['onInstallerFormSubmit'] = onInstallerFormSubmit;

  return (
    <FormGenerator
      formDisabled={disabled}
      formJson={installerFormJson}
      formValues={{ Installer: getObjectForPrefill(contract['installerReport']) }}
    />
  );
};

export default InstallerForm;
