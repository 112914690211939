import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { ArrowUpRightCircle, Files, InfoSquareFill, PencilSquare, PlusCircle, Printer } from 'react-bootstrap-icons';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { findDifferenceInObjects, setAttribute } from '../../form-generator/helpers/utility';
import { deleteFileFromServer, makeApiRequests } from '../../helpers/api';
import {
  ENDPOINTS,
  RETURN_PATHS,
  contactSearchSortByOptions,
  contactToggleOptions,
  createFilterOptionsForContact
} from '../../helpers/constants';
import { addDocHubFormFieldsToForm, convertKeyValueToFormObject, getObjectForPrefill } from '../../helpers/formHelpers';
import { isObjectIncomplete } from '../../helpers/global';
import { getProfileFromLocalStorage, getSalesRepOfSalesManager } from '../../helpers/session';
import AlertModal from '../AlertModal';
import NotFound from '../NotFound';
import OverViewColumns from '../OverViewColumns';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import FileViewer from '../common/FileViewer';
import FloatingButton from '../common/FloatingButton';
import HorizontalProgress from '../common/HorizontalProgress';
import QuickToggles from '../common/QuickToggles';
import File from '../common/file';
import SearchBox from '../common/searchbox';
import ContactList from '../crm/ContactList';
import CreateSideBar from '../crm/CreateSidebar';
import { addChoicesToCRMForm, editFormJson, validateAndCleanupCRMForm } from '../crm/form';
import EditInventory from '../inventory/EditInventory';
import InventoryPicker from '../inventory/InventoryPicker';
import PotentialUnitsOverview from '../inventory/PotentialUnitsOverview';
import ContactInfoSideBar from './ContactInfoSidebar';
import ListFilterText from './ListFilterText';
import PipelineLoaderCard from './PipelineLoaderCard';
import StatusTabs from './StatusTabs';

const staticSectionFields = [{ key: 'salesLocation', label: 'Sales Location', isObject: true, objectKey: 'location' }];

const InventoryEditSideBar = ({ editingInventory, appChoices, onHide, onInventoryDelete, onInventoryEdit }) => {
  return (
    <SlidingSideBar fullScreen visible={editingInventory} onClose={onHide} title={'Inventory Details'}>
      {editingInventory && (
        <EditInventory
          id={editingInventory['_id']}
          inventoryFromSearch={editingInventory}
          appChoices={appChoices}
          fromSearch
          onInventoryDelete={onInventoryDelete}
          onInventoryEdit={onInventoryEdit}
        />
      )}
    </SlidingSideBar>
  );
};

const CRMPipeline = ({ appChoices }) => {
  const history = useHistory();

  const [role] = useState(localStorage.getItem('user-role'));
  const [statuses, setStatuses] = useState(
    appChoices.find(choice => choice.key === 'Contact Status').values.map(s => ({ name: s }))
  );
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);

  const [activeContact, setActiveContact] = useState(null);

  const [activeStatusObject, setActiveStatusObject] = useState(statuses[0]);

  const [form, setForm] = useState(null);
  const [toggling, setToggling] = useState(false);
  const [allListMode, setAllListMode] = useState(true);

  const [sidebarMode, setSidebarMode] = useState(null);
  const [inventoryPopupMeta, setInventoryPopupMeta] = useState(null);
  const [assigningInventory, setAssigningInventory] = useState(false);
  const [removeUnitAlertMeta, setRemoveUnitAlertMeta] = useState(null);
  const [removingInventory, setRemovingInventory] = useState(false);
  const [editingInventory, setEditingInventory] = useState(null);
  const [zipCodes, setZipCodes] = useState([]);
  const [createCrmMeta, setCreateCrmMeta] = useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [importInventoryPopupMeta, setImportInventoryPopupMeta] = useState(null);
  const [deleteFileModalMeta, setDeleteFileModalMeta] = useState(null);
  const [hideForm, setHideForm] = useState(false);

  const fetchContacts = async ({ sortBy, maxLimit, descSort, filters, query }) => {
    setLoading(true);
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_SEARCH_NEW,
      requestBody: {
        filter: filters,
        sort: { [sortBy]: descSort ? -1 : 1 },
        query,
        pageNumber: 1,
        pageSize: maxLimit === 'All' ? undefined : maxLimit,
        returnPaths: RETURN_PATHS.CONTACT,
        populate: false
      }
    });
    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setAllListMode(true);
    setContacts(response.results);
  };

  const filterContacts = () => {
    setFilteredContacts(
      allListMode || !activeStatusObject ? [...contacts] : contacts.filter(c => c['status'] === activeStatusObject.name)
    );
  };

  useEffect(() => {
    setStatuses(statuses.map(s => ({ ...s, count: contacts.filter(r => r['status'] === s.name).length })));
  }, [contacts]);

  useEffect(() => filterContacts(), [contacts, activeStatusObject, allListMode]);

  useEffect(() => {
    const activeInFilteredIndex = activeContact
      ? filteredContacts.findIndex(c => c['_id'] === activeContact['_id'])
      : -1;
    const contact = filteredContacts[activeInFilteredIndex !== -1 ? activeInFilteredIndex : 0];
    updateContactState(contact);
  }, [filteredContacts]);

  const setUpForm = () => {
    const form = cloneDeep(editFormJson(role, true));
    const newFormJson = addDocHubFormFieldsToForm({ formJson: form, appChoices, model: 'Contact' });
    addChoicesToCRMForm({ appChoices, form: newFormJson, ignoreTableFields: true });
    setZipCodes(appChoices.find(a => a.key === 'zipCodes')['values']);

    if (['super admin', 'admin', 'sales manager'].includes(role)) {
      const members = (['super admin', 'admin'].includes(role)
        ? appChoices
            .find(c => c.key === 'members')
            .values.filter(m => ['super admin', 'admin', 'sales', 'sales manager'].includes(m.role.toLowerCase()))
        : [getProfileFromLocalStorage(), ...getSalesRepOfSalesManager()]
      ).sort((m1, m2) => m1.name.localeCompare(m2.name));

      setAttribute(newFormJson, 'contactOwner', 'options', members.map(s => s['name']));
      setAttribute(newFormJson, 'contactOwner', 'optionValues', members.map(s => s['_id']));
    }

    setForm(newFormJson);
  };

  useEffect(() => {
    setUpForm();
  }, []);

  useEffect(() => {
    setUploadedFiles([]);

    if (!activeContact) {
      setActiveStatusObject(null);
      return;
    }

    setActiveStatusObject(statuses.find(s => s.name === activeContact['status']));

    setHideForm(true);

    setTimeout(() => {
      setHideForm(false);
    }, 100);

    if (!activeContact.hasOwnProperty('loadingError') && isObjectIncomplete(activeContact, RETURN_PATHS.CONTACT)) {
      fetchCompleteContact(activeContact);
    }
  }, [activeContact]);

  const fetchCompleteContact = async activeContact => {
    updateContactList({ ...activeContact, loadingError: false });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_SEARCH_NEW,
      requestBody: {
        filter: { _id: activeContact._id },
        populate: true
      }
    });

    if (error) {
      updateContactList({ ...activeContact, loadingError: true });
      toast.error(error);
      return;
    }

    updateContactList(response.results[0]);
  };

  const updateContactState = contact => {
    if (!contact) return setActiveContact(null);

    const clonedContact = cloneDeep(contact);
    console.log({ optional2: contact.optionalEquipmentAndAccessories });

    convertKeyValueToFormObject(clonedContact, appChoices);
    setActiveContact(clonedContact);

    setLoading(false);
  };

  const onCrmEditFormSubmit = async form => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    //checks
    const valid = validateAndCleanupCRMForm(form);
    if (!valid) return;

    form['files'] = [...activeContact.files.map(f => f._id), ...uploadedFiles.map(f => f.id)];

    toast.info('Please wait, processing contact..');

    const formData = findDifferenceInObjects(form, activeContact, ['_id']);
    const { response, error } = await makeApiRequests({
      requestBody: formData,
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact._id),
      method: 'PUT'
    });

    if (error) {
      return toast.error(error);
    }

    toast.success(`Contact updated successfully!`);

    updateContactList(response);

    //if status has been changed, show status info as well
    if (Object.keys(formData).includes('status')) {
      toast.info(`Contact ${response['buyer']} moved to ${response['status']}.`);
    }
  };

  const autoFillMailingAddress = e => {
    try {
      const zipCode = e ? e.target.value : document.getElementById('mailingZipCodePipeline').value;
      const zipCodeFiltered = zipCodes.filter(zip => zipCode == zip['Zip Code']);

      if (zipCodeFiltered.length > 0) {
        document.getElementById('mailingStatePipeline').value = zipCodeFiltered[0]['Property State'];
        document.getElementById('mailingCityPipeline').value = zipCodeFiltered[0]['Property City'];
        document.getElementById('mailingCountyPipeline').value = zipCodeFiltered[0]['Property County'];
      }
    } catch (e) {}
  };

  const autoFillAddress = e => {
    try {
      const zipCode = e ? e.target.value : document.getElementById('mailingZipCodePipeline').value;
      const zipCodeFiltered = zipCodes.filter(zip => zipCode == zip['Zip Code']);

      if (zipCodeFiltered.length > 0) {
        document.getElementById('propertyStatePipeline').value = zipCodeFiltered[0]['Property State'];
        document.getElementById('propertyCityPipeline').value = zipCodeFiltered[0]['Property City'];
        document.getElementById('propertyCountyPipeline').value = zipCodeFiltered[0]['Property County'];
      }
    } catch (e) {}
  };

  window['autoFillMailingAddress'] = autoFillMailingAddress;
  window['autoFillAddress'] = autoFillAddress;
  window['onCrmEditFormSubmit'] = onCrmEditFormSubmit;

  const onContactSelect = contact => {
    const index = filteredContacts.findIndex(c => c['_id'] === contact['_id']);
    if (index !== -1) updateContactState(filteredContacts[index]);
  };

  const updateContactList = (contact, forFile) => {
    const contactIndex = contacts.findIndex(c => c['_id'] === contact['_id']);
    if (contactIndex !== -1) {
      contacts[contactIndex] = contact;
      setContacts([...contacts]);
    } else {
      setContacts([contact, ...contacts]);
    }

    setCreateCrmMeta(null);
    if (!forFile) setSidebarMode(null);
  };

  const toggleContact = async (label, key, value) => {
    setToggling(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact._id),
      requestBody: { [key]: value },
      method: 'PUT'
    });

    setToggling(false);
    if (error) {
      toast.error(error);
      return;
    }

    updateContactList(response);

    //if status has been changed, show status info as well
    if (key === 'status') {
      toast.info(`Contact ${response['buyer']} moved to ${response['status']}.`);
    } else {
      toast.success(`Contact's ${label} updated successfully!`);
    }

    setToggling(false);
  };

  const onContactInfoClick = () => {
    setSidebarMode('view');
  };

  const onContactEditClick = () => {
    setSidebarMode('edit');
  };

  const onContactDelete = contactId => {
    const index = contacts.findIndex(c => c['_id'] === contactId);
    if (index !== -1) {
      contacts.splice(index, 1);
      setContacts([...contacts]);
    }
    setSidebarMode(null);
  };

  const onContactInfoSideBarHide = () => {
    setSidebarMode(null);
  };

  const onLinkUnitClick = replacingInventoryUnit => {
    setInventoryPopupMeta({ replacingInventoryUnit });
  };

  const onInventorySubmit = async inventoryUnits => {
    const inventoryUnit = inventoryUnits[0];
    setAssigningInventory(true);

    let newUnitIds;
    if (activeContact['inventoryUnit']) {
      newUnitIds = activeContact['inventoryUnit'].map(u => u._id);
      if (inventoryPopupMeta['replacingInventoryUnit']) {
        const replacingIndex = newUnitIds.indexOf(inventoryPopupMeta['replacingInventoryUnit']['_id']);
        newUnitIds[replacingIndex] = inventoryUnit['_id'];
      } else {
        newUnitIds.push(inventoryUnit['_id']);
      }
    } else {
      newUnitIds = [inventoryUnit['_id']];
    }

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact['_id']),
      requestBody: { inventoryUnit: newUnitIds },
      method: 'PUT'
    });

    setAssigningInventory(false);

    if (error) {
      toast(error, {
        type: 'error'
      });
      return;
    }

    toast(`Inventory unit updated successfully!`, {
      type: 'success'
    });

    updateContactList(response);
    setInventoryPopupMeta(null);
  };

  const onRemoveUnitClick = removingInventoryUnit => {
    setRemoveUnitAlertMeta({ removingInventoryUnit });
  };

  const removeInventoryUnit = async () => {
    setRemovingInventory(true);

    let newUnitIds = activeContact['inventoryUnit'].map(u => u._id);
    const removingIndex = newUnitIds.indexOf(removeUnitAlertMeta['removingInventoryUnit']['_id']);
    newUnitIds.splice(removingIndex, 1);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact['_id']),
      requestBody: { inventoryUnit: newUnitIds },
      method: 'PUT'
    });

    setRemovingInventory(false);

    if (error) {
      toast(error, {
        type: 'error'
      });
      return;
    }

    toast(`Inventory unit removed successfully!`, {
      type: 'success'
    });

    updateContactList(response);
    setRemoveUnitAlertMeta(null);
  };

  const onInventoryEdit = updatedInventory => {
    const inventoryUnits = activeContact['inventoryUnit'];

    const existingIndex = inventoryUnits.findIndex(u => u['_id'] === updatedInventory['_id']);
    if (existingIndex !== -1) {
      activeContact['inventoryUnit'][existingIndex] = updatedInventory;
    }

    updateContactList(activeContact);
    setEditingInventory(null);
  };

  const onInventoryDelete = deletedInventoryId => {
    const inventoryUnits = activeContact['inventoryUnit'];

    const existingIndex = inventoryUnits.findIndex(u => u['_id'] === deletedInventoryId);
    if (existingIndex !== -1) {
      activeContact['inventoryUnit'].splice(existingIndex, 1);
    }

    updateContactList(activeContact);
    setEditingInventory(null);
  };

  const onUnitSelect = unit => {
    setInventoryPopupMeta({ ...inventoryPopupMeta, selectedUnit: unit });
  };

  const onImportClick = () => {
    if (activeContact['inventoryUnit'] && activeContact['inventoryUnit'].length > 1) {
      setImportInventoryPopupMeta({ crm: activeContact });
      return;
    }

    history.push({
      pathname: '/contract/create',
      state: {
        preFillValues: activeContact,
        inventoryUnit:
          activeContact['inventoryUnit'] && activeContact['inventoryUnit'].length > 0
            ? activeContact['inventoryUnit'][0]
            : undefined
      }
    });
  };

  const onDuplicateClick = () => {
    setCreateCrmMeta({ prefillContact: { ...activeContact } });
  };

  const onImportInventorySubmit = inventoryList => {
    const inventory = inventoryList[0];
    const { crm } = importInventoryPopupMeta;

    history.push({
      pathname: '/contract/create',
      state: {
        preFillValues: crm,
        inventoryUnit: inventory
      }
    });
  };

  const onImportUnitSelect = unit => {
    setImportInventoryPopupMeta({ ...importInventoryPopupMeta, selectedUnit: unit });
  };

  const onFileDeleteClick = file => {
    setDeleteFileModalMeta({ file });
  };

  const deleteFile = async () => {
    const { file: toBeDeletedFile } = deleteFileModalMeta;
    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: true });

    const newFileArray = activeContact.files.filter(f => f._id !== toBeDeletedFile._id).map(f => f._id);

    const { response, error } = await makeApiRequests({
      requestBody: { files: newFileArray },
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(activeContact._id),
      method: 'PUT'
    });

    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: false });
    if (error) {
      return toast.error(error);
    }

    setDeleteFileModalMeta(null);
    updateContactList(response);
    deleteFileFromServer(toBeDeletedFile);
  };

  return (
    <Container fluid className={'h-100 pt-2 pb-3 px-md-2'}>
      <SearchBox
        appChoices={appChoices}
        filterCreationOptions={createFilterOptionsForContact(role)}
        sortByOptions={contactSearchSortByOptions}
        maxLimitOptions={[10, 20, 50, 100, 200, 'All']}
        onSearchOptionsChange={fetchContacts}
        disabled={loading}
      />
      {loading && <HorizontalProgress text={'Fetching contacts...'} />}
      <hr />

      {contacts.length > 0 ? (
        <Row className="h-100 px-md-3">
          <Col xs={4} md={2} className="p-0 pb-5 overflow-auto h-100">
            <ListFilterText allListMode={allListMode} onClearFilterClick={() => setAllListMode(true)} />
            <ContactList
              contacts={filteredContacts}
              activeId={activeContact ? activeContact['_id'] : ''}
              onSelect={onContactSelect}
              appChoices={appChoices}
            />
          </Col>
          <Col xs={8} md={10} className="h-100 overflow-auto px-1 mb-5">
            <StatusTabs
              statuses={statuses}
              activeStatusObject={activeStatusObject}
              onStatusClick={s => {
                setAllListMode(false);
                setActiveStatusObject(s);
              }}
            />
            <Card className="mt-2 mb-5">
              {activeContact ? (
                isObjectIncomplete(activeContact, RETURN_PATHS.CONTACT) ? (
                  <PipelineLoaderCard
                    loadingError={activeContact?.loadingError}
                    title={[activeContact['buyer'], activeContact['coBuyer']].filter(Boolean).join(', ')}
                    onRetryClick={() => fetchCompleteContact(activeContact)}
                  />
                ) : (
                  <>
                    <Card.Header className="bg-primary text-white px-2 py-1  smallFont">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h6 className="mb-0 d-inline-block midFont">
                            <Link
                              className=" text-white"
                              to={'/crm/' + activeContact['_id']}
                              target="_blank"
                              onClick={e => e.stopPropagation()}
                            >
                              {activeContact['buyer']}
                              {activeContact['coBuyer'] ? `, ${activeContact['coBuyer']}` : ''}
                              <ArrowUpRightCircle size={14} />
                            </Link>
                          </h6>
                          <InfoSquareFill className="align-text-bottom ml-2 hover-light" onClick={onContactInfoClick} />
                          <PencilSquare className="align-text-bottom mx-2 hover-light" onClick={onContactEditClick} />

                          {activeContact['manufacturer'] && activeContact['nanufacturer'].isNPS && (
                            <Badge className="align-text-bottom mr-2" variant={'info'}>
                              NPS Sale
                            </Badge>
                          )}
                        </div>

                        <div className="align-self-center">
                          {['super admin', 'admin', 'sales', 'sales manager'].includes(role) && (
                            <Link
                              target="_blank"
                              to={`/dochub/print-groups?contactId=${activeContact['_id']}&source=Contact`}
                            >
                              <button className="btn btn-sm btn-info py-0 px-1 mx-1">
                                <Printer className="align-text-top mr-2" />
                                Print
                              </button>
                            </Link>
                          )}
                          <Button
                            onClick={() => setShowFileViewer(true)}
                            variant="dark"
                            size="sm"
                            className="py-0 px-1 mr-2"
                          >
                            <Files className="align-text-top mr-2" />
                            View Files
                          </Button>
                          {['super admin', 'admin'].includes(role) && (
                            <Button onClick={onImportClick} size="sm" variant="success" className="py-0 px-1 mr-2">
                              Import
                            </Button>
                          )}
                          <Button onClick={onDuplicateClick} size="sm" variant="success" className="py-0 px-1">
                            Duplicate
                          </Button>
                        </div>
                      </div>
                    </Card.Header>
                    {!hideForm && (
                      <Card.Body className="p-2 fade-in">
                        {activeContact['exportedContract'] && (
                          <>
                            <h6 className="midFont text-right text-muted  mt-3 mb-0">
                              <i>
                                This contact has already been exported to contract{' '}
                                <Link target="_blank" to={`/contract/${activeContact['exportedContract']._id}`}>
                                  <b className="text-dark">{activeContact['exportedContract']['buyer']}</b>
                                </Link>
                                . You are not allowed to edit it.
                              </i>
                            </h6>
                            <hr className="my-2" />
                          </>
                        )}
                        <OverViewColumns
                          className="px-3"
                          fontSize="smallFont"
                          md={2}
                          data={activeContact}
                          fieldsToShow={staticSectionFields}
                        />
                        <hr className="my-2" />

                        {!activeContact['exportedContract'] && (
                          <div className="mx-2">
                            <QuickToggles
                              data={activeContact}
                              toggleOptions={contactToggleOptions({ contact: activeContact, appChoices })}
                              onToggle={toggleContact}
                              toggling={toggling}
                            />
                          </div>
                        )}
                        <hr className="my-2" />
                        <div className="m-3">
                          <PotentialUnitsOverview
                            onLinkNewUnitClick={onLinkUnitClick}
                            onRemoveUnitClick={onRemoveUnitClick}
                            onChangeUnitClick={onLinkUnitClick}
                            inventoryUnits={activeContact['inventoryUnit']}
                            onEditUnitClick={unit => setEditingInventory(unit)}
                            disabled={activeContact['exportedContract']}
                          />
                        </div>
                        {!activeContact['exportedContract'] &&
                          ['super admin', 'admin', 'sales', 'sales manager'].includes(role) && (
                            <File
                              uploadedFiles={uploadedFiles}
                              onUploadedFilesChange={setUploadedFiles}
                              containerClassName="mx-3 mb-2"
                            />
                          )}
                        {form && !createCrmMeta && sidebarMode === null && !editingInventory && (
                          <FormGenerator
                            prefix="crm-pipeline"
                            formJson={form}
                            formValues={{ update: getObjectForPrefill(activeContact) }}
                            formDisabled={
                              activeContact['exportedContract'] ||
                              !['super admin', 'admin', 'sales', 'sales manager'].includes(role)
                            }
                          />
                        )}
                        <hr />
                        <FileViewer
                          show={showFileViewer}
                          onHide={() => setShowFileViewer(false)}
                          files={activeContact['files']}
                          onFileDeleteClick={onFileDeleteClick}
                          driveFolder={activeContact['driveFolder']}
                        />
                        <AlertModal
                          show={deleteFileModalMeta !== null}
                          alertText={`Are you sure to delete this file (${
                            deleteFileModalMeta
                              ? deleteFileModalMeta.file.metadata && deleteFileModalMeta.file.metadata.originalName
                                ? deleteFileModalMeta.file.metadata.originalName
                                : deleteFileModalMeta.file.filename
                              : ''
                          }). You will lose access to this file permanently?`}
                          showProgress={deleteFileModalMeta && deleteFileModalMeta.deletingFile}
                          progressText="Deleting file..."
                          continueButtonText="Delete"
                          onDismissClick={() => setDeleteFileModalMeta(null)}
                          onHide={() => setDeleteFileModalMeta(null)}
                          onContinueClick={deleteFile}
                        />
                      </Card.Body>
                    )}
                    {!activeContact['exportedContract'] && (
                      <>
                        <InventoryPicker
                          excludedUnitIds={
                            activeContact['inventoryUnit'] ? activeContact['inventoryUnit'].map(u => u['_id']) : []
                          }
                          selectedUnits={
                            inventoryPopupMeta && inventoryPopupMeta.selectedUnit
                              ? [inventoryPopupMeta.selectedUnit]
                              : []
                          }
                          onUnitSelect={onUnitSelect}
                          show={inventoryPopupMeta !== null}
                          submitting={assigningInventory}
                          onSubmit={onInventorySubmit}
                          onInventoryPickerClose={() => setInventoryPopupMeta(null)}
                        />
                        {/** FOR IMPORT [NEEDS TO BE MERGED TO SINGLE PICKER ABOVE] */}
                        <InventoryPicker
                          title="Select an inventory unit to be imported"
                          show={importInventoryPopupMeta !== null}
                          selectedUnits={
                            importInventoryPopupMeta && importInventoryPopupMeta.selectedUnit
                              ? [importInventoryPopupMeta.selectedUnit]
                              : []
                          }
                          onUnitSelect={onImportUnitSelect}
                          onSubmit={onImportInventorySubmit}
                          fetchedInventories={
                            importInventoryPopupMeta && importInventoryPopupMeta['crm']['inventoryUnit']
                          }
                          onInventoryPickerClose={() => setImportInventoryPopupMeta(null)}
                        />
                        <AlertModal
                          show={removeUnitAlertMeta != null}
                          alertText="Are you sure to remove this inventory unit from contact?"
                          showProgress={removingInventory}
                          progressText="Removing unit..."
                          onDismissClick={() => setRemoveUnitAlertMeta(null)}
                          onHide={() => setRemoveUnitAlertMeta(null)}
                          onContinueClick={removeInventoryUnit}
                        />
                        {activeContact['inventoryUnit'] && activeContact['inventoryUnit'].length > 0 && (
                          <InventoryEditSideBar
                            appChoices={appChoices}
                            onHide={() => setEditingInventory(null)}
                            editingInventory={editingInventory}
                            onInventoryEdit={onInventoryEdit}
                            onInventoryDelete={onInventoryDelete}
                          />
                        )}
                      </>
                    )}
                  </>
                )
              ) : (
                <>
                  <Card.Header className="bg-primary text-white p-2">
                    <h6 className="mb-0">Select a contact</h6>
                  </Card.Header>
                  <Card.Body>
                    <h6 className="p-5 text-center">No contact selected. Selected contact will appear here...</h6>
                  </Card.Body>
                </>
              )}
            </Card>
          </Col>
        </Row>
      ) : (
        !loading && <NotFound text="No contacts to show!" />
      )}
      <CreateSideBar
        onContactAdd={updateContactList}
        appChoices={appChoices}
        show={createCrmMeta}
        onHide={() => setCreateCrmMeta(null)}
        prefillContact={createCrmMeta?.prefillContact}
      />
      <FloatingButton
        variant="success"
        text={'Add New Contact'}
        Icon={PlusCircle}
        onClick={() => setCreateCrmMeta({})}
      />
      <ContactInfoSideBar
        appChoices={appChoices}
        contact={contacts?.find(c => c._id === activeContact?._id)}
        sidebarMode={sidebarMode}
        onContactDelete={onContactDelete}
        onContactEdit={updateContactList}
        onContactInfoSideBarHide={onContactInfoSideBarHide}
      />
    </Container>
  );
};

export default CRMPipeline;
