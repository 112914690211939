import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { ArrowDownCircle, ArrowUpCircle, PlusCircleFill, Trash2Fill } from 'react-bootstrap-icons';
import TextButton from '../../components/TextButton';
import { mapFieldToElement } from '../helpers/TypeToElemMapper';
import { normalizeId } from '../helpers/utility';

const getInitialRowCount = (required, initialRowCount, preValue) => {
  if (preValue) return preValue.length;

  return required && initialRowCount < 1 ? 1 : initialRowCount;
};

const TableInput = ({
  id,
  title,
  tableFields: fields = [],
  initialRowCount = 0,
  className = '',
  value,
  preValue,
  onFormChange,
  size,
  disabled = false,
  required = false,
  fixedRowCount = false,
  defaultHideOptions = true,
  showHideToggle = true,
  dropdownOptions,
  onChange
}) => {
  let defaultValue = value || preValue;
  const [rowCount, setRowCount] = useState(
    Array(getInitialRowCount(required, initialRowCount, defaultValue))
      ?.fill(0)
      .map((_, index) => index)
  );

  const [hideComponent, setHideComponent] = useState(defaultHideOptions);

  const tableId = id;

  const removeRow = rowIndex => {
    if (rowCount?.length) {
      setRowCount([...rowCount?.filter(rc => rc !== rowIndex)]);
    }
  };
  const addRow = () => {
    setRowCount([...rowCount, rowCount?.length]);
  };

  const createTableRows = rowCount => {
    let tempRows = [];
    rowCount.forEach((rowIndex, index) => {
      tempRows.push(createSingleRow(rowIndex, index));
    });

    return tempRows;
  };

  const createSingleRow = (rowNum, rowIndex) => {
    return (
      <tr key={rowNum}>
        {[...fields, { title: 'Action' }].map((field, index) => (
          <>
            <td key={index} className={`${size === 'sm' ? 'p-1' : ''} text-center`}>
              {field?.title !== 'Action' ? (
                <>
                  {mapFieldToElement({
                    ...field,
                    id: normalizeId(tableId + '-' + (field.key || field.title) + '-' + rowIndex),
                    preValue: defaultValue && defaultValue[rowNum] ? defaultValue[rowNum][index] : null,
                    onFormChange,
                    size,
                    disabled,
                    style: {
                      fontSize: '10px',
                      height: field?.type === 'datalist-text' ? '25px' : 'auto'
                    },
                    options: dropdownOptions || field.options || [],
                    [['text', 'number'].includes(field.type) ? 'onTextChange' : 'onChange']: onChange
                  })}
                </>
              ) : (
                <Trash2Fill
                  className={`smallFont  ${disabled ? 'text-muted' : 'text-danger pointer'}`}
                  onClick={e => (disabled ? e.stopPropagation() : removeRow(rowNum))}
                  title="Delete This Row"
                />
              )}
            </td>
          </>
        ))}
      </tr>
    );
  };

  useEffect(() => {
    onChange && window[onChange] && window[onChange]();
  }, [rowCount, onChange]);
  return (
    <>
      <div className={`${hideComponent ? 'd-none' : ''} `}>
        <Table id={tableId} bordered className={'rounded ' + className} responsive>
          {/* mapping header */}
          <thead>
            <tr className={`bg-primary text-white smallFont `}>
              {[...fields, { title: 'Action' }].map((field, index) => (
                <th key={index} className={` text-center  ${size === 'sm' ? '' : ''} py-1 px-0`}>
                  {field.title}
                </th>
              ))}
            </tr>
          </thead>
          {/* mapping inputs */}
          {rowCount?.length > 0 && <tbody>{createTableRows(rowCount)}</tbody>}
          {rowCount?.length === 0 && (
            <tfoot>
              <tr>
                <td className={` mid text-center  ${size === 'sm' ? 'p-1' : ''}`} colSpan={fields.length}>
                  Nothing to show
                </td>
              </tr>
            </tfoot>
          )}
        </Table>
        {!disabled && !fixedRowCount && (
          <Row className="text-right">
            <Col xs={12}>
              <TextButton Icon={PlusCircleFill} variant="success" text="Add A Row" onClick={addRow} />
            </Col>
          </Row>
        )}
      </div>
      {showHideToggle && (
        <h6 className="hover-underline smallFont text-primary mb-2" onClick={() => setHideComponent(!hideComponent)}>
          {hideComponent ? (
            <>
              Show Options <ArrowDownCircle size={13} />
            </>
          ) : (
            <>
              Hide Options <ArrowUpCircle size={13} />
            </>
          )}
        </h6>
      )}
    </>
  );
};

export default TableInput;
