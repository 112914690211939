import moment from 'moment';
import React from 'react';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { ClipboardCheck, Gear, Globe, Mailbox, Phone } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { copyServiceDetails } from '../../helpers/global';

const statusToVariant = {
  Pending: 'primary',
  Claimed: 'info',
  Resolved: 'success'
};

const RequestItem = ({ request, fromBrowse = false, onClaimClick, onResolveClick, disableButton = false }) => {
  return (
    <div style={{ fontSize: 14 }} className="d-flex mt-3 p-2 rounded">
      <div className="text-center mb-3 p-2 rounded">
        <Gear size={34} />
      </div>
      <div className="flex-grow-1 pl-2">
        <div className="d-flex">
          <div className="flex-grow-1 align-self-center">
            <h6 className="d-inline-block mr-3 mb-0">{request['name']}</h6>
            <Badge className="align-text-bottom" variant={statusToVariant[request['status'] || 'Pending']}>
              {request['status'] || 'Pending'}
            </Badge>
          </div>
          <div>
            <p className="mb-0 mr-2  text-secondary d-inline-block">
              <b>
                <span className="text-dark">Request Date: </span>
              </b>
              {moment(request['createdAt']).format('MMMM Do YYYY')}
            </p>
            <Button
              onClick={e => {
                e.stopPropagation();
                copyServiceDetails(request);
                toast.success('Copied!');
              }}
              className="ml-2"
              variant="outline-dark"
              size="sm"
            >
              <ClipboardCheck className="mr-2" />
              <span className="align-middle">Copy</span>
            </Button>
          </div>
        </div>

        <hr className="my-2" />
        <Row className="mt-1">
          <Col xs={12} md={4}>
            <p className="mb-0  text-secondary">
              <b>
                <span className="text-dark">Service ID: </span>
              </b>
              {request['_id']}
            </p>
          </Col>

          <Col xs={4}>
            <p className="mb-0  text-secondary">
              <b>
                <span className="text-dark">Location of problem: </span>
              </b>
              {request['locationOfProblem'].join(', ')}
            </p>
          </Col>
          {request['resolvedDate'] && (
            <Col xs={12} md={4}>
              <p className="mb-0  text-secondary">
                <b>
                  <span className="text-dark">Resolved Date: </span>
                </b>
                {moment(request['resolvedDate']).format('MMMM Do YYYY')}
              </p>
            </Col>
          )}
        </Row>
        <Row className="mt-1">
          <Col xs={12} md={4}>
            <a className="text-secondary" href={`mailto:${request['emailAddress']}`}>
              <Mailbox className="mr-1 align-text-top text-dark" />
              {request['emailAddress']}
            </a>
          </Col>
          <Col xs={12} md={4}>
            <a className="text-secondary" href={`tell:${request['phoneNumber']}`}>
              <Phone className="mr-1 align-text-top text-dark" />
              {request['phoneNumber']}
            </a>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-0 text-secondary">
              <Globe className="mr-1 align-text-top text-dark" />
              {request['address']}
            </p>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={12} md={4}>
            <p className="mb-0  text-secondary">
              <b>
                <span className="text-dark">Type of Home: </span>
              </b>
              {request['typeOfHome']}
            </p>
          </Col>

          <Col xs={12} md={4}>
            <p className="mb-0  text-secondary">
              <b>
                <span className="text-dark">Home Manufacturer: </span>
              </b>
              {request['homeManufacturer']}
            </p>
          </Col>
          <Col xs={12} md={4}>
            <p className="mb-0  text-secondary">
              <b>
                <span className="text-dark">Type: </span>
              </b>
              {request['type'].join(', ')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-2">
            <p className="mb-0 mr-2  text-secondary d-inline-block">
              <b>
                <span className="text-dark">Service Issue: </span>
              </b>
            </p>
            <div className="border bg-primary-light rounded px-2 py-1 mt-1">{request['serviceIssue']}</div>
          </Col>
          <Col xs={12} className="mt-2">
            <p className="mb-0 mr-2  text-secondary d-inline-block">
              <b>
                <span className="text-dark">Remarks: </span>
              </b>
            </p>
            <div className="border bg-primary-light rounded px-2 py-1 mt-1">{request['remarks']}</div>
          </Col>
        </Row>
        {request['resolutionMessage'] && (
          <>
            <Row>
              <Col className="mt-2" xs={12}>
                <h6>
                  <b>Resolution Message:</b>
                </h6>
                <div className="alert alert-success rounded px-2 py-1 mt-1">{request['resolutionMessage']}</div>
              </Col>
            </Row>
          </>
        )}

        {!fromBrowse && (
          <Row className="mt-3">
            <Col className="text-right">
              {(!request['status'] || request['status'] === 'Pending') && (
                <Button size="sm" variant="info" onClick={onClaimClick} disabled={disableButton}>
                  Claim Request
                </Button>
              )}
              {request['status'] === 'Claimed' && (
                <Button size="sm" variant="success" onClick={onResolveClick} disabled={disableButton}>
                  Resolve Request
                </Button>
              )}
            </Col>
          </Row>
        )}

        <hr />
      </div>
    </div>
  );
};

export default RequestItem;
