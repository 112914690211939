import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Route, Switch, withRouter } from 'react-router';
import NotFound from '../../components/NotFound';
import CachedResponse from '../common/CachedResponse';
import PrintGroups from './PrintGroups';

const DocHub = () => {
  const [role] = useState(localStorage.getItem('user-role'));

  if (!['super admin', 'admin', 'sales', 'sales manager', 'service', 'inventory manager'].includes(role)) {
    return <NotFound text="You are not authorized to view this page" />;
  }

  const getActivePage = () => {
    return (
      <Switch>
        <Route exact path="/dochub/print-groups">
          <CachedResponse listEndpoint={'app-choices'} render={appChoices => <PrintGroups appChoices={appChoices} />} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    );
  };

  return (
    <>
      <Container fluid className="px-0 h-100">
        {getActivePage()}
      </Container>
    </>
  );
};

export default withRouter(DocHub);
