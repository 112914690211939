import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, FormControl, Modal, ProgressBar, Row, Table } from 'react-bootstrap';
import { CheckCircle, XCircle } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { getProfileFromLocalStorage } from '../../helpers/session';
import Loader from '../Loader';

const dateOptions = ['Week', 'Month', 'Year'];

const Schedule = ({ appChoices }) => {
  const [role] = useState(localStorage.getItem('user-role'));
  const [user] = useState(getProfileFromLocalStorage());
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState();
  const [activeDateOption, setActiveDateOption] = useState(dateOptions[2]);
  const [techs] = useState([
    ...appChoices.find(ac => ac.key === 'members').values.filter(m => m.role === 'Tech'),
    'Other'
  ]);
  const [techSchedules, setTechSchedules] = useState([]);
  const [overviewSchedules, setOverviewSchedules] = useState(null);
  const [activeActionSchedule, setActiveActionSchedule] = useState(null);
  const [actionSubmitting, setActionSubmitting] = useState(false);
  const [rejectionMessage, setRejectionMessage] = useState('');

  const fetchContractsForSchedule = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH,
      requestBody: {
        filter: {
          tech: { $ne: null }
        }
      }
    });
    setLoading(false);

    if (error) {
      return toast.error(error);
    }

    setContracts(response);
  };

  useEffect(() => {
    fetchContractsForSchedule();
  }, []);

  useEffect(() => {
    if (activeDateOption && contracts) {
      createSchedule(contracts);
    }
  }, [activeDateOption, contracts]);

  useEffect(() => {
    setRejectionMessage('');
  }, [activeActionSchedule]);

  const createSchedule = contracts => {
    if (contracts.length === 0) {
      setOverviewSchedules(null);
      setTechSchedules([]);
      return;
    }

    const today = new Date();
    const start = today.getTime() / 1000;

    const count = activeDateOption === dateOptions[0] ? 7 : activeDateOption === dateOptions[1] ? 30 : 365;
    today.setDate(today.getDate() + count - 1);
    const end = today.getTime() / 1000;

    const data = { dates: [], data: {} };
    const techSchedules = [];

    contracts.forEach(c => {
      const tech = c.tech;
      const techName = c.tech?.name || 'N/A';

      const buyer = c['buyer'];
      const id = c._id;
      const techStatus = c.techStatus;

      [
        { key: 'walkthrough', dateKey: 'start' },
        { key: 'service', dateKey: 'start' },
        { key: 'makeready', dateKey: 'start' },
        { key: 'makeready', dateKey: 'end' }
      ].forEach(({ key, dateKey }) => {
        if (!c[key]?.[dateKey]) return;

        const momentDate = moment(c[key][dateKey]);
        const momentMilli = momentDate.unix();
        if (momentMilli < start || momentMilli > end) return;

        const formattedDate = momentDate.format('DD MMM, yyyy');
        const buyerObj = { buyer, type: `${key.toUpperCase()} ${dateKey.toUpperCase()}`, id };

        if (data.data[techName]) {
          if (data.data[techName][formattedDate]) {
            data.data[techName][formattedDate].push(buyerObj);
          } else {
            data.data[techName][formattedDate] = [buyerObj];
          }
        } else {
          data.data[techName] = { [formattedDate]: [buyerObj] };
        }

        if (data.dates.findIndex(d => d.formattedDate === formattedDate) === -1)
          data.dates.push({ formattedDate, timestamp: momentMilli });
      });

      if (c.walkthrough?.start && tech && tech._id === user._id && !techStatus) {
        const walkDate = moment(c.walkthrough.start).format('DD MMM, yyyy');
        techSchedules.push({ buyer, walkDate, id });
      }
    });

    if (data.dates.length > 0) {
      data.dates.sort((a, b) => a.timestamp - b.timestamp);
      setOverviewSchedules(data);
    } else {
      setOverviewSchedules(null);
    }

    setTechSchedules(techSchedules);
  };

  const onAcceptScheduleClick = schedule => {
    setActiveActionSchedule({ schedule, action: 'Accept' });
  };

  const onRejectScheduleClick = schedule => {
    setActiveActionSchedule({ schedule, action: 'Reject' });
  };

  const UpcomingJobs = () => {
    return (
      <>
        <h5>Upcoming Jobs</h5>
        <hr className="my-2" />
        {techSchedules.length > 0 ? (
          <UpcomingJobsTable />
        ) : (
          <h5 className="p-5 text-muted text-center">Nothing to show</h5>
        )}
      </>
    );
  };

  const UpcomingJobsTable = () => {
    return (
      <Table bordered className="mb-3">
        <thead>
          <tr className="bg-dark text-white text-center">
            <th>Buyer</th>
            <th>Walkthrough Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {techSchedules.map(s => (
            <tr>
              <td>{s['buyer']}</td>
              <td>{s['walkDate']}</td>
              <td className="text-center">
                <Button variant="outline-success" size="sm" className="mr-2" onClick={() => onAcceptScheduleClick(s)}>
                  <CheckCircle />
                </Button>
                <Button variant="outline-danger" size="sm" onClick={() => onRejectScheduleClick(s)}>
                  <XCircle />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const OverView = () => {
    return (
      <>
        <h5>Overview</h5>
        <hr className="my-2" />
        {overviewSchedules ? <OverViewTable /> : <h5 className="p-5 text-muted text-center">Nothing to show</h5>}
      </>
    );
  };

  const OverViewTable = () => {
    return (
      <div style={{ overflow: 'scroll', height: '90%' }}>
        <Table bordered hover>
          <thead>
            <tr
              className="bg-dark text-white text-center"
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 6
              }}
            >
              <th
                className="bg-dark text-white text-center w-25"
                style={{
                  position: 'sticky',
                  left: 0,
                  fontSize: 14
                }}
              >
                Tech
              </th>
              {overviewSchedules.dates.map(d => (
                <th style={{ fontSize: 14 }}>{d.formattedDate}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {techs.map(tech => {
              const t = typeof tech === 'object' ? tech.name : tech;
              return (
                <tr>
                  <td
                    className="bg-dark border-right outline-white text-white w-25"
                    style={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 5,
                      fontSize: 14
                    }}
                  >
                    {t}
                  </td>
                  {overviewSchedules.dates.map(d => (
                    <td className="text-center">
                      {overviewSchedules.data[t] && overviewSchedules.data[t][d.formattedDate]
                        ? overviewSchedules.data[t][d.formattedDate].map(c => (
                            <>
                              <Badge variant="info" className="mr-2 px-2" style={{ fontSize: 11 }}>
                                <a className="text-white d-block mb-1" href={`/contract/${c['id']}`} target="_blank">
                                  {c['buyer']}
                                </a>
                                ({c['type']})
                              </Badge>
                            </>
                          ))
                        : ''}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  const handleClose = () => {
    setActiveActionSchedule(null);
  };

  const submitAction = async () => {
    if (activeActionSchedule.action === 'Reject' && !rejectionMessage) {
      return toast.error('Rejection message is required!');
    }

    setActionSubmitting(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        rejectionMessage
      },

      endpoint: ENDPOINTS.CONTRACTS_CHANGE_WALKTHROUGH_ACTION(
        activeActionSchedule.schedule.id,
        activeActionSchedule.action.toLowerCase()
      )
    });

    setActionSubmitting(false);
    if (error) {
      setActiveActionSchedule(null);
      return toast.error(error);
    }

    toast(`Walkthrough ${activeActionSchedule.action.toLowerCase()}ed successfully!`, {
      type: 'success'
    });

    const existingIndex = contracts.findIndex(s => s._id === activeActionSchedule.schedule.id);
    if (existingIndex !== -1) {
      contracts.splice(existingIndex, 1);
      setContracts([...contracts]);
    }

    setActiveActionSchedule(null);
  };

  const ActionModal = () => {
    return (
      <Modal show={activeActionSchedule !== null} onHide={handleClose} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{activeActionSchedule && `${activeActionSchedule.action} schedule`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activeActionSchedule && activeActionSchedule.action === 'Accept' ? (
            <h6>Are you sure to accept the schedule?</h6>
          ) : (
            <>
              <h6>Please provide a rejection message</h6>
              <FormControl as="textarea" value={rejectionMessage} onChange={e => setRejectionMessage(e.target.value)} />
            </>
          )}
          {actionSubmitting && (
            <ProgressBar
              animated
              now={100}
              label={activeActionSchedule ? `${activeActionSchedule.action}ing schedule...` : ''}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={actionSubmitting}>
            Cancel
          </Button>
          <Button variant="danger" onClick={submitAction} disabled={actionSubmitting}>
            {activeActionSchedule && activeActionSchedule.action}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <Container fluid className={'h-100  py-3'}>
      <Row>
        <Col className="mb-3 text-right align-self-center">
          <h6 className="d-inline-block mb-0 mr-2">Date:</h6>
          {dateOptions.map(d => (
            <Button
              onClick={() => setActiveDateOption(d)}
              size="sm"
              className="mr-1"
              variant={activeDateOption === d ? 'primary' : 'outline-primary'}
              disabled={loading}
            >
              {d}
            </Button>
          ))}
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : (
        <>
          {role === 'tech' ? (
            <>
              <UpcomingJobs />
              {ActionModal()}
            </>
          ) : (
            <OverView />
          )}
        </>
      )}
    </Container>
  );
};

export default Schedule;
