import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, ListGroup } from 'react-bootstrap';
import { Pin } from 'react-bootstrap-icons';
import TextInput from '../../form-generator/components/TextInput';
import { contactSearchPaths, contractSearchPaths, inventoryPaths } from '../../helpers/constants';
import { search } from '../../helpers/search';

const searchPaths = [
  ...inventoryPaths,
  ...contractSearchPaths.map(p => `contract/${p}`),
  ...contactSearchPaths.map(p => `contacts[]/${p}`)
];

const allSalesLocationObject = { _id: 'all', location: 'All' };

const InventoryList = ({ inventories = [], activeId = '', onSelect, appChoices }) => {
  const [query, setQuery] = useState('');
  const [filteredInventories, setFilteredInventories] = useState([]);
  const [selectedSaleLocation, setSelectedSaleLocation] = useState(allSalesLocationObject);

  const salesLocations = useMemo(
    () => [allSalesLocationObject, ...appChoices.find(a => a.key === 'salesLocation').values],
    [appChoices]
  );

  useEffect(() => {
    const unitsByLocation = inventories.filter(
      c =>
        selectedSaleLocation._id === 'all' || (c['unitLocation']?._id || c['unitLocation']) === selectedSaleLocation._id
    );

    setFilteredInventories(search(unitsByLocation, searchPaths, query, '_id'));
  }, [query, selectedSaleLocation, inventories]);

  return (
    <div className="px-2">
      <div className="d-flex mb-2">
        <div className="flex-grow-1 mr-1">
          <TextInput size="sm" hint="Quick search..." onChange={e => setQuery(e.target.value)} />
        </div>
        <Dropdown>
          <Dropdown.Toggle size="sm" block variant="outline-primary" id="dropdown-basic">
            <Pin className="mr-3 align-text-bottom" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {salesLocations.map(s => (
              <Dropdown.Item
                className={`${s._id === selectedSaleLocation._id ? 'bg-primary text-white' : ''}`}
                onClick={() => setSelectedSaleLocation(s)}
                key={s._id}
              >
                {s.location}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <ListGroup>
        {filteredInventories.length > 0 ? (
          filteredInventories.map((c, i) => (
            <ListGroup.Item
              style={{ fontSize: 12 }}
              className={`py-1 text-${c['_id'] === activeId ? 'white' : 'dark'}`}
              key={c['_id']}
              action
              active={c['_id'] === activeId}
              onClick={() => onSelect && onSelect(c)}
            >
              {c['serialA'] || c['serialB'] || 'N/A'}
            </ListGroup.Item>
          ))
        ) : (
          <h6 className="py-5 text-center border rounded smallFont">No inventories to show </h6>
        )}
      </ListGroup>
    </div>
  );
};

export default InventoryList;
