import React from 'react';

const TextButton = ({
  Icon,
  onClick,
  text,
  variant = 'primary',
  iconSize = 12,
  className = '',
  disabled,
  style = {}
}) => {
  return (
    <span
      className={`d-inline-block px-1 text-${disabled ? 'muted' : variant} ${
        !disabled ? 'hover-light' : ''
      } rounded smallFont ${className}`}
      size="sm"
      onClick={onClick}
      style={style}
    >
      {Icon && <Icon size={iconSize} className="align-middle mr-1" />}
      <span className="align-middle">{text}</span>
    </span>
  );
};

export default TextButton;
