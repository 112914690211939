import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { ArrowUpRightCircle, Files, Mailbox, Pen, Trash } from 'react-bootstrap-icons';
import { withRouter } from 'react-router';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { normalizeId, setAttribute } from '../../form-generator/helpers/utility';
import { deleteFileFromServer, makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, contractMilestoneDates, typeOfTransactionOptions } from '../../helpers/constants';
import { addDocHubFormFieldsToForm, convertKeyValueToFormObject, getObjectForPrefill } from '../../helpers/formHelpers';
import AlertModal from '../AlertModal';
import Loader from '../Loader';
import NotFound from '../NotFound';
import SearchShortcut from '../SearchShortcut';
import Comments from '../comments/Comments';
import FileViewer from '../common/FileViewer';
import ServiceReportModal from '../common/ServiceReportModal';
import File from '../common/file';
import ContractedUnitOverview from '../inventory/ContractedUnitOverview';
import InventoryPicker from '../inventory/InventoryPicker';
import ContractReports from './ContractReports';
import NotificationModal from './NotificationModal';
import { addChoicesToContractForm, editFormJson, validateAndCleanupForm } from './form';
import { cloneDeep } from 'lodash';

const EditForm = ({
  contractFromSearch,
  appChoices,
  fromSearch,
  onContractEdit,
  onContractDelete,
  onAddInventoryPopupMetaChange,
  addInvenroryPopupMeta,
  onAddInvetoryPopupMetaClose
}) => {
  const { id } = useParams();
  const [role] = useState(localStorage.getItem('user-role'));
  const history = useHistory();

  const [contractId] = useState(contractFromSearch ? contractFromSearch._id : id);

  const [form, setForm] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [contractError, setContractError] = useState('');
  const [formDisabled, setFormDisabled] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contractDeleting, setContractDeleting] = useState(false);
  const [contract, setContract] = useState();
  const [zipCodes, setZipCodes] = useState([]);
  const [showServiceReportModal, setShowServiceReportModal] = useState(false);

  const [serviceOrderRows, setServiceOrderRows] = useState(null);
  const [serviceRowSubmitting, setServiceRowSubmitting] = useState(false);
  const [activeNotificationType, setActiveNotificationType] = useState();
  const [sendingNotification, setSendingNotification] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [inventoryPopupMeta, setInventoryPopupMeta] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [deleteFileModalMeta, setDeleteFileModalMeta] = useState(null);
  const [deleteCommentModalMeta, setDeleteCommentModalMeta] = useState(null);

  const fetchContract = async () => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH,
      requestBody: {
        filter: {
          _id: contractId
        }
      }
    });
    setLoading(false);

    if (error) {
      return setContractError(error);
    }

    if (response.length === 0) {
      return setContractError('No contract found!');
    }

    updateContractState(response[0]);
  };

  useEffect(() => {
    setUpForm();
    if (!fromSearch) {
      //fetch and show contract
      fetchContract();
    } else {
      //directly show fetched contract
      updateContractState(contractFromSearch);
    }
  }, []);

  const setUpForm = () => {
    const newFormJson = addDocHubFormFieldsToForm({ formJson: editFormJson, appChoices });
    addChoicesToContractForm({ appChoices, form: newFormJson, ignoreTableFields: true });
    setZipCodes(appChoices.find(a => a.key === 'zipCodes')['values']);
    setForm(newFormJson);
  };

  const updateContractState = contract => {
    const clonedContract = cloneDeep({
      ...contract,
      specialInstructions: contract.specialInstructions.map(specialInstruction => [specialInstruction])
    });

    setSelectedInventory(clonedContract['inventoryUnit']);

    //convert contract important dates to linear fields
    contractMilestoneDates.forEach(({ key, start, end, emailField, options }) => {
      const milestoneObject = clonedContract[key];
      if (milestoneObject) {
        clonedContract[start] = milestoneObject.start;
        clonedContract[end] = milestoneObject.end;
        clonedContract[emailField] = milestoneObject.notificationEmails
          ? milestoneObject.notificationEmails.map(e => (e === clonedContract['buyerEmail'] ? 'buyer-mhc' : e))
          : [];
        clonedContract[options] = milestoneObject.options;
      }
    });

    //manage type of transaction
    if (
      clonedContract['typeOfTransaction'] &&
      ['Real |', 'Personal |'].some(v => clonedContract['typeOfTransaction'].includes(v)) &&
      !typeOfTransactionOptions.includes(clonedContract['typeOfTransaction'])
    ) {
      const typeOtherValue = clonedContract['typeOfTransaction'].split(' | ');
      clonedContract['typeOfTransaction'] = `${typeOtherValue[0]} | Other`;
      clonedContract['otherType'] = typeOtherValue[1];
    }

    convertKeyValueToFormObject(clonedContract, appChoices);
    setContract(clonedContract);
    setLoading(false);
  };

  const updateDateSetup = () => {
    const value = document.getElementById('status').value;
    setAttribute(form, 'dateSetUpStart', 'required', value === 'Set');
    setForm({ ...form });
  };

  window['updateDateSetup'] = updateDateSetup;

  const onEditFormSubmit = async form => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    //checks
    const valid = validateAndCleanupForm(form);
    if (!valid) return;

    form['files'] = [...contract.files.map(f => f._id), ...uploadedFiles.map(f => f.id)];
    form['inventoryUnit'] = selectedInventory ? selectedInventory._id : null;

    form.specialInstructions = form.specialInstructions.flatMap(i => i);

    setButtonsDisabled(true);

    toast.info('Processing contract. This may take a while if Calendar events and email attachments are involved.');

    const { response, error } = await makeApiRequests({
      requestBody: form,
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contractId)
    });

    setButtonsDisabled(false);
    if (error) {
      return toast.error(error);
    }

    toast.success(`Contract updated successfully!`);
    setUploadedFiles([]);

    const details = {
      ...response
      //TODO manage files
    };

    if (onContractEdit) {
      setFormDisabled(true);
      onContractEdit && onContractEdit(details);
    } else {
      setLoading(true);
      setTimeout(() => setLoading(false), 100);
    }

    updateContractState(details);
  };

  const submitComment = async () => {
    toast.info('Adding comment...');
    setButtonsDisabled(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        comments: commentText
      },
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contractId)
    });

    setButtonsDisabled(false);

    if (error) {
      return toast.error(error);
    }

    toast.success(`Comment added successfully!`);
    setCommentText('');

    updateContractState(response);
    onContractEdit && onContractEdit(response);
  };

  const onCommentDeleteClick = comment => {
    setDeleteCommentModalMeta({ comment });
  };

  const deleteComment = async () => {
    const commentToDelete = deleteCommentModalMeta.comment._id;
    setDeleteCommentModalMeta({ ...deleteCommentModalMeta, showProgress: true });

    const { response, error } = await makeApiRequests({
      requestBody: {
        commentId: commentToDelete
      },
      method: 'POST',
      endpoint: ENDPOINTS.CONTRACTS_DELETE_COMMENT(contractId)
    });

    setDeleteCommentModalMeta({ ...deleteCommentModalMeta, showProgress: false });

    if (error) {
      return toast.error(error);
    }

    setDeleteCommentModalMeta(null);
    toast.success(`Comment deleted successfully!`);
    updateContractState(response);
    onContractEdit && onContractEdit(response);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const deleteContract = async () => {
    setContractDeleting(true);

    const { response, error } = await makeApiRequests({
      method: 'DELETE',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contractId)
    });

    setContractDeleting(false);

    if (error) {
      return toast.error(error);
    }

    toast(`Contract deleted successfully!`, {
      type: 'success'
    });

    if (fromSearch) {
      setShowDeleteModal(false);
      onContractDelete && onContractDelete(contractId);
    } else {
      history.push('/');
    }
  };

  const autoFillAddress = e => {
    try {
      const zipCode = e ? e.target.value : document.getElementById('zipCode').value;
      const zipCodeFiltered = zipCodes.filter(zip => zipCode == zip['Zip Code']);

      if (zipCodeFiltered.length > 0) {
        document.getElementById('propertyState').value = zipCodeFiltered[0]['Property State'];
        document.getElementById('propertyCity').value = zipCodeFiltered[0]['Property City'];
        document.getElementById('propertyCounty').value = zipCodeFiltered[0]['Property County'];
      }
    } catch (e) {}
  };

  const autoFillMailingAddress = e => {
    try {
      const zipCode = e ? e.target.value : document.getElementById('mailingZip').value;
      const zipCodeFiltered = zipCodes.filter(zip => zipCode == zip['Zip Code']);

      if (zipCodeFiltered.length > 0) {
        document.getElementById('mailingState').value = zipCodeFiltered[0]['Property State'];
        document.getElementById('mailingCity').value = zipCodeFiltered[0]['Property City'];
        document.getElementById('mailingCounty').value = zipCodeFiltered[0]['Property County'];
      }
    } catch (e) {}
  };

  window['autoFillMailingAddress'] = autoFillMailingAddress;
  window['autoFillAddress'] = autoFillAddress;

  window['onEditFormSubmit'] = onEditFormSubmit;

  const onServiceOrderReportClick = () => {
    setShowServiceReportModal(true);
  };

  const handleServiceReportClose = () => {
    setServiceOrderRows(null);
    setShowServiceReportModal(false);
  };

  const onServiceOrderRowsChange = rows => {
    setServiceOrderRows(rows);
  };

  const submitServiceReport = async () => {
    setServiceRowSubmitting(true);
    const submitRows = serviceOrderRows.map(r => {
      const obj = {
        ...r,
        Description: r['Description'] === 'Other' ? r['Description Other'] : r['Description'],
        Cause: r['Cause'] === 'Other' ? r['Cause Other'] : r['Cause'],
        'Repair Method': r['Repair Method'] === 'Other' ? r['Repair Method Other'] : r['Repair Method']
      };

      ['Repair Method Other', 'Cause Other', 'Description Other'].forEach(f => delete obj[f]);

      return obj;
    });

    const { response, error } = await makeApiRequests({
      requestBody: {
        serviceOrderReport: submitRows
      },
      method: 'PUT',
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contractId)
    });

    if (error) {
      setServiceRowSubmitting(false);
      toast.error(error);
      return;
    }

    toast.success('Service Order Report submitted successfully!');
    setServiceRowSubmitting(false);
    updateContractState(response);
    handleServiceReportClose();
  };

  const handleNotificationModalClose = () => {
    setActiveNotificationType(null);
  };

  const onSendNotificationClick = dateType => {
    if (!contract[dateType.start]) {
      toast.error(`Please provide ${dateType.key} date of the contract before sending notification!`);
      return;
    }

    setActiveNotificationType(dateType.key);
  };

  const onNotificationFormSubmit = async form => {
    const sendWalkthrough = form.notificationActions.includes('Send Walkthrough Report');
    const sendServiceOrderReport = form.notificationActions.includes('Send Service Order Report');

    if (sendServiceOrderReport) {
      if (!contract['serviceOrderReport']) {
        return toast.error(
          `You're choosing to attach a service order report, but it hasn't started yet. You can remove the attachment, or start the report before trying again.`
        );
      }

      if (!contract['inventoryUnit']) {
        return toast.error(
          `A inventory unit must be assigned to this contract before a Service Order Report can be sent. You can remove the attachment, or assign an inventory unit before trying again.`
        );
      }
    }

    setSendingNotification(true);

    toast.info(`Sending ${activeNotificationType} notification...`);

    const { error, response } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_NOTIFICATION,
      requestBody: {
        dateType: activeNotificationType,
        contractId,
        attachments: form.attachmentFiles,
        sendEmail: form.notificationActions.includes('Send Email'),
        createCalendarEvent: form.notificationActions.includes('Create Calendar Event'),
        sendWalkthrough,
        sendServiceOrderReport
      }
    });

    setSendingNotification(false);
    if (error) {
      toast.error(error);
      return;
    }

    toast.success('Notification sent successfully!');
    setActiveNotificationType(null);
  };

  window['onNotificationFormSubmit'] = onNotificationFormSubmit;

  const onButtonClick = () => {
    document.getElementById('edit').click();
  };

  const ButtonsRow = ({ bottom }) => (
    <Row>
      <Col xs={12} className={`text-right ${bottom ? 'my-4' : ' mb-3 mt-2'}`}>
        <Button disabled={buttonsDisabled} onClick={() => onButtonClick(false)} size="sm" className="ml-2">
          Save Record
        </Button>
      </Col>
    </Row>
  );

  const onLinkUnitClick = () => {
    setInventoryPopupMeta({});
  };

  const onRemoveUnitClick = () => {
    setSelectedInventory(null);
  };

  const onInventorySubmit = inventory => {
    setSelectedInventory(inventory[0]);
    setInventoryPopupMeta(null);
  };

  const onUnitSelect = unit => {
    setInventoryPopupMeta({ ...inventoryPopupMeta, selectedUnit: unit });
  };

  const onFileDeleteClick = file => {
    setDeleteFileModalMeta({ file });
  };

  const deleteFile = async () => {
    const { file: toBeDeletedFile } = deleteFileModalMeta;
    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: true });

    const newFileArray = contract.files.filter(f => f._id !== toBeDeletedFile._id).map(f => f._id);

    const { response, error } = await makeApiRequests({
      requestBody: { files: newFileArray },
      endpoint: ENDPOINTS.CONTRACTS_WITH_ID(contract._id),
      method: 'PUT'
    });

    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: false });
    if (error) {
      return toast.error(error);
    }

    setDeleteFileModalMeta(null);
    updateContractState(response);
    onContractEdit && onContractEdit(response, true);
    deleteFileFromServer(toBeDeletedFile);
  };

  const setDefaultValueToCheckedKeys = (inputId, appChoiceKey) => {
    try {
      const keyValueContainer = document.getElementById(inputId);

      const checkedKeys = [...keyValueContainer.getElementsByTagName('input')].filter(
        input => input.type === 'checkbox' && input.checked
      );

      checkedKeys.forEach(checkedKey => {
        const relatedTextBox = document.getElementById(`${inputId}-${normalizeId(checkedKey.value)}-key-value`);
        if (!relatedTextBox.value) {
          relatedTextBox.value =
            appChoices.find(c => c.key === appChoiceKey)?.values.find(c => c.name === checkedKey.value)?.defaultValue ||
            0;
        }
      });
    } catch (e) {}
  };

  const onCommissionSheetOptionsChange = e => {
    if (!e) return;

    setDefaultValueToCheckedKeys('commissionSheetOptions', 'Commission Options');
  };

  window['onCommissionSheetOptionsChange'] = onCommissionSheetOptionsChange;

  const onCommissionSheetLessOptionsChange = e => {
    if (!e) return;

    setDefaultValueToCheckedKeys('commissionSheetLessOptions', 'Commission Less Options');
  };
  window['onCommissionSheetLessOptionsChange'] = onCommissionSheetLessOptionsChange;

  return (
    <Container fluid className={!fromSearch ? 'px-md-3 py-4' : 'py-0 px-0'}>
      {!fromSearch && <SearchShortcut />}
      <Card>
        <Card.Body>
          {contractError ? (
            <NotFound text={contractError} />
          ) : (
            <>
              {!isLoading && form && contract ? (
                <>
                  {['super admin', 'admin', 'service'].includes(role) && (
                    <>
                      <Row>
                        <Col className="text-right">
                          <Link target="_blank" to={`/dochub/print-groups?contractId=${contract['_id']}`}>
                            <Button variant="outline-dark" size="sm" className="py-1 ml-2">
                              <ArrowUpRightCircle className="align-text-top mr-2" />
                              Go to print
                            </Button>
                          </Link>

                          <Button
                            onClick={() => setShowFileViewer(true)}
                            variant="outline-dark"
                            size="sm"
                            className="py-1 ml-2"
                          >
                            <Files className="align-text-top mr-2" />
                            View Files
                          </Button>

                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => setFormDisabled(false)}
                            className="py-1 ml-2"
                            disabled={!formDisabled || buttonsDisabled}
                          >
                            <Pen className="align-text-top mr-2" />
                            {formDisabled ? 'Edit' : 'Edit Mode Active'}
                          </Button>

                          {['super admin', 'admin'].includes(role) && (
                            <>
                              <Button
                                size="sm"
                                variant="outline-danger"
                                onClick={() => setShowDeleteModal(true)}
                                className="py-1 ml-2"
                                disabled={contractDeleting || buttonsDisabled}
                              >
                                <Trash className="align-text-top mr-2" />
                                Delete
                              </Button>
                              <AlertModal
                                alertText="Are you sure you want to delete the contract? This action cannot be undone!"
                                show={showDeleteModal}
                                onHide={handleDeleteModalClose}
                                continueButtonText="Delete"
                                continueButtonVariant="danger"
                                onContinueClick={deleteContract}
                                progressText={'Deleting contract...'}
                                showProgress={contractDeleting}
                                onDismissClick={handleDeleteModalClose}
                              />
                            </>
                          )}

                          <Dropdown className="d-inline-block ml-2">
                            <Dropdown.Toggle size="sm" variant="outline-info py-1">
                              <Mailbox className="align-text-top mr-2" /> Notify
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {contractMilestoneDates.map(type => (
                                <Dropdown.Item onClick={() => onSendNotificationClick(type)}>
                                  Notify {type.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>

                          <NotificationModal
                            activeNotificationType={activeNotificationType}
                            onHide={handleNotificationModalClose}
                            sendingNotification={sendingNotification}
                          />
                        </Col>
                      </Row>
                      <hr />
                    </>
                  )}

                  {!formDisabled && <ButtonsRow />}
                  <FormGenerator
                    formJson={form}
                    formValues={{ edit: getObjectForPrefill(contract) }}
                    formDisabled={formDisabled}
                  />
                  {!formDisabled && (
                    <File
                      uploadedFiles={uploadedFiles}
                      onUploadedFilesChange={setUploadedFiles}
                      containerClassName="mx-3"
                    />
                  )}

                  <div className="m-3">
                    <ContractedUnitOverview
                      disabled={formDisabled}
                      onLinkUnitClick={onLinkUnitClick}
                      onRemoveUnitClick={onRemoveUnitClick}
                      onChangeUnitClick={onLinkUnitClick}
                      inventoryUnit={selectedInventory}
                    />
                    <InventoryPicker
                      excludedUnitIds={selectedInventory ? [selectedInventory['ID']] : []}
                      show={inventoryPopupMeta}
                      selectedUnits={
                        inventoryPopupMeta && inventoryPopupMeta.selectedUnit ? [inventoryPopupMeta.selectedUnit] : []
                      }
                      onUnitSelect={onUnitSelect}
                      onSubmit={onInventorySubmit}
                      fromContracts
                      onInventoryPickerClose={() => setInventoryPopupMeta(null)}
                      onAddInventoryPopupMetaChange={onAddInventoryPopupMetaChange}
                      addInvenroryPopupMeta={addInvenroryPopupMeta}
                      onAddInvetoryPopupMetaClose={onAddInvetoryPopupMetaClose}
                    />
                  </div>

                  {!formDisabled && <ButtonsRow bottom />}
                  <hr />

                  <ContractReports contract={contract} onServiceOrderReportClick={onServiceOrderReportClick} />
                  <ServiceReportModal
                    show={showServiceReportModal}
                    onHide={handleServiceReportClose}
                    contract={contract}
                    serviceRowSubmitting={serviceRowSubmitting}
                    onServiceOrderRowsChange={onServiceOrderRowsChange}
                    onSubmit={submitServiceReport}
                  />

                  <hr className="my-2" />

                  <Comments
                    comments={contract['comments']}
                    disabled={buttonsDisabled}
                    onCommentSubmit={submitComment}
                    commentText={commentText}
                    onCommentTextChange={setCommentText}
                    onCommentDeleteClick={onCommentDeleteClick}
                  />

                  <FileViewer
                    sidebarZIndex={2000}
                    show={showFileViewer}
                    onHide={() => setShowFileViewer(false)}
                    files={contract['files']}
                    driveFolder={contract['driveFolder']}
                    onFileDeleteClick={onFileDeleteClick}
                  />
                  <AlertModal
                    show={deleteFileModalMeta !== null}
                    alertText={`Are you sure to delete this file (${
                      deleteFileModalMeta
                        ? deleteFileModalMeta.file.metadata && deleteFileModalMeta.file.metadata.originalName
                          ? deleteFileModalMeta.file.metadata.originalName
                          : deleteFileModalMeta.file.filename
                        : ''
                    }). You will lose access to this file permanently?`}
                    showProgress={deleteFileModalMeta && deleteFileModalMeta.deletingFile}
                    progressText="Deleting file..."
                    continueButtonText="Delete"
                    onDismissClick={() => setDeleteFileModalMeta(null)}
                    onHide={() => setDeleteFileModalMeta(null)}
                    onContinueClick={deleteFile}
                  />
                  {role === 'super admin' && (
                    <AlertModal
                      show={deleteCommentModalMeta !== null}
                      alertText="Are you sure to remove this comment?"
                      showProgress={deleteCommentModalMeta?.showProgress}
                      progressText="Deleting comment..."
                      onDismissClick={() => setDeleteCommentModalMeta(null)}
                      onHide={() => setDeleteCommentModalMeta(null)}
                      onContinueClick={deleteComment}
                    />
                  )}
                </>
              ) : (
                <Loader />
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default withRouter(EditForm);
