import React from 'react';
import DataTable from '../DataTable';
import EditForm from '../main/EditForm';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';

const contractIgnoreFields = ['mostRecentComment', 'Comments', 'Optional Equipment And Accessories'];

const ContractInfoSideBar = ({
  sidebarMode,
  onContractInfoSideBarHide,
  contract,
  appChoices,
  onContractEdit,
  onContractDelete
}) => {
  return (
    <SlidingSideBar
      fullScreen
      visible={sidebarMode !== null}
      onClose={onContractInfoSideBarHide}
      title={'Contract Details'}
    >
      {contract && sidebarMode !== null && (
        <>
          {sidebarMode === 'view' ? (
            <DataTable data={contract} ignoreFields={contractIgnoreFields} />
          ) : (
            <EditForm
              contractFromSearch={contract}
              appChoices={appChoices}
              fromSearch
              onContractDelete={onContractDelete}
              onContractEdit={onContractEdit}
            />
          )}
        </>
      )}
    </SlidingSideBar>
  );
};

export default ContractInfoSideBar;
