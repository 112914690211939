import React, { useEffect, useState } from 'react';
import { Button, Card, Col, FormControl, Row, Table } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import DropDownInput from '../../form-generator/components/DropDownInput';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { downloadFileFromBase64 } from '../../helpers/global';
import Loader from '../Loader';
import TextWithEdit from '../TextWithEdit';
import Toggler from '../Toggler';

const headers = [
  'Buyer',
  'Sales Location',
  'Manufacturer',
  'Serial # A',
  'Status',
  'Funding Status',
  'Floorplan Lender',
  'Lender',
  'Cash Paid',
  'Application Number',
  'Invoice'
];

const headerKeys = [
  'buyer',
  'salesLocation',
  'manufacturer',
  'serialA',
  'status',
  'fundingStatus',
  'floorplanLender',
  'lender',
  'cashPaid',
  'applicationNumber',
  'invoice'
];

const FloorplanUpdate = ({ appChoices }) => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [statusOptions] = useState(appChoices.find(ac => ac.key === 'Status').values);
  const [status, setSelectedStatus] = useState([...statusOptions]);

  const [fundingStatusOptions] = useState(['Unassigned', ...appChoices.find(ac => ac.key === 'Funding Status').values]);
  const [fundingStatus, setSelectedFundingStatus] = useState(
    fundingStatusOptions.map(o => (o === 'Unassigned' ? null : o))
  );

  const [salesLocationOptions] = useState([
    { location: 'All', _id: 'All' },
    ...appChoices.find(ac => ac.key === 'salesLocation').values
  ]);

  const [salesLocation, setSelectedSalesLocation] = useState(salesLocationOptions[0]);

  const [manufacturerOptions] = useState([
    { name: 'All', _id: 'All' },
    ...appChoices.find(ac => ac.key === 'manufacturer').values
  ]);
  const [manufacturer, setSelectedManufacterer] = useState(manufacturerOptions[0]);

  const [lenderOptions] = useState([
    { lienholderCorporateName: 'All', _id: 'All' },
    ...appChoices.find(ac => ac.key === 'lender').values
  ]);
  const [lender, setSelectedLender] = useState(lenderOptions[0]);

  const [cashPaidOptions] = useState(['Yes', 'No']);
  const [cashPaidFilterOptions] = useState(['All', ...cashPaidOptions]);
  const [cashPaid, setCashPaid] = useState('All');

  const [floorplanLenderOptions] = useState([
    { name: 'All', _id: 'All' },
    ...appChoices.find(ac => ac.key === 'floorplanLender').values
  ]);
  const [floorplanLender, setSelectedFloorplanLender] = useState(floorplanLenderOptions[0]);

  const [filteredContracts, setFilteredContracts] = useState([]);
  const [excludedContracts, setExcludedContracts] = useState([]);

  const fetchContracts = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setContracts(
      response
        .filter(c => c['salesLocation'])
        .map(c => {
          const { inventoryUnit } = c;
          if (!inventoryUnit) return c;

          inventoryUnit['inventoryId'] = inventoryUnit['_id'];
          delete inventoryUnit['_id'];
          return { ...c, ...inventoryUnit };
        })
    );
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const filterContracts = () => {
    const tempContracts = contracts.filter(c => {
      return [
        { title: 'status', value: status },
        {
          title: 'fundingStatus',
          value: fundingStatus
        },
        {
          title: 'salesLocation',
          value: salesLocation,
          isObject: true
        },
        { title: 'cashPaid', value: cashPaid },
        {
          title: 'manufacturer',
          value: manufacturer,
          isObject: true
        },
        { title: 'lender', value: lender, isObject: true },
        {
          title: 'floorplanLender',
          value: floorplanLender,
          isObject: true
        }
      ].every(({ title, value, isObject }) => {
        if (!Array.isArray(value)) value = [value];

        if (isObject) {
          value = value.map(v => v._id);
        }

        return value.includes('All') || value.includes(isObject ? c[title]?._id : c[title]);
      });
    });

    const groupedContracts = [];
    for (const location of salesLocationOptions) {
      groupedContracts.push(
        ...tempContracts
          .filter(c => c['salesLocation']._id === location._id)
          .sort((a, b) => {
            return a['dateSigned'] && b['dateSigned'] ? Date.parse(b['dateSigned']) - Date.parse(a['dateSigned']) : -1;
          })
      );
    }

    setFilteredContracts(groupedContracts);
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    setExcludedContracts([]);
    filterContracts();
  }, [contracts, status, lender, floorplanLender, manufacturer, fundingStatus, salesLocation, cashPaid]);

  const SelectionBox = () => {
    return (
      <>
        <Card className="border rounded">
          <Card.Header className="bg-primary">
            <h5 className="mb-0 text-white">Explore Floorplan Updates</h5>
          </Card.Header>
          <Card.Body className="py-3">
            <Row>
              {[
                {
                  title: 'Status',
                  value: status,
                  options: statusOptions,
                  onChange: setSelectedStatus,
                  multiple: true
                },
                {
                  title: 'Funding Status',
                  value: fundingStatus,
                  options: fundingStatusOptions,
                  onChange: setSelectedFundingStatus,
                  multiple: true
                },
                {
                  title: 'Sales Location',
                  value: salesLocation,
                  options: salesLocationOptions,
                  onChange: setSelectedSalesLocation,
                  isObject: true,
                  objectLabelKey: 'location'
                },
                {
                  title: 'Manufacturer',
                  value: manufacturer,
                  options: manufacturerOptions,
                  onChange: setSelectedManufacterer,
                  isObject: true,
                  objectLabelKey: 'name'
                },
                {
                  title: 'Lender',
                  value: lender,
                  options: lenderOptions,
                  onChange: setSelectedLender,
                  isObject: true,
                  objectLabelKey: 'lienholderCorporateName'
                },
                {
                  title: 'Floorplan Lender',
                  value: floorplanLender,
                  options: floorplanLenderOptions,
                  onChange: setSelectedFloorplanLender,
                  isObject: true,
                  objectLabelKey: 'name'
                },
                { title: 'Cash Paid', value: cashPaid, options: cashPaidFilterOptions, onChange: setCashPaid }
              ].map(dropdownMeta => (
                <Col key={dropdownMeta.title} xs={12} md={4} className="px-4 mt-3">
                  <h6 className="mb-2">{dropdownMeta.title}</h6>
                  {dropdownMeta.multiple ? (
                    <MultiSelect
                      showCheckbox
                      closeOnChangedValue={false}
                      className="midFont"
                      value={dropdownMeta.value.map(v =>
                        dropdownMeta.isObject
                          ? { label: v[dropdownMeta.objectLabelKey], value: v._id }
                          : { label: v, value: v }
                      )}
                      options={dropdownMeta.options.map(o =>
                        dropdownMeta.isObject
                          ? { label: o[dropdownMeta.objectLabelKey], value: o._id }
                          : { label: o, value: o === 'Unassigned' ? null : o }
                      )}
                      onChange={list =>
                        dropdownMeta.onChange(
                          list.map(listOption =>
                            dropdownMeta.isObject
                              ? dropdownMeta.options.find(o => o._id === listOption.value)
                              : listOption.value
                          )
                        )
                      }
                    />
                  ) : (
                    <DropDownInput
                      value={dropdownMeta.isObject ? dropdownMeta.value._id : dropdownMeta.value}
                      options={
                        dropdownMeta.isObject
                          ? dropdownMeta.options.map(o => o[dropdownMeta.objectLabelKey])
                          : dropdownMeta.options
                      }
                      optionValues={
                        dropdownMeta.isObject
                          ? dropdownMeta.options.map(o => o._id)
                          : dropdownMeta.options.map(o => (o === 'Unassigned' ? null : o))
                      }
                      onChangeFunction={e => {
                        if (!e) return;
                        dropdownMeta.onChange(
                          dropdownMeta.isObject
                            ? dropdownMeta.options.find(o => o._id === e.target.value)
                            : e.target.value
                        );
                      }}
                      required
                    />
                  )}
                </Col>
              ))}
              <Col xs={12} className="mt-3 text-right">
                <Button onClick={printDocument} disabled={submitting || filteredContracts.length === 0}>
                  Print
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  };

  const onInvoiceRequiredInvalidation = contract => {
    toast.error('Invoice Amount is required!');
  };

  const onInvoiceAmountSubmit = async (contract, amount) => {
    const parsedAmount = Number(amount);
    toast.info('Please wait updating amount...');

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(contract.inventoryId),
      requestBody: {
        invoice: parsedAmount
      },
      method: 'PUT'
    });

    if (error) {
      toast.error(error);
      return;
    }

    const index = contracts.findIndex(c => c['_id'] === contract['_id']);
    if (index !== -1) {
      contracts[index]['invoice'] = parsedAmount;
    }

    setContracts([...contracts]);
    toast.success(`Invoice for buyer ${contract['buyer']} updated successfully`);
  };

  const updateContract = (contract, field, value) => {
    const existingContract = contracts.find(c => c['_id'] === contract['_id']);
    if (existingContract) {
      existingContract[field] = value;
    }

    setContracts([...contracts]);
  };

  const onExcludeContractChange = (id, checked) => {
    if (checked) {
      const exisiting = excludedContracts.indexOf(id);
      if (exisiting !== -1) excludedContracts.splice(exisiting, 1);
    } else {
      if (!excludedContracts.includes(id)) excludedContracts.push(id);
    }
    setExcludedContracts([...excludedContracts]);
  };

  const printDocument = async () => {
    toast.info('Submitting report, please wait...');
    setSubmitting(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.DOCHUB_PRINT_TABLE,
      requestBody: {
        table: [
          headers,
          ...filteredContracts
            .filter(c => !excludedContracts.includes(c['_id']))
            .map(c =>
              headerKeys.map(headerKey => {
                if (!c[headerKey]) return '';

                if (headerKey === 'salesLocation') return c[headerKey].location;
                if (headerKey === 'lender') return c[headerKey].lienholderCorporateName;

                return typeof c[headerKey] === 'object' ? c[headerKey].name : c[headerKey];
              })
            )
        ],
        fileName: `floorplan-${new Date().toDateString()}.pdf`
      }
    });

    setSubmitting(false);
    if (error) return toast.error(error);

    toast.success('Document created successfully, file is being downloaded...');
    const { fileName, data, mimeType } = response;
    downloadFileFromBase64({ fileName, data, mimeType });
  };

  return (
    <div className="px-0 px-md-4 py-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          {SelectionBox()}
          <hr />
          <h6>Updates:</h6>
          <Table style={{ fontSize: 12 }} responsive bordered className="text-center">
            <tr className="bg-dark text-light">
              <th>Print?</th>
              {headers.map(h => (
                <th key={`header-${h}`}>{h}</th>
              ))}
            </tr>
            {filteredContracts.length > 0 ? (
              <>
                {filteredContracts.map(c => (
                  <tr key={c['_id']}>
                    <td>
                      <FormControl
                        checked={!excludedContracts.includes(c['_id'])}
                        style={{ width: 16, height: 16 }}
                        type="checkbox"
                        onChange={e => onExcludeContractChange(c['_id'], e.target.checked)}
                      />
                    </td>
                    <td>{c['buyer']}</td>
                    <td>{c['salesLocation'].location}</td>
                    <td>{c['manufacturer']?.name || '-'}</td>
                    <td>{c['serialA']}</td>
                    <td className="px-1">
                      <Toggler
                        id={c['_id']}
                        fieldName="status"
                        value={c['status']}
                        options={statusOptions}
                        onFieldUpdate={(field, value) => updateContract(c, field, value)}
                      />
                    </td>
                    <td className="px-1">
                      <Toggler
                        id={c['_id']}
                        fieldName="fundingStatus"
                        value={c['fundingStatus']}
                        options={fundingStatusOptions}
                        onFieldUpdate={(field, value) => updateContract(c, field, value)}
                      />
                    </td>
                    <td style={{ minWidth: 150 }}>{c['floorplanLender']?.name || '-'}</td>
                    <td>{c['lender']?.lienholderCorporateName || '-'}</td>
                    <td className="px-1">
                      <Toggler
                        id={c['_id']}
                        fieldName="cashPaid"
                        value={c['cashPaid']}
                        options={cashPaidOptions}
                        onFieldUpdate={(field, value) => updateContract(c, field, value)}
                      />
                    </td>
                    <td>{c['applicationNumber']}</td>
                    <td style={{ minWidth: 120 }}>
                      <TextWithEdit
                        preFix="$"
                        text={c['invoice'] || 0}
                        onSubmit={value => onInvoiceAmountSubmit(c, value)}
                        required
                        onRequiredInvalidation={() => onInvoiceRequiredInvalidation(c)}
                      />
                    </td>
                  </tr>
                ))}

                <tr>
                  <td colSpan={headers.length} className="text-right">
                    <b>Total</b>
                  </td>
                  <td>
                    $
                    {filteredContracts
                      .map(c => c['invoice'] || 0)
                      .reduce((a, b) => a + b, 0)
                      .toFixed(2)}
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={headers.length + 1}>No contracts to show</td>
              </tr>
            )}
          </Table>
          <h6 className="text-right text-muted">
            Total Reports: <span className="text-dark">{filteredContracts.length}</span>
          </h6>
        </>
      )}
    </div>
  );
};

export default FloorplanUpdate;
