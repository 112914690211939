import { APP_ROLES, APP_ROLES_WITHOUT_ADMINS, DOCHUB_DATA_SOURCES } from '../../helpers/constants';

//COMMON
const addressHeaders = [
  { name: 'Address', key: 'address' },
  { name: 'City', key: 'city' },
  { name: 'State', key: 'state', minWidth: 60 },
  { name: 'Zip', key: 'zip', minWidth: 60 }
];

export const getRows = (headers = [], excludedFields = []) => [
  {
    columns: (typeof headers === 'function' ? headers() : headers)
      .filter(h => !excludedFields?.includes(h.key))
      .map(
        ({
          name,
          formTitle,
          key: id,
          type = 'text',
          formType,
          variant,
          label,
          showLabel,
          required = true,
          options,
          optionValues,
          defaultColumn
        }) => ({
          default: defaultColumn || 6,
          field: {
            title: formTitle || name,
            id,
            key: id,
            type: formType || (type === 'boolean' ? 'checkbox' : type),
            required,
            variant,
            label,
            showLabel,
            options,
            optionValues
          }
        })
      )
  }
];

// Section Headers

export const sectionVideoHeaders = [
  { name: 'Name', key: 'name' },
  { name: 'Path', key: 'path', type: 'appLink' },
  {
    name: 'Video Links',
    key: 'videoUrls',
    type: 'multi-text',
    linkToKey: 'videoUrls',
    defaultColumn: 12,
    required: false
  }
];

export const sectionVideoAddRows = getRows(sectionVideoHeaders);
export const sectionVideoUpdateRows = getRows([
  { name: 'Name', key: 'name', defaultColumn: 12 },
  { name: 'Video Links', key: 'videoUrls', type: 'multi-text', defaultColumn: 12, required: false }
]);

export const sectionVideosSecondaryUpdateRows = getRows([
  { name: 'Video Links', key: 'videoUrls', type: 'multi-text', defaultColumn: 12, required: false }
]);

export const sectionVideosearchKeys = ['name'];

//MANUFACTURERS
const manufacturerHeaders = [
  { name: 'Name', key: 'name' },
  { name: 'License #', key: 'licenseNumber' },
  ...addressHeaders,
  {
    name: 'NPS',
    key: 'isNPS',
    type: 'boolean',
    showAsBadge: true,
    label: 'Is NPS?',
    showLabel: true,
    required: false
  },
  {
    name: 'Factory Makeready',
    key: 'isFactoryMakeReady',
    type: 'boolean',
    showAsBadge: true,
    label: 'Is Factory Makeready?',
    badgeVariant: 'dark',
    showLabel: true,
    required: false
  },
  { name: 'Factory Short Name', key: 'factoryShortName' },
  { name: 'Added On', key: 'createdAt', type: 'date' }
];

const manufacturerAddRows = getRows(manufacturerHeaders, ['createdAt']);
const manufacturerUpdateRows = [...manufacturerAddRows];

// Insurance Company

const insuranceCompanyHeaders = [
  { name: 'Lienholder Corporate Name', key: 'lienholderCorporateName' },
  { name: 'Phone #', key: 'phoneNumber' },
  { name: 'Fax #', key: 'faxNumber' },
  { name: 'NLMS #', key: 'nlmsNumber' },
  ...addressHeaders,
  { name: 'Short Name', key: 'shortName' },
  { name: 'Added On', key: 'createdAt', type: 'date' }
];

const insuranceCompanyAddRows = getRows(insuranceCompanyHeaders, ['createdAt']);
const insuranceCompanyUpdateRows = [...insuranceCompanyAddRows];

//LENDERS
const lenderHeaders = [
  { name: 'Lienholder Corporate Name', key: 'lienholderCorporateName' },
  { name: 'Phone #', key: 'phoneNumber' },
  { name: 'Fax #', key: 'faxNumber' },
  { name: 'NLMS #', key: 'nlmsNumber' },
  ...addressHeaders,
  { name: 'Short Name', key: 'shortName' },
  { name: 'Added On', key: 'createdAt', type: 'date' }
];
const lenderAddRows = getRows(lenderHeaders, ['createdAt']);
const lenderUpdateRows = [...lenderAddRows];

//GENERAL CONTRACTOR
const generalContractorHeaders = [
  { name: 'Name', key: 'name' },
  { name: 'Email', key: 'email' },
  {
    name: 'Category',
    key: 'category',
    type: 'dropdown',
    options: ['Factory', 'Transport', 'Factory Transport', 'Contractor']
  },
  { name: 'Phone #', key: 'phoneNumber' },
  { name: 'Fax #', key: 'faxNumber' },
  ...addressHeaders,
  { name: 'Added On', key: 'createdAt', type: 'date' }
];
const generalContractorAddRows = getRows(generalContractorHeaders, ['createdAt']);
const generalContractorUpdateRows = [...generalContractorAddRows];

//Tech
const techHeaders = [
  { name: 'Name', key: 'name' },
  { name: 'Phone #', key: 'phoneNumber' },
  { name: 'Fax #', key: 'faxNumber' },
  ...addressHeaders,
  { name: 'Added On', key: 'createdAt', type: 'date' }
];
const techAddRows = getRows(techHeaders, ['createdAt']);
const techUpdateRows = [...techAddRows];

//SALES LOCATION
export const salesLocationHeaders = [
  { name: 'Location', key: 'location' },
  { name: 'General Manager', key: 'generalManager' },
  { name: 'Email', key: 'email' },
  { name: 'County', key: 'county' },
  { name: 'Name', key: 'name', minWidth: 150 },
  ...addressHeaders,
  { name: 'Phone #', key: 'phoneNumber' },
  { name: 'Website', key: 'website', linkToKey: 'website' },
  { name: 'RBI', key: 'rbi' },
  { name: 'Account #', key: 'accountNumber' },
  { name: 'Appraisal Office', key: 'appraisalOfficeName', minWidth: 180 },
  { name: 'Appraisal Office Address', key: 'appraisalOfficeAddress', minWidth: 180 },
  { name: 'Appraisal Office Phone', key: 'appraisalOfficePhone' },
  { name: 'Tax Office', key: 'taxOfficeName' },
  { name: 'Tax Office Address', key: 'taxOfficeAddress', minWidth: 200 },
  { name: 'Tax Office Phone', key: 'taxOfficePhone' },
  { name: 'Tax Repesentative', key: 'taxRepresentative', required: false },
  { name: 'Tax Repesentative Title', key: 'taxRepresentativeTitle', required: false },
  { name: 'Tax Repesentative Email', key: 'taxRepresentativeEmail', required: false },
  { name: 'Tax Repesentative Phone', key: 'taxRepresentativePhone', required: false }
];

export const salesLocationAddRows = getRows(salesLocationHeaders, ['createdAt']);
export const salesLocationUpdateRows = [...salesLocationAddRows];

//SALES LOCATION
export const siteHeaders = [{ name: 'Name', key: 'name' }];

export const siteAddRows = getRows(siteHeaders);
export const siteUpdateRows = [...siteAddRows];

//INSTALLERS
const installerHeaders = [
  { name: 'Name', key: 'name' },
  { name: 'License #', key: 'licenseNumber' },
  { name: 'Expiration Date', key: 'expirationDate', variant: 'date' },
  ...addressHeaders,
  { name: 'Phone #', key: 'phoneNumber' },
  { name: 'Fax #', key: 'faxNumber' },
  { name: 'Employees', key: 'employees', type: 'multi-text', defaultColumn: 12, required: false },
  { name: 'Added On', key: 'createdAt', type: 'date' }
];

const installerAddRows = getRows(installerHeaders, ['createdAt']);
const installerUpdateRows = [...installerAddRows];

//Floorplan Lender
const floorplanLendersHeaders = [
  { name: 'Name', key: 'name' },
  { name: 'Address', key: 'address' },
  { name: 'Phone #', key: 'phoneNumber' },
  { name: 'Email', key: 'email', variant: 'email' },
  { name: 'Added On', key: 'createdAt', type: 'date' }
];

const floorplanAddRows = getRows(floorplanLendersHeaders, ['createdAt']);
const floorplanUpdateRows = [...floorplanAddRows];

//USERS [not in array of entities][separate component]
export const userHeaders = [
  { name: 'Name', key: 'name' },
  { name: 'Phone', key: 'phone', type: 'phone', formType: 'text' },
  { name: 'Email', key: 'email', type: 'email', formType: 'text' },
  { name: 'Role', key: 'role', type: 'dropdown', showAsBadge: true },
  { name: 'Sales Location', key: 'salesLocation', type: 'dropdown', objectKey: 'location', required: false },
  {
    name: 'Installer',
    formTitle: 'Installer (Only for installer role)',
    key: 'installer',
    type: 'dropdown',
    objectKey: 'name',
    required: false
  },
  { name: 'Active', key: 'active', type: 'boolean', showAsBadge: true },
  { name: 'OTP', key: 'temporaryKey' },
  { name: 'Added On', key: 'createdAt', type: 'date' }
];

export const getUserAddRows = isSuperAdmin =>
  getRows(
    userHeaders.map(u =>
      u.key === 'role'
        ? {
            ...u,
            options: isSuperAdmin ? APP_ROLES : APP_ROLES_WITHOUT_ADMINS,
            optionValues: isSuperAdmin ? APP_ROLES : APP_ROLES_WITHOUT_ADMINS
          }
        : u
    ),
    ['createdAt', 'active', 'temporaryKey']
  );

export const getUserUpdateRows = isSuperAdmin =>
  getRows(
    userHeaders.map(u =>
      u.key === 'role'
        ? {
            ...u,
            options: isSuperAdmin ? APP_ROLES : APP_ROLES_WITHOUT_ADMINS,
            optionValues: isSuperAdmin ? APP_ROLES : APP_ROLES_WITHOUT_ADMINS
          }
        : u
    ),
    ['createdAt', 'active', 'email', 'temporaryKey']
  );

//Print Groups
const printGroupsHeaders = categories => [
  { name: 'Name', key: 'name' },
  { name: 'Url', key: 'url', linkToKey: 'url' },
  {
    name: 'Roles',
    key: 'roles',
    type: 'checkbox-group',
    options: APP_ROLES.filter(r => !['Super Admin', 'Admin', 'Sales Manager'].includes(r))
  },
  { name: 'Data Sources', key: 'dataSources', type: 'checkbox-group', options: DOCHUB_DATA_SOURCES },
  {
    name: 'Category',
    key: 'category',
    defaultColumn: 12,
    required: false,
    type: 'dropdown',
    options: categories || []
  }
];

const printGroupsAddRows = categories => getRows(printGroupsHeaders(categories));
const printGroupsUpdateRows = categories => [...printGroupsAddRows(categories)];

// get an array of entities
export const adminEntities = [
  {
    appChoiceKey: 'manufacturer',
    name: 'Manufacturers',
    endpoint: '/manufacturers',
    route: '/manufacturer',
    tableHeaders: manufacturerHeaders,
    addRows: manufacturerAddRows,
    updateRows: manufacturerUpdateRows,
    searchKeys: ['name', 'licenseNumber', 'factoryShortName']
  },
  {
    appChoiceKey: 'lender',
    name: 'Lenders',
    endpoint: '/lenders',
    route: '/lender',
    tableHeaders: lenderHeaders,
    addRows: lenderAddRows,
    updateRows: lenderUpdateRows,
    searchKeys: ['lienHolderCorporateName', 'shortName', 'nlmsNumber']
  },
  {
    appChoiceKey: 'insuranceCompany',
    name: 'Insurance Companies',
    endpoint: '/insurance-companies',
    route: '/insurance-company',
    tableHeaders: insuranceCompanyHeaders,
    addRows: insuranceCompanyAddRows,
    updateRows: insuranceCompanyUpdateRows,
    searchKeys: ['lienHolderCorporateName', 'shortName', 'nlmsNumber']
  },

  {
    appChoiceKey: 'installer',
    name: 'Installers',
    endpoint: '/installers',
    route: '/installer',
    tableHeaders: installerHeaders,
    addRows: installerAddRows,
    updateRows: installerUpdateRows,
    searchKeys: ['name', 'licenseNumber']
  },
  {
    appChoiceKey: 'floorplanLender',
    name: 'Floorplan Lenders',
    endpoint: '/floorplan-lenders',
    route: '/floorplan-lenders',
    tableHeaders: floorplanLendersHeaders,
    addRows: floorplanAddRows,
    updateRows: floorplanUpdateRows,
    searchKeys: ['name', 'email']
  },
  {
    appChoiceKey: 'generalContractor',
    name: 'General Contractor',
    endpoint: '/general-contractors',
    route: '/general-contractor',
    tableHeaders: generalContractorHeaders,
    addRows: generalContractorAddRows,
    updateRows: generalContractorUpdateRows,
    searchKeys: ['name']
  },
  {
    appChoiceKey: 'tech',
    name: 'Tech',
    endpoint: '/techs',
    route: '/tech',
    tableHeaders: techHeaders,
    addRows: techAddRows,
    updateRows: techUpdateRows,
    searchKeys: ['name']
  },
  {
    appChoiceKey: 'printGroups',
    name: 'Print Groups',
    endpoint: '/print-groups',
    route: '/print-groups',
    tableHeaders: categories => printGroupsHeaders(categories),
    addRows: categories => printGroupsAddRows(categories),
    updateRows: categories => printGroupsUpdateRows(categories),
    searchKeys: ['name'],
    groupBy: 'category',
    groupValues: categories => [...categories],
    showAdditionalHeader: true,
    draggable: true,
    onDragEndpoint: '/print-groups/update-many'
  }
];
