import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import { causeOptions, descOptions, repairOptions, serviceOrderOptions } from '../../helpers/constants';
import ServiceOrderRow from './ServiceOrderRow';

const emptyRow = id => {
  const row = {
    Description: 'Other',
    'Description Other': '',
    Part:
      serviceOrderOptions[descOptions[0]]['parts'].length > 0 ? serviceOrderOptions[descOptions[0]]['parts'][0] : '',
    Location: [],
    'Repair Method': 'Other',
    'Repair Method Other': '',
    Cause: 'Other',
    'Cause Other': '',
    Hours: '',
    id: id
  };

  return row;
};

const getInitialRows = preFillReport => {
  return [
    ...preFillReport.map((row, index) => {
      const a = {
        Description: '',
        Part: '',
        Location: [],
        'Repair Method': '',
        Cause: '',
        Hours: '',
        id: 0
      };

      const desc = row['Description'];
      const cause = row['Cause'];
      const repairMethod = row['Repair Method'];

      const obj = {
        Description: descOptions.includes(desc) ? desc : 'Other',
        'Description Other': descOptions.includes(desc) ? '' : desc,
        Part:
          row['Part'] ||
          (serviceOrderOptions[desc] && serviceOrderOptions[desc]['parts'].length > 0
            ? serviceOrderOptions[desc]['parts'][0]
            : ''),
        Location: row['Location'] || [],
        'Repair Method': repairOptions.includes(repairMethod) ? repairMethod : 'Other',
        'Repair Method Other': repairOptions.includes(repairMethod) ? '' : repairMethod,
        Cause: causeOptions.includes(cause) ? cause : 'Other',
        'Cause Other': causeOptions.includes(cause) ? '' : cause,
        Hours: row['Hours'] || '',
        id: index
      };

      return obj;
    }),
    ...(preFillReport.length === 0 ? [emptyRow(preFillReport.length)] : [])
  ];
};

const ServiceOrderReport = ({ preFillReport, onRowsChange }) => {
  const [rows, setRows] = useState(getInitialRows(preFillReport ? preFillReport : []));

  const onAddRowClick = () => {
    setRows([...rows, emptyRow(rows[rows.length - 1]['id'] + 1)]);
  };

  const onCloseClick = index => {
    rows.splice(index, 1);
    setRows([...rows]);
  };

  const onRowChange = (row, index) => {
    rows[index] = { ...row, id: rows[index].id };
    setRows([...rows]);
  };

  useEffect(() => {
    onRowsChange && onRowsChange(rows);
  }, [rows]);

  return (
    <>
      {rows.map((r, index) => (
        <ServiceOrderRow
          onCrossClick={() => onCloseClick(index)}
          showCloseButton={rows.length > 1}
          position={index + 1}
          row={r}
          key={`r-${r.id}`}
          onRowChange={newRow => onRowChange(newRow, index)}
        />
      ))}
      <Row className="my-2 text-right">
        <Col>
          <Button variant="success" size="sm" onClick={onAddRowClick}>
            <PlusCircle className="mr-2 align-middle" /> Add Row
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ServiceOrderReport;
