import React from 'react';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import CreateForm from './CreateForm';

const CreateSideBar = ({ show, onHide, appChoices, onContractAdd, prefillContract }) => {
  return (
    <SlidingSideBar fullScreen visible={show} onClose={onHide} title={'New Contract'}>
      {show && <CreateForm appChoices={appChoices} onContractAdd={onContractAdd} prefillContract={prefillContract} />}
    </SlidingSideBar>
  );
};

export default CreateSideBar;
