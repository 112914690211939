import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, ListGroup } from 'react-bootstrap';
import { Pin } from 'react-bootstrap-icons';
import TextInput from '../../form-generator/components/TextInput';
import { contactSearchPaths, inventoryPaths } from '../../helpers/constants';
import { search } from '../../helpers/search';

const searchPaths = [...contactSearchPaths, ...inventoryPaths.map(p => `inventoryUnit[]/${p}`)];
const allSalesLocationObject = { _id: 'all', location: 'All' };

const ContactList = ({ contacts = [], activeId = '', onSelect, appChoices }) => {
  const [query, setQuery] = useState('');
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [selectedSaleLocation, setSelectedSaleLocation] = useState(allSalesLocationObject);

  useEffect(() => {
    const contactsByLocation = contacts.filter(
      c =>
        selectedSaleLocation._id === 'all' ||
        (c['salesLocation']?._id || c['salesLocation']) === selectedSaleLocation._id
    );

    setFilteredContacts(search(contactsByLocation, searchPaths, query, '_id'));
  }, [query, selectedSaleLocation, contacts]);

  const salesLocations = useMemo(
    () => [allSalesLocationObject, ...appChoices.find(a => a.key === 'salesLocation').values],
    [appChoices]
  );

  return (
    <div className="px-2">
      <div className="d-flex mb-2">
        <div className="flex-grow-1 mr-1">
          <TextInput size="sm" hint="Quick search..." onChange={e => setQuery(e.target.value)} />
        </div>
        <Dropdown>
          <Dropdown.Toggle size="sm" block variant="outline-primary" id="dropdown-basic">
            <Pin className="mr-3 align-text-bottom" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {salesLocations.map(s => (
              <Dropdown.Item
                className={`${s._id === selectedSaleLocation._id ? 'bg-primary text-white' : ''}`}
                onClick={() => setSelectedSaleLocation(s)}
                key={s._id}
              >
                {s.location}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <ListGroup>
        {filteredContacts.length > 0 ? (
          filteredContacts.map((c, i) => (
            <ListGroup.Item
              style={{ fontSize: 14 }}
              className={`py-1 text-${c['_id'] === activeId ? 'white' : 'dark'}`}
              key={c['_id']}
              action
              active={c['_id'] === activeId}
              onClick={() => onSelect && onSelect(c)}
            >
              {c['buyer']}
            </ListGroup.Item>
          ))
        ) : (
          <h6 className="py-5 text-center border rounded smallFont">No contacts to show </h6>
        )}
      </ListGroup>
    </div>
  );
};

export default ContactList;
