import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import CachedResponse from '../common/CachedResponse';
import EntityCRUD from '../common/EntityCRUD';
import NotFound from '../NotFound';
import AppChoices from './AppChoices';
import { adminEntities } from './constants';
import LotSets from './LotSets';
import SalesLocation from './SalesLocation';
import SectionsVideo from './SectionsVideo';
import Users from './Users';
import DocHubFields from './DocHubFields';

const Admin = () => {
  const [role] = useState(localStorage.getItem('user-role'));

  // Group Values For Print Group Category

  const printGroupGroupValues = JSON.parse(localStorage.getItem('app-choices'))?.find(
    v => v.key === 'Print Groups Categories'
  )?.values;

  if (!['super admin', 'admin'].includes(role)) {
    return <NotFound text="You are not authorized to view this page" />;
  }

  return (
    <Switch>
      <Route exact path={'/admin/users'}>
        <CachedResponse listEndpoint={'app-choices'} render={appChoices => <Users appChoices={appChoices} />} />
      </Route>
      <Route exact path={'/admin/app-choices'}>
        <AppChoices />
      </Route>
      <Route exact path={`/admin/sales-location`}>
        <SalesLocation />
      </Route>
      {adminEntities.map(e => (
        <Route exact path={`/admin${e.route}`}>
          <EntityCRUD
            entityAppChoiceKey={e.appChoiceKey}
            entityName={e.name}
            endpoint={e.endpoint}
            entityTableHeaders={
              typeof e.tableHeaders === 'function' ? e.tableHeaders(printGroupGroupValues) : e?.tableHeaders
            }
            entityTableSearchKeys={e.searchKeys}
            addFormRows={typeof e.addRows === 'function' ? e?.addRows(printGroupGroupValues) : e.addRows}
            updateFormRows={typeof e.updateRows === 'function' ? e?.updateRows(printGroupGroupValues) : e.updateRows}
            groupBy={e?.groupBy}
            groupValues={typeof e.groupValues === 'function' ? e?.groupValues(printGroupGroupValues) : e?.groupValues}
            showAdditionalHeader={e?.showAdditionalHeader}
            draggable={e?.draggable}
            onDragEndpoint={e?.onDragEndpoint}
          />
        </Route>
      ))}
      <Route exact path={'/admin/lot-sets'}>
        <CachedResponse
          listEndpoint={'app-choices'}
          render={appChoices => <LotSets salesLocations={appChoices.find(c => c.key === 'salesLocation').values} />}
        />
      </Route>
      <Route exact path={'/admin/section-video'}>
        <SectionsVideo />
      </Route>
      <Route exact path={'/admin/dochub-fields'}>
        <DocHubFields />
      </Route>
    </Switch>
  );
};

export default Admin;
