import React, { useEffect, useState } from 'react';
import { Button, Modal, ProgressBar, Card, Container } from 'react-bootstrap';
import { Map, Pen, PlusCircle, Trash } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { getCoordinatesOfAddress, makeApiRequests } from '../../helpers/api';
import { defaultSitesLatLong, ENDPOINTS } from '../../helpers/constants';
import { getObjectForPrefill } from '../../helpers/formHelpers';
import AlertModal from '../AlertModal';
import EntityTable from '../common/EntityTable';
import Loader from '../Loader';
import { siteAddRows, siteHeaders, siteUpdateRows } from './constants';
import SiteMapPopup from './site-map/SiteMapPopup';

const siteTableSearchKeys = ['name'];

const getForm = ({ rows, type = 'Add' }) => {
  const form = {
    forms: [
      {
        name: `${type} Site`,
        markCompulsoryFields: true,
        hideFormName: true,
        compact: true,
        submit: {
          name: type,
          show: true,
          onSubmit: type === 'Add' ? 'onNewSiteFormSubmit' : 'onUpdateSiteFormSubmit'
        },
        rows
      }
    ]
  };

  return form;
};

const AddEditModal = ({ show, toBeEditedSite, onHide, showProgress, addForm, updateForm, taxFactorUpdate, sites }) => (
  <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
    <Modal.Header closeButton={!showProgress}>
      <Modal.Title>
        <h6 className="mb-0">
          {toBeEditedSite ? (taxFactorUpdate ? 'Update Tax Factors' : 'Update Site') : 'Add New Site'}
        </h6>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="overflow-auto px-1">
      <FormGenerator
        formJson={toBeEditedSite ? updateForm : addForm}
        formValues={toBeEditedSite ? { 'Update Site': getObjectForPrefill(toBeEditedSite) } : {}}
      />
      {showProgress && (
        <ProgressBar now={100} animated striped label={toBeEditedSite ? `Updating Site...` : `Saving Site....`} />
      )}
    </Modal.Body>
  </Modal>
);

/*
Todo 
1. 
*/

const SalesLocationSites = ({ salesLocation }) => {
  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [toBeDeletedSite, setToBeDeletedSite] = useState(null);
  const [siteActionMeta, setSiteActionMeta] = useState(null);
  const [siteAddingUpdating, setSiteAddingUpdating] = useState(false);
  const [siteMapMeta, setSiteMapMeta] = useState(null);
  const role = localStorage.getItem('user-role');

  const fetchSites = async () => {
    setLoading(true);
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SITE_LIST,
      method: 'POST',
      requestBody: {
        populate: true,
        filter: {
          salesLocation: salesLocation?._id
        }
      }
    });

    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setSites(response.results);
  };

  useEffect(() => {
    fetchSites();
  }, []);

  useEffect(() => {
    if (siteMapMeta) {
      setSiteMapMeta({ ...siteMapMeta, site: sites.find(s => s._id === siteMapMeta.site._id) });
    }
  }, [sites]);

  const deleteSite = async () => {
    setDeleteInProgress(true);

    const { response, error } = await makeApiRequests({
      endpoint: `${ENDPOINTS.SITE_BASE}/${toBeDeletedSite._id}`,
      method: 'DELETE'
    });

    setDeleteInProgress(false);
    if (error) {
      toast.error(error);
      return;
    }

    sites.splice(sites.findIndex(i => i._id === toBeDeletedSite._id), 1);
    setSites([...sites]);
    setToBeDeletedSite(null);
  };

  const onNewSiteFormSubmit = async form => {
    setSiteAddingUpdating(true);

    let coordinates = (await getCoordinatesOfAddress(salesLocation?.location)) || defaultSitesLatLong; // Fallback to default coordinates

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SITE_BASE,
      requestBody: { ...form, salesLocation: salesLocation?._id, coordinates, scale: 15 }
    });

    setSiteAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    setSites([response, ...sites]);
    setSiteActionMeta(null);
  };

  const onUpdateSiteFormSubmit = async form => {
    let objectToUpdate = form;

    setSiteAddingUpdating(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.SITE_WITH_ID(siteActionMeta.site._id),
      requestBody: { ...objectToUpdate },
      method: 'PUT'
    });

    setSiteAddingUpdating(false);
    if (error) {
      toast.error(error);
      return;
    }

    const siteIndex = sites.findIndex(i => i._id === response._id);
    sites[siteIndex] = { ...sites[siteIndex], ...response };
    setSites([...sites]);
    setSiteActionMeta(null);
  };

  window['onNewSiteFormSubmit'] = onNewSiteFormSubmit;
  window['onUpdateSiteFormSubmit'] = onUpdateSiteFormSubmit;

  const onSiteUpdate = site => {
    const existingIndex = sites.findIndex(s => s._id === site._id);
    if (existingIndex !== -1) {
      sites[existingIndex] = site;
    } else {
      sites.push(site);
    }
    setSites([...sites]);
  };

  console.log('sites', sites);

  return (
    <div fluid className="h-100 py-3 px-md-3 border border-primary bg-white">
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <h6 className="mb-0">{salesLocation?.location} Sites</h6>
        </div>
        {['admin', 'super admin'].includes(role) && (
          <div>
            <Button size="sm" variant="success" onClick={() => setSiteActionMeta({ mode: 'add' })}>
              <PlusCircle className="mr-1" /> New Site
            </Button>
          </div>
        )}
      </div>

      <hr className="my-1" />
      {loading ? (
        <Loader />
      ) : (
        <>
          <EntityTable
            rowKeyField={'_id'}
            data={sites}
            quickSearch={false}
            searchQueryKeys={siteTableSearchKeys}
            salesLocation={salesLocation}
            headers={siteHeaders}
            actionCell={site => (
              <div>
                <Button
                  variant="outline-primary"
                  className="px-2 py-0 mr-1"
                  onClick={() => setSiteMapMeta({ site: sites.find(s => s._id === site._id) })}
                >
                  <Map size={12} /> <span className="smallFont">Open Site Map</span>
                </Button>
                {['admin', 'super admin'].includes(role) && (
                  <>
                    <Button
                      variant="outline-primary"
                      className="px-1 py-0 mr-1"
                      onClick={() => setSiteActionMeta({ site, mode: 'edit' })}
                    >
                      <Pen size={10} />
                    </Button>
                    <Button variant="outline-danger" className="px-1 py-0 " onClick={() => setToBeDeletedSite(site)}>
                      <Trash size={10} />
                    </Button>
                  </>
                )}
              </div>
            )}
          />
          {sites.length > 0 && (
            <AlertModal
              show={toBeDeletedSite !== null}
              alertText={`Are you sure you want to delete this site? This action cannot be undone!`}
              onHide={() => setToBeDeletedSite(null)}
              onDismissClick={() => setToBeDeletedSite(null)}
              onContinueClick={deleteSite}
              progressText={`Deleting site...`}
              showProgress={deleteInProgress}
            />
          )}
          <AddEditModal
            show={siteActionMeta !== null}
            onHide={() => setSiteActionMeta(null)}
            toBeEditedSite={siteActionMeta ? siteActionMeta.site : null}
            showProgress={siteAddingUpdating}
            addForm={getForm({ rows: siteAddRows })}
            updateForm={getForm({
              rows: siteUpdateRows,
              type: 'Update'
            })}
          />
          <SiteMapPopup
            show={Boolean(siteMapMeta)}
            site={siteMapMeta?.site}
            onSiteUpdate={onSiteUpdate}
            onHide={() => setSiteMapMeta(null)}
          />
        </>
      )}
    </div>
  );
};

export default SalesLocationSites;
