import { useEffect } from 'react';

const useClientTitle = () => {
  useEffect(() => {
    document.title = process.env.REACT_APP_CLIENT;
  }, []);

  return;
};

export default useClientTitle;
