import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Files, Pen, Printer, Trash } from 'react-bootstrap-icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { normalizeId, setAttribute } from '../../form-generator/helpers/utility';
import { deleteFileFromServer, makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { addDocHubFormFieldsToForm, convertKeyValueToFormObject, getObjectForPrefill } from '../../helpers/formHelpers';
import { getProfileFromLocalStorage, getSalesRepOfSalesManager } from '../../helpers/session';
import AlertModal from '../AlertModal';
import Loader from '../Loader';
import NotFound from '../NotFound';
import FileViewer from '../common/FileViewer';
import File from '../common/file';
import InventoryPicker from '../inventory/InventoryPicker';
import PotentialUnitsOverview from '../inventory/PotentialUnitsOverview';
import { addChoicesToCRMForm, editFormJson, validateAndCleanupCRMForm } from './form';
import { cloneDeep } from 'lodash';

const EditCrm = ({
  contactFromSearch,
  appChoices,
  fromSearch,
  onContactEdit,
  onContactDelete,
  onSearchCRMFileLoaded,
  onAddInventoryPopupMetaChange,
  addInvenroryPopupMeta,
  onAddInvetoryPopupMetaClose
}) => {
  const { id: idFromParams } = useParams();

  const [form, setForm] = useState(null);
  const [id] = useState(contactFromSearch ? contactFromSearch._id : idFromParams);
  const [isLoading, setLoading] = useState(!fromSearch);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [crmError, setCrmError] = useState('');
  const [formDisabled, setFormDisabled] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [crmDeleting, setCrmDeleting] = useState(false);
  const [contact, setContact] = useState();
  const [role] = useState(localStorage.getItem('user-role'));
  const [selectedInventory, setSelectedInventory] = useState([]);
  const [inventoryPopupMeta, setInventoryPopupMeta] = useState(null);
  const [zipCodes, setZipCodes] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [deleteFileModalMeta, setDeleteFileModalMeta] = useState(null);

  const history = useHistory();

  const fetchContact = async () => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTACTS_SEARCH,
      requestBody: {
        filter: {
          _id: id
        }
      }
    });

    setLoading(false);
    if (error) return setCrmError(error);

    if (response.length === 0) {
      return setCrmError('No contract found!');
    }

    const contact = response[0];
    if (!fromSearch && contact['exportedContract']) {
      return history.push(`/contract/${contact['exportedContract']._id}`);
    }

    updateContactState(contact);
  };

  useEffect(() => {
    setUpForm();

    if (!fromSearch) {
      fetchContact();
    } else {
      updateContactState(contactFromSearch, !contactFromSearch['fileNames']);
    }
  }, []);

  const setUpForm = () => {
    const form = cloneDeep(editFormJson(role));
    const newFormJson = addDocHubFormFieldsToForm({ formJson: form, appChoices, model: 'Contact' });
    addChoicesToCRMForm({ appChoices, form: newFormJson, ignoreTableFields: true });
    setZipCodes(appChoices.find(a => a.key === 'zipCodes')['values']);

    if (['super admin', 'admin', 'sales manager'].includes(role)) {
      const members = (['super admin', 'admin'].includes(role)
        ? appChoices
            .find(c => c.key === 'members')
            .values.filter(m => ['super admin', 'admin', 'sales', 'sales manager'].includes(m.role.toLowerCase()))
        : [getProfileFromLocalStorage(), ...getSalesRepOfSalesManager()]
      ).sort((m1, m2) => m1.name.localeCompare(m2.name));

      setAttribute(
        newFormJson,
        'contactOwner',
        'options',
        members.map(s => s['name'])
      );
      setAttribute(
        newFormJson,
        'contactOwner',
        'optionValues',
        members.map(s => s['_id'])
      );
    }

    setForm(newFormJson);
  };

  const updateContactState = (contact, updateFilesFromServer = false) => {
    const clonedContact = cloneDeep(contact);
    setSelectedInventory(clonedContact['inventoryUnit'] || []);

    convertKeyValueToFormObject(clonedContact, appChoices);
    setContact(clonedContact);

    setLoading(false);
  };

  const onCrmEditFormSubmit = async form => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    //checks
    const valid = validateAndCleanupCRMForm(form);
    if (!valid) return;

    form['files'] = [...contact.files.map(f => f._id), ...uploadedFiles.map(f => f.id)];
    form['inventoryUnit'] = selectedInventory ? selectedInventory.map(i => i['_id']) : [];

    setButtonsDisabled(true);
    toast.info('Please wait, processing contact..');

    const { response, error } = await makeApiRequests({
      requestBody: form,
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(contact._id),
      method: 'PUT'
    });

    setButtonsDisabled(false);
    if (error) return toast.error(error);

    toast.success(`Crm updated successfully!`);
    setUploadedFiles([]);

    const details = {
      ...response
      //TODO manage files
    };

    if (onContactEdit) {
      setFormDisabled(true);
      onContactEdit && onContactEdit(details);
    } else {
      setLoading(true);
      setTimeout(() => setLoading(false), 100);
    }
    updateContactState(details);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const deleteCrm = async () => {
    setCrmDeleting(true);

    const { response, error } = await makeApiRequests({
      method: 'DELETE',
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(contact._id)
    });

    setCrmDeleting(false);

    if (error) {
      return toast.error(error);
    }

    setShowDeleteModal(false);
    toast(`Contact deleted successfully!`, {
      type: 'success'
    });

    if (fromSearch) {
      setShowDeleteModal(false);
      onContactDelete && onContactDelete(contact._id);
    } else {
      history.push('/crm/search');
    }
  };

  const autoFillAddress = e => {
    try {
      const zipCode = e ? e.target.value : document.getElementById('zipCode').value;
      const zipCodeFiltered = zipCodes.filter(zip => zipCode == zip['Zip Code']);

      if (zipCodeFiltered.length > 0) {
        document.getElementById('propertyState').value = zipCodeFiltered[0]['Property State'];
        document.getElementById('propertyCity').value = zipCodeFiltered[0]['Property City'];
        document.getElementById('propertyCounty').value = zipCodeFiltered[0]['Property County'];
      }
    } catch (e) {}
  };

  const autoFillMailingAddress = e => {
    try {
      const zipCode = e ? e.target.value : document.getElementById('mailingZip').value;
      const zipCodeFiltered = zipCodes.filter(zip => zipCode == zip['Zip Code']);

      if (zipCodeFiltered.length > 0) {
        document.getElementById('mailingState').value = zipCodeFiltered[0]['Property State'];
        document.getElementById('mailingCity').value = zipCodeFiltered[0]['Property City'];
        document.getElementById('mailingCounty').value = zipCodeFiltered[0]['Property County'];
      }
    } catch (e) {}
  };

  window['autoFillMailingAddress'] = autoFillMailingAddress;
  window['autoFillAddress'] = autoFillAddress;
  window['onCrmEditFormSubmit'] = onCrmEditFormSubmit;

  if (crmError) {
    return <NotFound text={crmError} />;
  }

  const onButtonClick = () => {
    document.getElementById('update').click();
  };

  const ButtonsRow = ({ bottom }) => (
    <Row>
      <Col xs={12} className={`text-right ${bottom ? 'my-4' : ' mb-3 mt-2'}`}>
        <Button disabled={buttonsDisabled} onClick={() => onButtonClick(false)} size="sm" className="ml-2">
          Save Record
        </Button>
      </Col>
    </Row>
  );

  const onLinkUnitClick = replacingUnit => {
    setInventoryPopupMeta({ replacingUnit });
  };

  const onRemoveUnitClick = removingUnit => {
    const existingIndex = selectedInventory.findIndex(i => i['_id'] === removingUnit['_id']);
    if (existingIndex !== -1) {
      selectedInventory.splice(existingIndex, 1);
    }
    setSelectedInventory([...selectedInventory]);
  };

  const onInventorySubmit = inventoryList => {
    const inventory = inventoryList[0];
    const { replacingUnit } = inventoryPopupMeta;

    if (replacingUnit) {
      const existingIndex = selectedInventory.findIndex(i => i['_id'] === replacingUnit['_id']);
      if (existingIndex !== -1) {
        selectedInventory[existingIndex] = inventory;
      }
      setSelectedInventory([...selectedInventory]);
    } else {
      setSelectedInventory([...selectedInventory, inventory]);
    }
    setInventoryPopupMeta(null);
  };

  const onUnitSelect = unit => {
    setInventoryPopupMeta({ ...inventoryPopupMeta, selectedUnit: unit });
  };

  const onFileDeleteClick = file => {
    setDeleteFileModalMeta({ file });
  };

  const deleteFile = async () => {
    const { file: toBeDeletedFile } = deleteFileModalMeta;
    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: true });

    const newFileArray = contact.files.filter(f => f._id !== toBeDeletedFile._id).map(f => f._id);

    const { response, error } = await makeApiRequests({
      requestBody: { files: newFileArray },
      endpoint: ENDPOINTS.CONTACTS_WITH_ID(contact._id),
      method: 'PUT'
    });

    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: false });
    if (error) {
      return toast.error(error);
    }

    setDeleteFileModalMeta(null);
    updateContactState(response);
    onContactEdit && onContactEdit(response, true);
    deleteFileFromServer(toBeDeletedFile);
  };

  const setDefaultValueToCheckedKeys = (inputId, appChoiceKey) => {
    try {
      const keyValueContainer = document.getElementById(inputId);

      const checkedKeys = [...keyValueContainer.getElementsByTagName('input')].filter(
        input => input.type === 'checkbox' && input.checked
      );

      checkedKeys.forEach(checkedKey => {
        const relatedTextBox = document.getElementById(`${inputId}-${normalizeId(checkedKey.value)}-key-value`);
        if (!relatedTextBox.value) {
          relatedTextBox.value =
            appChoices.find(c => c.key === appChoiceKey)?.values.find(c => c.name === checkedKey.value)?.defaultValue ||
            0;
        }
      });
    } catch (e) {}
  };

  const onCommissionSheetOptionsChange = e => {
    if (!e) return;

    setDefaultValueToCheckedKeys('commissionSheetOptions', 'Commission Options');
  };

  window['onCommissionSheetOptionsChange'] = onCommissionSheetOptionsChange;

  const onCommissionSheetLessOptionsChange = e => {
    if (!e) return;

    setDefaultValueToCheckedKeys('commissionSheetLessOptions', 'Commission Less Options');
  };
  window['onCommissionSheetLessOptionsChange'] = onCommissionSheetLessOptionsChange;

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && form && contact && (
        <>
          <Row>
            <Col className="text-md-right mt-2 mt-md-0">
              {['super admin', 'admin', 'sales', 'sales manager'].includes(role) && (
                <Link target="_blank" to={`/dochub/print-groups?contactId=${contact['_id']}&source=Contact`}>
                  <Button
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    className="mr-1"
                    variant="outline-dark"
                    size="sm"
                  >
                    <Printer className="align-text-top" />
                  </Button>
                </Link>
              )}

              <Button onClick={() => setShowFileViewer(true)} variant="outline-dark" size="sm" className="py-1 ml-2">
                <Files className="align-text-top mr-2" />
                View Files
              </Button>

              {!contact['exportedContract'] && (
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => setFormDisabled(false)}
                  className="py-1 ml-2"
                  disabled={!formDisabled || buttonsDisabled}
                >
                  <Pen className="align-text-top mr-2" />
                  {formDisabled ? 'Edit' : 'Edit Mode Active'}
                </Button>
              )}
              <Button
                size="sm"
                variant="outline-danger"
                onClick={() => setShowDeleteModal(true)}
                className="py-1 ml-2"
                disabled={crmDeleting || buttonsDisabled}
              >
                <Trash className="align-text-top mr-2" />
                Delete
              </Button>
              <AlertModal
                alertText="Are you sure you want to delete the contact? This action cannot be undone!"
                show={showDeleteModal}
                onHide={handleDeleteModalClose}
                continueButtonText="Delete"
                continueButtonVariant="danger"
                onContinueClick={deleteCrm}
                progressText={'Deleting contact...'}
                showProgress={crmDeleting}
                onDismissClick={handleDeleteModalClose}
              />
            </Col>
          </Row>
          <hr />
          {!formDisabled && <ButtonsRow />}

          <FormGenerator
            formJson={form}
            formValues={{ update: getObjectForPrefill(contact) }}
            formDisabled={formDisabled}
          />
          {!formDisabled && (
            <File uploadedFiles={uploadedFiles} onUploadedFilesChange={setUploadedFiles} containerClassName="mx-3" />
          )}
          <div className="m-3">
            <PotentialUnitsOverview
              disabled={formDisabled}
              onLinkNewUnitClick={onLinkUnitClick}
              onRemoveUnitClick={onRemoveUnitClick}
              onChangeUnitClick={onLinkUnitClick}
              inventoryUnits={selectedInventory}
            />
            <InventoryPicker
              excludedUnitIds={selectedInventory ? selectedInventory.map(u => u['_id']) : []}
              show={inventoryPopupMeta !== null}
              onSubmit={onInventorySubmit}
              selectedUnits={
                inventoryPopupMeta && inventoryPopupMeta.selectedUnit ? [inventoryPopupMeta.selectedUnit] : []
              }
              onUnitSelect={onUnitSelect}
              onInventoryPickerClose={() => setInventoryPopupMeta(null)}
              onAddInventoryPopupMetaChange={onAddInventoryPopupMetaChange}
              addInvenroryPopupMeta={addInvenroryPopupMeta}
              onAddInvetoryPopupMetaClose={onAddInvetoryPopupMetaClose}
            />
          </div>
          {contact['exportedContract'] && (
            <h6 className="midFont text-right text-muted  mt-3 mb-0">
              <i>
                This contact has already been exported to contract{' '}
                <Link target="_blank" to={`/contract/${contact['exportedContract']._id}`}>
                  <b className="text-dark">{contact['exportedContract']['buyer']}</b>
                </Link>
                . You are not allowed to edit it.
              </i>
            </h6>
          )}
          {!formDisabled && <ButtonsRow bottom />}
          <FileViewer
            sidebarZIndex={2000}
            show={showFileViewer}
            onHide={() => setShowFileViewer(false)}
            files={contact['files']}
            driveFolder={contact['driveFolder']}
            onFileDeleteClick={onFileDeleteClick}
          />
          <AlertModal
            show={deleteFileModalMeta !== null}
            alertText={`Are you sure to delete this file (${
              deleteFileModalMeta
                ? deleteFileModalMeta.file.metadata && deleteFileModalMeta.file.metadata.originalName
                  ? deleteFileModalMeta.file.metadata.originalName
                  : deleteFileModalMeta.file.filename
                : ''
            }). You will lose access to this file permanently?`}
            showProgress={deleteFileModalMeta && deleteFileModalMeta.deletingFile}
            progressText="Deleting file..."
            continueButtonText="Delete"
            onDismissClick={() => setDeleteFileModalMeta(null)}
            onHide={() => setDeleteFileModalMeta(null)}
            onContinueClick={deleteFile}
          />
        </>
      )}
    </>
  );
};

export default EditCrm;
