import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { normalizeId } from '../helpers/utility';
import TextInput from './TextInput';

import ThreeStateSwitch from './ThreeStateSwitch';

const getPreValueNotes = defaultValues => {
  const noteDefaultValue = defaultValues.find(v => v.key === 'notes');
  return noteDefaultValue ? noteDefaultValue['value'] : '';
};

const KeyValueSwitchInput = ({
  id,
  options=[],
  value: defaultValues = [],
  onChange,
  disabled = false,
  preValue,
  boxWidth = 6,
  required = false,
  title = ''
}) => {
  if (preValue) defaultValues = preValue;

  //called after render only once
  useEffect(() => {
    internalOnChange();
  }, []);

  const internalOnChange = () => {
    //run internal functions here

    if (onChange && window[onChange]) {
      window[onChange]();
    }
  };

  return (
    <Row className={'mb-4 ' + (required ? 'fg-key-value-switch' : '')} id={id}>
      {options.map((option, index) => {
        const optionId = id + normalizeId(option);
        const name = title + '-' + option;
        const preValueIndex = defaultValues.findIndex(v => v.key === option);
        const preValue = preValueIndex !== -1 ? defaultValues[preValueIndex]['value'] : 'na';

        return (
          <>
            <Col xs={12} md={boxWidth} className="mt-2">
              <label className="option-label my-1 text-muted">{option}</label>
            </Col>
            <Col xs={12} md={boxWidth} className="mt-2">
              <ThreeStateSwitch name={name} optionId={optionId} preValue={preValue} disabled={disabled} />
            </Col>
            <Col xs={12}>
              <hr className="my-1" />
            </Col>
          </>
        );
      })}
      <Col xs={12}>
        <h6 className="my-2">Notes</h6>
        <TextInput
          disabled={disabled}
          variant="text-area"
          id={id + '-notes'}
          preValue={getPreValueNotes(defaultValues)}
        />
      </Col>
    </Row>
  );
};

export default KeyValueSwitchInput;
