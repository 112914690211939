import React from 'react';
import CachedResponse from '../../common/CachedResponse';
import SlidingSideBar from '../../SlidingSideBar/SlidingSideBar';
import PayrollExplorer from './PayrollExplorer';

const PayrollViewSidebar = ({
  show,
  activeContract,
  appChoices,
  onHide,
  payrollBillMeta,
  onPayrollBillMetaChange,
  submitPayroll,
  updatePayroll,
  payrolls,
  onPayrollsChange,
  loading,
  setToBeDeletedBill,
  deleteItem,
  deleteInProgress,
  toBeDeletedBill,
  onPayrollAdd,
  onShowRecordPayrollSidebarChange,
  showRecordPayrollSidebar,
  onInternalPayrollFileDeletion
}) => {
  return (
    <SlidingSideBar fullScreen visible={show} onClose={onHide} title={'Payrolls'}>
      {show && (
        <CachedResponse
          listEndpoint={'app-choices'}
          render={appChoices => (
            <PayrollExplorer
              appChoices={appChoices}
              activeContract={activeContract}
              payrollBillMeta={payrollBillMeta}
              onPayrollBillMetaChange={onPayrollBillMetaChange}
              submitPayroll={submitPayroll}
              updatePayroll={updatePayroll}
              payrolls={payrolls}
              onPayrollsChange={onPayrollsChange}
              loading={loading}
              setToBeDeletedBill={setToBeDeletedBill}
              deleteItem={deleteItem}
              deleteInProgress={deleteInProgress}
              toBeDeletedBill={toBeDeletedBill}
              onPayrollAdd={onPayrollAdd}
              onShowRecordPayrollSidebarChange={onShowRecordPayrollSidebarChange}
              showRecordPayrollSidebar={showRecordPayrollSidebar}
              onInternalPayrollFileDeletion={onInternalPayrollFileDeletion}
            />
          )}
        />
      )}
    </SlidingSideBar>
  );
};

export default PayrollViewSidebar;
