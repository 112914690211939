import React from 'react';
import { Dropdown } from 'react-bootstrap';
import CircularProgressBar from './circular-progress';

/**
 * 
  single toggle option = { label: 'Status', key: 'status', variant: 'info', options: [{option, value}] //used if value is a object, we show the specified key of that object } 
 */

const QuickToggles = ({ data, toggleOptions = [], onToggle, toggling, showLabel = true }) => {
  return (
    <div className="my-1 d-flex alignItems-center">
      {toggleOptions.map(({ label: optionLabel, key, variant = 'primary', options = [] }) => (
        <div key={key} className="d-inline-block mt-0 ml-2">
          {showLabel && (
            <p style={{ fontSize: 10 }} className="mb-0 text-muted">
              {optionLabel}
            </p>
          )}
          <Dropdown onClick={e => e.stopPropagation()} className="mb-1 mb-md-0 d-inline-block mr-2">
            <Dropdown.Toggle
              className="p-1"
              variant={variant}
              disabled={toggling}
              style={{ border: 0, fontSize: 12 }}
              size="sm"
            >
              {data[key] &&
              options.map(o => o.value).includes(typeof data[key] === 'object' ? data[key]._id : data[key])
                ? options.find(o => o.value === (typeof data[key] === 'object' ? data[key]._id : data[key])).option
                : 'Unassigned'}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {options.map(({ option, value, isTitle, title }) => {
                if (isTitle) {
                  return (
                    <Dropdown.Item
                      disabled={true}
                      className={`smallFont py-1 bg-primary-light text-dark px-2`}
                      key={title}
                    >
                      {title}
                    </Dropdown.Item>
                  );
                }

                const active = data[key] && value === (typeof data[key] === 'object' ? data[key]._id : data[key]);

                return (
                  <Dropdown.Item
                    active={active}
                    key={`${key}-${value}`}
                    onClick={() => {
                      if (active) return;
                      onToggle(optionLabel, key, value);
                    }}
                  >
                    {option || 'No name'}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ))}
      {toggling && <CircularProgressBar />}
    </div>
  );
};

export default QuickToggles;
