import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { ArrowUpRightCircle } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const staticReports = [
  {
    columnName: 'walkthroughReport',
    title: 'Walkthrough Report',
    textToShow: 'Partial',
    link: '/report/walkthrough'
  },
  {
    columnName: 'makereadyReport',
    title: 'Makeready Report',
    textToShow: 'View Report',
    link: '/report/makeready'
  },
  {
    columnName: 'installerReport',
    title: 'Installer Report',
    textToShow: 'View Report',
    link: '/report/installer'
  }
];

const ContractReports = ({ contract, onServiceOrderReportClick }) => {
  return (
    <>
      <h6>Reports</h6>
      <Row className="mt-3 text-center">
        {staticReports.map(({ columnName, title, link, textToShow }, index) => (
          <Col key={title} xs={6} md={3} className="mb-2 mb-md-0 h-100">
            <Card className="h-100">
              <Card.Header className="p-2 bg-primary-light">{title}</Card.Header>
              <Card.Body className="py-3 px-2 midFont">
                {contract[columnName] ? (
                  <Link from="/" target="_blank" to={`contract/${contract._id}${link}`}>
                    {columnName.includes('walkthrough') ? contract['walkthroughStatus'] || textToShow : textToShow}
                    <ArrowUpRightCircle className="ml-2 align-text-top" size={20} />
                  </Link>
                ) : (
                  <b className="text-muted">Not started</b>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
        {/* Service Order Report */}
        <Col xs={6} md={3} className="mb-2 mb-md-0 h-100">
          <Card className="h-100">
            <Card.Header className="p-2 bg-primary-light">Service Order Report</Card.Header>
            <Card.Body className="py-3 px-2 midFont">
              {contract['serviceOrderReport'] ? (
                <>
                  <Button variant="primary" size="sm" className="m-0 py-0" onClick={onServiceOrderReportClick}>
                    View
                  </Button>
                  <Link target="_blank" to={`/dochub/print-groups?contractId=${contract._id}&document=Service Request`}>
                    <Button variant="primary" size="sm" className="m-0 py-0 ml-1">
                      Print
                    </Button>
                  </Link>
                </>
              ) : (
                <Button variant="primary" size="sm" className="m-0 py-0" onClick={onServiceOrderReportClick}>
                  Start
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ContractReports;
