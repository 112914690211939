import React from 'react';
import { Table } from 'react-bootstrap';
import './AppTable.scss';

const AppTable = ({ children }) => {
  return (
    <Table bordered responsive hover className="smallFont mt-2">
      {children}
    </Table>
  );
};

export default AppTable;
