import { highlightError, setAttribute } from '../../form-generator/helpers/utility';
import { fillSizesInInventory, updateComunsAccordingToSections } from '../../helpers/global';

const headerRow = title => {
  return [
    {
      columns: [
        {
          default: 12,
          xs: 12,
          className: 'align-self-center',
          field: {
            type: 'custom-html',
            html: `<h3 class="mb-0"><b>${title}</b></h3><hr class="d-md-none"/>`
          }
        }
      ]
    },
    {
      columns: [
        {
          default: 12,
          field: {
            type: 'custom-html',
            html: '<hr class="my-1"/>'
          }
        }
      ]
    }
  ];
};

/**
Lot Set (Yes/No)
 */

const fieldRows = (createMode, fromPipeline = false, noOfSections, onNumberOfSectionsChange) => [
  {
    columns: [
      {
        default: 3,
        xs: 12,
        field: {
          id: 'isRSO',
          title: 'RSO',
          key: 'isRSO',
          type: 'dropdown',
          options: ['No', 'Yes'],
          required: true
        }
      },
      {
        default: 3,
        xs: 12,
        field: {
          id: 'unitLocation',
          title: 'Unit Location',
          type: 'dropdown',
          options: [],
          required: true
        }
      },

      {
        default: 3,
        xs: 12,
        field: {
          id: 'model',
          title: 'Model',
          type: 'text',
          required: false
        }
      },
      {
        default: 3,
        xs: 12,
        field: {
          id: 'manufacturer',
          title: 'Manufacturer',
          type: 'dropdown',
          options: [],
          required: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'dateOfManufacture',
          title: 'Date of manufacture',
          type: 'text',
          variant: 'date',
          required: false
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'vin',
          title: 'VIN (If Tiny Home)',
          type: 'text',
          required: false
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'numberOfSections',
          title: 'Number Of Sections',
          type: 'dropdown',
          required: false,
          options: [1, 2, 3],
          value: noOfSections || null,
          onChangeFunction: e => {
            if (!e) {
              return;
            }
            onNumberOfSectionsChange(e.target.value);
            fillSizesInInventory(document.getElementById('sizeOverall')?.value, noOfSections);
          }
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'sizeOverall',
          title: 'Size Overall',
          type: 'text',
          required: false,
          onChange: e => {
            if (!e) {
              return;
            }
            fillSizesInInventory(e.target.value, noOfSections);
          }
        }
      }
    ]
  },
  {
    columns: [
      {
        default: updateComunsAccordingToSections(noOfSections),
        xs: 12,
        field: {
          id: 'serialA',
          title: 'Serial # A',
          type: 'text',
          required: false
        }
      },
      {
        default: updateComunsAccordingToSections(noOfSections),
        xs: 12,
        field: {
          id: 'serialB',
          title: 'Serial # B',
          type: 'text',
          required: false
        }
      },
      {
        default: updateComunsAccordingToSections(noOfSections),
        xs: 12,
        field: {
          id: 'serialC',
          title: 'Serial # C',
          type: 'text',
          required: false
        }
      },
      {
        default: updateComunsAccordingToSections(noOfSections),
        xs: 12,
        field: {
          id: 'sizeA',
          title: 'Size A',
          type: 'text',
          required: true
        }
      },
      {
        default: updateComunsAccordingToSections(noOfSections),
        xs: 12,
        field: {
          id: 'sizeB',
          title: 'Size B',
          type: 'text',
          required: false
        }
      },
      {
        default: updateComunsAccordingToSections(noOfSections),
        xs: 12,
        field: {
          id: 'sizeC',
          title: 'Size C',
          type: 'text',
          required: false
        }
      },
      {
        default: updateComunsAccordingToSections(noOfSections),
        xs: 12,
        field: {
          id: 'hudA',
          title: 'HUD #A',
          type: 'text',
          required: false
        }
      },
      {
        default: updateComunsAccordingToSections(noOfSections),
        xs: 12,
        field: {
          id: 'hudB',
          title: 'HUD #B',
          type: 'text',
          required: false
        }
      },
      {
        default: updateComunsAccordingToSections(noOfSections),
        xs: 12,
        field: {
          id: 'hudC',
          title: 'HUD #C',
          type: 'text',
          required: false
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'type',
          title: 'Type',
          type: 'dropdown',
          options: [],
          required: true
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'year',
          title: 'Year',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'windZone',
          title: 'Wind Zone',
          type: 'text',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'exteriorSiding',
          title: 'Exterior Siding',
          type: 'dropdown',
          options: [],
          required: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'invoiceDate',
          title: 'Invoice Date',
          type: 'text',
          variant: 'date',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'invoice',
          title: 'Invoice',
          type: 'number',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'floorplanFinanceAmount',
          title: 'Floorplan Finance Amount',
          type: 'number',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'bedRooms',
          title: 'Bedrooms',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'bathRooms',
          title: 'Bathrooms',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          options: [],
          required: false,
          title: 'Floorplan Lender',
          type: 'dropdown',
          id: 'floorplanLender'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          options: ['No', 'Yes'],
          required: true,
          title: 'Lot Set',
          type: 'dropdown',
          id: 'lotSet'
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'typeOfUnit',
          title: 'Type of Unit',
          type: 'dropdown',
          required: true
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'weightA',
          title: 'Weight A',
          type: 'text',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'weightB',
          title: 'Weight B',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'sqFt',
          title: 'Sq Ft',
          type: 'number',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'thermalZone',
          title: 'Thermal Zone',
          type: 'text',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'roofLoad',
          title: 'Roof Load',
          type: 'text',
          required: false,
          value: '20 PSF'
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'floorRValues',
          title: 'Floor R Values',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'wallRValues',
          title: 'Walls R Values',
          type: 'text',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'ceilingRValues',
          title: 'Ceiling R Values',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'floorThickness',
          title: 'Floor Thickness',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'wallThickness',
          title: 'Walls Thickness',
          type: 'text',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'ceilingThickness',
          title: 'Ceiling Thickness',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'height',
          title: 'Height',
          type: 'number',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'maxSalesPrice',
          title: 'Max Sales Price',
          type: 'number',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'furnaceManufacturer',
          title: 'Furnace Manufacturer',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'furnaceModelNumber',
          title: 'Furnace Model Number',
          type: 'text',
          required: false
        }
      },
      {
        default: 4,
        xs: 12,
        field: {
          id: 'btuPerHour',
          title: 'B.T.U/hr',
          type: 'number',
          required: false
        }
      }
    ]
  }
];

export const formJson = (numberOfSections, onNumberOfSectionsChange) => ({
  forms: [
    {
      name: 'addInventory',
      hideFormName: true,
      markCompulsoryFields: true,
      compact: true,
      submit: {
        name: 'Add To Inventory',
        show: false,
        onSubmit: 'onInventoryFormSubmit'
      },
      rows: [...headerRow('Add New Inventory'), ...fieldRows(true, null, numberOfSections, onNumberOfSectionsChange)]
    }
  ]
});

export const editFormJson = (fromPipeline = false, noOfSections, onNumberOfSectionsChange) => {
  return {
    forms: [
      {
        name: 'update',
        hideFormName: true,
        markCompulsoryFields: true,
        compact: true,
        submit: {
          name: 'Update Record',
          show: fromPipeline,
          onSubmit: 'onInventoryEditFormSubmit'
        },
        rows: [
          ...(fromPipeline ? [] : headerRow('Update Inventory')),
          ...fieldRows(false, fromPipeline, noOfSections, onNumberOfSectionsChange)
        ]
      }
    ]
  };
};

//used during setup
export const addChoicesToInventoryForm = ({ appChoices, form }) => {
  const requiredChoices = [
    { appChoicesKey: 'salesLocation', formKey: 'unitLocation', labelKey: 'location' },
    { appChoicesKey: 'lender', formKey: 'lender', labelKey: 'lienholderCorporateName' },
    { appChoicesKey: 'floorplanLender', formKey: 'floorplanLender' },
    { appChoicesKey: 'Type', formKey: 'type' },
    { appChoicesKey: 'Exterior Siding', formKey: 'exteriorSiding' },
    { appChoicesKey: 'manufacturer', formKey: 'manufacturer', labelKey: 'factoryShortName' },
    { appChoicesKey: 'Type Of Unit', formKey: 'typeOfUnit' }
  ];

  requiredChoices.forEach(requiredChoice => {
    const choice = appChoices.find(a => a.key === requiredChoice.appChoicesKey);

    if (choice && choice.values.length > 0 && typeof choice.values[0] === 'object') {
      setAttribute(
        form,
        requiredChoice.formKey,
        'options',
        choice.values.map(c => c[requiredChoice.labelKey || 'name'])
      );
      setAttribute(form, requiredChoice.formKey, 'optionValues', choice.values.map(c => c._id));
      return;
    }

    setAttribute(form, requiredChoice.formKey, 'options', choice ? choice.values : []);
  });
};

//used after submit
export const validateAndCleanupForm = submittedForm => {
  const sizes = ['sizeA', 'sizeB', 'sizeC'];
  for (const size of sizes) {
    if (size !== 'Size A' && !submittedForm[size]) {
      continue;
    }

    const split = submittedForm[size].split('x').map(a => Number(a.trim()));
    if (split.length != 2 || split.includes(NaN)) {
      highlightError(document.getElementById(size), 'Please enter size in the format of a x b');
      return;
    }
  }

  //for additional dochub fields created by user
  const additionalFields = Object.keys(submittedForm).filter(k => k.endsWith('-additionalDochubField'));
  submittedForm['additionalFields'] = additionalFields.reduce(
    (acc, key) => ({
      ...acc,
      [key.replace('-additionalDochubField', '')]: submittedForm[key]
    }),
    {}
  );

  return true;
};
