import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player/lib';
import CircularProgressBar from './circular-progress';

const VideoPlayer = ({ onPlayerClose, videoLinks = [], openVideoplayer, title }) => {
  const [videoLoading, setVideoLoading] = useState(true);
  const [activeVideoTab, setActiveVideoTab] = useState(null);

  useEffect(() => {
    setActiveVideoTab(videoLinks[0]);
  }, [videoLinks]);

  return (
    <>
      <Modal show={openVideoplayer !== null} onHide={onPlayerClose}>
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body>
          {videoLinks.length > 1 &&
            videoLinks.map((videoLink, index) => {
              return (
                <Button
                  onClick={() => setActiveVideoTab(videoLinks[index])}
                  key={videoLink}
                  size="sm"
                  className="mx-1"
                  variant={activeVideoTab === videoLink ? 'primary' : 'secondary'}
                >
                  Video {index + 1}
                </Button>
              );
            })}
          {videoLoading && (
            <div className="d-flex justify-content-center align-items-center">
              <CircularProgressBar size={3} />
            </div>
          )}

          <ReactPlayer
            onReady={() => setVideoLoading(false)}
            url={activeVideoTab}
            controls
            height={380}
            width={'100%'}
            style={{ margin: '5px auto' }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VideoPlayer;
