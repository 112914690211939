import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { CheckCircle } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../components/Header';
import Loader from '../components/Loader';
import ContractSearchItem from '../components/main/ContractSearchItem';
import WalkThroughCheckList from '../components/ops/WalkThroughCheckList';
import { makeApiRequests } from '../helpers/api';
import { ENDPOINTS } from '../helpers/constants';

const WalkthroughConfirmation = () => {
  const { uuid } = useParams();
  const [formLoading, setFormLoading] = useState(true);
  const [contract, setContract] = useState();
  const [walkthroughConfirmed, setWalkThroughConfirmed] = useState(false);
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const loadContractData = async () => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH,
      requestBody: {
        filter: {
          uuid
        }
      }
    });

    setFormLoading(false);

    if (error) {
      return toast.error(error);
    }
    if (response.length === 0) {
      return toast.error('No contract found!');
    }

    const details = response[0];
    if (details['walkthroughStatus'] === 'Client Approved') {
      setWalkThroughConfirmed(true);
    }

    setContract(details);
  };

  useEffect(() => {
    loadContractData();
  }, []);

  const ConfirmedLayout = () => {
    return (
      <Row className="justify-content-center text-center h-100">
        <Col xs={12} className="px-3 align-self-center">
          <CheckCircle className="text-success" size={50} />
          <h2 className="my-4">
            <b>Thank You!</b>
          </h2>
          <h5>Your walkthrough checklist has been confirmed. We will get back to you as soon as possible</h5>
        </Col>
      </Row>
    );
  };

  const confirmCheckList = async () => {
    setDisableConfirmButton(true);

    const { response, error } = await makeApiRequests({
      method: 'GET',
      endpoint: ENDPOINTS.CONTRACTS_APPROVE_WALKTHROUGH(contract.uuid)
    });
    setDisableConfirmButton(false);
    if (error) {
      return toast.error(error);
    }
    
    setWalkThroughConfirmed(true);
  };

  return (
    <>
      <Header noMenu />
      <Container fluid className="py-2 px-md-5 h-100">
        {formLoading || !contract ? (
          <Loader />
        ) : walkthroughConfirmed ? (
          <ConfirmedLayout />
        ) : (
          <>
            <h6 className="text-muted my-2">Hi, Please confirm the walkthrough checklist below:</h6>
            <hr />
            <ContractSearchItem contract={contract} fromOpsDetails />
            <Card>
              <Card.Body>
                <WalkThroughCheckList
                  contract={contract}
                  fromConfirmation
                  confirmCheckList={confirmCheckList}
                  disableSubmitButton={disableConfirmButton}
                />
              </Card.Body>
            </Card>
          </>
        )}
      </Container>
    </>
  );
};

export default WalkthroughConfirmation;
