import React from 'react';
import { Button } from 'react-bootstrap';
import ContractSearchItem from '../main/ContractSearchItem';

const NoContractedBuyer = ({ onAddContractClick }) => {
  return (
    <div className="text-center ">
      <h6 className="mb-0 smallFont">
        This unit has not been contracted to any buyer. Tap on the button below to contract a buyer:
      </h6>
      <h6 className="smallFont text-muted">
        <b>*Contracting a buyer will remove all of its potential buyers.</b>
      </h6>
      <Button className="tinyFont" variant="success" size="sm" onClick={onAddContractClick}>
        Add contract
      </Button>
    </div>
  );
};

const ContractedBuyerOverview = ({
  contract,
  onAddContractClick,
  onRemoveContractClick,
  onChangeContractClick,
  activeContractId,
  onContractSelect
}) => {
  return (
    <>
      <h6 className="mb-2">Contracted Buyer</h6>

      <div className="border rounded p-3 mb-2">
        {contract ? (
          <ContractSearchItem
            key={contract['_id']}
            contract={contract}
            fromPicker
            onRemoveClick={onRemoveContractClick}
            onChangeClick={onChangeContractClick}
            onContactClick={onContractSelect}
            selected={activeContractId === contract['_id']}
            mini
          />
        ) : (
          <NoContractedBuyer onAddContractClick={onAddContractClick} />
        )}
      </div>
    </>
  );
};

export default ContractedBuyerOverview;
