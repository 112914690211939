import React from 'react';
import { Badge } from 'react-bootstrap';

const dividedRows = (tabs, numOfRows) => {
  if (numOfRows === 1) return [tabs];

  const tempTabs = [...tabs];
  let result = [];
  for (let i = numOfRows; i > 0; i--) {
    result.push(tempTabs.splice(0, Math.ceil(tempTabs.length / i)));
  }
  return result;
};

const Tabs = ({ tabs = [], onTabSelect, activeTab, scrollable, numOfScrollableRows = 1 }) => {
  return scrollable ? (
    <div style={{ whiteSpace: 'nowrap', overflowX: 'scroll' }} className="my-2">
      {dividedRows(tabs, numOfScrollableRows).map(row => (
        <div className="mb-1">
          {row.map(t => (
            <Badge
              className="hover-dark p-2 mr-1"
              onClick={() => onTabSelect(t)}
              key={t}
              variant={activeTab === t ? 'info' : 'primary'}
            >
              {t}
            </Badge>
          ))}
        </div>
      ))}
    </div>
  ) : (
    <div className="my-2">
      {tabs.map(t => (
        <Badge
          className="hover-dark p-2 mr-1"
          onClick={() => onTabSelect(t)}
          key={t}
          variant={activeTab === t ? 'info' : 'primary'}
        >
          {t}
        </Badge>
      ))}
    </div>
  );
};

export default Tabs;
