import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Modal, ProgressBar, Row } from 'react-bootstrap';
import { Files, Pen, Trash } from 'react-bootstrap-icons';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormGenerator from '../../form-generator/FormGenerator';
import { findDifferenceInObjects } from '../../form-generator/helpers/utility';
import { deleteFileFromServer, makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { addDocHubFormFieldsToForm, getObjectForPrefill } from '../../helpers/formHelpers';
import AlertModal from '../AlertModal';
import Comments from '../comments/Comments';
import File from '../common/file';
import FileViewer from '../common/FileViewer';
import SubmitButtonRow from '../common/SubmitButtonRow';
import Loader from '../Loader';
import NotFound from '../NotFound';
import { addChoicesToInventoryForm, editFormJson, validateAndCleanupForm } from './form';
import { fillSizesInInventory } from '../../helpers/global';

const EditInventory = ({ inventoryFromSearch, appChoices, fromSearch, onInventoryEdit, onInventoryDelete }) => {
  const { id } = useParams();
  const [inventoryId] = useState(inventoryFromSearch ? inventoryFromSearch._id : id);
  const [form, setForm] = useState(null);

  const [isFormLoading, setFormLoading] = useState(!fromSearch);
  const [numberOfSections, setNumberOfSections] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [inventoryError, setInventoryError] = useState('');
  const [formDisabled, setFormDisabled] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [inventoryDeleting, setInventoryDeleting] = useState(false);
  const [inventory, setInventory] = useState();
  const [commentText, setCommentText] = useState('');
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deleteFileModalMeta, setDeleteFileModalMeta] = useState(null);
  const [deleteCommentModalMeta, setDeleteCommentModalMeta] = useState(null);
  const role = useMemo(() => localStorage.getItem('user-role'), []);

  const history = useHistory();

  const fetchUnit = async () => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.INVENTORY_SEARCH,
      requestBody: {
        filter: {
          _id: inventoryId
        }
      }
    });

    setFormLoading(false);
    if (error) {
      return setInventoryError(error);
    }

    if (response.length === 0) {
      return setInventoryError('Inventory unit not found!');
    }

    updateInventoryState(response[0]);
  };

  useEffect(() => {
    if (!inventoryFromSearch) {
      fetchUnit();
    } else {
      updateInventoryState(inventoryFromSearch);
    }
  }, []);

  useEffect(() => {
    setUpForm();
    fillSizesInInventory(inventory?.sizeOverall || '', numberOfSections);
  }, [numberOfSections]);

  useEffect(() => {
    if (inventory) {
      onNumberOfSectionsChange(inventory?.numberOfSections);
    }
  }, [inventory]);

  const onNumberOfSectionsChange = number => {
    setNumberOfSections(Number(number));
  };

  const setUpForm = () => {
    const form = editFormJson(false, numberOfSections, onNumberOfSectionsChange);
    const newFormJson = addDocHubFormFieldsToForm({ formJson: form, appChoices, model: 'Inventory' });
    console.log({ numberOfSections, form, newFormJson });

    addChoicesToInventoryForm({ appChoices, form: newFormJson });
    setForm(newFormJson);
  };

  const updateInventoryState = inventory => {
    setInventory(inventory);
    setFormLoading(false);
  };

  const onInventoryEditFormSubmit = async form => {
    if (uploadedFiles.some(f => f.status === 'ERROR')) {
      return toast.error('One or more files have failed uploading, please discard these files and try again!');
    }

    if (uploadedFiles.some(f => f.status !== 'UPLOADED')) {
      return toast.error('Some of the files are still uploading to the server, please try again in a moment!');
    }

    //checks
    const valid = validateAndCleanupForm(form);
    if (!valid) return;

    form['files'] = [...inventory.files.map(f => f._id), ...uploadedFiles.map(f => f.id)];
    form.numberOfSections = Number(form?.numberOfSections);

    let allBValues = ['sizeB', 'serialB', 'hudB'];
    let allAValues = ['sizeA', 'serialA', 'hudA'];
    let allCValues = ['sizeC', 'serialC', 'hudC'];

    [...(numberOfSections === 1 ? allBValues : []), ...(numberOfSections <= 2 ? allCValues : [])].forEach(id => {
      form[id] = null;
    });

    setButtonsDisabled(true);

    toast.info('Please wait, processing inventory..');

    const { response, error } = await makeApiRequests({
      requestBody: findDifferenceInObjects(form, inventory, ['_id']),
      method: 'PUT',
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(inventory._id)
    });

    setButtonsDisabled(false);

    if (error) {
      toast.error(error);
      return;
    }

    toast(`Inventory updated successfully!`, {
      type: 'success'
    });

    if (onInventoryEdit) {
      setFormDisabled(true);
      onInventoryEdit && onInventoryEdit(response);
    } else {
      setFormLoading(true);
      setTimeout(() => setFormLoading(false), 100);
    }
    updateInventoryState(response);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  const deleteInventory = async () => {
    setInventoryDeleting(true);

    const { response, error } = await makeApiRequests({
      method: 'DELETE',
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(inventoryId)
    });

    setInventoryDeleting(false);

    if (error) {
      toast.error(error);
      return;
    }

    toast(`Inventory deleted successfully!`, {
      type: 'success'
    });

    if (fromSearch) {
      setShowDeleteModal(false);
      onInventoryDelete && onInventoryDelete(inventoryId);
    } else {
      history.push('/pipeline/inventory');
    }
  };

  const DeleteModal = () => {
    return (
      <Modal show={showDeleteModal} onHide={handleClose} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>Delete Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {inventory && inventory['contact'] && inventory['contact'].length > 0 && (
            <>
              This inventory is a potential unit for buyers{' '}
              <b>{inventory['contact'].map(c => c['Buyer']).join(', ')}. </b>
              Deleting it will remove it as a potential unit from all the buyers.{' '}
            </>
          )}
          {inventory && inventory['contract'] && (
            <>
              This inventory has already been contracted with the buyer <b>{inventory['contract']['Buyer']}. </b>
              Deleting it will remove it from the contracted buyer.{' '}
            </>
          )}
          Are you sure you want to delete this inventory? This action cannot be undone!
          {inventoryDeleting && <ProgressBar animated now={100} label="Deleting inventory..." />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={inventoryDeleting}>
            No
          </Button>
          <Button variant="danger" onClick={deleteInventory} disabled={inventoryDeleting}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  window['onInventoryEditFormSubmit'] = onInventoryEditFormSubmit;

  if (inventoryError) {
    return <NotFound text={inventoryError} />;
  }

  const submitComment = async () => {
    toast.info('Adding comment...');
    setButtonsDisabled(true);

    const { response, error } = await makeApiRequests({
      requestBody: {
        comments: commentText
      },
      method: 'PUT',
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(inventory._id)
    });

    setButtonsDisabled(false);

    if (error) {
      return toast.error(error);
    }

    toast.success(`Comment added successfully!`);
    setCommentText('');
    updateInventoryState(response);
    onInventoryEdit && onInventoryEdit(response);
  };

  const onCommentDeleteClick = comment => {
    setDeleteCommentModalMeta({ comment });
  };

  const deleteComment = async () => {
    const commentToDelete = deleteCommentModalMeta.comment._id;
    setDeleteCommentModalMeta({ ...deleteCommentModalMeta, showProgress: true });

    const { response, error } = await makeApiRequests({
      requestBody: {
        commentId: commentToDelete
      },
      method: 'POST',
      endpoint: ENDPOINTS.INVENTORY_DELETE_COMMENT(inventory._id)
    });

    setDeleteCommentModalMeta({ ...deleteCommentModalMeta, showProgress: false });

    if (error) {
      return toast.error(error);
    }

    setDeleteCommentModalMeta(null);
    toast.success(`Comment deleted successfully!`);
    updateInventoryState(response);
    onInventoryEdit && onInventoryEdit(response);
  };

  const onFileDeleteClick = file => {
    setDeleteFileModalMeta({ file });
  };

  const deleteFile = async () => {
    const { file: toBeDeletedFile } = deleteFileModalMeta;
    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: true });

    const newFileArray = inventory.files.filter(f => f._id !== toBeDeletedFile._id).map(f => f._id);

    const { response, error } = await makeApiRequests({
      requestBody: { files: newFileArray },
      endpoint: ENDPOINTS.INVENTORY_WITH_ID(inventory._id),
      method: 'PUT'
    });

    setDeleteFileModalMeta({ ...deleteFileModalMeta, deletingFile: false });
    if (error) {
      return toast.error(error);
    }

    setDeleteFileModalMeta(null);
    updateInventoryState(response);
    onInventoryEdit && onInventoryEdit(response, true);
    deleteFileFromServer(toBeDeletedFile);
  };

  return (
    <>
      {isFormLoading && <Loader />}
      {!isFormLoading && form && inventory && (
        <>
          <Row>
            <Col className="text-md-right mt-2 mt-md-0">
              <Button onClick={() => setShowFileViewer(true)} variant="outline-dark" size="sm" className="py-1 ml-2">
                <Files className="align-text-top mr-2" />
                View Files
              </Button>

              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => setFormDisabled(false)}
                className=" py-1 ml-2"
                disabled={!formDisabled || buttonsDisabled}
              >
                <Pen className="align-text-top mr-2" />
                {formDisabled ? 'Edit' : 'Edit Mode Active'}
              </Button>

              <Button
                size="sm"
                variant="outline-danger"
                onClick={() => setShowDeleteModal(true)}
                className="py-1 ml-2"
                disabled={inventoryDeleting || buttonsDisabled}
              >
                <Trash className="align-text-top mr-2" />
                Delete
              </Button>
              {DeleteModal()}
            </Col>
          </Row>
          <hr />

          <FormGenerator
            formJson={form}
            formValues={{ update: getObjectForPrefill(inventory) }}
            formDisabled={formDisabled}
          />
          {!formDisabled && (
            <>
              <File uploadedFiles={uploadedFiles} onUploadedFilesChange={setUploadedFiles} containerClassName="mx-3" />
              <SubmitButtonRow
                bottom
                disabled={buttonsDisabled}
                showGoToDocHub={false}
                onSaveClick={() => document.getElementById('update').click()}
              />
            </>
          )}

          <hr className="my-2" />

          <Comments
            comments={inventory['comments']}
            disabled={buttonsDisabled}
            onCommentSubmit={submitComment}
            commentText={commentText}
            onCommentTextChange={setCommentText}
            onCommentDeleteClick={onCommentDeleteClick}
          />
          <FileViewer
            sidebarZIndex={2000}
            show={showFileViewer}
            onHide={() => setShowFileViewer(false)}
            files={inventory['files']}
            driveFolder={inventory['driveFolder']}
            onFileDeleteClick={onFileDeleteClick}
          />
          <AlertModal
            show={deleteFileModalMeta !== null}
            alertText={`Are you sure to delete this file (${
              deleteFileModalMeta
                ? deleteFileModalMeta.file.metadata && deleteFileModalMeta.file.metadata.originalName
                  ? deleteFileModalMeta.file.metadata.originalName
                  : deleteFileModalMeta.file.filename
                : ''
            }). You will lose access to this file permanently?`}
            showProgress={deleteFileModalMeta && deleteFileModalMeta.deletingFile}
            progressText="Deleting file..."
            continueButtonText="Delete"
            onDismissClick={() => setDeleteFileModalMeta(null)}
            onHide={() => setDeleteFileModalMeta(null)}
            onContinueClick={deleteFile}
          />
          {role === 'super admin' && (
            <AlertModal
              show={deleteCommentModalMeta !== null}
              alertText="Are you sure to remove this comment?"
              showProgress={deleteCommentModalMeta?.showProgress}
              progressText="Deleting comment..."
              onDismissClick={() => setDeleteCommentModalMeta(null)}
              onHide={() => setDeleteCommentModalMeta(null)}
              onContinueClick={deleteComment}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditInventory;
