import React from 'react';
import { Card } from 'react-bootstrap';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import CircularProgressBar from '../common/circular-progress';
import TextButton from '../TextButton';

const PipelineLoaderCard = ({ title, loadingError, onRetryClick }) => {
  return (
    <>
      <Card.Header className="bg-primary text-white px-2 py-2 smallFont">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <h6 className="mb-0 d-inline-block midFont hover-underline">{title}</h6>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-2 text-center">
        {loadingError ? (
          <>
            <ExclamationTriangle className="text-danger" size={24} />
            <TextButton
              fontSize="smallFont"
              className="my-2"
              onClick={onRetryClick}
              variant="dark"
              text={'Something went wrong, click here try loading again!'}
            />
          </>
        ) : (
          <CircularProgressBar />
        )}
      </Card.Body>
    </>
  );
};

export default PipelineLoaderCard;
