import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import EditInventory from './EditInventory';

const InventoryDetails = ({ appChoices }) => {
  const { id } = useParams();

  return (
    <Container fluid className="py-2 px-md-4">
      <Card>
        <Card.Body>
          <EditInventory id={id} appChoices={appChoices} />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default InventoryDetails;
