import React from 'react';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import CreateCrm from './CreateCrm';

const CreateSideBar = ({ show, onHide, appChoices, onContactAdd, prefillContact }) => {
  return (
    <SlidingSideBar fullScreen visible={show} onClose={onHide} title={'New Contact'}>
      {show && <CreateCrm appChoices={appChoices} onContactAdd={onContactAdd} preFillContact={prefillContact} />}
    </SlidingSideBar>
  );
};

export default CreateSideBar;
