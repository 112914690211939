import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import DropDownInput from '../../form-generator/components/DropDownInput';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, isMicasa, months } from '../../helpers/constants';
import Loader from '../Loader';
import PnlEditSidebar from '../main/PnlEditSidebar';

const getYears = () => {
  const nowYear = new Date().getFullYear();
  const years = [nowYear];

  for (let index = 1; index <= 5; index++) {
    years.push(nowYear - index);
  }

  return years.reverse();
};

const totalGPMinuendGroup = [
  { label: 'SP / Comm', key: 'salesPrice' },
  { label: 'Rebate', key: 'amountRebateReceived' },
  { label: 'Bon/pts', staticValue: 499 }
];

const totalGPSubtrahendGroup = appChoices => [
  { label: 'Invoice', key: 'invoice' },
  ...(appChoices
    .find(a => a.key === 'Commission Options')
    ?.values?.map(v => ({ label: v.name, key: v.name, isPnLOption: true })) || []),
  { label: 'Comm.', key: 'commissions' }
];

const getHeaders = appChoices => [
  { label: 'Buyer', key: 'buyer' },
  ...totalGPSubtrahendGroup(appChoices),
  ...totalGPMinuendGroup,
  { label: 'TOTAL GP' },
  { label: 'Lender', key: 'lender', isObject: true, valueKey: 'lienHolderCorporateName' },
  { label: 'Salesperson', key: 'salesperson' },
  { label: 'Manufacturer', key: 'manufacturer', isObject: true },
  { label: 'Wind Zone', key: 'windZone' }
];

const calculateTotalGP = (contract, appChoices) => {
  const totalGPMinuend = totalGPMinuendGroup.reduce((prev, { key, staticValue }) => {
    let value = staticValue || contract[key] || 0;

    return prev + value;
  }, 0);

  const totalGPSubtrahend = totalGPSubtrahendGroup(appChoices).reduce((prev, { key, staticValue, isPnLOption }) => {
    let value;
    if (key === 'commissions') {
      value = calculateCommission(contract);
    } else {
      if (isPnLOption) {
        value = getPnlValue(contract, key);
      } else {
        value = staticValue || contract[key] || 0;
      }
    }

    return prev + value;
  }, 0);

  return totalGPMinuend - totalGPSubtrahend;
};

const getOptionsValue = (contract, contractKey) => {
  return contract[contractKey]?.reduce((prevValue, keyValue) => prevValue + Number(keyValue.value), 0) || 0;
};

const calculateCommission = contract => {
  const salesPrice = contract['salesPrice'] ? Number(contract['salesPrice']) : 0;

  const netCommTotalCosts = getOptionsValue(contract, 'commissionSheetLessOptions');
  const netCommissions = salesPrice - netCommTotalCosts;

  const optionsTotal = getOptionsValue(contract, 'commissionSheetOptions');
  const grossProfit = netCommissions - optionsTotal;

  let commissionDue;
  if (isMicasa) {
    const gmCommission = 0.1 * grossProfit;
    const smCommission = 0.05 * grossProfit;
    const profitAfterMgrPay = grossProfit - gmCommission - smCommission;
    commissionDue = profitAfterMgrPay >= 10000 ? 0.25 * profitAfterMgrPay : 0.2 * profitAfterMgrPay;
  } else {
    commissionDue = grossProfit / 2;
  }

  return commissionDue;
};

const getPnlValue = (contract, key) => {
  //first look in pnl
  if (contract['pnlOptions']) {
    const keyValue = contract['pnlOptions'].find(o => o.key === key);
    if (keyValue) {
      return Number(keyValue.value);
    }
  }

  //if not in pnl use commissionSheetOptions
  const value = contract['commissionSheetOptions']?.find(o => o.key === key)?.value;
  return value ? Number(value) : 0;
};

const PnLRow = ({ contract, headers = [], onEditClick, appChoices }) => {
  return (
    <tr>
      {headers.map(({ label, key, isObject, staticValue, valueKey = 'name', isPnLOption }) => {
        if (label === 'TOTAL GP')
          return (
            <td>
              <b>${calculateTotalGP(contract, appChoices).toFixed(2)}</b>
            </td>
          );

        if (label === 'Comm.') return <td>${calculateCommission(contract).toFixed(2)}</td>;

        let value = isPnLOption ? getPnlValue(contract, key) : contract[key];

        if (staticValue) {
          value = staticValue;
        }

        if (isObject) {
          value = contract[key]?.[valueKey];
        }

        return <td>{typeof value === 'number' ? `${value.toFixed(2)}` : value || '-'}</td>;
      })}
      <td>
        <Pencil className="hover-light" onClick={() => onEditClick(contract)} />
      </td>
    </tr>
  );
};

const SelectionBox = ({
  selectedMonth,
  onMonthChange,
  selectedYear,
  onYearChange,
  years,
  salesLocations,
  selectedSalesLocation,
  onSalesLocationChange
}) => {
  return (
    <>
      <Card className="border rounded">
        <Card.Header className="bg-primary">
          <h5 className="mb-0 text-white">Dealboard (PnL)</h5>
        </Card.Header>
        <Card.Body className="py-3">
          <Row>
            {[
              {
                title: 'Sales Locations',
                options: salesLocations.map(s => s.location),
                optionValues: salesLocations.map(s => s._id),
                value: selectedSalesLocation,
                onChange: onSalesLocationChange
              },
              { title: 'Select Month', options: months, value: selectedMonth, onChange: onMonthChange },
              { title: 'Select Year', options: years, value: selectedYear, onChange: onYearChange }
            ].map(({ title, options, optionValues, value, onChange }) => (
              <Col xs={12} md={4} className="px-4 mt-3">
                <h6 className="mb-2">{title}</h6>
                <DropDownInput
                  id="monthSelect"
                  value={value}
                  size="sm"
                  options={options}
                  optionValues={optionValues}
                  onChangeFunction={e => e && onChange(e)}
                  required
                />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

const mapUnitToContract = c => {
  const { inventoryUnit } = c;
  if (!inventoryUnit) return c;

  inventoryUnit['inventoryId'] = inventoryUnit['_id'];
  delete inventoryUnit['_id'];
  delete inventoryUnit['createdAt'];

  const newContract = { ...c, ...inventoryUnit };

  inventoryUnit['_id'] = inventoryUnit['inventoryId'];
  return newContract;
};

const PnL = ({ appChoices }) => {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [years] = useState(getYears());
  const [selectedYear, setSelectedYear] = useState(years[years.length - 1]);
  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [salesLocations] = useState(appChoices.find(ac => ac.key === 'salesLocation').values);
  const [selectedSalesLocation, setSelectedSalesLocation] = useState(salesLocations[0]._id);
  const [editingContract, setEditingContract] = useState(null);
  const [headers] = useState(getHeaders(appChoices));

  const fetchContracts = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CONTRACTS_SEARCH,
      requestBody: {
        filter: {
          dateOfDeposit: { $ne: null },
          inventoryUnit: { $ne: null }
        },
        sort: '-dateOfDeposit'
      }
    });

    setLoading(false);
    if (error) {
      return toast.error(error);
    }

    setContracts(response.filter(c => c['salesLocation']).map(mapUnitToContract));
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const filterContracts = () => {
    const dateType = 'dateOfDeposit';

    const tempContracts = contracts.filter(contract => {
      if (contract.salesLocation._id !== selectedSalesLocation) return false;

      const month = months.indexOf(selectedMonth);
      const dateToFilter = new Date(contract[dateType]);
      return month == dateToFilter.getMonth() && selectedYear == dateToFilter.getFullYear();
    });

    setFilteredContracts(tempContracts);
  };

  useEffect(() => {
    if (loading) {
      return;
    }

    filterContracts();
  }, [contracts, selectedYear, selectedMonth, selectedSalesLocation]);

  const onYearChange = e => {
    setSelectedYear(e.target.value);
  };

  const onMonthChange = e => {
    setSelectedMonth(e.target.value);
  };

  const onSalesLocationChange = e => {
    setSelectedSalesLocation(e.target.value);
  };

  const updateContractList = newContract => {
    const existingIndex = contracts.findIndex(c => c._id === newContract._id);
    if (existingIndex !== -1) {
      contracts[existingIndex] = { ...contracts[existingIndex], ...mapUnitToContract(newContract) };
      setContracts([...contracts]);
    }

    setEditingContract(null);
  };

  return (
    <div className="px-0 px-md-5 py-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          <SelectionBox
            years={years}
            selectedMonth={selectedMonth}
            onMonthChange={onMonthChange}
            selectedYear={selectedYear}
            onYearChange={onYearChange}
            salesLocations={salesLocations}
            selectedSalesLocation={selectedSalesLocation}
            onSalesLocationChange={onSalesLocationChange}
          />
          <hr />

          <h6>PnL:</h6>
          <Table style={{ fontSize: 12 }} responsive bordered className="text-center">
            <tr className="bg-dark text-light">
              {headers.map(h => (
                <th style={{ minWidth: 80 }} key={h.label}>
                  {h.label}
                </th>
              ))}
              <th style={{ minWidth: 80 }}>Action</th>
            </tr>
            {filteredContracts.length > 0 ? (
              <>
                {filteredContracts.map(c => (
                  <PnLRow
                    contract={c}
                    key={c._id}
                    headers={headers}
                    onEditClick={contract => setEditingContract(contract)}
                    appChoices={appChoices}
                  />
                ))}
                {/* TOTAL GP */}
                <tr>
                  <td className="text-right" colSpan={headers.findIndex(h => h.label === 'TOTAL GP')}>
                    TOTAL GP
                  </td>
                  <td>
                    <b>
                      ${filteredContracts.reduce((prev, c) => prev + calculateTotalGP(c, appChoices), 0).toFixed(2)}
                    </b>
                  </td>
                </tr>
                {/* AVERAGE GP */}
                <tr>
                  <td className="text-right" colSpan={headers.findIndex(h => h.label === 'TOTAL GP')}>
                    AVERAGE GP
                  </td>
                  <td>
                    <b>
                      $
                      {(
                        filteredContracts.reduce((prev, c) => prev + calculateTotalGP(c, appChoices), 0) /
                        filteredContracts.length
                      ).toFixed(2)}
                    </b>
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={headers.length + 1}>No contracts to show</td>
              </tr>
            )}
          </Table>
          <h6 className="text-right text-muted">
            Total Reports: <span className="text-dark">{filteredContracts.length}</span>
          </h6>
          {filteredContracts.length > 0 && (
            <PnlEditSidebar
              show={editingContract !== null}
              appChoices={appChoices}
              contract={editingContract}
              onContractEdit={updateContractList}
              onHide={() => setEditingContract(null)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PnL;
